import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiDelete, apiGet, apiPost, apiPut, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { clearMyStore, getAllCustomerStores, getMarketPricingData, getOrderStatusCountSuccess, getStoreDashboardDataSuccess, setCustomerStores, setMyStore, setMyStoresIsRefreshTokenValid, setSelectedMarketPricingData, setSelectedMarketWarehouseSettings, setSelectedStore, setSelectedStoreFilter } from '../actions/store.actions';
import { defaultBuyboxStrategy } from '../../utils/constants';
import { setMarketSnapshotMeta } from '../actions/create-store.actions';
import { setCustomerInfoAuthorization } from '../actions/customer.actions';
import i18next from 'i18next';
import { createSmartPricerStrategy } from '../actions/smartpricer.actions';
import Swal from 'sweetalert2';
import { store } from '../configureStore';
import { withActionPromise } from '../actions/app.actions';

const { REACT_APP_ROLES_USER } = process.env;

function* runGetCustomerMarkets(action) {
    const payload = action?.payload;
    const response = yield call(apiGet(``, CONTROLLERS.CUSTOMERMAINSELLER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        var selectedStoreMarketId = payload || (response.data && response.data.length > 0 ? response.data[response.data.length - 1].marketId : null);
        yield all([
            put(setCustomerStores(response.data)),
            put(setSelectedStore(response.data.find(x => x.marketId === selectedStoreMarketId)))
        ]);
    }
}


function* runGetCustomerStore(action) {
    const payload = action.payload;

    const path = `/GetCustomerMainSellerByMarketId?marketId=${payload.marketId}&customerMainID=${payload.customerId}`;
    // /GetCustomerMainSellerByMarketId?marketId=${payload.marketId}&customerMainID=${payload.customerId}

    const response = yield call(apiGet(path, CONTROLLERS.CUSTOMERMAINSELLER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setMyStore(response.data.find(s => s.id === payload.customerMainSellerId)));
    }
}

function* runCreateCustomerStore(action) {
    const payload = action.payload;

    var customer = store.getState().customer;
    var customerSettings = store.getState().settings;

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.CUSTOMERMAINSELLER, true, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {

        var templateBuyboxStrategy = defaultBuyboxStrategy;
        templateBuyboxStrategy.customerMainSellerId = response.data.id;

        //user
        if (customer.customerInfo?.role?.includes(REACT_APP_ROLES_USER)) {
            if (response.data.roleId && response.data.id) {
                yield all([
                    put(setMarketSnapshotMeta({ "customerStoreSubmitted": true })),
                    put(setCustomerInfoAuthorization([{
                        customerMainSellerId: response.data.id,
                        roleIds: [response.data.roleId]
                    }])),

                    put(withActionPromise(getAllCustomerStores(customerSettings?.lastVisitedMarketId || ""))),
                    put(createSmartPricerStrategy(templateBuyboxStrategy))
                ]);
            } else {
                yield all([
                    put(setMarketSnapshotMeta({ "customerStoreSubmitted": true })),
                    put(withActionPromise(getAllCustomerStores(customerSettings?.lastVisitedMarketId || ""))),
                    put(createSmartPricerStrategy(templateBuyboxStrategy))
                ]);
            }

        }
        else {
            yield all([
                put(setMarketSnapshotMeta({ "customerStoreSubmitted": true })),
                put(withActionPromise(getAllCustomerStores(customerSettings?.lastVisitedMarketId || "")))
            ]);
        }

    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error33') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateCustomerStore(action) {
    const payload = action.payload;
    const response = yield call(apiPut(``, payload, false, CONTROLLERS.CUSTOMERMAINSELLER, false, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {

        yield all([
            put(setMyStore(response.data)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info10'), 'success'))
        ]);

    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error34') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteCustomerStore(action) {
    const payload = action.payload;
    const response = yield call(apiDelete(`/${payload.marketId}`, CONTROLLERS.CUSTOMERMAINSELLER, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(clearMyStore()),
            put(getAllCustomerStores()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info11'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error35') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetStoreFilter(action) {

    var path = `/${action.payload}`;
    const response = yield call(apiGet(path, CONTROLLERS.CUSTOMERMAINSELLERSFILTER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(setSelectedStoreFilter(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error36') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateStoreFilter(action) {
    const payload = action.payload;

    const response = yield call(apiPut(``, payload, false, CONTROLLERS.CUSTOMERMAINSELLERSFILTER, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {


        yield all([
            put(setSelectedStoreFilter(response.data)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info12'), 'success'))
        ]);

    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error37') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetMarketWarehouseSettings(action) {
    const payload = action.payload;

    let path = `?marketId=${payload.marketId}&customerMainSellerId=${payload.customerMainSellerId}`;

    const response = yield call(apiGet(path, CONTROLLERS.CUSTOMERMAINSELLERSSETTING, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setSelectedMarketWarehouseSettings(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error38') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateStoreSettings(action) {
    const payload = action.payload;

    const response = yield call(apiPut(``, payload, false, CONTROLLERS.CUSTOMERMAINSELLERSSETTING, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {


        yield all([
            //put(setSelectedMarketWarehouseSettings(response.data)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info13'), 'success'))
        ]);

    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error39') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetPricingData(action) {
    const payload = action.payload;
    let path = `/GetCostUnit?marketId=${payload.marketId}&customerMainSellerId=${payload.customerMainSellerId}`;

    const response = yield call(apiGet(path, CONTROLLERS.CUSTOMERMAINSELLERSSETTING, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setSelectedMarketPricingData(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error56') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdatePricingData(action) {
    const payload = action.payload;

    const response = yield call(apiPut(`/UpdateCostUnit`, payload, false, CONTROLLERS.CUSTOMERMAINSELLERSSETTING, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {


        yield all([
            put(getMarketPricingData(
                {
                    customerMainSellerId: payload.customerMainSellerId,
                    marketId: payload.marketId
                }
            )),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info26'), 'success'))
        ]);

    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error57') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runUpsertStoreToken(action) {
    const payload = action.payload;

    const response = yield call(apiPut(`/AddOrUpdateToken`, payload, false, CONTROLLERS.CUSTOMERMAINSELLER, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {


        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info14'), 'success')),
            put(setMyStoresIsRefreshTokenValid(true))
        ]);

    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error40') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runGetDashBoardData(action) {
    const payload = action.payload;
    let path = `?customerMainSellerId=${payload.customerMainSellerId}&startDate=${payload.startDate}&endDate=${payload.endDate}&graphicType=${payload.graphicType || 0}`;

    const response = yield call(apiGet(path, CONTROLLERS.DASHBOARD, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getStoreDashboardDataSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetOrderStatusCount(action) {
    const payload = action.payload;
    let path = `/GetOrderStatusCount?customerMainSellerId=${payload.customerMainSellerId}`;
    if (payload.startDate) {
        path += `&startDate=${payload.startDate}&endDate=${payload.endDate}`;
    }
    if (payload.endDate) {
        path += `&endDate=${payload.endDate}`;
    }

    const response = yield call(apiGet(path, CONTROLLERS.DASHBOARD, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getOrderStatusCountSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


export default function* storeSaga() {
    yield all([
        takeLatest(types.GET_STORE_FILTER_REQUESTED, safe(runGetStoreFilter)),
        takeLatest(types.UPDATE_STORE_FILTER_REQUESTED, safe(runUpdateStoreFilter)),
        takeLatest(types.GET_STORE_REQUESTED, safe(runGetCustomerStore)),
        takeLatest(types.GET_ALL_CUSTOMER_STORES_REQUESTED, safe(runGetCustomerMarkets)),
        takeLatest(types.CREATE_STORE_REQUESTED, safe(runCreateCustomerStore)),
        takeLatest(types.UPDATE_STORE_REQUESTED, safe(runUpdateCustomerStore)),
        takeLatest(types.DELETE_STORE_REQUESTED, safe(runDeleteCustomerStore)),
        takeLatest(types.GET_SELECTED_WAREHOUSE_SETTINGS_REQUESTED, safe(runGetMarketWarehouseSettings)),
        takeLatest(types.UPDATE_STORE_SETTINGS_REQUESTED, safe(runUpdateStoreSettings)),
        takeLatest(types.GET_SELECTED_PRICING_DATA_REQUESTED, safe(runGetPricingData)),
        takeLatest(types.UPDATE_PRICING_DATA_REQUESTED, safe(runUpdatePricingData)),
        takeLatest(types.UPSERT_STORE_TOKEN, safe(runUpsertStoreToken)),
        takeLatest(types.GET_STORE_DASHBOARD_DATA_REQUESTED, safe(runGetDashBoardData)),
        takeLatest(types.GET_STORE_DASHBOARD_ORDER_STATUS_REQUESTED, safe(runGetOrderStatusCount))
    ]);
}