import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrInventoryFilter, DrPageFilter, DrPagination, DrReadMore, DrSelectBox, DrStars } from '../../components/component-index';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { buttonDropdownDirection, buttonVariants, modals, pageFilterTypes, siteExtensions } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ExcelExport2 } from '../../excelexport';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import { clearProductsDetails, deleteProductsByAsins, getInventoryProductsWithDetail, getProductsBrands, getProductsCategories, saveProductAsinBlock, saveProductIsFavourite, saveProductNonPriced, saveProductUploadApprove } from '../../state-management/actions/inventory.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import i18next from 'i18next';
import * as moment from 'moment';
import { getPageFilters } from '../../state-management/actions/page-filters.actions';
import { inventoryPageOptions } from '../../components/hoc/Constants/dr-constants.component';
import { addRemoveAsinsFromSmartPricerStrategy, getAllSmartPricerStrategies } from '../../state-management/actions/smartpricer.actions';
import { Field, Formik } from 'formik';
import "jspdf-autotable";
import { generatePdfReport } from '../../utils/utils';
import { CONTROLLERS, apiGet } from '../../utils/axiosApiCalls';
import { getCustomerStore } from '../../state-management/actions/store.actions';
import { setInventoryPageSelections } from '../../state-management/actions/settings.actions';
import Swal from 'sweetalert2';
import { toastr } from 'react-redux-toastr';

function InventoryPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('inventoryPage');
  const { category, brand, searchkey, filterKey } = useParams();
  const products = useSelector(state => state.inventory.products);
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const inventoryPageFilters = useSelector(state => state.pageFilter?.inventoryPageFilters);
  const smartPricerStrategies = useSelector(state => state.smartPricer.smartPricerStrategies);
  const pageSelections = useSelector(state => state.settings?.pageSelections?.inventoryPageSelections);
  const productStatusCounts = useSelector(state => state.reports.productStatusCounts);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
  const [inventoryProducts, setInventoryProducts] = useState(null);
  const [detailedFilterData, setDetailedFilterData] = useState(null);
  const [isFavouriteProducts, setIsFavouriteProducts] = useState(false);

  const [inventoryStatusOptionsWithCounts, setInventoryStatusOptionsWithCounts] = useState(inventoryPageOptions);
  // const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    dispatch(withActionPromise(getProductsCategories(selectedStore?.customerMainSellerId)));
    dispatch(withActionPromise(getProductsBrands(selectedStore?.customerMainSellerId)));
    dispatch(withActionPromise(getAllSmartPricerStrategies({ customerMainSellerId: selectedStore?.customerMainSellerId, strategyTargetType: "-1" })));
    dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: pageSelections?.inventoryStatus || 4, tableSkip: 0, tableLength: 1000, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
    dispatch(withActionPromise(clearProductsDetails()));

    if (category) {
      let searchModel = {
        productCategory: category
      }
      dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: 4, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
      setDetailedFilterData(searchModel);
    }

    if (brand) {
      let searchModel = {
        productBrand: brand
      }
      dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: 4, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
      setDetailedFilterData(searchModel);
    }

    if (filterKey) {
      let searchModel = null;
      if (filterKey === "buybox") {
        searchModel = {
          onlyIfMeBuyboxSeller: true
        }
      }
      if (filterKey === "lowest") {
        searchModel = {
          onlyIfMeLowestSeller: true
        }
      }
      if (filterKey === "nostock") {
        searchModel = {
          onlyIfUnAvailableProductsAtSourceMarket: true
        }
      }
      if (filterKey === "minprice") {
        searchModel = {
          onlyIfProductPriceIsMin: true
        }
      }
      if (filterKey === "maxprice") {
        searchModel = {
          onlyIfProductPriceIsMax: true
        }
      }

      if (filterKey === "transferredToStore") {
        searchModel = {
          status: 4
        }
      }

      dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: 4, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
      setDetailedFilterData(searchModel);
    }
    if (searchkey) {
      let searchModel = {
        keywords: searchkey
      }
      dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: 4, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
      setDetailedFilterData(searchModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var newOptions = inventoryPageOptions?.map(x => ({ value: x.value, label: x.label + "(" + (productStatusCounts?.find(y => y.status === x.value)?.productCount || 0) + ")", isCritical: x.isCritical }))

    setInventoryStatusOptionsWithCounts(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStatusCounts, inventoryPageOptions]);

  useEffect(() => {
    if (selectedStore?.customerMainSellerId) {
      dispatch(getCustomerStore({
        marketId: selectedStore?.marketId,
        customerMainSellerId: selectedStore?.customerMainSellerId
      }));

      dispatch(withActionPromise(getPageFilters(
        {
          customerMainSellerId: selectedStore?.customerMainSellerId,
          pageNumber: pageFilterTypes.inventory,
          pageName: Object.keys(pageFilterTypes)[Object.values(pageFilterTypes).indexOf(0)]
        })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore?.customerMainSellerId]);

  useEffect(() => {

    var requestData = {
      status: parseInt(pageSelections?.inventoryStatus), //  parseInt((detailedFilterData?.status ? detailedFilterData?.status : (pageSelections?.inventoryStatus || 4))),
      customerMainSellerId: selectedStore?.customerMainSellerId,
      isFavourite: isFavouriteProducts === true ? true : null,
      filterData: !detailedFilterData?.keywords ? detailedFilterData : null,
      Skip: pageSelections?.tableSkip,
      PageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : products?.totalRecordCount,
      keywords: detailedFilterData?.keywords,
      orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "updatedDate desc",
    }
    dispatch(withActionPromise(getInventoryProductsWithDetail(requestData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailedFilterData, selectedStore?.customerMainSellerId, pageSelections?.inventoryStatus, pageSelections?.tableSkip, pageSelections?.pagelength, isFavouriteProducts]);

  useEffect(() => {

    if (products?.data) {
      if (products?.data.length > 0) {



        let data = Object.assign([], products.data);
        data.forEach(product => {
          let smartpricerId = "-1";
          if (smartPricerStrategies && smartPricerStrategies.length > 0) {
            smartPricerStrategies.forEach(strategy => {
              if (strategy.smartPricerStrategy === 2) {
                if (strategy.asins && strategy.asins.length > 0 && strategy.asins.find(x => x === product.asin)) {
                  smartpricerId = strategy.id;
                }
              }

            });


            if (smartpricerId === "-1") {
              var categoryBasedStrategy = smartPricerStrategies?.find(x => x.smartPricerStrategy === 1 && x.productCategoryId && x.productCategoryId === product.categoryId);
              if (categoryBasedStrategy) {
                smartpricerId = categoryBasedStrategy.id;
              }
            }
            if (smartpricerId === "-1") {
              var defaultStrategy = smartPricerStrategies?.filter(x => x.smartPricerStrategy === 0 && x.isDefault) && smartPricerStrategies?.filter(x => x.smartPricerStrategy === 0 && x.isDefault)[0];
              if (defaultStrategy) {
                smartpricerId = defaultStrategy.id;
              }
            }
          }
          product.selectedSmartpricer = smartpricerId;
        });
        // setInventoryProducts({
        //   draw: 9,
        //   data: data,
        //   recordsTotal: products.totalRecordCount,
        //   recordsFiltered: products.totalRecordCount, // response.totalRecordCount
        // })
        setInventoryProducts(data);
        // setInventoryProducts(data);
        // setTotalRows(products.totalRecordCount);
      }
      else {
        setInventoryProducts([]);
      }
    }
    else {
      setInventoryProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const columns = [
    { data: "isFavourite", title: "", width: "8%", minWidth: "110px", maxWidth: "110px", searchable: false, checkall: true, checkData: "customerMainSellerProductId" },
    { data: "productName", title: t('dataTable.productName'), minWidth: "200px", searchable: true },
    { data: "asin", title: "ASIN", minWidth: "210px", maxWidth: "210px", searchable: true },
    { data: "sellingPrice", title: t('dataTable.sellingPrice'), minWidth: "220px", maxWidth: "220px", searchable: false },
    { data: "sellingPrice", title: t('dataTable.competition'), minWidth: "210px", maxWidth: "210px", searchable: false },
    { data: "brandName", title: t('dataTable.brand'), minWidth: "200px", searchable: true },
    { data: null, title: t('dataTable.smartPricer'), minWidth: "170px", maxWidth: "170px", searchable: false },
    { data: "updatedDate", title: t('dataTable.updateDate'), minWidth: "145px", maxWidth: "145px", searchable: false },
    { data: null, title: "", minWidth: "50px", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: true,
      className: "text-center position-relative",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          // <div className="position-relative">
          <>

            {!(rowData.status === 1 && rowData.isApprove) &&
              <input type="checkbox" className="ckbox position-absolute form-check-input form-checked-info " name="name" value={rowData.asin} style={{ top: '1px', left: '10px' }}
                onChange={(e) => {
                  e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                }}
              />}

            <div className="card-aside-img cursor-pointer mx-auto" >

              {(rowData.status === 1 && rowData.isApprove) &&
                <span className="badge bg-success"  >
                  {t('dataTable.approvedProduct')}
                </span>
              }
              <div className="img-sm h-100 p-0 m-0 rounded-0">
                <img src={rowData.productPicture} alt="img" className="img-thumbnail" data-tag="pic-modal-opener"
                  onClick={(e) => {
                    e.preventDefault();
                    if (rowData.productPicture) {
                      dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [rowData.productPicture] }))
                    }
                  }}
                ></img>
              </div>
            </div>
            <div className="row justify-content-center mt-1">
              <div className="col-auto px-0 me-1">
                <button className="btn btn-primary btn-icon btn-wave waves-effect waves-light shadow btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/product-detail/' + rowData.asin);
                  }}
                >
                  <i className="fe fe-info" style={{ width: "12px" }}></i>
                </button>
              </div>
              <div className="col-auto px-0">
                <button className="btn btn-info btn-icon btn-wave waves-effect waves-light shadow btn-sm"
                  onClick={(e) => {
                    e.preventDefault();

                    dispatch(saveProductIsFavourite({
                      customerMainSellerProductId: rowData.customerMainSellerProductId,
                      isFavourite: !rowData.isFavourite,
                      inventoryRequestData: {
                        status: parseInt(pageSelections?.inventoryStatus),// parseInt((detailedFilterData?.status ? detailedFilterData?.status : (pageSelections?.inventoryStatus || 4))),
                        customerMainSellerId: selectedStore?.customerMainSellerId,
                        isFavourite: isFavouriteProducts === true ? true : null,
                        filterData: !detailedFilterData?.keywords ? detailedFilterData : null,
                        Skip: pageSelections?.tableSkip,
                        PageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : products?.totalRecordCount,
                        keywords: detailedFilterData?.keywords,
                        orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "updatedDate desc",
                      }
                    }));
                  }}>
                  {/* <i className={`fa cursor-pointer ${rowData.isFavourite ? "fa fa-star text-yellow" : "fa-star-o"}`}></i> */}
                  {/* <i className={`typcn ${rowData.isFavourite ? "typcn-heart text-red" : "typcn-heart-outline"}`}></i> */}
                  <i className={`${rowData.isFavourite ? "bi-heart-fill" : "bi-heart"}`}></i>
                </button>
              </div>
            </div>
          </>
        );
      }
    },
    {
      targets: 1,
      orderable: false,
      className: "text-center",
      width: "20%",
      searchable: true,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <div className="row">
              <div className="col-12">
                <a href={`/#/product-detail/${rowData.asin}`}>
                  <DrReadMore max={50} text={rowData.productName || ""} classes='fs-12'
                    data-bs-placement="right" data-bs-toggle="tooltip"
                    title={t('dataTable.productDetailText',
                      {
                        productName: rowData.productName,
                        brandName: rowData.brandName,
                        categoryName: rowData.destinationProductCategory?.categoryName + (rowData.destinationProductCategory?.subCategories?.length > 0 ? (" / " + rowData.destinationProductCategory?.subCategories[0].categoryName) : ""),
                        modelNumber: rowData.modelNumber
                      })}
                    onTextClicked={(e) => {
                      e.preventDefault();
                      // navigate('/product-detail/' + rowData.asin);

                      window.open(window.location.origin + "/#/product-detail/" + rowData.asin, '_blank');
                    }}
                  ></DrReadMore>
                </a>
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-auto">
                {rowData.destinationProductCategory &&
                  <span className="fs-12 my-auto mx-0 p-0 text-muted text-wrap">
                    {rowData.destinationProductCategory?.categoryName + (rowData.destinationProductCategory?.subCategories?.length > 0 ? (" / " + rowData.destinationProductCategory?.subCategories[0].categoryName) : "")}
                  </span>
                }
                {!rowData.destinationProductCategory &&
                  <span className="fs-12 my-auto mx-0 p-0 text-muted text-wrap">
                    {rowData.sourceProductCategory?.categoryName + (rowData.sourceProductCategory?.subCategories?.length > 0 ? (" / " + rowData.sourceProductCategory?.subCategories[0].categoryName) : "")}
                  </span>}
              </div>
            </div>
            <div className="row justify-content-start px-2">
              {rowData.hasAmazonSellersAtDestinationMarket &&
                <div className="col-auto px-0">
                  <i className="ri-typhoon-fill text-teal fs-18 cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip" title={"Amazon Seller"}></i>
                </div>
              }
              {rowData.hasPrimeSellersAtDestinationMarket &&
                <div className="col-auto px-0">
                  <i className="ri-shield-star-fill text-orange fs-18 cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                    title={"Prime Seller"}></i>
                </div>
              }
            </div>
          </>
        );
      }
    },
    {
      targets: 2,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <div className="row justify-content-center">
              <div className="col-auto px-0 d-flex">
                <span className="badge border bg-orange custom-badge cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                  title={t('dataTable.stock')}>
                  <i className="fe fe-shopping-bag me-2 d-inline-block"></i>
                  {rowData.quantity || 0}
                </span>
              </div>
              <div className="col-auto px-0 d-flex">
                <span className="badge border bg-purple custom-badge cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                  title={"Rating"}>
                  <i className="ri-line-chart-line me-2 d-inline-block"></i>
                  {rowData.ratingValue || 0}
                </span>
              </div>
              <div className="col-auto px-0 d-flex">
                <span className="badge border bg-success custom-badge cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                  title={((rowData.destinationProductCategory?.categoryName || "") + " Ranking")}>
                  <i className="ri-bar-chart-fill me-2 d-inline-block"></i>

                  {rowData.destinationProductCategory?.rank || 0}
                </span>

              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto px-0 my-auto">
                <button className="btn btn-link btn-sm btn-wave waves-effect waves-light px-0"
                  onClick={(e) => {
                    e.preventDefault();
                    // navigate('/product-detail/' + rowData.asin);
                    window.open(window.location.origin + "/#/product-detail/" + rowData.asin, '_blank');
                  }}
                >
                  {rowData.asin}
                </button>

              </div>
              <div className="col-auto px-0 my-auto">
                <span onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(rowData.asin);
                  toastr.info("Info", rowData.asin + " is copied to clipboard", { timeOut: 1000 })
                }}>
                  <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                </span>

              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <span className="fs-12 my-auto mx-0 p-0 text-muted">{rowData.sku}</span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <DrStars value={rowData.starsValue ? parseFloat(rowData.starsValue) : 0} review={rowData.reviewCount || 0}></DrStars>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto px-0">
                <span className="badge border bg-dark bg-opacity-50 custom-badge cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                  title={"Review"}>
                  <i className="ri-thumb-up-line me-2 d-inline-block"></i>
                  {rowData.reviewCount || 0}
                </span>

              </div>
            </div>
          </>
          // <div className="mx-auto" style={{ width: '90px' }}>

          // </div>
        );
      }
    },
    {
      targets: 3,
      orderable: true,
      searchable: false,
      className: "text-start",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(

          // <div className="d-flex flex-wrap">
          //   <div className="row justify-content-start w-90">
          //     <div className="col-12 px-0">
          //       {rowData.price !== null &&
          //         <span className="text-end badge rounded-pill bg-success fs-11 ms-2">Price: {selectedStoreCurrencyType.currencyCode} {Number(rowData.price).toFixed(2)}</span>
          //       }
          //     </div>
          //   </div>
          //   <div className="row justify-content-start mt-1 w-90">
          //     <div className="col-12 px-0">
          //       {rowData.warehousePrice !== null &&
          //         <span className="text-end badge rounded-pill bg-warning fs-11 ms-2">Shipping: {selectedStoreCurrencyType.currencyCode} {Number(rowData.warehousePrice).toFixed(2)}</span>
          //       }
          //     </div>
          //   </div>
          //   <div className="row justify-content-start mt-1 w-90">
          //     <div className="col-12 px-0">
          //       {rowData.sellingPrice !== null &&
          //         <span className="text-end badge rounded-pill bg-warning fs-11 ms-2">Customs: {selectedStoreCurrencyType.currencyCode} {Number(rowData.sellingPrice).toFixed(2)}</span>
          //       }
          //     </div>
          //   </div>
          //   <div className="row justify-content-start mt-1 w-90">
          //     <div className="col-12 px-0">
          //       {rowData.progressPayment !== null &&
          //         <span className="text-end badge rounded-pill bg-warning fs-11 ms-2">Proggres: {selectedStoreCurrencyType.currencyCode} {Number(rowData.progressPayment).toFixed(2)}</span>
          //       }
          //     </div>
          //   </div>
          //   <div className="row justify-content-start mt-1 w-90">
          //     <div className="col-12 px-0">
          //       {rowData.profitPercent !== null &&
          //         <span className="text-end badge rounded-pill bg-success fs-11 ms-2">Profit(%):{Number(rowData.profitPercent).toFixed(2)}%</span>
          //       }
          //     </div>
          //   </div>
          // </div>
          <>
            <div className="row">
              <div className="col">
                <span className="badge border bg-success bg-opacity-50 custom-badge cursor-pointer w-100 d-inline-flex justify-content-between">
                  <div className="row">
                    <div className="col-auto pe-0">
                      <i className="fe fe-chevrons-down d-inline-block"></i>
                    </div>
                    <div className="col-auto px-0">
                      <i className="fe fe-more-vertical me-3 d-inline-block"></i>
                    </div>
                  </div>


                  {/* <i class="ri-arrow-up-double-fill  d-inline-block"></i> */}
                  {selectedStoreCurrencyType.currencyCode} {Number(rowData.minSellingPrice || 0).toFixed(2)}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="badge border bg-success custom-badge cursor-pointer w-100 d-inline-flex justify-content-between fw-bold fs-14">
                  <i className="fe fe-loader d-inline-block"></i>
                  <i className="fe fe-more-vertical me-3 d-inline-block"></i>

                  {/* <i class="ri-arrow-up-double-fill  d-inline-block"></i> */}
                  {selectedStoreCurrencyType.currencyCode} {Number(rowData.sellingPrice || 0).toFixed(2)} {"(%" + Number(rowData.profitPercent).toFixed(2) + ")"}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="badge border bg-success bg-opacity-50 custom-badge cursor-pointer w-100 d-inline-flex justify-content-between">
                  <div className="row">
                    <div className="col-auto pe-0">
                      <i className="fe fe-chevrons-up d-inline-block"></i>
                    </div>
                    <div className="col-auto px-0">
                      <i className="fe fe-more-vertical me-3 d-inline-block"></i>
                    </div>
                  </div>
                  {/* <i class="ri-arrow-up-double-fill  d-inline-block"></i> */}
                  {selectedStoreCurrencyType.currencyCode} {Number(rowData.maxSellingPrice || 0).toFixed(2)}
                </span>
              </div>
            </div>
          </>
        );
      }
    },
    {
      targets: 4,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <div className="row">
              <div className="col">
                <span className="badge border bg-info bg-opacity-50 custom-badge cursor-pointer w-100 d-inline-flex justify-content-between">
                  <div className="row">
                    <div className="col-auto pe-0">
                      Buybox
                    </div>
                    <div className="col-auto px-0">
                      <i className="fe fe-more-vertical me-3 d-inline-block"></i>
                    </div>
                  </div>


                  {/* <i class="ri-arrow-up-double-fill  d-inline-block"></i> */}
                  {selectedStoreCurrencyType.buyboxPriceCurrencyCode || selectedStoreCurrencyType.currencyCode} {Number(rowData.buyboxPrice || 0).toFixed(2)}
                  <div className="row">
                    <div className="col-auto px-0">
                      <i className="fe fe-more-vertical ms-3 d-inline-block"></i>
                    </div>
                    <div className="col-auto ps-0">
                      {!rowData.hasMeAsBuyboxSeller && <i className="fe fe-x text-danger fw-bold d-inline-block"></i>}
                      {rowData.hasMeAsBuyboxSeller && <i className="fe fe-check text-success fw-bold d-inline-block"></i>}
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="badge border bg-info bg-opacity-50 custom-badge cursor-pointer w-100 d-inline-flex justify-content-between">
                  <div className="row">
                    <div className="col-auto pe-0">
                      Lowest
                    </div>
                    <div className="col-auto px-0">
                      <i className="fe fe-more-vertical me-3 d-inline-block"></i>
                    </div>
                  </div>
                  {selectedStoreCurrencyType.lowestPriceCurrencyCode || selectedStoreCurrencyType.currencyCode} {Number(rowData.lowestPrice || 0).toFixed(2)}
                  <div className="row">
                    <div className="col-auto px-0">
                      <i className="fe fe-more-vertical ms-3 d-inline-block"></i>
                    </div>
                    <div className="col-auto ps-0">
                      {!rowData.hasMeAsLowestSeller && <i className="fe fe-x text-danger fw-bold d-inline-block"></i>}
                      {rowData.hasMeAsLowestSeller && <i className="fe fe-check text-success fw-bold d-inline-block"></i>}
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="badge border bg-info bg-opacity-50 custom-badge cursor-pointer w-100 d-inline-flex justify-content-between">
                  <div className="row">
                    <div className="col-auto pe-0">
                      Seller Count
                    </div>
                    <div className="col-auto px-0">
                      <i className="fe fe-more-vertical me-3 d-inline-block"></i>
                    </div>
                  </div>
                  {rowData.totalSellerCount || 0}
                </span>
              </div>
            </div>
          </>
        );
      }
    },
    {
      targets: 5,
      orderable: true,
      className: "text-start",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          // <div className="text-center" style={{ fontSize: '16px', minWidth: '100px' }}>
          // <p>
          //   <i className="fa fa-circle" style={{ marginRight: '10px', color: '#ff0000' }}></i>
          <>
            <div className="row justify-content-start px-3">
              <div className="col-auto px-0">
                {(rowData.hasCIPOCertified === true || rowData.hasUSPTOCertified === true) && <i className="fs-13 text-danger ri-shield-fill"></i>}
                {!(rowData.hasCIPOCertified === true || rowData.hasUSPTOCertified === true) && <i className="fs-13 text-success ri-shield-check-fill"></i>}
              </div>
              <div className="col-auto px-0">
                <span className="fs-13 text-uppercase fw-bold cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                  title={rowData.handlingTime ? (rowData.handlingTime + " " + "Days") : ""}>
                  {rowData.brandName}
                </span>
              </div>
            </div>

            <div className="row justify-content-start px-3">
              <div className="col-auto">
                <span className="fs-12 my-auto mx-0 p-0 text-muted">{rowData.handlingTime ? (rowData.handlingTime + " " + "Days") : ""}</span>
              </div>
            </div>
          </>

          // </p>
          // </div>
        );
      }
    },
    {
      targets: 6,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          // <div className="text-center" style={{ fontSize: '16px', minWidth: '100px' }}>
          // <p>
          //   <i className="fa fa-circle" style={{ marginRight: '10px', color: '#ff0000' }}></i>
          <>
            <Formik
              initialValues={
                {
                  selectedSmartpricer: rowData.selectedSmartpricer || null
                }
              }
              validateOnBlur={true}
              enableReinitialize={true}
              validateOnMount={true}
            >
              {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setTouched,
                setFieldValue
              }) => (
                <Field
                  onBlur={() => { setTouched({ ...touched.selectedSmartpricer, 'selectedSmartpricer': true }) }}
                  onChange={(e) => {
                    if (e) {
                      if (e.value !== values.selectedSmartpricer) {
                        handleSmartPricerSelectedSubmit(values.selectedSmartpricer, e.value, [rowData.asin]);
                      }
                    }
                  }}
                  className="text-start"
                  name={'selectedSmartpricer'}
                  component={DrSelectBox}
                  placeholder={t('dataTable.selectStrategy')}
                  options={
                    [
                      ...(smartPricerStrategies?.find(x => x.productCategoryId && x.smartPricerStrategy === 1 && x.productCategoryId === rowData.categoryId) ? [] : smartPricerStrategies?.filter(x => x.smartPricerStrategy === 0 && x.isDefault)?.map(x => ({ value: x.id, label: x.smartPricerName }))),
                      ...(smartPricerStrategies?.filter(x => x.productCategoryId && x.smartPricerStrategy === 1 && x.productCategoryId === rowData.categoryId))?.map(x => ({ value: x.id, label: x.smartPricerName })),
                      ...(smartPricerStrategies?.filter(y => y.smartPricerStrategy === 2)?.map(x => ({ value: x.id, label: x.smartPricerName })))
                    ]
                  } />
              )
              }
            </Formik>
          </>

          // <span className="fs-12 my-auto mx-0 p-0 font-weight-normal text-uppercase">{rowData.categoryName}</span>
          // </p>
          // </div>
        );
      }
    },
    {
      targets: 7,
      orderable: true,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <div className="row justify-content-center">
              <div className="col-auto">
                <span className="fs-12 my-auto mx-0 p-0">{moment(rowData.updatedDate).fromNow()}</span>
              </div>
            </div>
            {(rowData.couponPercentValue > 0 || rowData.couponPriceValue > 0) &&
              <div className="row justify-content-center">
                <div className="col-auto px-0">
                  <span className="badge border bg-teal custom-badge cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                    title={"Coupon"}>
                    {/* {rowData.couponType === "percent" && <i className="ri-discount-percent-fill me-2 d-inline-block"></i>}
                  {rowData.couponType !== "percent" && <i className="ri-cash-fill me-2 d-inline-block"></i>} */}
                    {/* {rowData.couponType === "percent" && <i className="fe fe-percent me-2 d-inline-block"></i>}
                  {rowData.couponType !== "percent" && <i className="fe fe-dollar-sign me-2 d-inline-block"></i>}
                  {rowData.couponValue || 0} */}
                    {rowData.couponPercentValue > 0 && <i className="fe fe-percent me-2 d-inline-block"></i>}
                    {/* {rowData.couponPriceValue > 0 && <i className="fe fe-dollar-sign me-2 d-inline-block"></i>} */}
                    {rowData.couponPercentValue > 0 ? Number(rowData.couponPercentValue).toFixed(2) : 0}
                    {/* {rowData.couponPriceValue > 0 ? Number(rowData.couponPriceValue).toFixed(2) : 0} */}
                  </span>
                </div>
              </div>
            }
            {(rowData.primePercentValue > 0 || rowData.primePriceValue < 0) &&
              <div className="row justify-content-center">
                <div className="col-auto px-0">
                  <span className="badge border bg-info custom-badge cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                    title={"Prime Discount"}>
                    {/* {rowData.primePercentValue > 0 && <i className="fe fe-percent me-2 d-inline-block"></i>} */}
                    {rowData.primePriceValue > 0 && <i className="fe fe-dollar-sign me-2 d-inline-block"></i>}
                    {/* {rowData.primePercentValue > 0 ? Number(rowData.primePercentValue).toFixed(2) : 0} */}
                    {rowData.primePriceValue > 0 ? Number(rowData.primePriceValue).toFixed(2) : 0}
                  </span>
                </div>
              </div>

            }

            {/* {moment().startOf("day").diff(moment(rowData.updatedDate).startOf("day"), 'days') === 0 && "Today"}
            {moment().startOf("day").diff(moment(rowData.updatedDate).startOf("day"), 'days') === 1 && "Yesterday"}*/}
            {/* {moment().startOf("day").diff(moment(rowData.updatedDate).startOf("day"), 'days') > 1 && moment(rowData.updatedDate).format('LLLL')} */}
          </>
          // <span className="fs-12 my-auto mx-0 p-0">{moment(rowData.updatedDate).format('L LT')}</span>

        );
      }
    },
    {
      targets: 8,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>
            <span id="droprightMenuButton" className="text-muted cursor-pointer" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18"></i></span>
            <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
              <span className="dropdown-item d-flex align-items-center cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/product-detail/' + rowData.asin);
                }}>
                {t("dataTable.seeDetails")}
              </span>

              <a className="dropdown-item d-flex align-items-center cursor-pointer" target="_blank" rel="noreferrer"
                href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/" + rowData.modelNumber + "/dp/" + rowData.asin}>
                {t("dataTable.seeAtAmazon")}
              </a>
              {pageSelections?.inventoryStatus === 2 &&
                <span className="dropdown-item d-flex align-items-center cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    apiGet(`/GetById?customerMainSellerProductId=${rowData.customerMainSellerProductId}`, CONTROLLERS.PRODUCT, true, true)().then((response) => {
                      if (response && response.isSuccess && response.resultType === 1 && response.data) {
                        Swal.fire(i18next.t('drSweetAlertButton:infoTitle'), response.data?.statusDescription, 'info')
                      }
                    }).catch((err) => {

                    });

                  }}>
                  {t("dataTable.whyCriteriaDidNotMatch")}
                </span>
              }
              {pageSelections?.inventoryStatus === 3 && <span className="dropdown-item d-flex align-items-center cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();

                  var requestData = {
                    inventoryRequestData: {
                      status: parseInt(pageSelections?.inventoryStatus), // parseInt(detailedFilterData?.status || pageSelections?.inventoryStatus),
                      customerMainSellerId: selectedStore?.customerMainSellerId,
                      // "isFavourite": true,
                      filterData: detailedFilterData,
                      Skip: pageSelections?.tableSkip,
                      PageSize: pageSelections?.tableLength
                    },
                    approveRequestData: {
                      customerMainSellerProductIdList: [rowData.customerMainSellerProductId]
                    }
                  }

                  dispatch(saveProductUploadApprove(requestData));

                }}>
                {t("dataTable.approveProduct")}
              </span>}
              <span className="dropdown-item d-flex align-items-center cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/product-variants/' + rowData.asin);

                }}>
                {t("dataTable.goToVariations")}
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer text-danger"
                onClick={(e) => {
                  e.preventDefault();

                  var data = {
                    inventoryRequestData: {
                      status: parseInt(pageSelections?.inventoryStatus),// parseInt(detailedFilterData?.status || pageSelections?.inventoryStatus),
                      customerMainSellerId: selectedStore?.customerMainSellerId,
                      // "isFavourite": true,
                      filterData: detailedFilterData,
                      Skip: pageSelections?.tableSkip,
                      PageSize: pageSelections?.tableLength
                    },
                    deleteRequestData: {
                      asins: [
                        rowData.asin
                      ],
                      customerMainSellerId: selectedStore.customerMainSellerId
                    }
                  }
                  dispatch(withActionPromise(deleteProductsByAsins(data)))
                }}>
                {t("dataTable.deleteProduct")}
              </span>

              <span className="dropdown-item d-flex align-items-center cursor-pointer text-danger"
                onClick={(e) => {
                  e.preventDefault();

                  var requestData = {
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                    asinList: [
                      rowData.asin
                    ]
                  }

                  dispatch(withActionPromise(saveProductAsinBlock(requestData)));
                }}>
                {t("dataTable.blockProductAsin")}
              </span>
            </div>
          </div>)
      }
    }
  ];

  const detailedSearchClick = (e, searchModel) => {
    if (searchModel?.status) {
      dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: searchModel?.status, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
    }

    setDetailedFilterData(searchModel);
  };

  const optionClickCallBack = (value, label) => {
    dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: value, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
  };

  const refreshClickedCallBack = () => {
    if (selectedStore && pageSelections?.inventoryStatus) {
      var requestData = {
        status: parseInt(pageSelections?.inventoryStatus), // parseInt(detailedFilterData?.status || pageSelections?.inventoryStatus),
        customerMainSellerId: selectedStore?.customerMainSellerId,
        // "isFavourite": true,
        filterData: detailedFilterData,
        Skip: pageSelections?.tableSkip,
        PageSize: pageSelections?.tableLength,
        keywords: detailedFilterData?.keywords,
        orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : null,
      }
      dispatch(withActionPromise(getInventoryProductsWithDetail(requestData)));

    }
  };



  const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {
    if (checkedValues && checkedValues.length > 0) {
      if (selectedOperation === "0" || selectedOperation === "1") //export
      {
        var data = inventoryProducts.filter(item1 =>
          !!checkedValues.find(item2 => item1.asin === item2));

        if (selectedOperation === "0") {

          const tableColumn = [
            {
              header: "PRODUCT TITLE",
              key: "productName",
              width: "50"
            },
            {
              header: "ASIN",
              key: "asin",
              width: "15"
            },
            {
              header: "SKU",
              key: "sku",
              width: "20"
            },
            {
              header: "PRICE",
              key: "price",
              width: "15"
            },
            {
              header: "STOCK",
              key: "quantity",
              width: "10"
            },
            {
              header: "BRAND",
              key: "brandName",
              width: "15"
            },
            {
              header: "UPDATED DATE",
              key: "updatedDate",
              width: "30"
            }
          ];

          const tableRows = [];

          // for each ticket pass all its data into an array
          data.forEach(product => {
            const productData = {
              productName: product.productName,
              asin: product.asin,
              sku: product.sku,
              price: "$" + Number(product.price).toFixed(2),
              quantity: product.quantity,
              brandName: product.brandName,
              updatedDate: moment(product.updatedDate).format('L LT')
            };
            // push each tickcet's info into a row
            tableRows.push(productData);
          });

          ExcelExport2({ excelData: data, columns: tableColumn, fileName: "inventory" });

        }
        else if (selectedOperation === "1") {
          const tableColumn = ["PRODUCT TITLE", "ASIN", "SKU", "PRICE", "STOCK", "BRAND", "UPDATED DATE"];
          // define an empty array of rows
          const tableRows = [];

          // for each ticket pass all its data into an array
          data.forEach(product => {
            const productData = [
              product.productName,
              product.asin,
              product.sku,
              "$" + Number(product.price).toFixed(2),
              product.quantity,
              product.brandName,
              moment(product.updatedDate).format('L LT')
            ];
            // push each tickcet's info into a row
            tableRows.push(productData);
          });
          generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Inventory Products", orientation: 'l', reportName: "inventory_products" });

        }
      }
      else if (selectedOperation === "2") // compare
      {
        navigate('/product-compare/' + checkedValues.join());
      }
      else if (selectedOperation === "3") {
        var request = {
          inventoryRequestData: {
            status: parseInt(pageSelections?.inventoryStatus), // parseInt(detailedFilterData?.status || pageSelections?.inventoryStatus),
            customerMainSellerId: selectedStore?.customerMainSellerId,
            // "isFavourite": true,
            filterData: detailedFilterData,
            Skip: pageSelections?.tableSkip,
            PageSize: pageSelections?.tableLength
          },
          deleteRequestData: {
            asins: [
              ...checkedValues
            ],
            customerMainSellerId: selectedStore.customerMainSellerId
          }
        }

        dispatch(withActionPromise(deleteProductsByAsins(request)));
      }
      else if (selectedOperation === "5") {
        let selectedProducts = inventoryProducts.filter(item1 =>
          !!checkedValues.find(item2 => item1.asin === item2 && item1.status === 3));

        var productIds = selectedProducts.map(function (o) {
          return o.customerMainSellerProductId;
        });

        var requestApproveData = {
          inventoryRequestData: {
            status: parseInt(pageSelections?.inventoryStatus), // parseInt(detailedFilterData?.status || pageSelections?.inventoryStatus),
            customerMainSellerId: selectedStore?.customerMainSellerId,
            // "isFavourite": true,
            filterData: detailedFilterData,
            Skip: pageSelections?.tableSkip,
            PageSize: pageSelections?.tableLength
          },
          approveRequestData: {
            customerMainSellerProductIdList: productIds
          }
        }

        dispatch(saveProductUploadApprove(requestApproveData));
      }
      else if (selectedOperation === "8") {

        let selectedProducts = inventoryProducts.filter(item1 =>
          !!checkedValues.find(item2 => item1.asin === item2));

        let asins = selectedProducts.map(function (o) {
          return o.asin;
        });

        var requestAsinBlockData = {
          customerMainSellerId: selectedStore?.customerMainSellerId,
          asinList: asins
        }

        dispatch(withActionPromise(saveProductAsinBlock(requestAsinBlockData)));
      }
      else if (selectedOperation === "9") {

        let selectedProducts = inventoryProducts.filter(item1 =>
          !!checkedValues.find(item2 => item1.asin === item2));

        let asins = selectedProducts.map(function (o) {
          return o.asin;
        });

        var requestAsinNonPrizerData = {
          customerMainSellerId: selectedStore?.customerMainSellerId,
          asinList: asins
        }

        dispatch(withActionPromise(saveProductNonPriced(requestAsinNonPrizerData)));
      }
    }
  }

  const handleSmartPricerSelectedSubmit = async (previousSmartPricerId, currentSmartPricerId, asins) => {
    var data = {
      previousSmartPricerId: previousSmartPricerId,
      currentSmartPricerId: currentSmartPricerId,
      asins: asins
    }
    dispatch(addRemoveAsinsFromSmartPricerStrategy(data));
  }

  return (
    <>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
        { navigationText: inventoryStatusOptionsWithCounts.find(x => x.value === pageSelections?.inventoryStatus)?.label, navigationPath: "" }]} >
      </DrBreadCrump>

      <div className="row justify-content-center justify-content-sm-between justify-content-md-between justify-content-lg-between justify-content-xl-between justify-content-xxl-between mb-2">
        {selectedStore &&
          <>
            <div className="col-auto my-1">
              <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(getRouteByKey("ADD_PRODUCT"));
                }}>
                <i className="me-2 fa fa-forward"></i>
                {t('goToNewProducts')}
              </button>
            </div>
            <div className="col-auto my-1 ps-0">
              <DrButtonDropdown className="ms-2 shadow"
                options={inventoryStatusOptionsWithCounts}
                optionClickCallBack={optionClickCallBack}
                selectedValue={pageSelections?.inventoryStatus}
                variant={buttonVariants.light}
                direction={buttonDropdownDirection.horizontal}
                selectedVariant={buttonVariants.teal}
              >
              </DrButtonDropdown>
            </div>
          </>
        }
      </div>
      {detailedFilterData &&
        <div className="row">
          <div className="col-12">
            <div className="card rounded-10 mb-2">
              <div className="card-body rounded p-2 bg-teal">
                <div className="row justify-content-between">
                  <div className="col-auto my-auto">
                    <label className="text-white my-auto">
                      Detaylı Arama Uygulanmıştır!
                    </label>
                  </div>
                  <div className="col-auto my-auto">
                    <button className="btn btn-danger btn-wave waves-effect waves-light shadow btn-sm rounded-pill"
                      onClick={(e) => {
                        e.preventDefault();
                        setDetailedFilterData(null);
                        navigate("/inventory")
                      }}
                    >
                      <i className="fe fe-trash-2 me-2"></i>
                      Filtreyi Kaldır</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div id="inventoryTable" className="row mb-5">
        <div className="col-12">

          <div className="card custom-card">
            <div className="card-body">
              {!selectedStore &&
                <div className="row row-md row-lg justify-content-center my-3 px-1">
                  <div className="col-lg-auto col-md-2">
                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                      onClick={(e) => {
                        e.preventDefault();
                        Promise.all([
                          dispatch(withActionPromise(clearCreateStoreInformationState()))
                        ]).then((e) => {
                          navigate(getRouteByKey("MARKET_SELECTION"));
                        });;

                      }}>
                      <i className="me-2 fe fe-chevrons-right"></i>
                      {t('goToMarketSelection')}
                    </button>
                  </div>
                </div>
              }
              {selectedStore && <>
                <DrDataTable
                  data={inventoryProducts}
                  columns={columns}
                  columnDefs={columnDefs}
                  doesHaveBorder={false}
                  headerSearch={true}
                  // headerSelectbox={true}

                  search={true}
                  // order={[[8, 'desc']]}
                  order={pageSelections?.sortingInfo?.length > 0 ? [[parseInt(pageSelections?.sortingInfo[0]), pageSelections?.sortingInfo[2]]] : [[7, 'desc']]}
                  minWidth="850px"
                  isMultiSelectEnabled={true}
                  detailedSearchChildren={
                    <DrPageFilter
                      searchDetail={<DrInventoryFilter detailedFilterData={detailedFilterData} detailedSearchClickCallBack={detailedSearchClick} />}
                      isSearchSavingActive={true}
                      savedFilters={inventoryPageFilters}
                      filterType={pageFilterTypes.inventory}
                      refreshClickedCallBack={refreshClickedCallBack}
                      selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                      searchKeyUpCallBack={(e) => {
                        if (e?.length > 0) {
                          let searchModel = {
                            keywords: e
                          }
                          setDetailedFilterData(searchModel);
                        }
                        else {
                          setDetailedFilterData(null);
                        }
                      }}
                      onlyFavouriteCallBack={(e) => {
                        setIsFavouriteProducts(e);
                      }}
                    >
                    </DrPageFilter>
                  }
                  pageLength={pageSelections?.pagelength || 20}

                  paginationPlugin={
                    <DrPagination
                      paginationData={
                        {
                          tableSkip: (pageSelections?.tableSkip || 0),
                          totalRecordCount: products?.totalRecordCount,// products?.totalRecordCount || pageSelections.tableLength,
                          tablePageLength: pageSelections?.pagelength || 20
                        }

                      }
                      tablePageLengthChanged={(e) => {
                        dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: pageSelections?.inventoryStatus, tableSkip: 0, tableLength: (products?.totalRecordCount || pageSelections.tableLength), detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: e, sortingInfo: pageSelections?.sortingInfo })));
                      }}
                      tablePageNumberChanged={(e) => {
                        dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: pageSelections?.inventoryStatus, tableSkip: e, tableLength: (products?.totalRecordCount || pageSelections.tableLength), detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
                      }}
                    >
                    </DrPagination>

                  }
                  tableSortOrderChanged={(e) => {
                    console.log("sortingInfo", e);
                    dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: pageSelections?.inventoryStatus, tableSkip: pageSelections?.tableSkip, tableLength: (products?.totalRecordCount || pageSelections.tableLength), detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: e })));
                  }}
                />
              </>
              }
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default InventoryPage;