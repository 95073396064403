import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiGet, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getProductKeepaDetailsSuccess } from '../actions/keepa.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetProductKeepaDetails(action) {
    const payload = action.payload;

    var path = `?Asin=${payload.asin}`;
    const response = yield call(apiGet(path, CONTROLLERS.KEEPA, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getProductKeepaDetailsSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error13') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* keepaSaga() {
    yield all([
        takeLatest(types.GET_PRODUCT_KEEPA_DETAILS_REQUESTED, safe(runGetProductKeepaDetails))
    ]);
}