import { React } from 'react';

function DrStars({ value = 0, review = 0, className = "fs-18 m-0 cursor-pointer", ...rest }) {

    return (
        <div className="row">
            <div className="col-auto p-0">
                <p className={className} {...rest} data-bs-placement="right" data-bs-toggle="tooltip"
                    title={value}>
                    <i className={`text-warning align-middle ${value ? (value > 0 && value < 1 ? "ri-star-half-s-fill" : (value >= 1 ? "ri-star-s-fill" : "ri-star-s-line")) : "ri-star-s-line"}`}></i>
                    <i className={`text-warning align-middle ${value ? (value > 1 && value < 2 ? "ri-star-half-s-fill" : (value >= 2 ? "ri-star-s-fill" : "ri-star-s-line")) : "ri-star-s-line"}`}></i>
                    <i className={`text-warning align-middle ${value ? (value > 2 && value < 3 ? "ri-star-half-s-fill" : (value >= 3 ? "ri-star-s-fill" : "ri-star-s-line")) : "ri-star-s-line"}`}></i>
                    <i className={`text-warning align-middle ${value ? (value > 3 && value < 4 ? "ri-star-half-s-fill" : (value >= 4 ? "ri-star-s-fill" : "ri-star-s-line")) : "ri-star-s-line"}`}></i>
                    <i className={`text-warning align-middle ${value ? (value > 4 && value < 5 ? "ri-star-half-s-fill" : (value >= 5 ? "ri-star-s-fill" : "ri-star-s-line")) : "ri-star-s-line"}`}></i>

                </p>
            </div>
            {value > 0 &&
                <div className="col-auto my-auto pt-1 px-0">
                    <span className="fs-12 fw-bold ">{value}</span>
                </div>
            }
            {/* {review > 0 &&
                <div className="col-auto my-auto pt-1 px-0">
                    <span className="fs-12 rounded bg-outline-warning text-warning ">{review}</span>
                </div>
            } */}

        </div>

    );
}

export default DrStars;
