import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrFilePondUploadInput, DrFileUploadInput, DrInput, DrReadMore, DrSweetAlertButton } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from "formik";
import $ from 'jquery';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import i18next from 'i18next';
import Restricted from '../../security/Restricted';
import { modals, staticRoles, sweetAlertVariants } from '../../utils/constants';
import noImage from '../../assets/img/users/profile1.png'
import { deleteWarehouseLostPackage, getWarehousesLostPackages, saveWarehouseLostPackage } from '../../state-management/actions/warehouses.actions';
import Swal from 'sweetalert2';
import { composeFormData } from '../../utils/utils';

import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// import "../../assets/libs/filepond-plugin-image-preview/filepond-plugin-image-preview.min.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function WarehouseLostPackagesPage() {
  const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN,REACT_APP_ROLES_SUSPENDED_ADMIN, REACT_APP_ROLES_STORE } = process.env;
  const { t } = useTranslation('warehouseLostPackagesPage');
  const warehouseLostPackages = useSelector(state => state.warehouses.warehouseLostPackages);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [packagesData, setPackagesData] = useState(null);
  const customerInfo = useSelector(state => state.customer.customerInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWarehousesLostPackages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = Object.assign([], warehouseLostPackages);
    data.forEach(product => {

      let pics = [];
      if (product.picture1?.length > 0) {
        pics.push(product.picture1);
      }
      if (product.picture2?.length > 0) {
        pics.push(product.picture2);
      }
      if (product.picture3?.length > 0) {
        pics.push(product.picture3);
      }
      product.pics = pics;
    });
    setPackagesData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseLostPackages]);


  const unCheckAllSelections = () => {
    $(document.querySelectorAll('#lostPackagesTable tr')).removeClass("selected");
    $(document.querySelectorAll('input.ckbox[type="checkbox"]:checked')).prop("checked", false);
  }

  const columns = [
    { data: "id", title: "", width: "5%", minWidth: "70px", searchable: false, checkall: true, checkData: "id" },
    { data: "packageCode", title: t("packageCode"), minWidth: "110px", maxWidth: "110px", searchable: true },
    { data: "asin", title: "Asin", minWidth: "110px", maxWidth: "110px", searchable: true },
    { data: "amazonOrderNumber", title: t("amazonOrderNumber"), minWidth: "150px", maxWidth: "150px", searchable: true },
    { data: "descriptions", title: t("descriptions"), minWidth: "100px", searchable: true },
    { data: null, title: "", minWidth: "50px", maxWidth: "50px", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <div className="card-aside-img cursor-pointer border border-gray-300 rounded container" style={{ textAlign: 'start', minWidth: '64px', position: 'relative' }}
              onClick={(e) => {
                e.preventDefault();
                if (rowData.pics.length > 0) {
                  dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: rowData.pics.map(x => x) || [noImage] }))
                }
              }} >
              <div className="row justify-content-start">
                {rowData.pics.length > 0 && rowData.pics.slice(0, (rowData.pics.length > 1 ? 2 : rowData.pics.length)).map((picSource, index) => {

                  return (
                    <div key={"product-pic-" + index} className={`px-0 rounded-0 ${rowData.pics.length > 1 ? "col-6 " : "col-12 "}`} >
                      <img src={picSource || noImage} className="img-thumbnail" alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                      ></img>
                    </div>
                  )
                })}

              </div>
              {rowData.pics.length > 2 &&
                <div className="row justify-content-start">
                  {rowData.pics.slice(2, (rowData.pics.length > 3 ? 4 : rowData.pics.length)).map((picSource, index) => {

                    return (
                      <div key={"product-pic-" + (index + 2)} className="col-6 px-0 rounded-0">
                        <img src={picSource || noImage} alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                        ></img>
                      </div>
                    )
                  })}

                </div>
              }
              {rowData.pics.length > 1 && <span className="badge bg-danger tx-bold" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>{rowData.pics.length}</span>}

            </div>
          </>
        )
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      width: "20%",
      searchable: true,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <span className="fs-12 my-auto mx-0 p-0">{rowData.packageCode}</span>
        );
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <span className="fs-12 my-auto mx-0 p-0">{rowData.asin}</span>
        );
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="fs-12 my-auto mx-0 p-0 text-break">{rowData.amazonOrderNumber}</span><br />
          </>);
      }
    },
    {
      targets: 4,
      orderable: true,
      searchable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <DrReadMore max={25} text={rowData.descriptions} classes='fs-12'
              data-bs-placement="right" data-bs-toggle="tooltip"
              title={rowData.descriptions}
            ></DrReadMore>
          </>
        );
      }
    },
    {
      targets: 5,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>
            {customerInfo?.role?.includes(REACT_APP_ROLES_STORE) &&

              <DrSweetAlertButton
                className="btn btn-icon btn-sm btn-danger btn-wave waves-effect waves-light shadow"
                buttonText={""}
                buttonIcon={<i className="fe fe-trash-2"></i>}
                onCloseConfirmCallBack={(value) => {
                  if (value) {
                    handleDeleteLostPackage(rowData.id);
                  }
                }}
                sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
              >
              </DrSweetAlertButton >
            }

          </div>
        )
      }
    }

  ];


  const initialValues = {

    id: selectedPackage?.id || null,
    packageCode: selectedPackage?.packageCode || "",
    asin: selectedPackage?.asin || "",
    customerId: selectedPackage?.customerId || "",
    customerMainSellerId: selectedPackage?.customerMainSellerId || "",
    amazonOrderNumber: selectedPackage?.amazonOrderNumber || "",
    pictures: selectedPackage?.pictures || [],
    descriptions: selectedPackage?.descriptions || "",
    picture1: selectedPackage?.picture1 || "",
    picture2: selectedPackage?.picture2 || "",
    picture3: selectedPackage?.picture3 || "",
  };

  const rowClickedCallBack = async (rowData) => {
    setSelectedPackage({ ...rowData });
    // dispatch(saveCustomerInfo(customerSubUserId));
  }


  const handleUpdateLostPackage = async (values) => {
    // let formData = new FormData();
    // composeFormData(formData, { ...values });

    // console.log(formData)
    dispatch(withActionPromise(saveWarehouseLostPackage(values)));
  }

  const handleDeleteLostPackage = async (value) => {
    await Promise.all([
      dispatch(withActionPromise(deleteWarehouseLostPackage(value)))
    ]).then((e) => {
      if (e) {
        setSelectedPackage(null);
      }
    });
  }


  return (
    <>
      <DrBreadCrump
        breadCrumps={[{ navigationText: customerInfo?.role?.includes(REACT_APP_ROLES_STORE) ? i18next.t('constants:breadCrumps.warehouseManagement') : i18next.t('constants:breadCrumps.orders'), navigationPath: "" },
        { navigationText: i18next.t('constants:breadCrumps.warehouseLostPackages'), navigationPath: "" }]} >
      </DrBreadCrump>
      <div className="row row-md row-lg d-flex justify-content-center">
        <div className="col-12">
          <div className="card custom-card">
            <div className="card-body">
              <Restricted allowedTo={[
                REACT_APP_ROLES_STORE
              ]}>
                <Formik
                  initialValues={initialValues}
                  validateOnBlur={true}
                  // onSubmit={handleSubmit}
                  // validationSchema={subUserInfoSchema}
                  enableReinitialize={true}
                  validateOnMount={true}
                >
                  {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    setTouched,
                    setFieldValue,
                    resetForm

                  }) => (
                    <form>
                      <div className={`row justify-content-end`} >
                        <div className="col-auto my-auto">
                          <Restricted allowedTo={[
                            REACT_APP_ROLES_SUPERADMIN,
                            REACT_APP_ROLES_ADMIN,
                            REACT_APP_ROLES_SUSPENDED_ADMIN,
                            REACT_APP_ROLES_STORE
                          ]}>
                            <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                              onClick={(e) => {
                                e.preventDefault();
                                unCheckAllSelections();
                                setSelectedPackage(null);
                                resetForm();
                              }}>
                              <i className="w-6 me-2 fa fa-plus"></i>
                              {t('addLostPackage')}
                            </button>
                          </Restricted>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-sm-5 col-md-4 col-lg-4">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group has-validation">
                                <label>{t('packageCode')}</label>
                                <DrInput value={values.packageCode} className={`form-control`} placeholder={t('packageCode')} name="packageCode" type="text"
                                  onChange={handleChange}
                                  onBlur={() => { setTouched({ ...touched, 'packageCode': true }) }}
                                  isInvalid={(touched.packageCode && errors.packageCode)}
                                  isValid={touched.packageCode && !errors.packageCode}
                                  validationText={errors.packageCode}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group has-validation">
                                <label>Asin</label>
                                <DrInput value={values.asin} className={`form-control`} placeholder="Asin" name="asin" type="text"
                                  onChange={handleChange}
                                  onBlur={() => { setTouched({ ...touched, 'asin': true }) }}
                                  isInvalid={(touched.asin && errors.asin)}
                                  isValid={touched.asin && !errors.asin}
                                  validationText={errors.asin}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group has-validation">
                                <label>{t('amazonOrderNumber')}</label>
                                <DrInput value={values.amazonOrderNumber} className={`form-control`} placeholder={t('amazonOrderNumber')} name="amazonOrderNumber" type="text"
                                  onChange={handleChange}
                                  onBlur={() => { setTouched({ ...touched, 'amazonOrderNumber': true }) }}
                                  isInvalid={(touched.amazonOrderNumber && errors.amazonOrderNumber)}
                                  isValid={touched.amazonOrderNumber && !errors.amazonOrderNumber}
                                  validationText={errors.amazonOrderNumber}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group has-validation">
                                <label>{t('customerId')}</label>
                                <DrInput value={values.customerId} className={`form-control`} placeholder={t('customerId')} name="customerId" type="text"
                                  onChange={handleChange}
                                  onBlur={() => { setTouched({ ...touched, 'customerId': true }) }}
                                  isInvalid={(touched.customerId && errors.customerId)}
                                  isValid={touched.customerId && !errors.customerId}
                                  validationText={errors.customerId}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group has-validation">
                                <label>{t('customerMainSellerId')}</label>
                                <DrInput value={values.customerMainSellerId} className={`form-control`} placeholder={t('customerMainSellerId')} name="customerMainSellerId" type="text"
                                  onChange={handleChange}
                                  onBlur={() => { setTouched({ ...touched, 'customerMainSellerId': true }) }}
                                  isInvalid={(touched.customerMainSellerId && errors.customerMainSellerId)}
                                  isValid={touched.customerMainSellerId && !errors.customerMainSellerId}
                                  validationText={errors.customerMainSellerId}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-7 col-md-8 col-lg-8">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group has-validation">
                                <label>{t('descriptions')}</label>
                                <textarea id="descriptions-textarea" className={`form-control py-0 w-100`} placeholder={t('descriptions')} type="text"
                                  style={{ minHeight: '162px', height: "auto" }}
                                  value={values.descriptions}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    var textarea = document.getElementById("descriptions-textarea");
                                    textarea.style.height = "";
                                    textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";

                                    setFieldValue("descriptions", e.currentTarget.value)
                                  }}
                                  onBlur={() => { setTouched({ ...touched.descriptions, 'descriptions': true }) }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-12">

                              <Field onBlur={() => { setTouched({ ...touched.pictures, 'pictures': true }) }}
                                name={'pictures'}
                                component={DrFilePondUploadInput}
                                placeholder={t('selectFile')}
                                selectedFiles={values.pictures}
                                accept={['image/*', 'image/png', 'image/gif', 'image/jpg', 'image/jpeg']}
                                maxFiles={3}
                                maxFileSize={512000}
                                removeFileTypeStringFromContent={false}
                                allowMultiple={true}
                                labelIdle={t('fileuploadLabel')}
                                onChange={e => {
                                  // Set currently active file objects to this.state

                                  setFieldValue("pictures", e.filePondItems);
                                  if (e.returnValueItems.length > 0) {
                                    setFieldValue("picture1", e.returnValueItems[0].attachment || "");
                                  }
                                  if (e.returnValueItems.length > 1) {
                                    setFieldValue("picture2", e.returnValueItems[1].attachment || "");
                                  }
                                  if (e.returnValueItems.length > 2) {
                                    setFieldValue("picture3", e.returnValueItems[2].attachment || "");
                                  }
                                }}
                              />


                              {/* <DrFilePondUploadInput
                              selectedFiles={values.pictures}
                              accept={['image/*']}
                              maxFiles={3}
                              maxFileSize={512000}
                              removeFileTypeStringFromContent={false}
                              allowMultiple={true}
                              onChange={e => {
                                // Set currently active file objects to this.state

                   
                                setFieldValue("pictures", e.filePondItems);
                                if (e.returnValueItems.length > 0) {
                                  setFieldValue("picture1", e.returnValueItems[0].attachment || "");
                                }
                                if (e.returnValueItems.length > 1) {
                                  setFieldValue("picture2", e.returnValueItems[1].attachment || "");
                                }
                                if (e.returnValueItems.length > 2) {
                                  setFieldValue("picture3", e.returnValueItems[2].attachment || "");
                                }
                              }}
                            >

                            </DrFilePondUploadInput> */}
                            </div>

                          </div>
                        </div>
                      </div>



                      <div className="row justify-content-end mt-2">

                        <div className="col-auto my-auto">
                          <Restricted allowedTo={[
                            REACT_APP_ROLES_SUPERADMIN,
                            REACT_APP_ROLES_ADMIN,
                            REACT_APP_ROLES_SUSPENDED_ADMIN,
                            REACT_APP_ROLES_STORE
                          ]}>
                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                              onClick={(e) => {
                                e.preventDefault();
                                if (isValid) {
                                  handleUpdateLostPackage(values);
                                }
                              }}
                            >{t('saveChanges')}</button>
                          </Restricted>
                        </div>
                      </div>
                    </form>
                  )
                  }
                </Formik>
              </Restricted>
              <div id="lostPackagesTable" className="row mb-5 mt-2">
                <div className="col-12 horizontal-scrollable">
                  <DrDataTable
                    data={packagesData}
                    columns={columns}
                    columnDefs={columnDefs}
                    doesHaveBorder={false}
                    headerSearch={false}
                    search={false}
                    minWidth="850px"
                    isRowClickEnabled={true}
                    rowClickedCallBack={rowClickedCallBack}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </>
  )
}

export default WarehouseLostPackagesPage;