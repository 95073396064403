import React, { createRef, useEffect, useRef } from 'react';
import * as THREE from 'three';
import $ from 'jquery';

function DrThreeDCube({ renderSizeX = 200, renderSizeY = 200, boxsizeX = 1, boxsizeY = 1, boxsizeZ = 1, maxEdgeValue = 3 }) {
    // const ref = useRef(null);
    // const threeDRef = createRef();
    const threeDRef = useRef(null);
    const getBoxGeometry = (x, y, z) => {

        let biggestEdge = 0;
        let divisionConstant = 1;

        if (Number(x) >= biggestEdge) {
            biggestEdge = Number(x);
        }
        if (Number(y) >= biggestEdge) {
            biggestEdge = Number(y);
        }

        if (Number(z) >= biggestEdge) {
            biggestEdge = Number(z);
        }

        if (biggestEdge > maxEdgeValue) {
            divisionConstant = Number(maxEdgeValue) / Number(biggestEdge);
        }

        return new THREE.BoxGeometry((x * divisionConstant), (y * divisionConstant), (z * divisionConstant));
    }


    useEffect(() => {
        if (threeDRef.current !== null) {

            let mouseX = 0;
            let mouseY = 0;

            const scene = new THREE.Scene();
            const camera = new THREE.PerspectiveCamera();
            const renderer = new THREE.WebGLRenderer();
            renderer.setSize(renderSizeX, renderSizeY);
            threeDRef.current.appendChild(renderer.domElement);

            const cubeColor1 = new THREE.Color("rgb(7, 104, 171)");
            const cubeColor2 = new THREE.Color("rgb(9, 173, 149)");
            const cubeColor3 = new THREE.Color("rgb(247, 183, 49)");

            scene.background = new THREE.Color("0xffffff");
            const materials = [
                // Front face 
                new THREE.MeshBasicMaterial({ color: cubeColor1 }),
                // Back face 
                new THREE.MeshBasicMaterial({ color: cubeColor1 }),
                // Top face
                new THREE.MeshBasicMaterial({ color: cubeColor2 }),
                // Bottom face 
                new THREE.MeshBasicMaterial({ color: cubeColor2 }),
                // Right face
                new THREE.MeshBasicMaterial({ color: cubeColor3 }),
                // Left face 
                new THREE.MeshBasicMaterial({ color: cubeColor3 })
            ];


            const geometry = getBoxGeometry(boxsizeX, boxsizeY, boxsizeZ);
            const material = new THREE.MeshBasicMaterial({ color: cubeColor1 });

            // const cube = new THREE.Mesh(new THREE.BoxGeometry(), materials);
            const cube = new THREE.Mesh(geometry, materials);
            scene.add(cube);

            camera.position.z = 5;

            const animate = () => {
                requestAnimationFrame(animate);

                cube.rotation.x += (mouseY - cube.rotation.x) * 0.05;
                cube.rotation.y += (mouseX - cube.rotation.y) * 0.05;

                renderer.render(scene, camera);
            };

            const onMouseMove = (event) => {
                mouseX = (event.clientX / 200) * 2 - 1;
                mouseY = -(event.clientY / 200) * 2 + 1;
            };

            animate();

            window.addEventListener('mousemove', onMouseMove);

            return () => {
                window.removeEventListener('mousemove', onMouseMove);
                if (threeDRef.current) {
                    threeDRef.current.removeChild(renderer.domElement);
                }

            };
        }

    }, [threeDRef.current, renderSizeX, renderSizeY, boxsizeX, boxsizeY, boxsizeZ, maxEdgeValue]);
    return (
        <>
            <div ref={threeDRef}></div>
        </>

    )
}


export default DrThreeDCube

