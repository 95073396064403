import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiGet, apiPost, apiPut, apiDelete, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getBlockedProductsAsins, getBlockedProductsAsinsSuccess, getBlockedWords, getInventoryProductDetailSuccess, getInventoryProductsWithDetail, getInventoryProductsWithDetailSuccess, getInventorySummarySuccess, getProductNonPricedAsins, getProductNonPricedAsinsSuccess, getProductsBrands, getProductsBrandsSuccess, getProductsCategories, getProductsCategoriesSuccess, getProductsDetailsByAsinsSuccess, setAsinPoolsSuccess, setBlockedWordsSuccess, setBrandPoolsSuccess, setProductHistoryListSuccess, setProductVariantsSuccess } from '../actions/inventory.actions';
import i18next from 'i18next';
import { getInventoryProductStatusCounts } from '../actions/reports.actions';
import Swal from 'sweetalert2';

function* runGetInventoryProductsWithDetail(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/GetProductList`, payload, false, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(getInventoryProductStatusCounts(payload.customerMainSellerId)),
            put(getInventoryProductsWithDetailSuccess({
                totalRecordCount: response.totalRecordCount,
                filterRecordCount: response.filterRecordCount,
                data: response.data
            }))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error9') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetInventoryProductDetail(action) {
    const payload = action.payload;

    var path = `/GetById?customerMainSellerProductId=${payload.customerMainSellerProductId}`;

    const response = yield call(apiGet(path, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getInventoryProductDetailSuccess(response.data))
        ]);
    }
    else {
        yield all([
            put(getInventoryProductDetailSuccess(null)),
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error10') + " " + (response && response.message ? response.message : ""), 'error'))
        ]);
    }
}

function* runGetProductsDetailsByAsins(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/GetByAsinList`, payload, false, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getProductsDetailsByAsinsSuccess(response.data)),
        ]);
    }
    else {
        yield all([
            put(getProductsDetailsByAsinsSuccess([])),
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error10') + " " + (response && response.message ? response.message : ""), 'error'))
        ]);
    }
}

function* runCreateInventoryProducts(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info5'), 'success'))
    }
}

function* runSaveProductIsFavourite(action) {
    const payload = action.payload;

    const response = yield call(apiPut(`/FavouriteProduct`, payload, false, CONTROLLERS.PRODUCT, false));
    if (response && response.isSuccess && response.data && response.resultType === 1) {
        if (payload.inventoryRequestData) {

            yield put(getInventoryProductsWithDetail(payload.inventoryRequestData));
        }
    }
}

function* runDeleteProductsByAsins(action) {
    const payload = action.payload;
    const response = yield call(apiPost(`/DeleteProduct`, payload.deleteRequestData, false, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info28'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error58') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runGetProductsCategories(action) {
    const payload = action.payload;

    var path = `/GetProductCategories?customerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.PRODUCTCATEGORY, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getProductsCategoriesSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error11') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetProductsBrands(action) {
    const payload = action.payload;

    var path = `/GetProductBrands?customerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.PRODUCTBRAND, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getProductsBrandsSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error12') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runProductUploadApprove(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/ProductUploadApprove`, payload.approveRequestData, false, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getInventoryProductsWithDetail(payload.inventoryRequestData)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error60') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCategoryBlocked(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/CategoryBlocked`, payload, false, CONTROLLERS.PRODUCTCATEGORY, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getProductsCategories(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runBrandBlocked(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/BrandBlocked`, payload, false, CONTROLLERS.PRODUCTBRAND, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getProductsBrands(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);

    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runGetAsinBlockedList(action) {
    const payload = action.payload;

    var path = `/GetAsinBlockedList?customerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getBlockedProductsAsinsSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runProductAsinBlock(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/CreateAsinBlocked`, payload, false, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getBlockedProductsAsins(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteAsinBlocked(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/DeleteAsinBlocked`, payload, false, CONTROLLERS.PRODUCT, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getBlockedProductsAsins(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetProductNonPricedAsinList(action) {
    const payload = action.payload;

    var path = `?customerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.PRODUCTNONPRICED, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getProductNonPricedAsinsSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSaveProductNonPriced(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.PRODUCTNONPRICED, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getProductNonPricedAsins(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);

    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteProductNonPriced(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/Delete`, payload, false, CONTROLLERS.PRODUCTNONPRICED, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getProductNonPricedAsins(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetBlockedWords(action) {
    var payload = action.payload;
    var path = `/GetForbiddenWord?CustomerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.SELLER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setBlockedWordsSuccess(response.data));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:warningTitle'), i18next.t('drSweetAlertButton:warningTexts.warning2'), 'warning'))
        ]);
    }
}

function* runUpdateBlockedWords(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/UpsertForbiddenWord`, payload, false, CONTROLLERS.SELLER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getBlockedWords(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info33'), 'success'))
        ]);
    }
    else {

        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

function* runDeleteBlockedWords(action) {
    const payload = action.payload;
    var path = `/DeleteForbiddenWord?id=${payload.id}`;

    const response = yield call(apiDelete(path, CONTROLLERS.SELLER, true, true));
    if (response && response.isSuccess) {

        yield all([
            put(getBlockedWords(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info34'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error65') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetProductVariants(action) {
    var payload = action.payload;

    const response = yield call(apiPost('/ProductVariants', payload, false, CONTROLLERS.PRODUCT, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setProductVariantsSuccess(response.data));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:warningTexts.error64'), 'error'))
        ]);
    }
}

function* runGetBrandPools(action) {

    const response = yield call(apiGet('/BrandPools', CONTROLLERS.SELLER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setBrandPoolsSuccess(response.data));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:warningTexts.error65'), 'error'))
        ]);
    }
}

function* runGetAsinPools(action) {

    const response = yield call(apiGet('/AsinPools', CONTROLLERS.SELLER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setAsinPoolsSuccess(response.data));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:warningTexts.error66'), 'error'))
        ]);
    }
}

function* runGetProductHistoryList(action) {
    var payload = action.payload;

    const response = yield call(apiPost('/ProductHistoryList', payload, false, CONTROLLERS.PRODUCT, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setProductHistoryListSuccess(response.data));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:warningTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

function* runProductSafeBrand(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/UpsertProductSafeBrand`, payload, false, CONTROLLERS.PRODUCTBRAND, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);

    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runGetInventorySummary(action) {
    const payload = action.payload;
    var path = `/GetInventory?CustomerMainSellerId=${payload.customerMainSellerId}`;

    const response = yield call(apiGet(path, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getInventorySummarySuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* inventorySaga() {
    yield all([
        takeLatest(types.CREATE_NEW_PRODUCTS_REQUESTED, safe(runCreateInventoryProducts)),
        takeLatest(types.SAVE_PRODUCT_IS_FAVOURITE_REQUESTED, safe(runSaveProductIsFavourite)),
        takeLatest(types.DELETE_PRODUCTS_REQUESTED, safe(runDeleteProductsByAsins)),
        takeLatest(types.GET_INVENTORY_PRODUCTS_WITH_DETAIL_REQUESTED, safe(runGetInventoryProductsWithDetail)),
        takeLatest(types.GET_INVENTORY_PRODUCT_DETAIL_REQUESTED, safe(runGetInventoryProductDetail)),
        takeLatest(types.GET_PRODUCTS_DETAILS_REQUESTED, safe(runGetProductsDetailsByAsins)),
        takeLatest(types.GET_PRODUCTS_CATEGORIES_REQUESTED, safe(runGetProductsCategories)),
        takeLatest(types.GET_PRODUCTS_BRANDS_REQUESTED, safe(runGetProductsBrands)),
        takeLatest(types.SAVE_PRODUCT_IS_APPROVED_REQUESTED, safe(runProductUploadApprove)),
        takeLatest(types.SAVE_PRODUCT_CATEGORY_IS_BLOCKED_REQUESTED, safe(runCategoryBlocked)),
        takeLatest(types.SAVE_PRODUCT_BRAND_IS_BLOCKED_REQUESTED, safe(runBrandBlocked)),
        takeLatest(types.SAVE_PRODUCT_ASIN_IS_BLOCKED_REQUESTED, safe(runProductAsinBlock)),
        takeLatest(types.SAVE_PRODUCT_ASIN_IS_UNBLOCKED_REQUESTED, safe(runDeleteAsinBlocked)),
        takeLatest(types.GET_BLOCKED_PRODUCTS_REQUESTED, safe(runGetAsinBlockedList)),
        takeLatest(types.GET_NON_PRIZED_PRODUCTS_REQUESTED, safe(runGetProductNonPricedAsinList)),
        takeLatest(types.SAVE_PRODUCT_ASIN_IS_NON_PRIZED_REQUESTED, safe(runSaveProductNonPriced)),
        takeLatest(types.SAVE_PRODUCT_ASIN_IS_NOT_NON_PRIZED_REQUESTED, safe(runDeleteProductNonPriced)),
        takeLatest(types.GET_BLOCKED_WORDS_REQUESTED, safe(runGetBlockedWords)),
        takeLatest(types.UPDATE_BLOCKED_WORDS_REQUESTED, safe(runUpdateBlockedWords)),
        takeLatest(types.DELETE_BLOCKED_WORDS_REQUESTED, safe(runDeleteBlockedWords)),
        takeLatest(types.GET_PRODUCT_VARIANTS_REQUESTED, safe(runGetProductVariants)),
        takeLatest(types.GET_BRAND_POOLS_REQUESTED, safe(runGetBrandPools)),
        takeLatest(types.GET_ASIN_POOLS_REQUESTED, safe(runGetAsinPools)),
        takeLatest(types.GET_PRODUCT_HISTORY_LIST_REQUESTED, safe(runGetProductHistoryList)),
        takeLatest(types.UPSERT_PRODUCT_SAFE_BRAND_REQUESTED, safe(runProductSafeBrand)),
        takeLatest(types.GET_INVENTORY_SUMMARY_REQUESTED, safe(runGetInventorySummary)),

    ]);
}