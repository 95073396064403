const types = {
    CREATE_NEW_PRODUCTS_REQUESTED: "CREATE_NEW_PRODUCTS_REQUESTED",
    SAVE_PRODUCT_IS_FAVOURITE_REQUESTED: "SAVE_PRODUCT_IS_FAVOURITE_REQUESTED",
    SAVE_PRODUCT_IS_FAVOURITE_SUCCESS: "SAVE_PRODUCT_IS_FAVOURITE_SUCCESS",
    GET_INVENTORY_PRODUCTS_WITH_DETAIL_REQUESTED: "GET_INVENTORY_PRODUCTS_WITH_DETAIL_REQUESTED",
    GET_INVENTORY_PRODUCTS_WITH_DETAIL_SUCCESS: "GET_INVENTORY_PRODUCTS_WITH_DETAIL_SUCCESS",
    GET_INVENTORY_PRODUCT_DETAIL_REQUESTED: "GET_INVENTORY_PRODUCT_DETAIL_REQUESTED",
    GET_INVENTORY_PRODUCT_DETAIL_SUCCESS: "GET_INVENTORY_PRODUCT_DETAIL_SUCCESS",
    GET_PRODUCTS_DETAILS_REQUESTED: "GET_PRODUCTS_DETAILS_REQUESTED",
    GET_PRODUCTS_DETAILS_SUCCESS: "GET_PRODUCTS_DETAILS_SUCCESS",
    CLEAR_PRODUCTS_DETAILS: "CLEAR_PRODUCTS_DETAILS",
    CLEAR_ALL_PRODUCTS: "CLEAR_ALL_PRODUCTS",
    GET_PRODUCTS_CATEGORIES_REQUESTED: "GET_PRODUCTS_CATEGORIES_REQUESTED",
    GET_PRODUCTS_CATEGORIES_SUCCESS: "GET_PRODUCTS_CATEGORIES_SUCCESS",
    SAVE_PRODUCT_CATEGORY_IS_BLOCKED_REQUESTED: "SAVE_PRODUCT_CATEGORY_IS_BLOCKED_REQUESTED",
    SAVE_PRODUCT_CATEGORY_IS_BLOCKED_SUCCESS: "SAVE_PRODUCT_CATEGORY_IS_BLOCKED_SUCCESS",

    GET_PRODUCTS_BRANDS_REQUESTED: "GET_PRODUCTS_BRANDS_REQUESTED",
    GET_PRODUCTS_BRANDS_SUCCESS: "GET_PRODUCTS_BRANDS_SUCCESS",
    SAVE_PRODUCT_BRAND_IS_BLOCKED_REQUESTED: "SAVE_PRODUCT_BRAND_IS_BLOCKED_REQUESTED",
    SAVE_PRODUCT_BRAND_IS_BLOCKED_SUCCESS: "SAVE_PRODUCT_BRAND_IS_BLOCKED_SUCCESS",

    DELETE_PRODUCTS_REQUESTED: "DELETE_PRODUCTS_REQUESTED",
    SAVE_PRODUCT_IS_APPROVED_REQUESTED: "SAVE_PRODUCT_IS_APPROVED_REQUESTED",
    SAVE_PRODUCT_IS_APPROVED_SUCCESS: "SAVE_PRODUCT_IS_APPROVED_SUCCESS",

    GET_BLOCKED_PRODUCTS_REQUESTED: "GET_BLOCKED_PRODUCTS_REQUESTED",
    GET_BLOCKED_PRODUCTS_SUCCESS: "GET_BLOCKED_PRODUCTS_SUCCESS",
    SAVE_PRODUCT_ASIN_IS_BLOCKED_REQUESTED: "SAVE_PRODUCT_ASIN_IS_BLOCKED_REQUESTED",
    SAVE_PRODUCT_ASIN_IS_BLOCKED_SUCCESS: "SAVE_PRODUCT_ASIN_IS_BLOCKED_SUCCESS",
    SAVE_PRODUCT_ASIN_IS_UNBLOCKED_REQUESTED: "SAVE_PRODUCT_ASIN_IS_UNBLOCKED_REQUESTED",
    SAVE_PRODUCT_ASIN_IS_UNBLOCKED_SUCCESS: "SAVE_PRODUCT_ASIN_IS_UNBLOCKED_SUCCESS",

    GET_NON_PRIZED_PRODUCTS_REQUESTED: "GET_NON_PRIZED_PRODUCTS_REQUESTED",
    GET_NON_PRIZED_PRODUCTS_SUCCESS: "GET_NON_PRIZED_PRODUCTS_SUCCESS",
    SAVE_PRODUCT_ASIN_IS_NON_PRIZED_REQUESTED: "SAVE_PRODUCT_ASIN_IS_NON_PRIZED_REQUESTED",
    SAVE_PRODUCT_ASIN_IS_NON_PRIZED_SUCCESS: "SAVE_PRODUCT_ASIN_IS_NON_PRIZED_SUCCESS",
    SAVE_PRODUCT_ASIN_IS_NOT_NON_PRIZED_REQUESTED: "SAVE_PRODUCT_ASIN_IS_NOT_NON_PRIZED_REQUESTED",
    SAVE_PRODUCT_ASIN_IS_NOT_NON_PRIZED_SUCCESS: "SAVE_PRODUCT_ASIN_IS_NOT_NON_PRIZED_SUCCESS",

    GET_BLOCKED_WORDS_REQUESTED: "GET_BLOCKED_WORDS_REQUESTED",
    SET_BLOCKED_WORDS_SUCCESS: "SET_BLOCKED_WORDS_SUCCESS",
    UPDATE_BLOCKED_WORDS_REQUESTED: "UPDATE_BLOCKED_WORDS_REQUESTED",
    DELETE_BLOCKED_WORDS_REQUESTED: "DELETE_BLOCKED_WORDS_REQUESTED",

    GET_PRODUCT_VARIANTS_REQUESTED: "GET_PRODUCT_VARIANTS_REQUESTED",
    SET_PRODUCT_VARIANTS_SUCCESS: "SET_PRODUCT_VARIANTS_SUCCESS",

    GET_BRAND_POOLS_REQUESTED: "GET_BRAND_POOLS_REQUESTED",
    SET_BRAND_POOLS_SUCCESS: "SET_BRAND_POOLS_SUCCESS",
    
    GET_ASIN_POOLS_REQUESTED: "GET_ASIN_POOLS_REQUESTED",
    SET_ASIN_POOLS_SUCCESS: "SET_ASIN_POOLS_SUCCESS",
    
    GET_PRODUCT_HISTORY_LIST_REQUESTED: "GET_PRODUCT_HISTORY_LIST_REQUESTED",
    SET_PRODUCT_HISTORY_LIST_SUCCESS: "SET_PRODUCT_HISTORY_LIST_SUCCESS",

    UPSERT_PRODUCT_SAFE_BRAND_REQUESTED: "UPSERT_PRODUCT_SAFE_BRAND_REQUESTED",

    GET_INVENTORY_SUMMARY_REQUESTED: "GET_INVENTORY_SUMMARY_REQUESTED",
    GET_INVENTORY_SUMMARY_SUCCESS: "GET_INVENTORY_SUMMARY_SUCCESS",
}

export default types;

export const createNewProducts = (payload) => {
    return { type: types.CREATE_NEW_PRODUCTS_REQUESTED, payload };
};

export const saveProductIsFavourite = (payload) => {
    return { type: types.SAVE_PRODUCT_IS_FAVOURITE_REQUESTED, payload };
};

export const saveProductIsFavouriteSuccess = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCT_DETAIL_SUCCESS, payload };
};

export const deleteProductsByAsins = (payload) => {
    return { type: types.DELETE_PRODUCTS_REQUESTED, payload };
};

export const getInventoryProductsWithDetail = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCTS_WITH_DETAIL_REQUESTED, payload };
};

export const getInventoryProductsWithDetailSuccess = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCTS_WITH_DETAIL_SUCCESS, payload };
};

export const getInventoryProductDetail = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCT_DETAIL_REQUESTED, payload };
};

export const getInventoryProductDetailSuccess = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCT_DETAIL_SUCCESS, payload };
};

export const getProductsDetailsByAsins = (payload) => {
    return { type: types.GET_PRODUCTS_DETAILS_REQUESTED, payload };
};

export const getProductsDetailsByAsinsSuccess = (payload) => {
    return { type: types.GET_PRODUCTS_DETAILS_SUCCESS, payload };
};

export const clearProductsDetails = () => {
    return { type: types.CLEAR_PRODUCTS_DETAILS };
};

export const clearInventoryProducts = () => {
    return { type: types.CLEAR_ALL_PRODUCTS };
};

export const getProductsCategories = (payload) => {
    return { type: types.GET_PRODUCTS_CATEGORIES_REQUESTED, payload };
};

export const getProductsCategoriesSuccess = (payload) => {
    return { type: types.GET_PRODUCTS_CATEGORIES_SUCCESS, payload };
};

export const getProductsBrands = (payload) => {
    return { type: types.GET_PRODUCTS_BRANDS_REQUESTED, payload };
};

export const getProductsBrandsSuccess = (payload) => {
    return { type: types.GET_PRODUCTS_BRANDS_SUCCESS, payload };
};

export const saveProductUploadApprove = (payload) => {
    return { type: types.SAVE_PRODUCT_IS_APPROVED_REQUESTED, payload };
};

export const saveProductUploadApproveSuccess = (payload) => {
    return { type: types.SAVE_PRODUCT_IS_APPROVED_SUCCESS, payload };
};

export const saveProductCategoryBlock = (payload) => {
    return { type: types.SAVE_PRODUCT_CATEGORY_IS_BLOCKED_REQUESTED, payload };
};

export const saveProductCategoryBlockSuccess = (payload) => {
    return { type: types.SAVE_PRODUCT_CATEGORY_IS_BLOCKED_SUCCESS, payload };
};

export const saveProductBrandBlock = (payload) => {
    return { type: types.SAVE_PRODUCT_BRAND_IS_BLOCKED_REQUESTED, payload };
};

export const saveProductBrandBlockSuccess = (payload) => {
    return { type: types.SAVE_PRODUCT_BRAND_IS_BLOCKED_SUCCESS, payload };
};


export const getBlockedProductsAsins = (payload) => {
    return { type: types.GET_BLOCKED_PRODUCTS_REQUESTED, payload };
};

export const getBlockedProductsAsinsSuccess = (payload) => {
    return { type: types.GET_BLOCKED_PRODUCTS_SUCCESS, payload };
};

export const saveProductAsinBlock = (payload) => {
    return { type: types.SAVE_PRODUCT_ASIN_IS_BLOCKED_REQUESTED, payload };
};

export const saveProductAsinBlockSuccess = (payload) => {
    return { type: types.SAVE_PRODUCT_ASIN_IS_BLOCKED_SUCCESS, payload };
};

export const saveProductAsinUnBlock = (payload) => {
    return { type: types.SAVE_PRODUCT_ASIN_IS_UNBLOCKED_REQUESTED, payload };
};

export const saveProductAsinUnBlockSuccess = (payload) => {
    return { type: types.SAVE_PRODUCT_ASIN_IS_UNBLOCKED_SUCCESS, payload };
};


export const getProductNonPricedAsins = (payload) => {
    return { type: types.GET_NON_PRIZED_PRODUCTS_REQUESTED, payload };
};

export const getProductNonPricedAsinsSuccess = (payload) => {
    return { type: types.GET_NON_PRIZED_PRODUCTS_SUCCESS, payload };
};

export const saveProductNonPriced = (payload) => {
    return { type: types.SAVE_PRODUCT_ASIN_IS_NON_PRIZED_REQUESTED, payload };
};

export const saveProductNonPricedSuccess = (payload) => {
    return { type: types.SAVE_PRODUCT_ASIN_IS_NON_PRIZED_SUCCESS, payload };
};

export const saveDeleteProductNonPriced = (payload) => {
    return { type: types.SAVE_PRODUCT_ASIN_IS_NOT_NON_PRIZED_REQUESTED, payload };
};

export const saveDeleteProductNonPricedSuccess = (payload) => {
    return { type: types.SAVE_PRODUCT_ASIN_IS_NOT_NON_PRIZED_SUCCESS, payload };
};

export const getBlockedWords = (payload) => {
    return { type: types.GET_BLOCKED_WORDS_REQUESTED, payload };
};

export const setBlockedWordsSuccess = (payload) => {
    return { type: types.SET_BLOCKED_WORDS_SUCCESS, payload };
};

export const updateBlockedWords = (payload) => {
    return { type: types.UPDATE_BLOCKED_WORDS_REQUESTED, payload };
}

export const deleteBlockedWords = (payload) => {
    return { type: types.DELETE_BLOCKED_WORDS_REQUESTED, payload };
};

export const getProductVariants = (payload) => {
    return { type: types.GET_PRODUCT_VARIANTS_REQUESTED, payload };
}

export const setProductVariantsSuccess = (payload) => {
    return { type: types.SET_PRODUCT_VARIANTS_SUCCESS, payload };
}

export const getBrandPools = (payload) => {
    return { type: types.GET_BRAND_POOLS_REQUESTED, payload };
}

export const setBrandPoolsSuccess = (payload) => {
    return { type: types.SET_BRAND_POOLS_SUCCESS, payload };
}

export const getAsinPools = (payload) => {
    return { type: types.GET_ASIN_POOLS_REQUESTED, payload };
}

export const setAsinPoolsSuccess = (payload) => {
    return { type: types.SET_ASIN_POOLS_SUCCESS, payload };
}

export const getProductHistoryList = (payload) => {
    return { type: types.GET_PRODUCT_HISTORY_LIST_REQUESTED, payload };
}

export const setProductHistoryListSuccess = (payload) => {
    return { type: types.SET_PRODUCT_HISTORY_LIST_SUCCESS, payload };
}

export const upsertProductSafeBrand = (payload) => {
    return { type: types.UPSERT_PRODUCT_SAFE_BRAND_REQUESTED, payload };
}

export const getInventorySummary = (payload) => {
    return { type: types.GET_INVENTORY_SUMMARY_REQUESTED, payload };
};

export const getInventorySummarySuccess = (payload) => {
    return { type: types.GET_INVENTORY_SUMMARY_SUCCESS, payload };
};