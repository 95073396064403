import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { buttonVariants, modals, pageFilterTypes } from "../../../utils/constants";
import { setActivePageFiltersModal } from "../../../state-management/actions/app.actions";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { DrButtonDropdown, DrDualDatePicker, DrInput } from "../../component-index";
import { selectedRowsOperationOptions, timeOptions } from "../Constants/dr-constants.component";
import moment from "moment";

function DrPageFilter({ searchDetail, savedFilters = [], filterType, isSearchSavingActive = false, isSearchActive = true, pageFilterSelectedCallBack, refreshClickedCallBack, selectedRowsOperationsCallBack, selectedDates = [], timeIntervalOptionCallBack, searchKeyUpCallBack, isFavourite = false, onlyFavouriteCallBack }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drPageFilter');
    const [dates, setDates] = useState(selectedDates);
    const [onlyFavourite, setOnlyFavourite] = useState(isFavourite);
    const [searchKeyWord, setSearchKeyWord] = useState("");

    useEffect(() => {
        if (savedFilters && savedFilters.length > 0) {
            if (pageFilterSelectedCallBack) {
                pageFilterSelectedCallBack(savedFilters[0]);
            }
        }

        // let searchContent = document.getElementById("detailedSearchContent");
        let searchContent = document.getElementsByClassName("panel-collapse");
        if (searchContent && searchContent?.length > 0) {
            searchContent[0].addEventListener("show.bs.collapse", function (e) {
                if (e?.target?.id === "detailedSearchContent") {
                    $("#searchBox").addClass("d-none");
                    if(onlyFavouriteCallBack){
                        $("#favouriteBox").addClass("d-none");
                    }
                }
            });
            searchContent[0].addEventListener("hide.bs.collapse", function (e) {
                if (e?.target?.id === "detailedSearchContent") {
                    $("#searchBox").removeClass("d-none");
                    if(onlyFavouriteCallBack){
                        $("#favouriteBox").removeClass("d-none");
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedFilters]);

    useEffect(() => {
        setOnlyFavourite(isFavourite)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFavourite]);


    const filterSelectedCallBack = (e, filter) => {
        if (pageFilterSelectedCallBack) {
            pageFilterSelectedCallBack(filter);
        }
    };

    const selectedRowsOperation = (checkedValues, selectedOperation, filterType) => {
        if (selectedRowsOperationsCallBack) {
            selectedRowsOperationsCallBack(checkedValues, selectedOperation, filterType);
        }
    };

    const selectedDateIntervalOperation = (dateValues) => {
        if (timeIntervalOptionCallBack) {
            var dates = [(dateValues[0] ? moment.utc(dateValues[0].toString(), "YYYY/MM/DD").startOf('day').format() : null), (dateValues[1] ? moment.utc(dateValues[1].toString(), "YYYY/MM/DD").startOf('day').format() : null)];
            timeIntervalOptionCallBack(dates);
        }
    };


    return (
        <div className="pb-1">
            <div className="row justify-content-center justify-content-sm-between justify-content-md-between justify-content-lg-between justify-content-xxl-between align-items-center">
                <div className="col-auto">
                    <div className="row">
                        {refreshClickedCallBack && <div className="col-auto my-1 px-0">
                            <button className="btn btn-icon btn-teal btn-wave waves-effect waves-light"
                                onClick={(e) => {
                                    e.preventDefault();
                                    refreshClickedCallBack();
                                }}>
                                <i className="fe fe-refresh-cw" aria-hidden="true"></i>
                            </button>
                        </div>}
                        <div id="searchBox" className="col-auto my-1">
                            {isSearchActive &&

                                <>
                                    <DrInput type="text" id="dtableSearch" className="form-control rounded-right" placeholder="Search..."
                                        onKeyUp={(e) => {
                                            if (searchKeyUpCallBack) {
                                                // searchKeyUpCallBack($("#dtableSearch").val());
                                                // setSearchKeyWord($("#dtableSearch").val());
                                                if (e.key === 'Enter' || e.keyCode === 13) {
                                                    // $("#dTableSearchButton").click();
                                                    searchKeyUpCallBack($("#dtableSearch").val());
                                                }
                                                else if (!($("#dtableSearch").val()?.length > 0)) {
                                                    $("#dTableSearchButton").click();
                                                    searchKeyUpCallBack($("#dtableSearch").val());
                                                }

                                            }
                                            else {
                                                var table = $('#dtable').DataTable();
                                                if (table) {
                                                    table.search($("#dtableSearch").val()).draw();
                                                }
                                            }
                                        }}
                                        // onKeyDown={(e) => {
                                        //     if (searchKeyUpCallBack) {
                                        //         if (!($("#dtableSearch").val()?.length > 0)) {
                                        //             $("#dTableSearchButton").click();
                                        //         }

                                        //     }
                                        // }}
                                        icon={<i className="fe fe-search"></i>}
                                        iconPosition="left"
                                    />
                                    {/* <button id="dTableSearchButton" className="btn btn-icon "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (searchKeyUpCallBack) {
                                                searchKeyUpCallBack($("#dtableSearch").val());
                                            }
                                        }}></button> */}
                                </>
                            }
                        </div>
                        {onlyFavouriteCallBack && <div id="favouriteBox" className="col-auto my-auto px-0">
                            <DrInput className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyFavourite" type="checkbox"
                                value=""
                                checked={onlyFavourite}
                                onChange={(e) => {
                                    onlyFavouriteCallBack(e.target.checked);
                                    setOnlyFavourite(e.target.checked);
                                }}
                                label={t("datatable.showOnlyFavourite")}
                            />
                        </div>}
                    </div>
                </div>
                <div className="col-auto p-0">
                    <div className="row justify-content-center justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xxl-end align-items-center">
                        {timeIntervalOptionCallBack &&
                            <div className="col-auto my-1 ps-0">
                                <DrDualDatePicker
                                    className="shadow"
                                    selectedDates={dates}
                                    onCloseCallBack={(dateValues) => {
                                        selectedDateIntervalOperation(dateValues);
                                    }}
                                    timeOptionsSource={timeOptions}
                                ></DrDualDatePicker>
                            </div>
                        }

                        {searchDetail &&
                            <div className="col-auto my-1 ps-0">

                                <button className="btn btn-primary-ghost btn-wave waves-effect waves-light shadow me-1 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent">
                                    {t('detailedSearch')}
                                    <i className="fe fe-chevron-down ms-2" />
                                </button>

                            </div>
                        }
                        {(searchDetail && isSearchSavingActive) &&
                            <div className="col-auto my-1 ps-0">

                                <button className="btn btn-primary btn-wave waves-effect waves-light shadow me-1" type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(setActivePageFiltersModal({ name: modals.savedPageFilters, isGlobal: true, filtersSource: savedFilters, filterType: filterType, filterSelectedCallBack: filterSelectedCallBack }))
                                    }}>
                                    {t('savedFilters')}<i className="fe fe-search ms-2" />
                                </button>

                            </div>
                        }
                        {selectedRowsOperationsCallBack && selectedRowsOperationOptions.filter(x => x.pagetype === filterType || x.pagetype === pageFilterTypes.all)?.length > 0 &&
                            <div className="col-auto my-1 ps-0">
                                <DrButtonDropdown fixedTextKey="options" className="text-primary" options={selectedRowsOperationOptions.filter(x => x.pagetype === filterType || x.pagetype === pageFilterTypes.all).sort((a, b) => a.sortOrder - b.sortOrder)} variant={buttonVariants.primaryGhost}
                                    optionClickCallBack={(e) => {
                                        var checkedValues = Array.from(document.querySelectorAll('#dtable input[type="checkbox"]'))
                                            .filter((checkbox) => checkbox.checked && (checkbox.classList.contains('ckbox') || checkbox.classList.contains('ckboxsub')))
                                            .map((checkbox) => {
                                                return checkbox.value;
                                            });

                                        selectedRowsOperation(checkedValues, e, filterType);
                                    }}>
                                </DrButtonDropdown>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {searchDetail && <div className="panel-collapse collapse pb-3" id="detailedSearchContent">
                {searchDetail}
            </div>}
        </div>
    )
}

export default DrPageFilter