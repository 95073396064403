import React, { useEffect, useState } from 'react'
import { DrNavigator, DrWarehouseSelection } from '../../components/component-index';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { setShippingIds, setSnapshotStorePageSubmission } from '../../state-management/actions/create-store.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import { getWarehouseShippings, getWarehouses } from '../../state-management/actions/warehouses.actions';
import { staticWarehouseSelectionComponentKey } from '../../utils/constants';
import { sourceMarketOptions } from '../../components/hoc/Constants/dr-constants.component';

function WarehouseSelectionPage() {
    const dispatch = useDispatch();
    const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
    const allWarehouseShippings = useSelector(state => state.warehouses.allWarehouseShippings);
    const marketPreferences = useSelector(state => state.createStoreSnapshot.marketPreferences);
    const [warehousesData, setWarehousesData] = useState([]);

    useEffect(() => {
        var request= {
            marketId: marketPreferences?.sourceMarkets?.length>0 ? marketPreferences?.sourceMarkets[0] : sourceMarketOptions.find(x => x.countryCode === "US")?.value 
        }
        dispatch(getWarehouses(request));
        dispatch(getWarehouseShippings(request));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allWarehouses) {
            let data = Object.assign([], allWarehouses);
            data.forEach(warehouse => {
                warehouse.warehouseShippings = Object.assign([], allWarehouseShippings && allWarehouseShippings.find(x => x.wareHouseId === warehouse.id)?.shippings);
                warehouse.marketId = marketPreferences?.sourceMarkets?.length>0 ? marketPreferences?.sourceMarkets[0] : sourceMarketOptions.find(x => x.countryCode === "US")?.value
            });
            setWarehousesData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouses, allWarehouseShippings]);

    const initialValues = {
        selectedIds: [],
        warehouses: warehousesData
    }

    const handleSubmit = async (values) => {

    }

    const warehouseShippingsSelectedCallback = async (values) => {

    }

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            onSubmit={handleSubmit}
            //validationSchema={storeSettingsSchema}
            enableReinitialize={true}
            validateOnMount={true}
            initialTouched={true}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setTouched,
                setFieldValue
            }) => (
                <div className="content clearfix">
                    <form name="warehouse-selection-form" className="needs-validation" autoComplete="off">
                        <section>
                            <div className="row row-md d-flex justify-content-center">
                                <div className="col-12">
                                    <h3 className="text-center">Warehouse Selection</h3>
                                </div>
                            </div>
                            <div className="row row-md row-lg justify-content-center">
                                <div className="col-12">
                                    {/* {<DrWarehouseSelection componentKey={"wrSelection"} isCreateStore={true} onWarehouseSelected={onWarehouseSelected}>
                                            </DrWarehouseSelection>} */}
                                    <Field target={document.body}
                                        onBlur={() => { setTouched({ ...touched, 'selectedIds': true }) }} label={'selectedIds'} name={'selectedIds'}
                                        componentKey={staticWarehouseSelectionComponentKey.selectWarehouseSuit}
                                        component={DrWarehouseSelection}
                                        source={values.warehouses}
                                        warehouseShippingsSelectedCallback={(values) => {
                                            setFieldValue("selectedIds", values);
                                        }}
                                    >

                                    </Field>
                                </div>
                            </div>
                        </section>
                        {<DrNavigator backAddress="STORE_PREFERENCES" nextAddress="CONNECT_AMAZON" disabled={!isValid}
                            callback={() => {
                                return Promise.all([
                                    dispatch(withActionPromise(setShippingIds(values.selectedIds))),
                                    dispatch(withActionPromise(dispatch(setSnapshotStorePageSubmission({ wareHouseSelectionPage: true }))))
                                ]);
                            }}
                        />}
                    </form>
                </div>
            )
            }
        </Formik>
    )
}

export default WarehouseSelectionPage;