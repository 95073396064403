import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { DrButtonDropdown, DrDualDatePicker, DrInput, DrSelectBox } from '../../component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonVariants, modals, pageFilterTypes } from '../../../utils/constants';
import { clearActiveSavePagefilterModal, setActiveSavePagefilterModal, withActionPromise } from '../../../state-management/actions/app.actions';
import { flaggedCountryOptions, inventoryPageOptions, maxDeliveryDaysAll, operationsOptions } from '../Constants/dr-constants.component';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { setInventoryPageSelections } from '../../../state-management/actions/settings.actions';

function DrInventoryFilter({ detailedFilterData, detailedSearchClickCallBack }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('inventoryPageFilter');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const pageFilters = useSelector(state => state.pageFilter);
    const productCategories = useSelector(state => state.inventory.productsCategories);
    const productsBrands = useSelector(state => state.inventory.productsBrands);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.inventoryPageSelections);
    const [filter, setFilter] = useState(null);

    const defaultValues = {

        onlySoldByMe: false,
        onlyIfNoBuyboxPriceAtDestinationMarket: false,

        onlyIfMeBuyboxSeller: false,
        onlyIfMeNotBuyboxSeller: false,

        onlyIfMeLowestSeller: false,
        onlyIfMeNotLowestSeller: false,

        onlyIfPrimeSellersAtDestinationMarket: false,
        onlyIfNoPrimeSellersAtDestinationMarket: false,

        onlyIfWithSalesRankAtDestinationMarket: false,
        onlyIfNoSalesRankAtDestinationMarket: false,

        onlyIfWithChineseSellersAtDestinationMarket: false,
        onlyIfNoChineseSellersAtDestinationMarket: false,

        onlyIfWithAmazonSellersAtDestinationMarket: false,
        onlyIfNoAmazonSellersAtDestinationMarket: false,

        onlyIfProductPriceIsMin: false,
        onlyIfProductPriceIsMax: false,


        onlyIfShippingPriceIsHigherThanPriceAtSourceMarket: false,
        onlyIfUnAvailableProductsAtSourceMarket: false,
        onlyIfBrandAndSellerNameIsSameAtSourceMarket: false,

        onlyIfWithSalesRankAtSourceMarket: false,
        onlyIfNoSalesRankAtSourceMarket: false,

        onlyIfSoldByAmazonAtSourceMarket: false,
        onlyIfNotSoldByAmazonAtSourceMarket: false,

        onlyIfFBAProductsAtSourceMarket: false,
        onlyIfNoFBAProductsAtSourceMarket: false,

        onlyIfWithCouponAtSourceMarket: false,
        onlyIfNoCouponAtSourceMarket: false,


        onlyIfChangeToFulfilledByAmazon: false,
        onlyIfWithDiscount: false,
        onlyIfNoShip: false,
        onlyIfNoShippingInfo: false,
        onlyIfNoImportFeeInfo: false,
        onlyIfPriceAppearsInCart: false,
        onlyIfNoPriceInfo: false,

        onlyIfAsinIsDifferent: false,
        onlyIfProfitBasedOnAsin: false,

        onlyIfIHaveSoldBefore: false,
        onlyIfIHaveNotSoldBefore: false,

        onlyIfTrademarkIsRegistered: false,
        onlyIfTrademarkIsNotRegistered: false,

        onlyIfSellerDopingProducts: false,
        onlyIfNotSellerDopingProducts: false,

        onlyIfNoDeliveryDateInfo: false,
        onlyIfDeliveryDateInfo: false,

        minPrice: null,
        maxPrice: null,
        minQuantity: null,
        maxQuantity: null,
        minTotalCost: null,
        maxTotalCost: null,

        minPriceCouponValue: null,
        maxPriceCouponValue: null,
        minPercentCouponValue: null,
        maxPercentCouponValue: null,

        minProfitPercent: null,
        maxProfitPercent: null,
        productCreatedDateStart: null,
        productCreatedDateEnd: null,

        lastUpdatedDateStart: null,
        productCategory: "",
        productSubCategory: "",
        productBrand: "",
        minProductSourceMarketPrice: null,
        maxProductSourceMarketPrice: null,
        minShippingPrice: null,
        maxShippingPrice: null,

        minPrimeSellerCountAtSourceMarket: null,
        maxPrimeSellerCountAtSourceMarket: null,

        minSourceMarketBuyboxSellersBrandCount: null,
        maxSourceMarketBuyboxSellersBrandCount: null,

        minQuantityAtSourceMarket: null,
        maxQuantityAtSourceMarket: null,

        minSalesRankAtSourceMarket: null,
        maxSalesRankAtSourceMarket: null,

        starsValue: null,
        starsOperation: "<", // < , <= , >, >= , =

        reviewCount: null,
        reviewOperation: "<", // < , <= , >, >= , =

        minSellerDopingCost: null,
        maxSellerDopingCost: null,

        minSellerDopingLBS: null,
        maxSellerDopingLBS: null,


        minSalesRankAtDestinationMarket: null,
        maxSalesRankAtDestinationMarket: null,

        minOtherSellersCount: null,
        maxOtherSellersCount: null,

        minPricePercentFromBuyboxSellerAndMe: null,
        maxPricePercentFromBuyboxSellerAndMe: null,

        minPricePercentFromLowestSellerAndMe: null,
        maxPricePercentFromLowestSellerAndMe: null,

        minPricePercentFromAveragePriceAndMyPrice: null,
        maxPricePercentFromAveragePriceAndMyPrice: null,

        minPricePercentFromManufacturerAndMe: null,
        maxPricePercentFromManufacturerAndMe: null,

        minPricePercentFromAmazonAndMe: null,
        maxPricePercentFromAmazonAndMe: null,

        minPricePercentFromCompetitionThresholdAndMe: null,
        maxPricePercentFromCompetitionThresholdAndMe: null,

        status: null,

        onlyIfWithPrimeDiscountAtSourceMarket: false,
        onlyIfNoPrimeDiscountAtSourceMarket: false,
        onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket: false,

        minPricePrimeValue: null,
        maxPricePrimeValue: null,
        minPercentPrimeValue: null,
        maxPercentPrimeValue: null,

        onlyIfNonReturnableProducts: false,
    }

    const [formData, setFormData] = useState(defaultValues);

    const initialValues = {

        onlySoldByMe: formData?.onlySoldByMe || false,
        onlyIfNoBuyboxPriceAtDestinationMarket: formData?.onlyIfNoBuyboxPriceAtDestinationMarket || false,

        onlyIfMeBuyboxSeller: formData?.onlyIfMeBuyboxSeller || false,
        onlyIfMeNotBuyboxSeller: formData?.onlyIfMeNotBuyboxSeller || false,

        onlyIfMeLowestSeller: formData?.onlyIfMeLowestSeller || false,
        onlyIfMeNotLowestSeller: formData?.onlyIfMeNotLowestSeller || false,

        onlyIfPrimeSellersAtDestinationMarket: formData?.onlyIfPrimeSellersAtDestinationMarket || false,
        onlyIfNoPrimeSellersAtDestinationMarket: formData?.onlyIfNoPrimeSellersAtDestinationMarket || false,

        onlyIfWithSalesRankAtDestinationMarket: formData?.onlyIfWithSalesRankAtDestinationMarket || false,
        onlyIfNoSalesRankAtDestinationMarket: formData?.onlyIfNoSalesRankAtDestinationMarket || false,

        onlyIfWithChineseSellersAtDestinationMarket: formData?.onlyIfWithChineseSellersAtDestinationMarket || false,
        onlyIfNoChineseSellersAtDestinationMarket: formData?.onlyIfNoChineseSellersAtDestinationMarket || false,

        onlyIfWithAmazonSellersAtDestinationMarket: formData?.onlyIfWithAmazonSellersAtDestinationMarket || false,
        onlyIfNoAmazonSellersAtDestinationMarket: formData?.onlyIfNoAmazonSellersAtDestinationMarket || false,

        onlyIfProductPriceIsMin: formData?.onlyIfProductPriceIsMin || false,
        onlyIfProductPriceIsMax: formData?.onlyIfProductPriceIsMax || false,


        onlyIfShippingPriceIsHigherThanPriceAtSourceMarket: formData?.onlyIfShippingPriceIsHigherThanPriceAtSourceMarket || false,
        onlyIfUnAvailableProductsAtSourceMarket: formData?.onlyIfUnAvailableProductsAtSourceMarket || false,
        onlyIfBrandAndSellerNameIsSameAtSourceMarket: formData?.onlyIfBrandAndSellerNameIsSameAtSourceMarket || false,

        onlyIfWithSalesRankAtSourceMarket: formData?.onlyIfWithSalesRankAtSourceMarket || false,
        onlyIfNoSalesRankAtSourceMarket: formData?.onlyIfNoSalesRankAtSourceMarket || false,

        onlyIfSoldByAmazonAtSourceMarket: formData?.onlyIfSoldByAmazonAtSourceMarket || false,
        onlyIfNotSoldByAmazonAtSourceMarket: formData?.onlyIfNotSoldByAmazonAtSourceMarket || false,

        onlyIfFBAProductsAtSourceMarket: formData?.onlyIfFBAProductsAtSourceMarket || false,
        onlyIfNoFBAProductsAtSourceMarket: formData?.onlyIfNoFBAProductsAtSourceMarket || false,

        onlyIfWithCouponAtSourceMarket: formData?.onlyIfWithCouponAtSourceMarket || false,
        onlyIfNoCouponAtSourceMarket: formData?.onlyIfNoCouponAtSourceMarket || false,


        onlyIfChangeToFulfilledByAmazon: formData?.onlyIfChangeToFulfilledByAmazon || false,
        onlyIfWithDiscount: formData?.onlyIfWithDiscount || false,
        onlyIfNoShip: formData?.onlyIfNoShip || false,
        onlyIfNoShippingInfo: formData?.onlyIfNoShippingInfo || false,
        onlyIfNoImportFeeInfo: formData?.onlyIfNoImportFeeInfo || false,
        onlyIfPriceAppearsInCart: formData?.onlyIfPriceAppearsInCart || false,
        onlyIfNoPriceInfo: formData?.onlyIfNoPriceInfo || false,

        onlyIfAsinIsDifferent: formData?.onlyIfAsinIsDifferent || false,
        onlyIfProfitBasedOnAsin: formData?.onlyIfProfitBasedOnAsin || false, // asin bazlı kar belirlediğim yani smartpricerde asin bazlı stratejisi olanlar

        onlyIfIHaveSoldBefore: formData?.onlyIfIHaveSoldBefore || false,
        onlyIfIHaveNotSoldBefore: formData?.onlyIfIHaveNotSoldBefore || false,

        onlyIfTrademarkIsRegistered: formData?.onlyIfTrademarkIsRegistered || false,
        onlyIfTrademarkIsNotRegistered: formData?.onlyIfTrademarkIsNotRegistered || false,

        onlyIfSellerDopingProducts: formData?.onlyIfSellerDopingProducts || false,
        onlyIfNotSellerDopingProducts: formData?.onlyIfNotSellerDopingProducts || false,

        onlyIfNoDeliveryDateInfo: formData?.onlyIfNoDeliveryDateInfo || false,
        onlyIfDeliveryDateInfo: formData?.onlyIfDeliveryDateInfo || false,

        minPrice: formData?.minPrice || null,
        maxPrice: formData?.maxPrice || null,
        minQuantity: formData?.minQuantity || null,
        maxQuantity: formData?.maxQuantity || null,
        minTotalCost: formData?.minTotalCost || null,
        maxTotalCost: formData?.maxTotalCost || null,

        minPriceCouponValue: formData?.minPriceCouponValue || null,
        maxPriceCouponValue: formData?.maxPriceCouponValue || null,
        minPercentCouponValue: formData?.minPercentCouponValue || null,
        maxPercentCouponValue: formData?.maxPercentCouponValue || null,  // bazı ürünlerde direk 15 dolar indirim kuponu var örnek bazılarında %5 örneğin eğer iki kupon tipine göre filtre girersem ürünleri joinlemen gerekir

        minProfitPercent: formData?.minProfitPercent || null,
        maxProfitPercent: formData?.maxProfitPercent || null,
        productCreatedDateStart: formData?.productCreatedDateStart || null,
        productCreatedDateEnd: formData?.productCreatedDateEnd || null, // tarih aralığında eklenen ürünler

        lastUpdatedDateStart: formData?.lastUpdatedDateStart || null, // son 3 gün içinde eklenen ürünler isticem örneğin ben sana tarih göndericem o tarihten büyük güncelleme tarihi olanları gönderirsin

        productCategory: formData?.productCategory || "",
        productSubCategory: formData?.productSubCategory || "",
        productBrand: formData?.productBrand || "",
        minProductSourceMarketPrice: formData?.minProductSourceMarketPrice || null,
        maxProductSourceMarketPrice: formData?.maxProductSourceMarketPrice || null,
        minShippingPrice: formData?.minShippingPrice || null,
        maxShippingPrice: formData?.maxShippingPrice || null,

        minPrimeSellerCountAtSourceMarket: formData?.minPrimeSellerCountAtSourceMarket || null,
        maxPrimeSellerCountAtSourceMarket: formData?.maxPrimeSellerCountAtSourceMarket || null,

        minSourceMarketBuyboxSellersBrandCount: formData?.minSourceMarketBuyboxSellersBrandCount || null,
        maxSourceMarketBuyboxSellersBrandCount: formData?.maxSourceMarketBuyboxSellersBrandCount || null, // source markette buybox satıcısı olanın sattığı marka sayısı

        minQuantityAtSourceMarket: formData?.minQuantityAtSourceMarket || null,
        maxQuantityAtSourceMarket: formData?.maxQuantityAtSourceMarket || null,

        minSalesRankAtSourceMarket: formData?.minSalesRankAtSourceMarket || null,
        maxSalesRankAtSourceMarket: formData?.maxSalesRankAtSourceMarket || null,

        starsValue: formData?.starsValue || null, // decimal
        starsOperation: "<", // < , <= , >, >= , =

        reviewCount: formData?.reviewCount || null, // int
        reviewOperation: "<", // < , <= , >, >= , =

        minSellerDopingCost: formData?.minSellerDopingCost || null,
        maxSellerDopingCost: formData?.maxSellerDopingCost || null,

        minSellerDopingLBS: formData?.minSellerDopingLBS || null, // decimal
        maxSellerDopingLBS: formData?.maxSellerDopingLBS || null,


        minSalesRankAtDestinationMarket: formData?.minSalesRankAtDestinationMarket || null,
        maxSalesRankAtDestinationMarket: formData?.maxSalesRankAtDestinationMarket || null,

        minOtherSellersCount: formData?.minOtherSellersCount || null,
        maxOtherSellersCount: formData?.maxOtherSellersCount || null,

        minPricePercentFromBuyboxSellerAndMe: formData?.minPricePercentFromBuyboxSellerAndMe || null,// decimal
        maxPricePercentFromBuyboxSellerAndMe: formData?.maxPricePercentFromBuyboxSellerAndMe || null,// decimal

        minPricePercentFromLowestSellerAndMe: formData?.minPricePercentFromLowestSellerAndMe || null,// decimal
        maxPricePercentFromLowestSellerAndMe: formData?.maxPricePercentFromLowestSellerAndMe || null,// decimal

        minPricePercentFromAveragePriceAndMyPrice: formData?.minPricePercentFromAveragePriceAndMyPrice || null,// decimal
        maxPricePercentFromAveragePriceAndMyPrice: formData?.maxPricePercentFromAveragePriceAndMyPrice || null,// decimal

        minPricePercentFromManufacturerAndMe: formData?.minPricePercentFromManufacturerAndMe || null,// decimal
        maxPricePercentFromManufacturerAndMe: formData?.maxPricePercentFromManufacturerAndMe || null,// decimal

        minPricePercentFromAmazonAndMe: formData?.minPricePercentFromAmazonAndMe || null,// decimal
        maxPricePercentFromAmazonAndMe: formData?.maxPricePercentFromAmazonAndMe || null,// decimal

        minPricePercentFromCompetitionThresholdAndMe: formData?.minPricePercentFromCompetitionThresholdAndMe || null,// decimal
        maxPricePercentFromCompetitionThresholdAndMe: formData?.maxPricePercentFromCompetitionThresholdAndMe || null,// decimal

        status: formData?.status || pageSelections?.inventoryStatus,

        onlyIfWithPrimeDiscountAtSourceMarket: formData?.onlyIfWithPrimeDiscountAtSourceMarket || false, //Kaynak pazarda Prime indirimi olan ürünler
        onlyIfNoPrimeDiscountAtSourceMarket: formData?.onlyIfNoPrimeDiscountAtSourceMarket || false,  // Kaynak pazarda Prime indirimi olmayan ürünler
        onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket: formData?.onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket || false, //Kaynak pazarda ve satış yapılan pazarda Buybox satıcıları aynı olan ürünler

        minPricePrimeValue: formData?.minPricePrimeValue || null,
        maxPricePrimeValue: formData?.maxPricePrimeValue || null,
        minPercentPrimeValue: formData?.minPercentPrimeValue || null,
        maxPercentPrimeValue: formData?.maxPercentPrimeValue || null,

        onlyIfNonReturnableProducts: formData?.onlyIfNonReturnableProducts || false, //İade edilemeyen ürünler
    }


    useEffect(() => {
        let panelOptions = document.getElementsByClassName("panelOptions");
        if (panelOptions && panelOptions?.length > 0) {
            panelOptions[0].addEventListener("show.bs.collapse", function (e) {
                // let shownItems = Object.assign({}, pageSelections?.detailedSearchShownAccordions);
                var shownItems = pageSelections?.detailedSearchShownAccordions;

                if (e?.target?.id === "panelSalesMarketOptions") {
                    shownItems.panelSalesMarket = true;
                }
                if (e?.target?.id === "panelSourceMarketOptions") {
                    shownItems.panelSourceMarket = true;
                }
                if (e?.target?.id === "panelCommonOptions") {
                    shownItems.panelCommon = true;
                }

                dispatch(withActionPromise(setInventoryPageSelections(
                    {
                        inventoryStatus: pageSelections?.inventoryStatus,
                        tableSkip: pageSelections?.tableSkip,
                        tableLength: pageSelections?.tableLength,
                        detailedSearchShownAccordions: shownItems,
                        pagelength: pageSelections?.pagelength,
                        sortingInfo: pageSelections?.sortingInfo
                    })));
            });
            panelOptions[0].addEventListener("hide.bs.collapse", function (e) {
                // let shownItems = Object.assign({}, pageSelections?.detailedSearchShownAccordions);
                var shownItems = pageSelections?.detailedSearchShownAccordions;

                if (e?.target?.id === "panelSalesMarketOptions") {
                    shownItems.panelSalesMarket = false;
                }
                if (e?.target?.id === "panelSourceMarketOptions") {
                    shownItems.panelSourceMarket = false;
                }
                if (e?.target?.id === "panelCommonOptions") {
                    shownItems.panelCommon = false;
                }

                dispatch(withActionPromise(setInventoryPageSelections(
                    {
                        inventoryStatus: pageSelections?.inventoryStatus,
                        tableSkip: pageSelections?.tableSkip,
                        tableLength: pageSelections?.tableLength,
                        detailedSearchShownAccordions: shownItems,
                        pagelength: pageSelections?.pagelength,
                        sortingInfo: pageSelections?.sortingInfo
                    })));
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (detailedFilterData) {
            
            setFilter(detailedFilterData);
            setFormData(detailedFilterData);
        }
        else {
            if (pageFilters.inventoryPageFilters && pageFilters.inventoryPageFilters.length > 0) {

                var selectedFilter = pageFilters.inventoryPageFilters.find(x => x.isSelected === true);
                setFilter(selectedFilter || null);
                setFormData(selectedFilter?.filterJson ? JSON.parse(selectedFilter.filterJson) : defaultValues);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageFilters, detailedFilterData]);

    const detailedSearchClick = (e, searchModel) => {

        if (searchModel) {
            let searchDirty = getDirtyValues(searchModel, defaultValues);
            console.log(searchDirty)
            detailedSearchClickCallBack(e, searchDirty);
        }
        else {
            detailedSearchClickCallBack(e, null);
        }

    };

    // const onDatePickerCallBack = (values) => {
    //     var a = values;

    //     if (values && values.length > 0 && values[0]) {

    //     }
    // }


    var panelSalesMarketClass = classNames({
        'panelSalesMarket': true,
        'accordion-collapse collapse': true,
        'show': pageSelections.detailedSearchShownAccordions?.panelSalesMarket === null || pageSelections.detailedSearchShownAccordions?.panelSalesMarket === true,
    });

    var panelSalesMarketButtonClass = classNames({
        'accordion-button': true,
        'collapsed': pageSelections.detailedSearchShownAccordions?.panelSalesMarket === false,
    });

    var panelSourceMarketClass = classNames({
        'panelSourceMarket': true,
        'accordion-collapse collapse': true,
        'show': pageSelections.detailedSearchShownAccordions?.panelSourceMarket === null || pageSelections.detailedSearchShownAccordions?.panelSourceMarket === true,
    });

    var panelSourceMarketButtonClass = classNames({
        'accordion-button': true,
        'collapsed': pageSelections.detailedSearchShownAccordions?.panelSourceMarket === false
    });

    var panelCommonClass = classNames({
        'panelCommon': true,
        'accordion-collapse collapse': true,
        'show': pageSelections.detailedSearchShownAccordions?.panelCommon === null || pageSelections.detailedSearchShownAccordions?.panelCommon === true,
    });

    var panelCommonButtonClass = classNames({
        'accordion-button': true,
        'collapsed': pageSelections.detailedSearchShownAccordions?.panelCommon === false
    });


    const getChangedValues = (values, initialValues) => {
        return Object
            .entries(values)
            .reduce((acc, [key, value]) => {
                const hasChanged = initialValues[key] !== value

                if (hasChanged) {
                    acc[key] = value
                }

                return acc
            }, {})
    }

    const getDirtyValues = (values, initialObject) => {
        const data = { ...values };
        const keyValues = Object.keys(data);

        const dirtyValues = keyValues.filter(
            (keyValue) => data[keyValue] !== initialObject[keyValue],
        );

        keyValues.forEach((key) => {
            if (!dirtyValues.includes(key)) delete data[key];
        });

        return data;
    }

    return (
        <div>

            <Formik
                initialValues={initialValues}
                validateOnBlur={true}
                // onSubmit={handleSubmit}
                enableReinitialize={true}
            // validateOnMount={true}
            // dirty={false}

            >
                {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setTouched,
                    setFieldValue,
                    resetForm
                }) => (
                    <form>
                        <div id="dtableDetailSearch" className="row row-md row-lg">
                            <div className="col-lg-12 col-xl-12 col-md-12 px-0">
                                <div className="card custom-card bg-info bg-opacity-10">
                                    <div className="card-body">
                                        <div className="row row-md row-lg mb-4 justify-content-between align-items-center">
                                            <div className="col-auto">
                                                <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        let shownItems = Object.assign({}, pageSelections?.detailedSearchShownAccordions);

                                                        if (pageSelections?.detailedSearchShownAccordions?.panelCommon === false
                                                            || pageSelections?.detailedSearchShownAccordions?.panelSalesMarket === false
                                                            || pageSelections?.detailedSearchShownAccordions?.panelSourceMarket === false) {
                                                            shownItems.panelCommon = true;
                                                            shownItems.panelSalesMarket = true;
                                                            shownItems.panelSourceMarket = true;
                                                        }
                                                        else if (pageSelections?.detailedSearchShownAccordions?.panelCommon
                                                            && pageSelections?.detailedSearchShownAccordions?.panelSalesMarket
                                                            && pageSelections?.detailedSearchShownAccordions?.panelSourceMarket) {
                                                            shownItems.panelCommon = false;
                                                            shownItems.panelSalesMarket = false;
                                                            shownItems.panelSourceMarket = false;
                                                        }
                                                        else {
                                                            shownItems.panelCommon = false;
                                                            shownItems.panelSalesMarket = false;
                                                            shownItems.panelSourceMarket = false;
                                                        }

                                                        dispatch(withActionPromise(setInventoryPageSelections(
                                                            {
                                                                inventoryStatus: pageSelections?.inventoryStatus,
                                                                tableSkip: pageSelections?.tableSkip,
                                                                tableLength: pageSelections?.tableLength,
                                                                detailedSearchShownAccordions: shownItems,
                                                                pagelength: pageSelections?.pagelength,
                                                                sortingInfo: pageSelections?.sortingInfo
                                                            })));
                                                    }}
                                                >
                                                    {pageSelections?.detailedSearchShownAccordions?.panelCommon
                                                        && pageSelections?.detailedSearchShownAccordions?.panelSalesMarket
                                                        && pageSelections?.detailedSearchShownAccordions?.panelSourceMarket
                                                        ? t("collapseAll") : (pageSelections?.detailedSearchShownAccordions?.panelCommon === null
                                                            && pageSelections?.detailedSearchShownAccordions?.panelSalesMarket === null
                                                            && pageSelections?.detailedSearchShownAccordions?.panelSourceMarket === null ? t("collapseAll") : t("seeAll"))}
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto pe-0">
                                                        <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setFormData(defaultValues);
                                                                setFilter(null);
                                                                resetForm();
                                                                detailedSearchClick(e, null);
                                                            }}>
                                                            <i className="w-6 me-2 fe fe-plus"></i>
                                                            {t("newFilter")}
                                                        </button>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className="btn btn-info btn-wave waves-effect waves-light shadow" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                detailedSearchClick(e, values);
                                                            }}
                                                        >
                                                            <i className="fe fe-search me-2"></i>
                                                            {t("search")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="panelOptions accordion col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6">
                                                <div className="accordion-item mb-1 shadow">
                                                    <h2 className="accordion-header">
                                                        <button className={panelSalesMarketButtonClass}
                                                            type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#panelSalesMarketOptions" aria-expanded="true"
                                                            aria-controls="panelSalesMarketOptions">
                                                            {t("salesMarketOptions")}
                                                        </button>
                                                    </h2>
                                                    <div id="panelSalesMarketOptions" className={panelSalesMarketClass}>
                                                        <div className="accordion-body">
                                                            <div className="card custom-card">
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlySoldByMe" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlySoldByMe" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlySoldByMe}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlySoldByMe) ? "" : "text-muted"}`} htmlFor="onlySoldByMe"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlySoldByMe")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoBuyboxPriceAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoBuyboxPriceAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoBuyboxPriceAtDestinationMarket}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoBuyboxPriceAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoBuyboxPriceAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {/* {t("onlyIfNoBuyboxPriceAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })} */}
                                                                            <Trans t={t} i18nKey="onlyIfNoBuyboxPriceAtDestinationMarket" values={{ salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label }} />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfMeBuyboxSeller" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfMeBuyboxSeller" type="checkbox"
                                                                                value=""
                                                                                checked={(values?.onlyIfMeBuyboxSeller && !values?.onlyIfMeNotBuyboxSeller) || false}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfMeBuyboxSeller", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfMeNotBuyboxSeller", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfMeBuyboxSeller) ? "" : "text-muted"}`} htmlFor="onlyIfMeBuyboxSeller"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfMeBuyboxSeller")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfMeNotBuyboxSeller" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfMeNotBuyboxSeller" type="checkbox"
                                                                                value=""
                                                                                checked={(!values?.onlyIfMeBuyboxSeller && values?.onlyIfMeNotBuyboxSeller) || false}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfMeNotBuyboxSeller", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfMeBuyboxSeller", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfMeNotBuyboxSeller) ? "" : "text-muted"}`} htmlFor="onlyIfMeNotBuyboxSeller"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfMeNotBuyboxSeller")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfMeLowestSeller" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfMeLowestSeller" type="checkbox"
                                                                                value=""
                                                                                checked={(values?.onlyIfMeLowestSeller && !values?.onlyIfMeNotLowestSeller) || false}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfMeLowestSeller", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfMeNotLowestSeller", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfMeLowestSeller) ? "" : "text-muted"}`} htmlFor="onlyIfMeLowestSeller"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfMeLowestSeller")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfMeNotLowestSeller" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfMeNotLowestSeller" type="checkbox"
                                                                                value=""
                                                                                checked={!values?.onlyIfMeLowestSeller && (values?.onlyIfMeNotLowestSeller || false)}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfMeNotLowestSeller", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfMeLowestSeller", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfMeNotLowestSeller) ? "" : "text-muted"}`} htmlFor="onlyIfMeNotLowestSeller"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfMeNotLowestSeller")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfPrimeSellersAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfPrimeSellersAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfPrimeSellersAtDestinationMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfPrimeSellersAtDestinationMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoPrimeSellersAtDestinationMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfPrimeSellersAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfPrimeSellersAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfPrimeSellersAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoPrimeSellersAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoPrimeSellersAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoPrimeSellersAtDestinationMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoPrimeSellersAtDestinationMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfPrimeSellersAtDestinationMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoPrimeSellersAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoPrimeSellersAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoPrimeSellersAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfWithSalesRankAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfWithSalesRankAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={(values?.onlyIfWithSalesRankAtDestinationMarket && !values?.onlyIfNoSalesRankAtDestinationMarket) || false}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfWithSalesRankAtDestinationMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoSalesRankAtDestinationMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfWithSalesRankAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfWithSalesRankAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfWithSalesRankAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoSalesRankAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoSalesRankAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={(!values?.onlyIfWithSalesRankAtDestinationMarket && values?.onlyIfNoSalesRankAtDestinationMarket) || false}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoSalesRankAtDestinationMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfWithSalesRankAtDestinationMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoSalesRankAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoSalesRankAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoSalesRankAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfWithChineseSellersAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfWithChineseSellersAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfWithChineseSellersAtDestinationMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfWithChineseSellersAtDestinationMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoChineseSellersAtDestinationMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfWithChineseSellersAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfWithChineseSellersAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfWithChineseSellersAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}

                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoChineseSellersAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoChineseSellersAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoChineseSellersAtDestinationMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoChineseSellersAtDestinationMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfWithChineseSellersAtDestinationMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoChineseSellersAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoChineseSellersAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoChineseSellersAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfWithAmazonSellersAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfWithAmazonSellersAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfWithAmazonSellersAtDestinationMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfWithAmazonSellersAtDestinationMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoAmazonSellersAtDestinationMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfWithAmazonSellersAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfWithAmazonSellersAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfWithAmazonSellersAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoAmazonSellersAtDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoAmazonSellersAtDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoAmazonSellersAtDestinationMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoAmazonSellersAtDestinationMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfWithAmazonSellersAtDestinationMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoAmazonSellersAtDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoAmazonSellersAtDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoAmazonSellersAtDestinationMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfProductPriceIsMin" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfProductPriceIsMin" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfProductPriceIsMin}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfProductPriceIsMin", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfProductPriceIsMax", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfProductPriceIsMin) ? "" : "text-muted"}`} htmlFor="onlyIfProductPriceIsMin"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfProductPriceIsMin")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfProductPriceIsMax" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfProductPriceIsMax" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfProductPriceIsMax}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfProductPriceIsMax", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfProductPriceIsMin", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfProductPriceIsMax) ? "" : "text-muted"}`} htmlFor="onlyIfProductPriceIsMax"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfProductPriceIsMax")}
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-1 shadow">
                                                    <h2 className="accordion-header">
                                                        <button className={panelSourceMarketButtonClass}
                                                            type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#panelSourceMarketOptions" aria-expanded="true"
                                                            aria-controls="panelSourceMarketOptions">
                                                            {t("sourceMarketOptions")}
                                                        </button>
                                                    </h2>
                                                    <div id="panelSourceMarketOptions" className={panelSourceMarketClass}>
                                                        <div className="accordion-body">
                                                            <div className="card custom-card">
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfShippingPriceIsHigherThanPriceAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfShippingPriceIsHigherThanPriceAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfShippingPriceIsHigherThanPriceAtSourceMarket}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfShippingPriceIsHigherThanPriceAtSourceMarket': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfShippingPriceIsHigherThanPriceAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfShippingPriceIsHigherThanPriceAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfShippingPriceIsHigherThanPriceAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfUnAvailableProductsAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfUnAvailableProductsAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfUnAvailableProductsAtSourceMarket}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfUnAvailableProductsAtSourceMarket': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfUnAvailableProductsAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfUnAvailableProductsAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfUnAvailableProductsAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfBrandAndSellerNameIsSameAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfBrandAndSellerNameIsSameAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfBrandAndSellerNameIsSameAtSourceMarket}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfBrandAndSellerNameIsSameAtSourceMarket': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfBrandAndSellerNameIsSameAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfBrandAndSellerNameIsSameAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfBrandAndSellerNameIsSameAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfWithSalesRankAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfWithSalesRankAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfWithSalesRankAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfWithSalesRankAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoSalesRankAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfWithSalesRankAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfWithSalesRankAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfWithSalesRankAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoSalesRankAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoSalesRankAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoSalesRankAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoSalesRankAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfWithSalesRankAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoSalesRankAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoSalesRankAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoSalesRankAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfSoldByAmazonAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfSoldByAmazonAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfSoldByAmazonAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfSoldByAmazonAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNotSoldByAmazonAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfSoldByAmazonAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfSoldByAmazonAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfSoldByAmazonAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNotSoldByAmazonAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNotSoldByAmazonAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNotSoldByAmazonAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNotSoldByAmazonAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfSoldByAmazonAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNotSoldByAmazonAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNotSoldByAmazonAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNotSoldByAmazonAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfFBAProductsAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfFBAProductsAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfFBAProductsAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfFBAProductsAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoFBAProductsAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfFBAProductsAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfFBAProductsAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfFBAProductsAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoFBAProductsAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoFBAProductsAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoFBAProductsAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoFBAProductsAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfFBAProductsAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoFBAProductsAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoFBAProductsAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoFBAProductsAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfWithCouponAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfWithCouponAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfWithCouponAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfWithCouponAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoCouponAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfWithCouponAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfWithCouponAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfWithCouponAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoCouponAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoCouponAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoCouponAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoCouponAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfWithCouponAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoCouponAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoCouponAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoCouponAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfWithPrimeDiscountAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfWithPrimeDiscountAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfWithPrimeDiscountAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfWithPrimeDiscountAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoPrimeDiscountAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfWithPrimeDiscountAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfWithPrimeDiscountAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfWithPrimeDiscountAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoPrimeDiscountAtSourceMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoPrimeDiscountAtSourceMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoPrimeDiscountAtSourceMarket}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoPrimeDiscountAtSourceMarket", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfWithPrimeDiscountAtSourceMarket", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoPrimeDiscountAtSourceMarket) ? "" : "text-muted"}`} htmlFor="onlyIfNoPrimeDiscountAtSourceMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoPrimeDiscountAtSourceMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket) ? "" : "text-muted"}`} htmlFor="onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfBuyboxSellerIsSameAtSourceAndDestinationMarket")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-1 shadow">
                                                    <h2 className="accordion-header">
                                                        <button className={panelCommonButtonClass}
                                                            type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#panelCommonOptions" aria-expanded="true"
                                                            aria-controls="panelCommonOptions">
                                                            {t("commonOptions")}
                                                        </button>
                                                    </h2>
                                                    <div id="panelCommonOptions" className={panelCommonClass}>
                                                        <div className="accordion-body">
                                                            <div className="card custom-card">
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfChangeToFulfilledByAmazon" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfChangeToFulfilledByAmazon" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfChangeToFulfilledByAmazon}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfChangeToFulfilledByAmazon': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfChangeToFulfilledByAmazon) ? "" : "text-muted"}`} htmlFor="onlyIfChangeToFulfilledByAmazon"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfChangeToFulfilledByAmazon")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfWithDiscount" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfWithDiscount" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfWithDiscount}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfWithDiscount': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfWithDiscount) ? "" : "text-muted"}`} htmlFor="onlyIfWithDiscount"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfWithDiscount")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoShip" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoShip" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoShip}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfNoShip': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoShip) ? "" : "text-muted"}`} htmlFor="onlyIfNoShip"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoShip", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoShippingInfo" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoShippingInfo" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoShippingInfo}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfNoShippingInfo': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoShippingInfo) ? "" : "text-muted"}`} htmlFor="onlyIfNoShippingInfo"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoShippingInfo")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoImportFeeInfo" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoImportFeeInfo" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoImportFeeInfo}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfNoImportFeeInfo': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoImportFeeInfo) ? "" : "text-muted"}`} htmlFor="onlyIfNoImportFeeInfo"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoImportFeeInfo")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfPriceAppearsInCart" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfPriceAppearsInCart" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfPriceAppearsInCart}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfPriceAppearsInCart': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfPriceAppearsInCart) ? "" : "text-muted"}`} htmlFor="onlyIfPriceAppearsInCart"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfPriceAppearsInCart")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoPriceInfo" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoPriceInfo" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoPriceInfo}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfNoPriceInfo': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoPriceInfo) ? "" : "text-muted"}`} htmlFor="onlyIfNoPriceInfo"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoPriceInfo")}
                                                                        </label>
                                                                    </div>
                                                                </div>


                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfAsinIsDifferent" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfAsinIsDifferent" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfAsinIsDifferent}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfAsinIsDifferent': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfAsinIsDifferent) ? "" : "text-muted"}`} htmlFor="onlyIfAsinIsDifferent"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfAsinIsDifferent")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfProfitBasedOnAsin" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfProfitBasedOnAsin" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfProfitBasedOnAsin}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfProfitBasedOnAsin': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfProfitBasedOnAsin) ? "" : "text-muted"}`} htmlFor="onlyIfProfitBasedOnAsin"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfProfitBasedOnAsin")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfIHaveSoldBefore" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfIHaveSoldBefore" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfIHaveSoldBefore}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfIHaveSoldBefore", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfIHaveNotSoldBefore", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfIHaveSoldBefore) ? "" : "text-muted"}`} htmlFor="onlyIfIHaveSoldBefore"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfIHaveSoldBefore")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfIHaveNotSoldBefore" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfIHaveNotSoldBefore" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfIHaveNotSoldBefore}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfIHaveNotSoldBefore", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfIHaveSoldBefore", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfIHaveNotSoldBefore) ? "" : "text-muted"}`} htmlFor="onlyIfIHaveNotSoldBefore"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfIHaveNotSoldBefore")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfTrademarkIsRegistered" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfTrademarkIsRegistered" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfTrademarkIsRegistered}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfTrademarkIsRegistered", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfTrademarkIsNotRegistered", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfTrademarkIsRegistered) ? "" : "text-muted"}`} htmlFor="onlyIfTrademarkIsRegistered"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfTrademarkIsRegistered")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfTrademarkIsNotRegistered" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfTrademarkIsNotRegistered" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfTrademarkIsNotRegistered}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfTrademarkIsNotRegistered", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfTrademarkIsRegistered", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfTrademarkIsNotRegistered) ? "" : "text-muted"}`} htmlFor="onlyIfTrademarkIsNotRegistered"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfTrademarkIsNotRegistered")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfSellerDopingProducts" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfSellerDopingProducts" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfSellerDopingProducts}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfSellerDopingProducts", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNotSellerDopingProducts", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfSellerDopingProducts) ? "" : "text-muted"}`} htmlFor="onlyIfSellerDopingProducts"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfSellerDopingProducts")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNotSellerDopingProducts" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNotSellerDopingProducts" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNotSellerDopingProducts}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNotSellerDopingProducts", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNotSellerDopingProducts", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNotSellerDopingProducts) ? "" : "text-muted"}`} htmlFor="onlyIfNotSellerDopingProducts"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNotSellerDopingProducts")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNoDeliveryDateInfo" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNoDeliveryDateInfo" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNoDeliveryDateInfo}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfNoDeliveryDateInfo", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfDeliveryDateInfo", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNoDeliveryDateInfo) ? "" : "text-muted"}`} htmlFor="onlyIfNoDeliveryDateInfo"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNoDeliveryDateInfo")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfDeliveryDateInfo" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfDeliveryDateInfo" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfDeliveryDateInfo}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("onlyIfDeliveryDateInfo", e.currentTarget.checked);
                                                                                    if (e.currentTarget.checked) {
                                                                                        setFieldValue("onlyIfNoDeliveryDateInfo", false);
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfDeliveryDateInfo) ? "" : "text-muted"}`} htmlFor="onlyIfDeliveryDateInfo"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfDeliveryDateInfo")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-1">
                                                                    <div className="col-auto mt-0">
                                                                        <div className="form-check form-check-md form-switch">
                                                                            <DrInput id="onlyIfNonReturnableProducts" className="form-check-input form-checked-success" role="switch" placeholder="" name="onlyIfNonReturnableProducts" type="checkbox"
                                                                                value=""
                                                                                checked={values?.onlyIfNonReturnableProducts}
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'onlyIfNonReturnableProducts': true }) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col mt-0">
                                                                        <label className={`form-check-label cursor-pointer no-select ${(values?.onlyIfNonReturnableProducts) ? "" : "text-muted"}`} htmlFor="onlyIfNonReturnableProducts"
                                                                            style={{ marginTop: '2px' }}>
                                                                            {t("onlyIfNonReturnableProducts")}
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6">
                                                <div className="card custom-card bg-light shadow py-2 px-4">
                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("status")}
                                                        </div>
                                                        <div className="col">
                                                            <DrButtonDropdown variant={buttonVariants.info} options={inventoryPageOptions}
                                                                optionClickCallBack={(value) => {
                                                                    setTouched({ ...touched, 'status': true });
                                                                    setFieldValue("status", value);
                                                                }}
                                                                selectedValue={values?.status}>
                                                            </DrButtonDropdown>

                                                        </div>
                                                    </div>



                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("price")}
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Min" name="minPrice" type="number" min="0" step="0.1"
                                                                value={values?.minPrice || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minPrice': true }) }}
                                                                icon={<i className="fe fe-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minPrice && errors.minPrice)}
                                                                isValid={touched.minPrice && !errors.minPrice}
                                                                validationText={errors.minPrice} />

                                                        </div>
                                                        {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPrice" type="number" min="0" step="0.1"
                                                                value={values?.maxPrice || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxPrice': true }) }}
                                                                icon={<i className="fe fe-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxPrice && errors.maxPrice)}
                                                                isValid={touched.maxPrice && !errors.maxPrice}
                                                                validationText={errors.maxPrice} />

                                                        </div>
                                                    </div>

                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("quantity")}
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Min" name="minQuantity" type="number" min="0" step="0.1"
                                                                value={values?.minQuantity || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minQuantity': true }) }}
                                                                icon={<i className="fe fe-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minQuantity && errors.minQuantity)}
                                                                isValid={touched.minQuantity && !errors.minQuantity}
                                                                validationText={errors.minQuantity} />

                                                        </div>
                                                        {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Max" name="maxQuantity" type="number" min="0" step="0.1"
                                                                value={values?.maxQuantity || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxQuantity': true }) }}
                                                                icon={<i className="fe fe-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxQuantity && errors.maxQuantity)}
                                                                isValid={touched.maxQuantity && !errors.maxQuantity}
                                                                validationText={errors.maxQuantity} />

                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("totalCost")}
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Min" name="minTotalCost" type="number" min="0" step="0.1"
                                                                value={values?.minTotalCost || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minTotalCost': true }) }}
                                                                icon={<i className="fe fe-dollar-sign"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minTotalCost && errors.minTotalCost)}
                                                                isValid={touched.minTotalCost && !errors.minTotalCost}
                                                                validationText={errors.minTotalCost} />

                                                        </div>
                                                        {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Max" name="maxTotalCost" type="number" min="0" step="0.1"
                                                                value={values?.maxTotalCost || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxTotalCost': true }) }}
                                                                icon={<i className="fe fe-dollar-sign"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxTotalCost && errors.maxTotalCost)}
                                                                isValid={touched.maxTotalCost && !errors.maxTotalCost}
                                                                validationText={errors.maxTotalCost} />

                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("priceCouponValue")}
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Min" name="minPriceCouponValue" type="number" min="0" step="1"
                                                                value={values?.minPriceCouponValue || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minPriceCouponValue': true }) }}
                                                                icon={<i className="fe fe-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minPriceCouponValue && errors.minPriceCouponValue)}
                                                                isValid={touched.minPriceCouponValue && !errors.minPriceCouponValue}
                                                                validationText={errors.minPriceCouponValue} />

                                                        </div>
                                                        {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPriceCouponValue" type="number" min="0" step="1"
                                                                value={values?.maxPriceCouponValue || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxPriceCouponValue': true }) }}
                                                                icon={<i className="fe fe-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxPriceCouponValue && errors.maxPriceCouponValue)}
                                                                isValid={touched.maxPriceCouponValue && !errors.maxPriceCouponValue}
                                                                validationText={errors.maxPriceCouponValue} />

                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("percentCouponValue")}
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Min" name="minPercentCouponValue" type="number" min="0" step="1"
                                                                value={values?.minPercentCouponValue || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minPercentCouponValue': true }) }}
                                                                icon={<i className="fe fe-dollar-sign"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minPercentCouponValue && errors.minPercentCouponValue)}
                                                                isValid={touched.minPercentCouponValue && !errors.minPercentCouponValue}
                                                                validationText={errors.minPercentCouponValue} />

                                                        </div>
                                                        {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPercentCouponValue" type="number" min="0" step="0.1"
                                                                value={values?.maxPercentCouponValue || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxPercentCouponValue': true }) }}
                                                                icon={<i className="fe fe-dollar-sign"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxPercentCouponValue && errors.maxPercentCouponValue)}
                                                                isValid={touched.maxPercentCouponValue && !errors.maxPercentCouponValue}
                                                                validationText={errors.maxPercentCouponValue} />

                                                        </div>
                                                    </div>

                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("pricePrimeValue")}
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Min" name="minPricePrimeValue" type="number" min="0" step="1"
                                                                value={values?.minPricePrimeValue || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minPricePrimeValue': true }) }}
                                                                icon={<i className="fe fe-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minPricePrimeValue && errors.minPricePrimeValue)}
                                                                isValid={touched.minPricePrimeValue && !errors.minPricePrimeValue}
                                                                validationText={errors.minPricePrimeValue} />

                                                        </div>
                                                        {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPricePrimeValue" type="number" min="0" step="1"
                                                                value={values?.maxPricePrimeValue || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxPricePrimeValue': true }) }}
                                                                icon={<i className="fe fe-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxPricePrimeValue && errors.maxPricePrimeValue)}
                                                                isValid={touched.maxPricePrimeValue && !errors.maxPricePrimeValue}
                                                                validationText={errors.maxPricePrimeValue} />

                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("percentPrimeValue")}
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Min" name="minPercentPrimeValue" type="number" min="0" step="1"
                                                                value={values?.minPercentPrimeValue || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minPercentPrimeValue': true }) }}
                                                                icon={<i className="fe fe-dollar-sign"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minPercentPrimeValue && errors.minPercentPrimeValue)}
                                                                isValid={touched.minPercentPrimeValue && !errors.minPercentPrimeValue}
                                                                validationText={errors.minPercentPrimeValue} />

                                                        </div>
                                                        {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPercentPrimeValue" type="number" min="0" step="0.1"
                                                                value={values?.maxPercentPrimeValue || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxPercentPrimeValue': true }) }}
                                                                icon={<i className="fe fe-dollar-sign"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxPercentPrimeValue && errors.maxPercentPrimeValue)}
                                                                isValid={touched.maxPercentPrimeValue && !errors.maxPercentPrimeValue}
                                                                validationText={errors.maxPercentPrimeValue} />

                                                        </div>
                                                    </div>


                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("profitPercent")}
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Min" name="minProfitPercent" type="number" min="0" step="0.1"
                                                                value={values?.minProfitPercent || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minProfitPercent': true }) }}
                                                                icon={<i className="fe fe-dollar-sign"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minProfitPercent && errors.minProfitPercent)}
                                                                isValid={touched.minProfitPercent && !errors.minProfitPercent}
                                                                validationText={errors.minProfitPercent} />

                                                        </div>
                                                        {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                        <div className="col">
                                                            <DrInput className="form-control form-control-sm" placeholder="Max" name="maxProfitPercent" type="number" min="0" step="0.1"
                                                                value={values?.maxProfitPercent || ""}
                                                                // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxProfitPercent': true }) }}
                                                                icon={<i className="fe fe-dollar-sign"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxProfitPercent && errors.maxProfitPercent)}
                                                                isValid={touched.maxProfitPercent && !errors.maxProfitPercent}
                                                                validationText={errors.maxProfitPercent} />

                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("productCreatedDate")}
                                                        </div>
                                                        <div className="col">

                                                            <DrDualDatePicker
                                                                selectedDates={
                                                                    [
                                                                        (values?.productCreatedDateStart ? moment.utc(values?.productCreatedDateStart, "YYYY/MM/DD").startOf('day').format() : null),
                                                                        (values?.productCreatedDateEnd ? moment.utc(values?.productCreatedDateEnd, "YYYY/MM/DD").startOf('day').format() : null)
                                                                    ]
                                                                }
                                                                onBlur={() => { setTouched({ ...touched, 'productCreatedDateStart': true, 'productCreatedDateEnd': true }) }}

                                                                onCloseCallBack={(dateValues) => {
                                                                    if (dateValues && dateValues.length > 0) {
                                                                        setFieldValue("productCreatedDateStart", dateValues[0] ? moment.utc(dateValues[0].toString(), "YYYY/MM/DD").startOf('day').format() : null);
                                                                        setFieldValue("productCreatedDateEnd", dateValues[1] ? moment.utc(dateValues[1].toString(), "YYYY/MM/DD").startOf('day').format() : null);
                                                                    }
                                                                    else {
                                                                        setFieldValue("productCreatedDateStart", null);
                                                                        setFieldValue("productCreatedDateEnd", null);
                                                                    }
                                                                }}>
                                                            </DrDualDatePicker>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center mb-1">
                                                        <div className="col-4">
                                                            {t("lastUpdatedDateStart")}
                                                        </div>
                                                        <div className="col">
                                                            <Field onBlur={() => { setTouched({ ...touched.lastUpdatedDateStart, 'lastUpdatedDateStart': true }) }}
                                                                name={'lastUpdatedDateStart'}
                                                                component={DrSelectBox}
                                                                placeholder="Last Updated Date"
                                                                options={maxDeliveryDaysAll} />
                                                            {(touched.lastUpdatedDateStart && errors.lastUpdatedDateStart) && <label type="invalid" className="form-control form-control-sm is-invalid">
                                                                {errors.lastUpdatedDateStart}
                                                            </label>}
                                                        </div>
                                                    </div>

                                                    <div className="row align-items-center my-2 py-2  bg-info bg-opacity-25 shadow">
                                                        <div className="col-12">
                                                            <div className="row align-items-center">
                                                                <div className="col-4">
                                                                    {t("productCategory")}
                                                                </div>
                                                                <div className="col">
                                                                    <Field
                                                                        onBlur={() => { setTouched({ ...touched.productCategory, 'productCategory': true }) }}
                                                                        onChange={(e) => {
                                                                            console.log(e)
                                                                            setFieldValue("productCategory", e.label);
                                                                        }}
                                                                        name={'productCategory'}
                                                                        component={DrSelectBox}
                                                                        placeholder="Select Category"
                                                                        options={productCategories?.map(x => ({ value: x.categoryName, label: x.categoryName }))} />
                                                                    {(touched.productCategory && errors.productCategory) && <label type="invalid" className="form-control form-control-sm is-invalid">
                                                                        {errors.productCategory}
                                                                    </label>}
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("productSubCategory")}
                                                                </div>
                                                                <div className="col">
                                                                    <Field
                                                                        onBlur={() => { setTouched({ ...touched.productSubCategory, 'productSubCategory': true }) }}
                                                                        onChange={(e) => {
                                                                            setFieldValue("productSubCategory", e.value);
                                                                        }}
                                                                        name={'productSubCategory'}
                                                                        component={DrSelectBox}
                                                                        placeholder="Select Category"
                                                                        options={productCategories?.find(p => p.categoryName === values?.productCategory)?.subCategories?.map(x => ({ value: x.categoryName, label: x.categoryName }))} />
                                                                    {(touched.productSubCategory && errors.productSubCategory) && <label type="invalid" className="form-control form-control-sm is-invalid">
                                                                        {errors.productSubCategory}
                                                                    </label>}
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("productBrand")}
                                                                </div>
                                                                <div className="col">
                                                                    <Field
                                                                        onBlur={() => { setTouched({ ...touched.productBrand, 'productBrand': true }) }}
                                                                        onChange={(e) => {
                                                                            setFieldValue("productBrand", e.value);
                                                                        }}
                                                                        name={'productBrand'}
                                                                        component={DrSelectBox}
                                                                        placeholder="Select Brand"
                                                                        options={productsBrands?.map(x => ({ value: x.brandName, label: x.brandName }))} />
                                                                    {(touched.productBrand && errors.productBrand) && <label type="invalid" className="form-control form-control-sm is-invalid">
                                                                        {errors.productBrand}
                                                                    </label>}
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("productSourceMarketPrice")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minProductSourceMarketPrice" type="number" min="0" step="0.1"
                                                                        value={values?.minProductSourceMarketPrice || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minProductSourceMarketPrice': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minProductSourceMarketPrice && errors.minProductSourceMarketPrice)}
                                                                        isValid={touched.minProductSourceMarketPrice && !errors.minProductSourceMarketPrice}
                                                                        validationText={errors.minProductSourceMarketPrice} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxProductSourceMarketPrice" type="number" min="0" step="0.1"
                                                                        value={values?.maxProductSourceMarketPrice || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxProductSourceMarketPrice': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxProductSourceMarketPrice && errors.maxProductSourceMarketPrice)}
                                                                        isValid={touched.maxProductSourceMarketPrice && !errors.maxProductSourceMarketPrice}
                                                                        validationText={errors.maxProductSourceMarketPrice} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("shippingPrice")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minShippingPrice" type="number" min="0" step="0.1"
                                                                        value={values?.minShippingPrice || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minShippingPrice': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minShippingPrice && errors.minShippingPrice)}
                                                                        isValid={touched.minShippingPrice && !errors.minShippingPrice}
                                                                        validationText={errors.minShippingPrice} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxShippingPrice" type="number" min="0" step="0.1"
                                                                        value={values?.maxShippingPrice || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxShippingPrice': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxShippingPrice && errors.maxShippingPrice)}
                                                                        isValid={touched.maxShippingPrice && !errors.maxShippingPrice}
                                                                        validationText={errors.maxShippingPrice} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("primeSellerCountAtSourceMarket")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minPrimeSellerCountAtSourceMarket" type="number" min="0" max="20" step="1"
                                                                        value={values?.minPrimeSellerCountAtSourceMarket || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minPrimeSellerCountAtSourceMarket': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minPrimeSellerCountAtSourceMarket && errors.minPrimeSellerCountAtSourceMarket)}
                                                                        isValid={touched.minPrimeSellerCountAtSourceMarket && !errors.minPrimeSellerCountAtSourceMarket}
                                                                        validationText={errors.minPrimeSellerCountAtSourceMarket} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPrimeSellerCountAtSourceMarket" type="number" min="0" max="20" step="1"
                                                                        value={values?.maxPrimeSellerCountAtSourceMarket || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxPrimeSellerCountAtSourceMarket': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxPrimeSellerCountAtSourceMarket && errors.maxPrimeSellerCountAtSourceMarket)}
                                                                        isValid={touched.maxPrimeSellerCountAtSourceMarket && !errors.maxPrimeSellerCountAtSourceMarket}
                                                                        validationText={errors.maxPrimeSellerCountAtSourceMarket} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("sourceMarketBuyboxSellersBrandCount")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minSourceMarketBuyboxSellersBrandCount" type="number" min="0" max="20" step="1"
                                                                        value={values?.minSourceMarketBuyboxSellersBrandCount || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minSourceMarketBuyboxSellersBrandCount': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minSourceMarketBuyboxSellersBrandCount && errors.minSourceMarketBuyboxSellersBrandCount)}
                                                                        isValid={touched.minSourceMarketBuyboxSellersBrandCount && !errors.minSourceMarketBuyboxSellersBrandCount}
                                                                        validationText={errors.minSourceMarketBuyboxSellersBrandCount} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxSourceMarketBuyboxSellersBrandCount" type="number" min="0" max="20" step="1"
                                                                        value={values?.maxSourceMarketBuyboxSellersBrandCount || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxSourceMarketBuyboxSellersBrandCount': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxSourceMarketBuyboxSellersBrandCount && errors.maxSourceMarketBuyboxSellersBrandCount)}
                                                                        isValid={touched.maxSourceMarketBuyboxSellersBrandCount && !errors.maxSourceMarketBuyboxSellersBrandCount}
                                                                        validationText={errors.maxSourceMarketBuyboxSellersBrandCount} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("quantityAtSourceMarket")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minQuantityAtSourceMarket" type="number" min="0" max="20" step="1"
                                                                        value={values?.minQuantityAtSourceMarket || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minQuantityAtSourceMarket': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minQuantityAtSourceMarket && errors.minQuantityAtSourceMarket)}
                                                                        isValid={touched.minQuantityAtSourceMarket && !errors.minQuantityAtSourceMarket}
                                                                        validationText={errors.minQuantityAtSourceMarket} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxQuantityAtSourceMarket" type="number" min="0" max="20" step="1"
                                                                        value={values?.maxQuantityAtSourceMarket || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxQuantityAtSourceMarket': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxQuantityAtSourceMarket && errors.maxQuantityAtSourceMarket)}
                                                                        isValid={touched.maxQuantityAtSourceMarket && !errors.maxQuantityAtSourceMarket}
                                                                        validationText={errors.maxQuantityAtSourceMarket} />

                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("salesRankAtSourceMarket")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minSalesRankAtSourceMarket" type="number" min="0" step="1"
                                                                        value={values?.minSalesRankAtSourceMarket || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minSalesRankAtSourceMarket': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minSalesRankAtSourceMarket && errors.minSalesRankAtSourceMarket)}
                                                                        isValid={touched.minSalesRankAtSourceMarket && !errors.minSalesRankAtSourceMarket}
                                                                        validationText={errors.minSalesRankAtSourceMarket} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxSalesRankAtSourceMarket" type="number" min="0" step="1"
                                                                        value={values?.maxSalesRankAtSourceMarket || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxSalesRankAtSourceMarket': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxSalesRankAtSourceMarket && errors.maxSalesRankAtSourceMarket)}
                                                                        isValid={touched.maxSalesRankAtSourceMarket && !errors.maxSalesRankAtSourceMarket}
                                                                        validationText={errors.maxSalesRankAtSourceMarket} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("reviewCount")}
                                                                </div>
                                                                <div className="col">
                                                                    <Field onBlur={() => { setTouched({ ...touched.reviewOperation, 'reviewOperation': true }) }}
                                                                        name={'reviewOperation'}
                                                                        component={DrSelectBox}
                                                                        placeholder="Select"
                                                                        options={operationsOptions} />
                                                                    {(touched.reviewOperation && errors.reviewOperation) && <label type="invalid" className="form-control form-control-sm is-invalid">
                                                                        {errors.reviewOperation}
                                                                    </label>}
                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Review" name="reviewCount" type="number" min="0" step="1"
                                                                        value={values?.reviewCount || ""}
                                                                        disabled={`${values?.reviewOperation ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'reviewCount': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.reviewCount && errors.reviewCount)}
                                                                        isValid={touched.reviewCount && !errors.reviewCount}
                                                                        validationText={errors.reviewCount} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("starsValue")}
                                                                </div>
                                                                <div className="col">
                                                                    <Field onBlur={() => { setTouched({ ...touched.starsOperation, 'starsOperation': true }) }}
                                                                        name={'starsOperation'}
                                                                        component={DrSelectBox}
                                                                        placeholder="Select"
                                                                        options={operationsOptions} />
                                                                    {(touched.starsOperation && errors.starsOperation) && <label type="invalid" className="form-control form-control-sm is-invalid">
                                                                        {errors.starsOperation}
                                                                    </label>}
                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Stars" name="starsValue" type="number" min="0" max="5" step="0.1"
                                                                        value={values?.starsValue || ""}
                                                                        disabled={`${values?.starsOperation ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'starsValue': true }) }}
                                                                        icon={<i className="fa fa-star"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.starsValue && errors.starsValue)}
                                                                        isValid={touched.starsValue && !errors.starsValue}
                                                                        validationText={errors.starsValue} />

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>



                                                    <div className="row align-items-center my-2 py-2 bg-danger bg-opacity-25 shadow">
                                                        <div className="col-12">
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("sellerDopingCost")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minSellerDopingCost" type="number" min="0" step="0.1"
                                                                        value={values?.minSellerDopingCost || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minSellerDopingCost': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minSellerDopingCost && errors.minSellerDopingCost)}
                                                                        isValid={touched.minSellerDopingCost && !errors.minSellerDopingCost}
                                                                        validationText={errors.minSellerDopingCost} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxSellerDopingCost" type="number" min="0" step="0.1"
                                                                        value={values?.maxSellerDopingCost || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxSellerDopingCost': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxSellerDopingCost && errors.maxSellerDopingCost)}
                                                                        isValid={touched.maxSellerDopingCost && !errors.maxSellerDopingCost}
                                                                        validationText={errors.maxSellerDopingCost} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("sellerDopingLBS")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minSellerDopingLBS" type="number" min="0" step="0.1"
                                                                        value={values?.minSellerDopingLBS || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minSellerDopingLBS': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minSellerDopingLBS && errors.minSellerDopingLBS)}
                                                                        isValid={touched.minSellerDopingLBS && !errors.minSellerDopingLBS}
                                                                        validationText={errors.minSellerDopingLBS} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                <label className="m-auto">-</label>
            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxSellerDopingLBS" type="number" min="0" step="0.1"
                                                                        value={values?.maxSellerDopingLBS || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxSellerDopingLBS': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxSellerDopingLBS && errors.maxSellerDopingLBS)}
                                                                        isValid={touched.maxSellerDopingLBS && !errors.maxSellerDopingLBS}
                                                                        validationText={errors.maxSellerDopingLBS} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row align-items-center my-2 py-2 bg-success bg-opacity-25 shadow">
                                                        <div className="col-12">

                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("salesRankAtDestinationMarket")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minSalesRankAtDestinationMarket" type="number" min="0" step="1"
                                                                        value={values?.minSalesRankAtDestinationMarket || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minSalesRankAtDestinationMarket': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minSalesRankAtDestinationMarket && errors.minSalesRankAtDestinationMarket)}
                                                                        isValid={touched.minSalesRankAtDestinationMarket && !errors.minSalesRankAtDestinationMarket}
                                                                        validationText={errors.minSalesRankAtDestinationMarket} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxSalesRankAtDestinationMarket" type="number" min="0" step="1"
                                                                        value={values?.maxSalesRankAtDestinationMarket || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxSalesRankAtDestinationMarket': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxSalesRankAtDestinationMarket && errors.maxSalesRankAtDestinationMarket)}
                                                                        isValid={touched.maxSalesRankAtDestinationMarket && !errors.maxSalesRankAtDestinationMarket}
                                                                        validationText={errors.maxSalesRankAtDestinationMarket} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("otherSellersCount")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minOtherSellersCount" type="number" min="0" step="1"
                                                                        value={values?.minOtherSellersCount || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minOtherSellersCount': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minOtherSellersCount && errors.minOtherSellersCount)}
                                                                        isValid={touched.minOtherSellersCount && !errors.minOtherSellersCount}
                                                                        validationText={errors.minOtherSellersCount} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxOtherSellersCount" type="number" min="0" step="1"
                                                                        value={values?.maxOtherSellersCount || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxOtherSellersCount': true }) }}
                                                                        icon={<i className="fe fe-package"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxOtherSellersCount && errors.maxOtherSellersCount)}
                                                                        isValid={touched.maxOtherSellersCount && !errors.maxOtherSellersCount}
                                                                        validationText={errors.maxOtherSellersCount} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("pricePercentFromBuyboxSellerAndMe")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minPricePercentFromBuyboxSellerAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.minPricePercentFromBuyboxSellerAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minPricePercentFromBuyboxSellerAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minPricePercentFromBuyboxSellerAndMe && errors.minPricePercentFromBuyboxSellerAndMe)}
                                                                        isValid={touched.minPricePercentFromBuyboxSellerAndMe && !errors.minPricePercentFromBuyboxSellerAndMe}
                                                                        validationText={errors.minPricePercentFromBuyboxSellerAndMe} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPricePercentFromBuyboxSellerAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.maxPricePercentFromBuyboxSellerAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxPricePercentFromBuyboxSellerAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxPricePercentFromBuyboxSellerAndMe && errors.maxPricePercentFromBuyboxSellerAndMe)}
                                                                        isValid={touched.maxPricePercentFromBuyboxSellerAndMe && !errors.maxPricePercentFromBuyboxSellerAndMe}
                                                                        validationText={errors.maxPricePercentFromBuyboxSellerAndMe} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("pricePercentFromLowestSellerAndMe")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minPricePercentFromLowestSellerAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.minPricePercentFromLowestSellerAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minPricePercentFromLowestSellerAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minPricePercentFromLowestSellerAndMe && errors.minPricePercentFromLowestSellerAndMe)}
                                                                        isValid={touched.minPricePercentFromLowestSellerAndMe && !errors.minPricePercentFromLowestSellerAndMe}
                                                                        validationText={errors.minPricePercentFromLowestSellerAndMe} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPricePercentFromLowestSellerAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.maxPricePercentFromLowestSellerAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxPricePercentFromLowestSellerAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxPricePercentFromLowestSellerAndMe && errors.maxPricePercentFromLowestSellerAndMe)}
                                                                        isValid={touched.maxPricePercentFromLowestSellerAndMe && !errors.maxPricePercentFromLowestSellerAndMe}
                                                                        validationText={errors.maxPricePercentFromLowestSellerAndMe} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("pricePercentFromAveragePriceAndMyPrice")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minPricePercentFromAveragePriceAndMyPrice" type="number" min="0" step="0.1"
                                                                        value={values?.minPricePercentFromAveragePriceAndMyPrice || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minPricePercentFromAveragePriceAndMyPrice': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minPricePercentFromAveragePriceAndMyPrice && errors.minPricePercentFromAveragePriceAndMyPrice)}
                                                                        isValid={touched.minPricePercentFromAveragePriceAndMyPrice && !errors.minPricePercentFromAveragePriceAndMyPrice}
                                                                        validationText={errors.minPricePercentFromAveragePriceAndMyPrice} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPricePercentFromAveragePriceAndMyPrice" type="number" min="0" step="0.1"
                                                                        value={values?.maxPricePercentFromAveragePriceAndMyPrice || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxPricePercentFromAveragePriceAndMyPrice': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxPricePercentFromAveragePriceAndMyPrice && errors.maxPricePercentFromAveragePriceAndMyPrice)}
                                                                        isValid={touched.maxPricePercentFromAveragePriceAndMyPrice && !errors.maxPricePercentFromAveragePriceAndMyPrice}
                                                                        validationText={errors.maxPricePercentFromAveragePriceAndMyPrice} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("pricePercentFromManufacturerAndMe")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minPricePercentFromManufacturerAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.minPricePercentFromManufacturerAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minPricePercentFromManufacturerAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minPricePercentFromManufacturerAndMe && errors.minPricePercentFromManufacturerAndMe)}
                                                                        isValid={touched.minPricePercentFromManufacturerAndMe && !errors.minPricePercentFromManufacturerAndMe}
                                                                        validationText={errors.minPricePercentFromManufacturerAndMe} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPricePercentFromManufacturerAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.maxPricePercentFromManufacturerAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxPricePercentFromManufacturerAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxPricePercentFromManufacturerAndMe && errors.maxPricePercentFromManufacturerAndMe)}
                                                                        isValid={touched.maxPricePercentFromManufacturerAndMe && !errors.maxPricePercentFromManufacturerAndMe}
                                                                        validationText={errors.maxPricePercentFromManufacturerAndMe} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("pricePercentFromAmazonAndMe")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minPricePercentFromAmazonAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.minPricePercentFromAmazonAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minPricePercentFromAmazonAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minPricePercentFromAmazonAndMe && errors.minPricePercentFromAmazonAndMe)}
                                                                        isValid={touched.minPricePercentFromAmazonAndMe && !errors.minPricePercentFromAmazonAndMe}
                                                                        validationText={errors.minPricePercentFromAmazonAndMe} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPricePercentFromAmazonAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.maxPricePercentFromAmazonAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxPricePercentFromAmazonAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxPricePercentFromAmazonAndMe && errors.maxPricePercentFromAmazonAndMe)}
                                                                        isValid={touched.maxPricePercentFromAmazonAndMe && !errors.maxPricePercentFromAmazonAndMe}
                                                                        validationText={errors.maxPricePercentFromAmazonAndMe} />

                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-1">
                                                                <div className="col-4">
                                                                    {t("pricePercentFromCompetitionThresholdAndMe")}
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Min" name="minPricePercentFromCompetitionThresholdAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.minPricePercentFromCompetitionThresholdAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'minPricePercentFromCompetitionThresholdAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.minPricePercentFromCompetitionThresholdAndMe && errors.minPricePercentFromCompetitionThresholdAndMe)}
                                                                        isValid={touched.minPricePercentFromCompetitionThresholdAndMe && !errors.minPricePercentFromCompetitionThresholdAndMe}
                                                                        validationText={errors.minPricePercentFromCompetitionThresholdAndMe} />

                                                                </div>
                                                                {/* <div className="col-1 px-0 m-0 text-center">
                                                                <label className="m-auto">-</label>
                                                            </div> */}
                                                                <div className="col">
                                                                    <DrInput className="form-control form-control-sm" placeholder="Max" name="maxPricePercentFromCompetitionThresholdAndMe" type="number" min="0" step="0.1"
                                                                        value={values?.maxPricePercentFromCompetitionThresholdAndMe || ""}
                                                                        // disabled={`${values?.enableSalesTaxRates ? "" : "disabled"}`}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'maxPricePercentFromCompetitionThresholdAndMe': true }) }}
                                                                        icon={<i className="fe fe-dollar-sign"></i>}
                                                                        iconPosition="left"
                                                                        isInvalid={(touched.maxPricePercentFromCompetitionThresholdAndMe && errors.maxPricePercentFromCompetitionThresholdAndMe)}
                                                                        isValid={touched.maxPricePercentFromCompetitionThresholdAndMe && !errors.maxPricePercentFromCompetitionThresholdAndMe}
                                                                        validationText={errors.maxPricePercentFromCompetitionThresholdAndMe} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>


                                        <div className="row row-md row-lg mb-4 justify-content-end">
                                            <div className="col-auto">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto pe-0">
                                                        <button className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // detailedSearchSave(e, values);

                                                                var filterData = {
                                                                    id: filter?.id,
                                                                    customerMainSellerId: selectedStore?.customerMainSellerId,
                                                                    pageNumber: pageFilterTypes.inventory,
                                                                    pageName: Object.keys(pageFilterTypes)[Object.values(pageFilterTypes).indexOf(0)],
                                                                    methodName: filter?.methodName || "getProduct",
                                                                    moduleName: filter?.moduleName || "inventory",
                                                                    description: '',
                                                                    filterJson: JSON.stringify(values),
                                                                    isSelected: true,

                                                                    filterName: filter?.filterName || "",
                                                                    isBatchStart: filter?.isBatchStart || false,
                                                                    batchHours: filter?.batchHours || 0,
                                                                    isNotification: filter?.isNotification || false,
                                                                    notificationHours: filter?.notificationHours || 0
                                                                }

                                                                dispatch(setActiveSavePagefilterModal({
                                                                    name: modals.pageFilterSave, isGlobal: true, source: filterData,
                                                                    pagefilterSavedCallBack: (response) => {
                                                                        dispatch(clearActiveSavePagefilterModal());
                                                                    }
                                                                }));
                                                            }}>
                                                            <i className="fe fe-save me-2"></i>
                                                            {t("save")}
                                                        </button>
                                                    </div>
                                                    <div className="col-auto ">
                                                        <button className="btn btn-info btn-wave waves-effect waves-light shadow" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                detailedSearchClick(e, values);
                                                            }}
                                                        >
                                                            <i className="fe fe-search me-2"></i>
                                                            {t("search")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )
                }
            </Formik>

        </div >

    )
}

export default DrInventoryFilter;