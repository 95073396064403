import React from "react";

function DrCustomChartTooltip({ title, source }) {

    return (
        <div className="shadow d-flex" style={{ width: '180px' }}>
            <div className="card custom-card">
                <div className="card-header p-2">
                    <h6 className="card-title mb-1"> {title}</h6>
                </div>
                <div className="card-body p-1">
                    <ul>
                        {source?.map((data, index) => {
                            return (
                                <li key={"chartdata_" + index}>
                                    <b>{data.label}:</b> {data.value}
                                </li>
                            )
                        })}

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default DrCustomChartTooltip;

