import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DrBreadCrump, DrThreeDCube } from '../../components/component-index';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { modals, siteExtensions } from '../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { deleteProductsByAsins, getProductsDetailsByAsins, saveProductAsinBlock, saveProductBrandBlock } from '../../state-management/actions/inventory.actions';
import noImage from '../../assets/img/pngs/no-image.png'
import { getProductKeepaDetails } from '../../state-management/actions/keepa.actions';
import { flaggedCountryOptions, inventoryPageOptions } from '../../components/hoc/Constants/dr-constants.component';
import classNames from 'classnames';
import { setProductDetailPageSelections } from '../../state-management/actions/settings.actions';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery';
import * as moment from 'moment';
import { number } from 'yup';

function ProductDetailPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { asin } = useParams();
    const { t } = useTranslation('productDetailPage');
    const selectedProducts = useSelector(state => state.inventory.selectedProducts);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const selectedProductKeepaDetails = useSelector(state => state.keepa.selectedProductKeepaDetails);
    const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.productDetailPageSelections);

    useEffect(() => {
        let panelProductDetails = document.getElementsByClassName("panelProductDetails");
        if (panelProductDetails && panelProductDetails?.length > 0) {
            panelProductDetails[0].addEventListener("show.bs.collapse", function (e) {
                // let shownItems = Object.assign({}, pageSelections?.detailedSearchShownAccordions);
                var shownItems = pageSelections?.productDetailShownAccordions;

                if (e?.target?.id === "panelDBSourceOptions") {
                    shownItems.panelDBSource = true;
                }
                if (e?.target?.id === "panelKeepaSourceOptions") {
                    shownItems.panelKeepaSource = true;
                }

                dispatch(withActionPromise(setProductDetailPageSelections({ productDetailShownAccordions: shownItems })));
            });
            panelProductDetails[0].addEventListener("hide.bs.collapse", function (e) {
                // let shownItems = Object.assign({}, pageSelections?.detailedSearchShownAccordions);
                var shownItems = pageSelections?.productDetailShownAccordions;

                if (e?.target?.id === "panelDBSourceOptions") {
                    shownItems.panelDBSource = false;
                }
                if (e?.target?.id === "panelKeepaSourceOptions") {
                    shownItems.panelKeepaSource = false;
                }

                dispatch(withActionPromise(setProductDetailPageSelections({ productDetailShownAccordions: shownItems })));
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (asin && selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getProductsDetailsByAsins({
                asinList: [asin],
                customerMainSellerId: selectedStore?.customerMainSellerId
            })));
            // dispatch(withActionPromise(getProductKeepaDetails({ asin: asin })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asin]);

    useEffect(() => {
        if (selectedProducts.length > 0) {
            setSelectedProduct(selectedProducts[0]);
            dispatch(withActionPromise(getProductKeepaDetails({ asin: selectedProducts[0]?.asin })));
        }
        else {
            setSelectedProduct(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts]);

    var panelDBSourceClass = classNames({
        'panelSalesMarket': true,
        'accordion-collapse collapse': true,
        'show': pageSelections?.productDetailShownAccordions?.panelDBSource === null || pageSelections?.productDetailShownAccordions?.panelDBSource === true,
    });

    var panelDBSourceButtonClass = classNames({
        'bg-info bg-opacity-75': true,
        'text-white': true,
        'accordion-button': true,
        'collapsed': pageSelections?.productDetailShownAccordions?.panelDBSource === false,
    });

    var panelKeepaSourceClass = classNames({
        'panelSourceMarket': true,
        'accordion-collapse collapse': true,
        'show': pageSelections?.productDetailShownAccordions?.panelKeepaSource === null || pageSelections?.productDetailShownAccordions?.panelKeepaSource === true,
    });

    var panelKeepaSourceButtonClass = classNames({
        'bg-success bg-opacity-75': true,
        'text-white': true,
        'accordion-button': true,
        'collapsed': pageSelections?.productDetailShownAccordions?.panelKeepaSource === false
    });


    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
                { navigationText: i18next.t('constants:breadCrumps.productDetail'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-xxl-9 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card custom-card mb-2">
                                <div className="card-body">
                                    <div className="row justify-content-sm-center justify-content-md-center justify-content-center">
                                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 px-0">
                                            <div className="w-100 h-100 bg-gray-300 p-2">
                                                <img alt="" className="img-thumbnail h-max cursor-pointer" src={selectedProduct?.productPicture || noImage}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (selectedProduct?.productPicture) {
                                                            dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [selectedProduct?.productPicture] }))
                                                        }
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                            <div className="row mb-1">
                                                <div className="col-12">
                                                    <h3 className="card-title fw-bold mb-3">{selectedProduct?.productName}</h3>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4 col-md-5 col-sm-5">
                                                    <p className="fs-13 fw-semibold mb-1">ASIN:</p>
                                                </div>
                                                <div className="col-8 col-md-7 col-sm-7">
                                                    <label>
                                                        <span className="text-white fs-13 fw-bold badge bg-info cursor-pointer">
                                                            {selectedProduct?.asin}
                                                        </span>
                                                        <span onClick={(e) => {
                                                            e.preventDefault();
                                                            navigator.clipboard.writeText(selectedProduct?.asin);
                                                            toastr.info("Info", selectedProduct?.asin + " is copied to clipboard", { timeOut: 1000 })
                                                        }}>
                                                            <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                                        </span>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4 col-md-5 col-sm-5">
                                                    <p className="fs-13 fw-semibold mb-1">SKU:</p>
                                                </div>
                                                <div className="col-8 col-md-7 col-sm-7">
                                                    <label>
                                                        <span className="badge bg-purple fs-13 text-white fw-semibold mb-1">{selectedProduct?.sku}</span>
                                                        <span onClick={(e) => {
                                                            e.preventDefault();
                                                            navigator.clipboard.writeText(selectedProduct?.sku);
                                                            toastr.info("Info", selectedProduct?.sku + " is copied to clipboard", { timeOut: 1000 })
                                                        }}>
                                                            <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4 col-md-5 col-sm-5">
                                                    <p className="fs-13 fw-semibold mb-1">Product Status:</p>
                                                </div>
                                                <div className="col-8 col-md-7 col-sm-7">
                                                    <span className="badge bg-teal">{inventoryPageOptions.find(x => x.value === selectedProduct?.status)?.label}</span>
                                                </div>
                                            </div>

                                            <div className="row mt-5 mb-1">
                                                <div className="col-12">
                                                    <h3 className="card-title fw-bold mb-3">{t("productProperties")}</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-xxl-5">
                                                    <div className="row mt-1">
                                                        <div className="col-4 col-xl-5 col-md-5 col-sm-5">
                                                            <p className="fs-13 fw-semibold mb-1">{t("width")}</p>
                                                        </div>
                                                        <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                            <span >{selectedProduct?.itemWidthValue && Number(selectedProduct?.itemWidthValue).toFixed(2)} {selectedProduct?.itemWidthUnit}</span>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-5 col-md-25 col-sm-5">
                                                            <p className="fs-13 fw-semibold mb-1">{t("length")}</p>
                                                        </div>
                                                        <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                            <span >{selectedProduct?.itemLengthValue && Number(selectedProduct?.itemLengthValue).toFixed(2)} {selectedProduct?.itemLengthUnit}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-5 col-md-5 col-sm-5">
                                                            <p className="fs-13 fw-semibold mb-1">{t("height")}</p>
                                                        </div>
                                                        <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                            <span >{selectedProduct?.itemHeightValue && Number(selectedProduct?.itemHeightValue).toFixed(2)} {selectedProduct?.itemHeightUnit}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-5 col-md-5 col-sm-5">
                                                            <p className="fs-13 fw-semibold mb-1">{t("weight")}</p>
                                                        </div>
                                                        <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                            <span >{selectedProduct?.itemWeightValue && Number(selectedProduct?.itemWeightValue).toFixed(2)} {selectedProduct?.itemWeightUnit}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-5 col-md-5 col-sm-5">
                                                            <p className="fs-13 fw-semibold mb-1">{t("partNumber")}</p>
                                                        </div>
                                                        <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                            <span >{selectedProduct?.partNumber}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-5 col-md-5 col-sm-5">
                                                            <p className="fs-13 fw-semibold mb-1">{t("variation")}</p>
                                                        </div>
                                                        <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                            <span >{selectedProduct?.size} {selectedProduct?.color} {selectedProduct?.style}</span>
                                                        </div>
                                                    </div>
                                                    {selectedProduct?.fabricType && selectedProduct?.fabricType.length > 0 &&
                                                        <div className="row">
                                                            <div className="col-4 col-xl-5 col-md-5 col-sm-5">
                                                                <p className="fs-13 fw-semibold mb-1">{t("fabricType")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                                <span >{selectedProduct?.fabricType}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {selectedProduct?.isLiquidContent && <div className="row">
                                                        <div className="col-4 col-xl-5 col-md-5 col-sm-5">
                                                            <p className="fs-13 fw-semibold mb-1">{t("isLiquidContent")}</p>
                                                        </div>
                                                        <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                            <span >{selectedProduct?.isLiquidContent && <i className="fe fe-check fs-16 text-success fw-bold"></i>}</span>
                                                        </div>
                                                    </div>}
                                                    {selectedProduct?.isBatteryRequired && <div className="row">
                                                        <div className="col-4 col-xl-5 col-md-5 col-sm-5">
                                                            <p className="fs-13 fw-semibold mb-1">{t("isBatteryRequired")}</p>
                                                        </div>
                                                        <div className="col-8 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                            <span >{selectedProduct?.isBatteryRequired && <i className="fe fe-check fs-16 text-success fw-bold"></i>}</span>
                                                        </div>
                                                    </div>}
                                                </div>
                                                <div className="col-12 col-xxl-7 ">
                                                    <div className="row mt-1" >
                                                        <div className="col-8">
                                                            <div className="row">

                                                                <div className="col-5 col-xl-5 col-md-5 col-sm-5">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("packageWidth")}</p>
                                                                </div>
                                                                <div className="col-7 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                                    <span >{selectedProduct?.packageWidthValue && Number(selectedProduct?.packageWidthValue).toFixed(2)} {selectedProduct?.packageWidthUnit}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-5 col-xl-5 col-md-5 col-sm-5">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("packageLength")}</p>
                                                                </div>
                                                                <div className="col-7 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                                    <span >{selectedProduct?.packageLengthValue && Number(selectedProduct?.packageLengthValue).toFixed(2)} {selectedProduct?.packageLengthUnit}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-5 col-xl-5 col-md-5 col-sm-5">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("packageHeight")}</p>
                                                                </div>
                                                                <div className="col-7 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                                    <span >{selectedProduct?.packageHeightValue && Number(selectedProduct?.packageHeightValue).toFixed(2)} {selectedProduct?.packageHeightUnit}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-5 col-xl-5 col-md-5 col-sm-5">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("packageWeight")}</p>
                                                                </div>
                                                                <div className="col-7 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                                    {selectedProduct?.packageWeightValue && <span >{Number(selectedProduct?.packageWeightValue).toFixed(2)} {selectedProduct?.packageWeightUnit}</span>}
                                                                    {!selectedProduct?.packageWeightValue && <span>n/a</span>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-5 col-xl-5 col-md-5 col-sm-5">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("packageQuantity")}</p>
                                                                </div>
                                                                <div className="col-7 col-xl-7 col-md-7 col-sm-7 ps-0">
                                                                    <span >{selectedProduct?.itemPackageQuantity}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                            </div>
                                                        </div>
                                                        <div id="threeDBox" className="col-4">
                                                            <DrThreeDCube
                                                                renderSizeX={130}
                                                                renderSizeY={130}
                                                                boxsizeX={selectedProduct?.packageLengthValue ? Number(selectedProduct?.packageLengthValue).toFixed(2) : 1}
                                                                boxsizeY={selectedProduct?.packageWidthValue ? Number(selectedProduct?.packageWidthValue).toFixed(2) : 1}
                                                                boxsizeZ={selectedProduct?.packageHeightValue ? Number(selectedProduct?.packageHeightValue).toFixed(2) : 1}
                                                                maxEdgeValue={3}
                                                            >

                                                            </DrThreeDCube>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panelProductDetails accordion">
                                <div className="accordion-item mb-1 shadow">
                                    <h2 className="accordion-header ">
                                        <button className={panelDBSourceButtonClass}
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#panelDBSourceOptions" aria-expanded="true"
                                            aria-controls="panelDBSourceOptions">
                                            Product Detail
                                        </button>
                                    </h2>
                                    <div id="panelDBSourceOptions" className={panelDBSourceClass}>
                                        <div className="accordion-body bg-info bg-opacity-10">
                                            {/* <div className="card custom-card bg-info bg-opacity-10">
                                                <div className="card-body p-2"> */}
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <div className="card custom-card bg-light  mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">Kaynak pazarda ürünün satıcısı:</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.sourceMarketSellerName || selectedProduct?.sellerId}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">Satıcı tipi:</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.sourceMarketSellerType}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">Marka:</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.brandName}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">USPTO tescil durumu:</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.hasUSPTOCertified ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">CIPO tescil durumu:</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.hasCIPOCertified ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("isPrimeExclusive")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.isPrimeExclusive ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("isBestSelling")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.isBestSellingProduct ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("isFBASellerExist")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.isFBASellerExists ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("isChineseSellerExist")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.isChineseSellerExists ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card custom-card bg-light  mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("salesMarketMainCategory")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.destinationProductCategory?.categoryName}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("salesMaketSubCategory")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.destinationProductCategory?.subCategories?.length > 0 ? selectedProduct?.destinationProductCategory?.subCategories[0].categoryName : ""}</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("salesMarketMainCategorySalesRank")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.destinationProductCategory?.rank}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("salesMarketSubCategorySalesRank")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.destinationProductCategory?.subCategories?.length > 0 ? selectedProduct?.destinationProductCategory?.subCategories[0].rank : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("sourceMarketMainCategorySalesRank")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.sourceProductCategory?.rank}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("sourceMarketSubCategorySalesRank")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.sourceProductCategory?.subCategories?.length > 0 ? selectedProduct?.sourceProductCategory?.subCategories[0].rank : ""}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <div className="card custom-card bg-success bg-opacity-10 mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("minPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedStoreCurrencyType.currencyCode} {Number(selectedProduct?.minSellingPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("maxPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedStoreCurrencyType.currencyCode} {Number(selectedProduct?.maxSellingPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("sellPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span className="fs-14 fw-bold">{selectedStoreCurrencyType.currencyCode} {Number(selectedProduct?.sellingPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("amazonCommission")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedStoreCurrencyType.currencyCode} {Number(selectedProduct?.amazonFeePrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("stock")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.stockCount}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("handlingTime")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.deliveryTime}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card custom-card bg-danger bg-opacity-10 mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("listingPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.sourceMarketPrice} </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("estimatedShippingPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.sourceShippingPrice}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("warehousePrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.warehousePrice} </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("totalPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span className="fs-14 fw-bold" >{selectedProduct?.sourceTotalCost}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card custom-card bg-info bg-opacity-10 mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("buyboxSeller")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    {selectedProduct?.buyboxOwnerSeller?.length > 0 &&
                                                                        <label>
                                                                            <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                                                href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore?.countryCode)?.extension + "/sp?marketplaceID=" + selectedStore?.marketSerialNumber + "&seller=" + selectedProduct?.buyboxOwnerSeller}>
                                                                                {selectedProduct?.buyboxOwnerSeller}
                                                                                <i className="ms-2 bx bxs-copy bx-xs bx-burst-hover fw-bold text-white cursor-pointer"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        navigator.clipboard.writeText(selectedProduct?.buyboxOwnerSeller);
                                                                                        toastr.info("Info", selectedProduct?.buyboxOwnerSeller + " is copied to clipboard", { timeOut: 1000 })
                                                                                    }}
                                                                                > </i>
                                                                            </a>
                                                                        </label>}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("buyboxPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.buyboxPriceCurrencyCode} {Number(selectedProduct?.buyboxPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("lowestSeller")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-4">
                                                                    {selectedProduct?.lowestPriceSeller?.length > 0 &&
                                                                        <label>
                                                                            <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                                                href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore?.countryCode)?.extension + "/sp?marketplaceID=" + selectedStore?.marketSerialNumber + "&seller=" + selectedProduct?.lowestPriceSeller}>
                                                                                {selectedProduct?.lowestPriceSeller}
                                                                                <i className="ms-2 bx bxs-copy bx-xs bx-burst-hover fw-bold text-white cursor-pointer"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        navigator.clipboard.writeText(selectedProduct?.lowestPriceSeller);
                                                                                        toastr.info("Info", selectedProduct?.lowestPriceSeller + " is copied to clipboard", { timeOut: 1000 })
                                                                                    }}
                                                                                > </i>
                                                                            </a>


                                                                        </label>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("lowestPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.lowestPriceCurrencyCode} {Number(selectedProduct?.lowestPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("primeSellerCount")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.primeSellerCount || 0}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-7 col-sm-7">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("totalSellerCount")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-5 col-sm-5">
                                                                    <span >{selectedProduct?.totalSellerCount || 0}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {selectedProductKeepaDetails &&
                                    <div className="accordion-item mb-1 shadow">
                                        <h2 className="accordion-header">
                                            <button className={panelKeepaSourceButtonClass}
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#panelKeepaSourceOptions" aria-expanded="true"
                                                aria-controls="panelKeepaSourceOptions">
                                                Keepa Product Detail
                                            </button>
                                        </h2>
                                        <div id="panelKeepaSourceOptions" className={panelKeepaSourceClass}>
                                            <div className="accordion-body bg-success bg-opacity-10">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6">
                                                        <div className="card custom-card bg-light  mb-2 shadow">
                                                            <div className="card-body p-3">
                                                                {/* <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.salesRank}</span>
                                                                    </div>
                                                                </div> */}
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank30DaysAvg")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.salesRank30DaysAvg}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank60DaysAvg")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.salesRank60DaysAvg}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank30DaysDrop")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.salesRank30DaysDrop}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank90DaysDrop")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.salesRank90DaysDrop}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank30DaysDropsLast")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.salesRank30DaysDropsLast}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank90DaysDropsLast")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.salesRank90DaysDropsLast}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card custom-card bg-light  mb-2 shadow">
                                                            <div className="card-body p-3">
                                                                {/* <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.newOfferCount")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.newOfferCount}</span>
                                                                    </div>
                                                                </div> */}
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.newOfferCountCurrent")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.newOfferCountCurrent}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.newOfferCount30DaysAvg")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.newOfferCount30DaysAvg}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.countOfRetrievedLiveOffersNewFBM")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.countOfRetrievedLiveOffersNewFBM}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.countOfRetrievedLiveOffersNewFBA")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.countOfRetrievedLiveOffersNewFBA}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="card custom-card bg-light  mb-2 shadow">
                                                            <div className="card-body p-3">
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.amazonCurrentPrice")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >USD {Number(selectedProductKeepaDetails?.amazonCurrentPrice || 0).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.referralFeePercent")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.referralFee}%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.referralFeeBasedOnCurrentBuyBoxprice")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedStoreCurrencyType.currencyCode} {Number(selectedProductKeepaDetails?.referralFeeBasedOnCurrentBuyBoxprice || 0).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.fbaPickAndPackFee")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >USD {Number(selectedProductKeepaDetails?.fbaPickAndPackFee || 0).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card custom-card bg-light  mb-2 shadow">
                                                            <div className="card-body p-3">
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBoxCurrent")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >USD {Number(selectedProductKeepaDetails?.buyBoxCurrent || 0).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBox30daysavg")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >USD {Number(selectedProductKeepaDetails?.buyBox30daysavg || 0).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBox30daysDrop")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.buyBox30daysDrop}%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBox90daysDrop")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.buyBox90daysDrop}%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBoxSeller")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.buyBoxSeller}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBoxUnqualified")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.buyBoxUnqualified ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                                                    </div>
                                                                </div>
                                                                <hr></hr>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.boughtInPastMonth")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.boughtInPastMonth}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col-8 col-md-7 col-sm-7">
                                                                        <p className="fs-13 fw-semibold mb-1">{t("keepa.freqBoughtTogether")}</p>
                                                                    </div>
                                                                    <div className="col-4 col-md-5 col-sm-5">
                                                                        <span >{selectedProductKeepaDetails.freqBoughtTogether}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>


                    </div>

                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 px-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card custom-card mb-2">
                                <div className="card-body">
                                    {selectedProducts?.length > 0 && <>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                {flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.icon}
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                        href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/" + selectedProduct?.modelNumber + "/dp/" + selectedProduct?.asin + "?th=1&psc=1"}>
                                                        {flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label} marketinde görüntüle
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                {flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.icon}
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                        href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/" + selectedProduct?.modelNumber + "/dp/" + selectedProduct?.asin + "/ref=olp_aod_redir_impl1?_encoding=UTF8&aod=1&th=1"}>
                                                        {flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label} marketinde teklifleri görüntüle
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                {flaggedCountryOptions?.find(x => x.value === (selectedProduct?.sourceMarketCountryCode || "US"))?.icon}
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                        href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === (selectedProduct?.sourceMarketCountryCode || "US"))?.extension + "/" + selectedProduct?.modelNumber + "/dp/" + selectedProduct?.asin + "?th=1&psc=1"}>
                                                        {flaggedCountryOptions?.find(x => x.value === (selectedProduct?.sourceMarketCountryCode || "US"))?.label} marketinde görüntüle
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                <i className="bx bx-rotate-left fs-20 me-1"></i>
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <span className="text-primary cursor-pointer"
                                                        onClick={(e) => {
                                                            navigate('/orders/s/' + selectedProduct?.asin);
                                                        }}
                                                    >
                                                        Siparişleri görüntüle
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                <i className="bx bxs-show fs-20 me-1"></i>
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                        href={"https://sellercentral.amazon." + siteExtensions.find(x => x.countryCode === selectedStore?.countryCode)?.extension + "/abis/listing/edit/offer?asin=" + selectedProduct?.asin + "&sku=" + selectedProduct?.sku + "&marketplaceID=" + selectedStore?.marketSerialNumber}>
                                                        SellerCentral'da görüntüle
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                <i className="bx bx-rotate-left fs-20 me-1"></i>
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <span className="text-primary cursor-pointer"
                                                        onClick={(e) => {
                                                            navigate('/update-list/' + selectedProduct?.asin);
                                                        }}
                                                    >
                                                        Güncelleme geçmişini görüntüle
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0" >
                                                <i className='bx bx-block fs-20 me-1 text-danger' ></i>
                                            </div>
                                            <div className="col">
                                                <span className="cursor-pointer text-danger"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        var requestData = {
                                                            customerMainSellerId: selectedStore?.customerMainSellerId,
                                                            asinList: [
                                                                selectedProduct?.asin
                                                            ]
                                                        }

                                                        dispatch(withActionPromise(saveProductAsinBlock(requestData)));
                                                    }}>
                                                    Ürün Asin'ini engelle
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0" >
                                                <i className='bx bx-block fs-20 me-1 text-danger' ></i>
                                            </div>
                                            <div className="col">
                                                <span className="cursor-pointer text-danger"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        var requestData = {
                                                            customerMainSellerId: selectedStore?.customerMainSellerId,
                                                            productBrandNames: [selectedProduct?.brandName],
                                                            isBlocked: true
                                                        }
                                                        dispatch(saveProductBrandBlock(requestData));

                                                    }}>
                                                    Engellenen markalara ekle
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1 px-0" >
                                                <i className='bx bx-block fs-20 me-1 text-danger' ></i>
                                            </div>
                                            <div className="col">
                                                <span className="cursor-pointer text-danger"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        var requestData = {
                                                            deleteRequestData: {
                                                                asins: [
                                                                    selectedProduct?.asin
                                                                ],
                                                                customerMainSellerId: selectedStore?.customerMainSellerId
                                                            }
                                                        }

                                                        dispatch(withActionPromise(deleteProductsByAsins(requestData)));

                                                    }}>
                                                    Ürünü mağazamdan kaldır
                                                </span>
                                            </div>
                                        </div>
                                    </>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            <span className="fs-13">Kargo ücreti biliniyor mu?</span>
                                        </div>
                                        <div className="col-3">
                                            <span>{selectedProduct?.shippingPrice > 0 ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            <span className="fs-13">Gümrük ücreti biliniyor mu?</span>
                                        </div>
                                        <div className="col-3">
                                            {selectedProduct?.customsDutyPrice > 0
                                                ? <span className="fs-13">{(selectedStoreCurrencyType.currencyCode + " " + Number(selectedProduct?.customsDutyPrice).toFixed(2))}</span>
                                                : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            <span className="fs-13">Stokta var mı?</span>
                                        </div>
                                        <div className="col-3">
                                            {selectedProduct?.stockCount > 0
                                                ? <span className="fs-13">{Number(selectedProduct?.stockCount)}</span>
                                                : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            <span className="fs-13">İndrimli ürün mü?</span>
                                        </div>
                                        <div className="col-3">
                                            <span>{selectedProduct?.isDiscounted > 0 ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            <span className="fs-13">Kuponlu ürün mü?</span>
                                        </div>
                                        <div className="col-3">
                                            {(selectedProduct?.couponPriceValue > 0 || selectedProduct?.couponPercentValue > 0) &&
                                                <span className="fs-13">
                                                    {/* {selectedProduct?.couponValue?.length > 0 ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>} */}
                                                    {selectedProduct?.couponPercentValue > 0 && <i className="fe fe-percent me-2 d-inline-block"></i>}
                                                    {/* {selectedProduct?.couponPriceValue > 0 && <i className="fe fe-dollar-sign me-2 d-inline-block"></i>} */}
                                                    {selectedProduct?.couponPercentValue > 0 ? Number(selectedProduct?.couponPercentValue).toFixed(2) : 0}
                                                    {/* {selectedProduct?.couponPriceValue > 0 ? selectedProduct?.couponPriceValue : 0} */}
                                                </span>
                                            }
                                            {!(selectedProduct?.couponPriceValue > 0 || selectedProduct?.couponPercentValue > 0) &&
                                                <span><i className="fe fe-x fs-16 text-danger fw-bold"></i></span>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            <span className="fs-13">Prime indirimli ürün mü?</span>
                                        </div>
                                        <div className="col-3">
                                            {(selectedProduct?.primePriceValue > 0 || selectedProduct?.primePercentValue > 0) &&
                                                <span className="fs-13">
                                                    {/* {selectedProduct?.couponValue?.length > 0 ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>} */}
                                                    {/* {selectedProduct?.primePercentValue > 0 && <i className="fe fe-percent me-2 d-inline-block"></i>} */}
                                                    {selectedProduct?.primePriceValue > 0 && <i className="fe fe-dollar-sign me-2 d-inline-block"></i>}
                                                    {/* {selectedProduct?.primePercentValue > 0 ? Number(selectedProduct?.primePercentValue).toFixed(2) : 0} */}
                                                    {selectedProduct?.primePriceValue > 0 ? Number(selectedProduct?.primePriceValue).toFixed(2) : 0}
                                                </span>
                                            }
                                            {!(selectedProduct?.primePriceValue > 0 || selectedProduct?.primePercentValue > 0) &&
                                                <span><i className="fe fe-x fs-16 text-danger fw-bold"></i></span>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            <span className="fs-13">Son envanter güncelleme tarihi:</span>
                                        </div>
                                        <div className="col-3">
                                            <span className="fs-13">{moment(selectedProduct?.lastInventoryUpdateDate).fromNow()}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            <span className="fs-13">Son ürün güncelleme tarihi:</span>
                                        </div>
                                        <div className="col-3">
                                            <span className="fs-13">{moment(selectedProduct?.lastProductUpdateDate).fromNow()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default ProductDetailPage