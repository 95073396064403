
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrFileUploadInput, DrInput, DrPageFilter, DrSelectBox } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonDropdownDirection, buttonVariants, staticRoles } from '../../utils/constants';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import i18next from 'i18next';
import { flaggedMarketOptions, } from '../../components/hoc/Constants/dr-constants.component';
import { Field, Formik } from 'formik';
import $ from 'jquery';
import Restricted from '../../security/Restricted';
import { deleteWarehouseExcel, getWarehouses, getWarehousesExcels, saveWarehouseExcel, saveWarehouseSettings } from '../../state-management/actions/warehouses.actions';
import { CONTROLLERS, apiGet } from '../../utils/axiosApiCalls';
import { composeFormData, getDirtyValues } from '../../utils/utils';
import templateExcell from '../../assets/img/files/Warehouse_Excel_Template.xlsx';
import { getShipStationCarriers, getShipStationCarrierServices } from '../../state-management/actions/shipstation.actions';



function WarehousePreferencesPage() {
    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_STORE } = process.env;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('warehousePreferencesPage');
    const warehousesExcelList = useSelector(state => state.warehouses.warehousesExcelList);
    const allMarkets = useSelector(state => state.markets.allMarkets);
    const shipstationCarriers = useSelector(state => state.shipstation.shipstationCarriers);
    const shipstationCarrierServices = useSelector(state => state.shipstation.shipstationCarrierServices);
    const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
    const customerInfo = useSelector(state => state.customer.customerInfo);

    const [selectedPreferences, setSelectedPreferences] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [sourceCountriesOptions, setSourceCountriesOptions] = useState(flaggedMarketOptions?.filter(x => x.value !== "-1"));
    const [destinationCountriesOptions, setDestinationCountriesOptions] = useState(flaggedMarketOptions?.filter(x => x.value !== "-1"));
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    useEffect(() => {
        dispatch(withActionPromise(getWarehouses()));
        dispatch(withActionPromise(getWarehousesExcels()));
        if (!(shipstationCarriers && shipstationCarriers?.length > 0)) {
            dispatch(withActionPromise(getShipStationCarriers()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allWarehouses?.length > 0) {
            if (customerInfo?.warehouseId) {
                setSelectedWarehouse(allWarehouses.find(x => x.id === customerInfo?.warehouseId));
            }
            else if (selectedWarehouseId) {
                setSelectedWarehouse(allWarehouses.find(x => x.id === selectedWarehouseId));
            }
            else {
                setSelectedWarehouse(allWarehouses[0]);
            }
        }
        else {
            setSelectedWarehouse(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouses, selectedWarehouseId, customerInfo]);

    useEffect(() => {

        if (warehousesExcelList && warehousesExcelList?.length > 0) {
            let data = Object.assign([], warehousesExcelList);
            data.forEach(warehouseData => {
                let fromMarketObject = allMarkets.find(x => x.id === warehouseData.fromMarketId);
                let toMarketObject = allMarkets.find(x => x.id === warehouseData.toMarketId);

                warehouseData.fromMarketName = t(('constants:countries.' + fromMarketObject.countryCode));
                warehouseData.sourceCountryCode = fromMarketObject.countryCode;
                warehouseData.toMarketName = t(('constants:countries.' + toMarketObject.countryCode));
                warehouseData.destinatonCountryCode = toMarketObject.countryCode;
            });

            setTableData(data);
        } else {
            setTableData([]);
            setSourceCountriesOptions(flaggedMarketOptions?.filter(x => x.value !== "-1"));
            setDestinationCountriesOptions(flaggedMarketOptions?.filter(x => x.value !== "-1"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehousesExcelList?.length, flaggedMarketOptions]);

    const columns = [
        { data: null, title: "", width: "10%", minWidth: "60px", maxWidth: "60px", searchable: false },
        { data: "fromMarketName", title: t('dataTable.sourceCountry'), minWidth: "120px", maxWidth: "120px", searchable: true },
        { data: null, title: "", minWidth: "40px", maxWidth: "40px", searchable: false },
        { data: "toMarketName", title: t('dataTable.destinationCountry'), minWidth: "120px", maxWidth: "120px", searchable: true },
        { data: "carrierCode", title: "CarrierCode", minWidth: "140px", maxWidth: "140px", searchable: true },
        { data: "carrierServiceCode", title: "ServiceCode", minWidth: "180px", maxWidth: "180px", searchable: true },
        { data: "updatedDate", title: t('dataTable.uploadedDate'), minWidth: "180px", searchable: false },
        { data: null, title: "", width: "10%", minWidth: "180px", maxWidth: "180px", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <div>
                            <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.fromMarketId}
                                onChange={(e) => {
                                    e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                                }}
                            />
                        </div>
                    </>);
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            width: "20%",
            searchable: true,
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">
                        <i className={'w-6 me-2 flag flag-' + (rowData.sourceCountryCode === 'UK' ? 'gb' : rowData.sourceCountryCode.toLowerCase())} />
                        {rowData.fromMarketName}
                    </span>
                );
            }
        },
        {
            targets: 2,
            orderable: false,
            className: "text-start",
            width: "20%",
            searchable: false,
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-16 text-primary my-auto mx-0 p-0">
                        <i className={'fe fe-arrow-right'} />
                    </span>
                );
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-start",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-12 my-auto mx-0 p-0">
                        <i className={'w-6 me-2 flag flag-' + (rowData.destinatonCountryCode === 'UK' ? 'gb' : rowData.destinatonCountryCode.toLowerCase())} />
                        {rowData.toMarketName}
                    </span>
                );
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-12 my-auto mx-0 p-0">{rowData.carrierCode}</span>
                );
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-12 my-auto mx-0 p-0">{rowData.carrierServiceCode}</span>
                );
            }
        },
        {
            targets: 6,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-12 my-auto mx-0 p-0">{moment(rowData.updatedDate).format('L LT')}</span>
                );
            }
        },
        {
            targets: 7,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div className="row">
                        <div className="col-auto">
                            <button className="btn btn-sm btn-success btn-wave waves-effect waves-light shadow"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleFileDownload(rowData.fromMarketId, rowData.toMarketId, rowData.carrierCode, rowData.carrierServiceCode);
                                }}>
                                <i className="me-2 fe fe-download"></i>
                                {t('dataTable.download')}
                            </button>
                        </div>
                        <div className="col-auto ps-0">
                            <button className="btn btn-sm btn-icon btn-danger btn-wave waves-effect waves-light shadow"
                                onClick={(e) => {
                                    e.preventDefault();
                                    var deleteRequest = {
                                        fromMarketId: rowData.fromMarketId,
                                        toMarketId: rowData.toMarketId,
                                        carrierCode: rowData.carrierCode,
                                        carrierServiceCode: rowData.carrierServiceCode
                                    }
                                    dispatch(withActionPromise(deleteWarehouseExcel(deleteRequest)));
                                }}
                            >
                                <i className="fe fe-trash-2" ></i>
                            </button>
                        </div>

                    </div>
                )
            }
        }
    ];

    const initialValues = {
        fromMarketId: selectedPreferences?.fromMarketId || "",
        toMarketId: selectedPreferences?.toMarketId || "",
        isDisabled: selectedPreferences?.fromMarketId ? true : false,
        excelFile: selectedPreferences?.excelFile || null,
        carrierServiceCode: selectedPreferences?.carrierServiceCode || "",
        carrierCode: selectedPreferences?.carrierCode || "",
    };

    const initialSettingsValues = {
        warehouseId: selectedWarehouse?.id,
        serviceFee: selectedWarehouse?.serviceFee || null,
        customsFeeType: selectedWarehouse?.customsFeeType || "",
        customsFeeMinCalc: selectedWarehouse?.customsFeeMinCalc || null,
        customsFeeMin: selectedWarehouse?.customsFeeMin || null,
        customsFee: selectedWarehouse?.customsFee || null,
        commissionFeeType: selectedWarehouse?.commissionFeeType || "",
        commissionFeeMinCalc: selectedWarehouse?.commissionFeeMinCalc || null,
        commissionFeeMin: selectedWarehouse?.commissionFeeMin || null,
        commissionFee: selectedWarehouse?.commissionFee || null,
    };

    const unCheckAllSelections = () => {
        $(document.querySelectorAll('#warehousePreferencesTable tr')).removeClass("selected");
        $(document.querySelectorAll('input[type="checkbox"]:checked')).prop("checked", false);
        $(document.querySelectorAll('input[type="file"]')).prop("value", '');
    }

    const rowClickedCallBack = async (rowData) => {
        if (rowData) {
            setSelectedPreferences({ ...rowData });
            if (rowData?.carrierCode) {
                callCarrierService(rowData?.carrierCode);
            }
        }
        // dispatch(saveCustomerInfo(customerSubUserId));
    }

    const handleFileDownload = async (fromMarketId, toMarketId, carrierCode, carrierServiceCode) => {


        await apiGet(`/WarehouseExcelExport?FromMarketId=${fromMarketId}&ToMarketId=${toMarketId}&CarrierCode=${carrierCode}&CarrierServiceCode=${carrierServiceCode}`, CONTROLLERS.SHIPSTATION, true, true, true)().then((response) => {
            if (response) {
                let fromMarketObject = allMarkets.find(x => x.id === fromMarketId);
                let toMarketObject = allMarkets.find(x => x.id === toMarketId);

                var excelFileName = "Warehouse_Excel_From_" + t(('constants:countries.' + fromMarketObject.countryCode)) + "_To_" + t(('constants:countries.' + toMarketObject.countryCode)) + "_" + moment().format('L');
                const url = URL.createObjectURL(
                    new Blob([response], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.innerText = 'Open the array URL';
                link.setAttribute('download', excelFileName);
                link.click();
            }
        }).catch((err) => {
            console.log("Error")
        });
    }

    const callCarrierService = (value) => {
        if (!(shipstationCarrierServices?.length > 0 && shipstationCarrierServices?.find(x => x.carrierCode === value))) {
            dispatch(getShipStationCarrierServices(value));
        }
    }

    return (
        <div style={{ marginBottom: '150px' }}>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.warehouseManagement'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.warehousePreferences'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div id="orderTable" className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">

                            <ul className="nav nav-pills mb-3 nav-justified tab-style-2 d-sm-flex d-block" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link`}
                                        data-bs-toggle="pill" data-bs-target="#warehouse-settings" type="button" role="tab" aria-controls="warehouse-settings" >
                                        <i className="fe fe-home ms-1 me-1"></i>
                                        Settings
                                    </button>

                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link active`}
                                        data-bs-toggle="pill" data-bs-target="#carrier-pricing" type="button" role="tab" aria-controls="carrier-pricing" >
                                        <i className="fe fe-home ms-1 me-1"></i>
                                        Carrier Pricing
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className={`tab-pane bg-success bg-opacity-10`} id="warehouse-settings">

                                    <Formik
                                        initialValues={initialSettingsValues}
                                        validateOnBlur={true}
                                        // onSubmit={handleSubmit}
                                        // validationSchema={subUserInfoSchema}
                                        enableReinitialize={true}
                                        validateOnMount={true}
                                    >
                                        {({
                                            isValid,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setTouched,
                                            setFieldValue,

                                        }) => (
                                            <form>
                                                <div className="row justify-content-center">

                                                    <Restricted allowedTo={[

                                                        REACT_APP_ROLES_STORE,
                                                    ]}>
                                                        <div className="col-auto col-xxl-5 col-lg-10 col-xl-6 col-md-8 col-sm-12">
                                                            <div className="row justify-content-center my-3">
                                                                <div className="col-auto my-auto">
                                                                    <label className="text-wrap fw-bold">{selectedWarehouse?.wareHouseName}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Restricted>
                                                    <Restricted allowedTo={[
                                                        REACT_APP_ROLES_SUPERADMIN,
                                                        // REACT_APP_ROLES_STORE,
                                                    ]}>
                                                        <div className="col-auto col-xxl-5 col-lg-10 col-xl-6 col-md-8 col-sm-12">
                                                            <div className="row justify-content-lg-end justify-content-center my-1">

                                                                <div className="col-auto">
                                                                    <DrButtonDropdown className=""
                                                                        options={allWarehouses?.map((x) => ({ value: x.id, label: x.wareHouseName }))}
                                                                        optionClickCallBack={(value) => {
                                                                            setSelectedWarehouseId(value);
                                                                        }}
                                                                        selectedValue={selectedWarehouseId}
                                                                        variant={buttonVariants.teal}
                                                                        direction={buttonDropdownDirection.vertical}
                                                                        selectedVariant={buttonVariants.teal}
                                                                    >
                                                                    </DrButtonDropdown>
                                                                </div>
                                                            </div>
                                                            <div className="row my-1">
                                                                <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                    <label className="text-wrap">Commission Fee Type</label>
                                                                </div>
                                                                <div className="col">
                                                                    <Field
                                                                        onBlur={() => { setTouched({ ...touched.commissionFeeType, 'commissionFeeType': true }) }}
                                                                        onChange={(e) => {
                                                                            setFieldValue("commissionFeeType", e.value);
                                                                        }}
                                                                        name={'commissionFeeType'}
                                                                        component={DrSelectBox}
                                                                        placeholder="Commission Fee Type"
                                                                        options={[{ value: "$", label: "$" }, { value: "%", label: "%" }]} />
                                                                    {(touched.commissionFeeType && errors.commissionFeeType) &&
                                                                        <label type="invalid" className="form-control is-invalid">
                                                                            {errors.commissionFeeType}
                                                                        </label>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="row my-1">
                                                                <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                    <label className="text-wrap">Commission Fee Min Calc.</label>
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control" placeholder="Commission Fee Min Calc" name="commissionFeeMinCalc" type="number" min="0" step="0.1"
                                                                        value={values.commissionFeeMinCalc || ""}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'commissionFeeMinCalc': true }) }}
                                                                        // icon={<i className="fe fe-dollar-sign"></i>}
                                                                        // iconPosition="left"
                                                                        isInvalid={(touched.commissionFeeMinCalc && errors.commissionFeeMinCalc)}
                                                                        isValid={touched.commissionFeeMinCalc && !errors.commissionFeeMinCalc}
                                                                        validationText={errors.commissionFeeMinCalc} />

                                                                </div>
                                                            </div>
                                                            <div className="row my-1">
                                                                <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                    <label className="text-wrap">Commission Fee Min.</label>
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control" placeholder="Commission Fee Min" name="commissionFeeMin" type="number" min="0" step="0.1"
                                                                        value={values.commissionFeeMin || ""}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'commissionFeeMin': true }) }}
                                                                        // icon={<i className="fe fe-dollar-sign"></i>}
                                                                        // iconPosition="left"
                                                                        isInvalid={(touched.commissionFeeMin && errors.commissionFeeMin)}
                                                                        isValid={touched.commissionFeeMin && !errors.commissionFeeMin}
                                                                        validationText={errors.commissionFeeMin} />

                                                                </div>
                                                            </div>
                                                            <div className="row my-1">
                                                                <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                    <label className="text-wrap">Commission Fee</label>
                                                                </div>
                                                                <div className="col">
                                                                    <DrInput className="form-control" placeholder="Commission Fee" name="commissionFee" type="number" min="0" step="0.1"
                                                                        value={values.commissionFee || ""}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'commissionFee': true }) }}
                                                                        // icon={<i className="fe fe-dollar-sign"></i>}
                                                                        // iconPosition="left"
                                                                        isInvalid={(touched.commissionFee && errors.commissionFee)}
                                                                        isValid={touched.commissionFee && !errors.commissionFee}
                                                                        validationText={errors.commissionFee} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Restricted>
                                                    <div className="col-auto col-xxl-5 col-lg-10 col-xl-6 col-md-8 col-sm-12 ">
                                                        <div className="row my-1">
                                                            <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                <label className="text-wrap">Service Fee</label>
                                                            </div>
                                                            <div className="col">
                                                                <DrInput className="form-control" placeholder="Service Fee" name="serviceFee" type="number" min="0" step="0.1"
                                                                    value={values.serviceFee || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'serviceFee': true }) }}
                                                                    // icon={<i className="fe fe-dollar-sign"></i>}
                                                                    // iconPosition="left"
                                                                    isInvalid={(touched.serviceFee && errors.serviceFee)}
                                                                    isValid={touched.serviceFee && !errors.serviceFee}
                                                                    validationText={errors.serviceFee} />

                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                <label className="text-wrap">Customs Fee Type</label>
                                                            </div>
                                                            <div className="col">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.customsFeeType, 'customsFeeType': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue("customsFeeType", e.value);
                                                                    }}
                                                                    name={'customsFeeType'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Customs Fee Type"
                                                                    options={[{ value: "$", label: "$" }, { value: "%", label: "%" }]} />
                                                                {(touched.customsFeeType && errors.customsFeeType) &&
                                                                    <label type="invalid" className="form-control is-invalid">
                                                                        {errors.customsFeeType}
                                                                    </label>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                <label className="text-wrap">Customs Fee Min Calc.</label>
                                                            </div>
                                                            <div className="col">
                                                                <DrInput className="form-control" placeholder="Customs Fee Min Calc" name="customsFeeMinCalc" type="number" min="0" step="0.1"
                                                                    value={values.customsFeeMinCalc || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'customsFeeMinCalc': true }) }}
                                                                    // icon={<i className="fe fe-dollar-sign"></i>}
                                                                    // iconPosition="left"
                                                                    isInvalid={(touched.customsFeeMinCalc && errors.customsFeeMinCalc)}
                                                                    isValid={touched.customsFeeMinCalc && !errors.customsFeeMinCalc}
                                                                    validationText={errors.customsFeeMinCalc} />

                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                <label className="text-wrap">Customs Fee Min.</label>
                                                            </div>
                                                            <div className="col">
                                                                <DrInput className="form-control" placeholder="Customs Fee Min" name="customsFeeMin" type="number" min="0" step="0.1"
                                                                    value={values.customsFeeMin || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'customsFeeMin': true }) }}
                                                                    // icon={<i className="fe fe-dollar-sign"></i>}
                                                                    // iconPosition="left"
                                                                    isInvalid={(touched.customsFeeMin && errors.customsFeeMin)}
                                                                    isValid={touched.customsFeeMin && !errors.customsFeeMin}
                                                                    validationText={errors.customsFeeMin} />

                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-6 col-xxl-4 col-xl-4 col-lg-4  my-auto">
                                                                <label className="text-wrap">Customs Fee</label>
                                                            </div>
                                                            <div className="col">
                                                                <DrInput className="form-control" placeholder="Customs Fee" name="customsFee" type="number" min="0" step="0.1"
                                                                    value={values.customsFee || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'customsFee': true }) }}
                                                                    // icon={<i className="fe fe-dollar-sign"></i>}
                                                                    // iconPosition="left"
                                                                    isInvalid={(touched.customsFee && errors.customsFee)}
                                                                    isValid={touched.customsFee && !errors.customsFee}
                                                                    validationText={errors.customsFee} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-lg-end justify-content-center my-3">

                                                    <div className="col-auto">
                                                        <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                let requestData = getDirtyValues(values, initialSettingsValues);
                                                                if (!customerInfo?.warehouseId) {
                                                                    requestData.warehouseId = selectedWarehouse?.id;
                                                                }
                                                                dispatch(saveWarehouseSettings(requestData));
                                                            }}
                                                        >{t('saveSettings')}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                        }
                                    </Formik>
                                </div>
                                <div className={`tab-pane bg-primary bg-opacity-05 active`} id="carrier-pricing">
                                    <Formik
                                        initialValues={initialValues}
                                        validateOnBlur={true}
                                        // onSubmit={handleSubmit}
                                        // validationSchema={subUserInfoSchema}
                                        enableReinitialize={true}
                                        validateOnMount={true}
                                    >
                                        {({
                                            isValid,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setTouched,
                                            setFieldValue

                                        }) => (
                                            <form>
                                                <div className={`row my-1 justify-content-end`} >
                                                    <div className="col-auto my-auto">
                                                        <Restricted allowedTo={[
                                                            REACT_APP_ROLES_SUPERADMIN,
                                                            REACT_APP_ROLES_STORE
                                                        ]}>
                                                            <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    unCheckAllSelections();
                                                                    setSelectedPreferences(null);
                                                                }}>
                                                                <i className="me-2 fe fe-plus"></i>
                                                                {t('addNewPreferences')}
                                                            </button>
                                                        </Restricted>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group has-validation">
                                                            <label>{t('sourceCountry')}</label>
                                                            <Field
                                                                onBlur={() => { setTouched({ ...touched.fromMarketId, 'fromMarketId': true }) }}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setFieldValue("fromMarketId", e.value);
                                                                        var newDestinationMarketOptions = flaggedMarketOptions?.filter(x => x.value !== "-1").map(x => (
                                                                            {
                                                                                value: x.value,
                                                                                label: x.label,
                                                                                icon: x.icon,
                                                                                countryCode: x.countryCode,
                                                                                // isDisabled: (tableData?.find(y => y.fromMarketId === e.value && y.toMarketId === x.value) ? true : false)
                                                                            }));
                                                                        setDestinationCountriesOptions(newDestinationMarketOptions);
                                                                    }
                                                                }}
                                                                disabled={values.isDisabled}
                                                                isMulti={false}
                                                                name={'fromMarketId'}
                                                                component={DrSelectBox}
                                                                placeholder={t('selectSourceCountry')}
                                                                selectedValue={values.fromMarketId || ""}
                                                                options={sourceCountriesOptions} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group has-validation">
                                                            <label>{t('destinationCountry')}</label>
                                                            <Field
                                                                onBlur={() => { setTouched({ ...touched.toMarketId, 'toMarketId': true }) }}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setFieldValue("toMarketId", e.value);
                                                                        var newSourceMarketOptions = flaggedMarketOptions?.filter(x => x.value !== "-1").map(x => (
                                                                            {
                                                                                value: x.value,
                                                                                label: x.label,
                                                                                icon: x.icon,
                                                                                countryCode: x.countryCode,
                                                                                // isDisabled: (tableData?.find(y => y.toMarketId === e.value && y.fromMarketId === x.value) ? true : false)
                                                                            }));
                                                                        setSourceCountriesOptions(newSourceMarketOptions);
                                                                    }
                                                                }}
                                                                disabled={values.isDisabled}
                                                                isMulti={false}
                                                                name={'toMarketId'}
                                                                component={DrSelectBox}
                                                                placeholder={t('selectDestinationCountry')}
                                                                selectedValue={values.toMarketId || ""}
                                                                options={destinationCountriesOptions} />
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group has-validation">
                                                            <label>Carrier Code Selection</label>
                                                            <Field
                                                                onBlur={() => { setTouched({ ...touched.carrierCode, 'carrierCode': true }) }}
                                                                onChange={(e) => {
                                                                    setFieldValue('carrierCode', e.value);
                                                                    callCarrierService(e.value);
                                                                }}
                                                                isMulti={false}
                                                                name={'carrierCode'}
                                                                component={DrSelectBox}
                                                                placeholder="Select Carrier"
                                                                options={shipstationCarriers?.map(x => ({ value: x.code, label: x.name }))} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-group has-validation">
                                                            <label>Carrier Service Selection</label>
                                                            <Field
                                                                onBlur={() => { setTouched({ ...touched.carrierServiceCode, 'carrierServiceCode': true }) }}
                                                                onChange={(e) => {
                                                                    setFieldValue('carrierServiceCode', e.value);
                                                                }}
                                                                isMulti={false}
                                                                name={'carrierServiceCode'}
                                                                component={DrSelectBox}
                                                                placeholder="Select Carrier"
                                                                options={shipstationCarrierServices?.find(x => x.carrierCode === values.carrierCode)?.data?.map(x => ({ value: x.code, label: x.name })) || []} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-8">
                                                        <div className="form-group has-validation">
                                                            <label>{t('pricingFile')}</label>
                                                            {/* <div className="col-auto"> */}
                                                            <Field onBlur={() => { setTouched({ ...touched.excelFile, 'excelFile': true }) }}
                                                                name={'excelFile'}
                                                                component={DrFileUploadInput}
                                                                placeholder={t('selectFile')}
                                                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                maxFiles={1}
                                                                maxFileSizeInBytes={10000000}
                                                                removeFileTypeStringFromContent={true}
                                                                disableInputChange={true}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        e.preventDefault();
                                                                        setFieldValue("excelFile", e.target.files[0]);
                                                                    }
                                                                }
                                                                }
                                                            />

                                                            {/* <input
                                                        type="file"
                                                        name={'excelFile'}
                                                        className="form-control"
                                                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        onBlur={() => { setTouched({ ...touched.excelFile, 'excelFile': true }) }}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                e.preventDefault();
                                                                // const reader = new FileReader();
                                                                // reader.readAsDataURL(e.target.files[0]);
                                                                // reader.onloadend = () => {

                                                                //     setFieldValue("excelFile", reader.result.replace('data:',''));
                                                                //     console.log("filecontent :", reader.result.replace('data:',''))
                                                                // }

                                                                // var file = onFileUploaded(e);
                                                                // console.log(file);
                                                                // let formData = new FormData();
                                                                // formData.append("ExcelFile", e.target.files[0],'fileName')
                                                                setFieldValue("excelFile", e.target.files[0]);
                                                                // console.log(e.target.files[0].attachment)
                                                                // console.log(document.querySelectorAll('input[type="file"]'));

                                                                // const reader = new FileReader();
                                                                // reader.readAsDataURL(e.target.files[0]);
                                                                // reader.onloadend = () => {

                                                                //     console.log({ attachment: reader.result, contentType: e.target.files[0].type, name: e.target.files[0].name });
                                                                //     setFieldValue("excelFile", reader.result);
                                                                // }

                                                            }
                                                        }}
                                                    /> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between my-3">
                                                    <div className="col-auto">
                                                        <a className="btn ripple btn-success" href={templateExcell}
                                                            download="Warehouse_Excel_Template.xlsx">
                                                            <i className="w-6 me-2 tx-16 text-bold fa fa-file-excel-o"></i>
                                                            {t('downloadTemplate')}
                                                        </a>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                let formData = new FormData();
                                                                composeFormData(formData, {
                                                                    fromMarketId: values.fromMarketId,
                                                                    toMarketId: values.toMarketId,
                                                                    excelFile: values.excelFile,
                                                                    carrierCode: values.carrierCode,
                                                                    carrierServiceCode: values.carrierServiceCode,
                                                                });

                                                                dispatch(saveWarehouseExcel(formData));
                                                            }}
                                                        >{t('saveChanges')}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                        }
                                    </Formik>
                                    <div id="warehousePreferencesTable" className="row">
                                        <div className="col-12">
                                            <DrDataTable
                                                data={tableData}
                                                columns={columns}
                                                columnDefs={columnDefs}
                                                doesHaveBorder={false}
                                                headerSearch={false}
                                                search={true}
                                                minWidth="850px"
                                                pageLength={20}
                                                isRowClickEnabled={true}
                                                rowClickedCallBack={rowClickedCallBack}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WarehousePreferencesPage