import Select, { components } from 'react-select'
import './dr-select-box.component.scss'



function DrSelectBox({
    options,
    field,
    form,
    isMulti = false,
    maxMultiSelectionCount,
    onChange,
    disableInputChange = false,
    setOptionAsArray,
    disabled,
    className,
    selectedValue,
    ...rest
}) {

    const getValue = (fieldValue) => {
        if (isMulti) {
            return fieldValue ? fieldValue : []
        }
        else if (selectedValue && selectedValue !== '') {
            return options?.find(option => option.value === selectedValue)
        }
        else if (fieldValue !== '') {
            return options?.find(option => option.value === fieldValue)
        }


        return '';
    }

    const inputChange = (changedOption) => {
        // for multi select

        if (Array.isArray(changedOption)) {
            if (maxMultiSelectionCount) {
                if (changedOption.length > maxMultiSelectionCount) {
                    changedOption.shift();
                }
            }
            form.setFieldValue(field.name, changedOption)
        }
        else if (changedOption) {
            var option = options.find(opt => opt.value === changedOption.value);
            form.setFieldValue(field.name, setOptionAsArray ? [].concat(option) : option.value)
        }
        else {
            form.setFieldValue(field.name, changedOption)
        }
    }

    // const customStyles = {
    //     dropdownIndicator: base => ({
    //         ...base,
    //         color: "red", // Custom colour
    //         padding: "4px"
    //     })
    // };

    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            {/* <img
                src={props.data.icon}
                style={{ width: 36 }}
                alt={props.data.label}
            /> */}
            {props.data.icon}
            {props.data.label}
        </Option>
    );

    const { MultiValue } = components;

    const MultiValueOption = (props) => {
        return (
            <MultiValue {...props}>
                {props.data.icon}
                {props.data.label}
            </MultiValue>
        );
    };

    return (
        <>
            <Select options={options}
                components={{
                    Option: IconOption,
                    MultiValue: isMulti ? MultiValueOption : null,
                }}
                name={field.name}
                value={getValue(field.value)}
                onChange={(e) => {
                    if (onChange)
                        onChange(e);
                    if (!disableInputChange)
                        inputChange(e);
                }}
                onBlur={field.onBlur}
                isMulti={isMulti}
                isDisabled={disabled}
                className={className}
                {...rest}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: (state.isFocused || state.isSelected) ? 'var(--primary-color)' : 'var(--default-border)',
                        minHeight: "20px"
                    }),
                    dropdownIndicator: base => ({
                        ...base,
                        color: 'var(--primary-color)', // Custom colour
                        padding: "4px",

                    }),

                    // menuPortal: base => ({ ...base, zIndex: 999 }),
                    menu: provided => ({ ...provided, zIndex: 3 })
                }}
                // menuPortalTarget={document.body} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: "2px",
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--gray-2)',
                        primary: 'var(--primary-color)',
                    },
                })}
            />
        </>
    )
}
export default DrSelectBox