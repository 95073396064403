import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import { DrSideBarElements, DrNavigateLink } from "../../component-index";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

function DrSideBar() {
    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN,
        REACT_APP_ROLES_STORE, REACT_APP_ROLES_GUEST,
        REACT_APP_ROLES_USER, REACT_APP_ROLES_ORDERS,
        REACT_APP_ROLES_INVENTORY, REACT_APP_ROLES_REPORT, REACT_APP_ROLES_LOGISTICS } = process.env;

    const location = useLocation();
    const { t } = useTranslation('drSidebar');
    const isLoggedin = useSelector(state => state.customer?.isLoggedin);
    const customerInfo = useSelector(state => state.customer.customerInfo);

    const sideBarData = [
        {
            name: t('ordermanagement'),
            navigateTo: "none",
            icon: <i className="fe fe-shopping-bag side-menu__icon"></i>,
            haveChildren: true,
            allowedTo: [
                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                REACT_APP_ROLES_ADMIN,//Admin
                REACT_APP_ROLES_SUSPENDED_ADMIN,
                REACT_APP_ROLES_ORDERS//Orders
            ],
            childElements: [
                {
                    name: t('orders'),
                    navigateTo: "/orders",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_ORDERS//Orders
                    ],
                },
                {
                    name: t('warehouseLostPackages'),
                    navigateTo: "/warehouse-lost-packages",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_ORDERS//Orders
                    ],
                }
            ]
        },
        // {
        //     name: t('orders'),
        //     navigateTo: "/orders",
        //     icon: <i className="fe fe-shopping-bag side-menu__icon"></i>,
        //     haveChildren: false,
        //     allowedTo: [
        //         REACT_APP_ROLES_SUPERADMIN,//Süper Admin
        //         REACT_APP_ROLES_ADMIN,//Admin
        //REACT_APP_ROLES_SUSPENDED_ADMIN,
        //         REACT_APP_ROLES_ORDERS//Orders
        //     ]
        // },
        {
            name: t('inventorymanagement'),
            navigateTo: "none",
            icon: <i className="fe fe-inbox side-menu__icon"></i>,
            haveChildren: true,
            allowedTo: [
                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                REACT_APP_ROLES_ADMIN,//Admin
                REACT_APP_ROLES_SUSPENDED_ADMIN,
                REACT_APP_ROLES_INVENTORY //Inventory
            ],
            childElements: [
                {
                    name: t('inventory'),
                    navigateTo: "/inventory",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ],
                },
                {
                    name: t('addnewproduct'),
                    navigateTo: "/addproduct",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                {
                    name: t('blockedProducts'),
                    navigateTo: "/blockedproducts",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                {
                    name: t('nonPrizedProducts'),
                    navigateTo: "/non-prized-products",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                {
                    name: t('productcompare'),
                    navigateTo: "/product-compare",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                {
                    name: t('productvariations'),
                    navigateTo: "/product-variants",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                {
                    name: t('bestsellingproducts'),
                    navigateTo: "/best-selling",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                // {
                //     name: t('duplicatelistings'),
                //     navigateTo: "/duplicate",
                //     // icon: FaObjectUngroup,
                //     haveChildren: false,
                //     allowedTo: [
                //         REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //         REACT_APP_ROLES_ADMIN,//Admin
                // REACT_APP_ROLES_SUSPENDED_ADMIN,
                //         REACT_APP_ROLES_INVENTORY //Inventory
                //     ]
                // },
                {
                    name: t('updatehistory'),
                    navigateTo: "/update-list",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                {
                    name: t('categories'),
                    navigateTo: "/categories",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                // {
                //     name: t('categorylists'),
                //     navigateTo: "none",
                //     // icon: FaCar,
                //     haveChildren: true,
                //     allowedTo: [
                //         REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //         REACT_APP_ROLES_ADMIN,//Admin
                //REACT_APP_ROLES_SUSPENDED_ADMIN,
                //         REACT_APP_ROLES_INVENTORY //Inventory
                //     ],
                //     childElements: [
                //         {
                //             name: t('categories'),
                //             navigateTo: "/categories",
                //             // icon: FaCar,
                //             haveChildren: false,
                //             allowedTo: [
                //                 REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //                 REACT_APP_ROLES_ADMIN,//Admin
                //REACT_APP_ROLES_SUSPENDED_ADMIN,
                //                 REACT_APP_ROLES_INVENTORY //Inventory
                //             ],
                //         },
                //         {
                //             name: t('blockedcategories'),
                //             navigateTo: "/blacklist",
                //             // icon: FaObjectUngroup,
                //             haveChildren: false,
                //             allowedTo: [
                //                 REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //                 REACT_APP_ROLES_ADMIN,//Admin
                //REACT_APP_ROLES_SUSPENDED_ADMIN,
                //                 REACT_APP_ROLES_INVENTORY //Inventory
                //             ]
                //         }
                //     ]
                // },
                {
                    name: "Marka",
                    navigateTo: "none",
                    // icon: FaObjectUngroup,
                    haveChildren: true,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ],
                    childElements: [
                        {
                            name: t('brandlists'),
                            navigateTo: "/brandlist",
                            // icon: FaObjectUngroup,
                            haveChildren: false,
                            allowedTo: [
                                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                                REACT_APP_ROLES_ADMIN,//Admin
                                REACT_APP_ROLES_SUSPENDED_ADMIN,
                                REACT_APP_ROLES_INVENTORY //Inventory
                            ]
                        },
                        {
                            name: t('copyrightBrandsList'),
                            navigateTo: "/copyright-brands",
                            // icon: FaObjectUngroup,
                            haveChildren: false,
                            allowedTo: [
                                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                                REACT_APP_ROLES_ADMIN,//Admin
                                REACT_APP_ROLES_SUSPENDED_ADMIN,
                                REACT_APP_ROLES_INVENTORY //Inventory
                            ]
                        },
                    ]
                },
                {
                    name: t('commonPools'),
                    navigateTo: "none",
                    // icon: FaObjectUngroup,
                    haveChildren: true,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ],
                    childElements: [
                        {
                            name: t('commonProductPool'),
                            navigateTo: "/common-product-pool",
                            // icon: FaObjectUngroup,
                            haveChildren: false,
                            allowedTo: [
                                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                                REACT_APP_ROLES_ADMIN,//Admin
                                REACT_APP_ROLES_SUSPENDED_ADMIN,
                                REACT_APP_ROLES_INVENTORY //Inventory
                            ]
                        },
                        {
                            name: t('commonBrandPool'),
                            navigateTo: "/common-brand-pool",
                            // icon: FaObjectUngroup,
                            haveChildren: false,
                            allowedTo: [
                                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                                REACT_APP_ROLES_ADMIN,//Admin
                                REACT_APP_ROLES_SUSPENDED_ADMIN,
                                REACT_APP_ROLES_INVENTORY //Inventory
                            ]
                        },
                    ]
                },
                {
                    name: t('blockedWords'),
                    navigateTo: "/blockedwords",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_INVENTORY //Inventory
                    ]
                },
                // {
                //     name: t('brandlists'),
                //     navigateTo: "none",
                //     // icon: FaObjectUngroup,
                //     haveChildren: true,
                //     allowedTo: [
                //         REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //         REACT_APP_ROLES_ADMIN,//Admin
                //REACT_APP_ROLES_SUSPENDED_ADMIN,
                //         REACT_APP_ROLES_INVENTORY //Inventory
                //     ],
                //     childElements: [
                //         {
                //             name: t('copyrightinquiry'),
                //             navigateTo: "/copyrightinquiry",
                //             // icon: FaCar,
                //             haveChildren: false,
                //             allowedTo: [
                //                 REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //                 REACT_APP_ROLES_ADMIN,//Admin
                //REACT_APP_ROLES_SUSPENDED_ADMIN,
                //                 REACT_APP_ROLES_INVENTORY //Inventory
                //             ],
                //         },
                //         {
                //             name: t('brandlists'),
                //             navigateTo: "/brandlist",
                //             // icon: FaObjectUngroup,
                //             haveChildren: false,
                //             allowedTo: [
                //                 REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //                 REACT_APP_ROLES_ADMIN,//Admin
                //REACT_APP_ROLES_SUSPENDED_ADMIN,
                //                 REACT_APP_ROLES_INVENTORY //Inventory
                //             ]
                //         },
                //         {
                //             name: t('blockedbrands'),
                //             navigateTo: "/brandblackwhitelist",
                //             // icon: FaObjectUngroup,
                //             haveChildren: false,
                //             allowedTo: [
                //                 REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //                 REACT_APP_ROLES_ADMIN,//Admin
                //REACT_APP_ROLES_SUSPENDED_ADMIN,
                //                 REACT_APP_ROLES_INVENTORY //Inventory
                //             ]
                //         },
                //         {
                //             name: t('safebrands'),
                //             navigateTo: "/safebrands",
                //             // icon: FaObjectUngroup,
                //             haveChildren: false,
                //             allowedTo: [
                //                 REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                //                 REACT_APP_ROLES_ADMIN,//Admin
                //REACT_APP_ROLES_SUSPENDED_ADMIN,
                //                 REACT_APP_ROLES_INVENTORY //Inventory
                //             ]
                //         }
                //     ]
                // }
            ]
        },
        {
            name: t('logisticsmanagement'),
            navigateTo: "none",
            icon: <i className="fe fe-truck side-menu__icon"></i>,
            haveChildren: true,
            allowedTo: [
                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                REACT_APP_ROLES_ADMIN,//Admin
                REACT_APP_ROLES_SUSPENDED_ADMIN,
                REACT_APP_ROLES_LOGISTICS //Logistics
            ],
            childElements: [
                {
                    name: t('general'),
                    navigateTo: "/general",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_LOGISTICS //Logistics
                    ],
                },
                {
                    name: t('shippings'),
                    navigateTo: "/shippings",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                },
                {
                    name: t('profile'),
                    navigateTo: "/profile",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_LOGISTICS //Logistics
                    ]
                },

                {
                    name: t('credit'),
                    navigateTo: "/credit",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_LOGISTICS //Logistics
                    ]
                },
                {
                    name: t('invoices'),
                    navigateTo: "/invoices",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_LOGISTICS //Logistics
                    ]
                }
            ]
        },
        {
            name: t('reportsandanalysis'),
            navigateTo: "none",
            icon: <i className="fe fe-file side-menu__icon"></i>,
            haveChildren: true,
            allowedTo: [
                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                REACT_APP_ROLES_ADMIN,//Admin
                REACT_APP_ROLES_SUSPENDED_ADMIN,
                REACT_APP_ROLES_REPORT //Report
            ],
            childElements: [
                {
                    name: "Satış Raporu",
                    navigateTo: "/sales-report",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ],
                },
                {
                    name: "Kar Raporu",
                    navigateTo: "/profit-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },

                {
                    name: "Tüm Mağazalar Raporu",
                    navigateTo: "/allstores-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },
                {
                    name: "Sorunlu Ürünler Raporu",
                    navigateTo: "/problematicproduct-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },
                {
                    name: "Fiyatlandırma Hatası Raporu",
                    navigateTo: "/pricing-errors-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },
                {
                    name: "Hesap Sağlığı",
                    navigateTo: "/health-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },
                {
                    name: "Buybox Dağılımı",
                    navigateTo: "/buybox-discrimination-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },
                {
                    name: "Business Raporu",
                    navigateTo: "/business-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },
                {
                    name: "Gelen Ödemeler",
                    navigateTo: "/income-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },
                {
                    name: "Popüler Zamanlar",
                    navigateTo: "/popularity-times-report",
                    // icon: FaObjectUngroup,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ]
                },
                {
                    name: "Otomatik Tarama",
                    navigateTo: "none",
                    // icon: FaObjectUngroup,
                    haveChildren: true,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                        REACT_APP_ROLES_ADMIN,//Admin
                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                        REACT_APP_ROLES_REPORT //Report
                    ],
                    childElements: [
                        {
                            name: "Arama geçmişi",
                            navigateTo: "/search-history-report",
                            // icon: FaCar,
                            haveChildren: false,
                            allowedTo: [
                                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                                REACT_APP_ROLES_ADMIN,//Admin
                                REACT_APP_ROLES_SUSPENDED_ADMIN,
                                REACT_APP_ROLES_REPORT //Report
                            ],
                        },
                        {
                            name: "Arama sonuçları",
                            navigateTo: "/search-results-report",
                            // icon: FaObjectUngroup,
                            haveChildren: false,
                            allowedTo: [
                                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                                REACT_APP_ROLES_ADMIN,//Admin
                                REACT_APP_ROLES_SUSPENDED_ADMIN,
                                REACT_APP_ROLES_REPORT //Report
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: t('warehouseManagement'),
            navigateTo: "none",
            icon: <i className="fe fe-package side-menu__icon"></i>,
            haveChildren: true,
            allowedTo: [
                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            ],
            childElements: [
                {
                    name: t('warehouseOrders'),
                    navigateTo: "/warehouse-orders",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                },
                {
                    name: t('warehousePreferences'),
                    navigateTo: "/warehouse-preferences",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                },
                {
                    name: t('warehouseLostPackages'),
                    navigateTo: "/warehouse-lost-packages",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                }
            ]
        },

        {
            name: t('warehouseOrders'),
            navigateTo: "/warehouse-orders",
            icon: <i className="fe fe-package side-menu__icon"></i>,
            haveChildren: false,
            allowedTo: [
                REACT_APP_ROLES_STORE,//Store
            ]
        },
        {
            name: t('warehousePreferences'),
            navigateTo: "/warehouse-preferences",
            icon: <i className="fe fe-settings side-menu__icon"></i>,
            haveChildren: false,
            allowedTo: [
                REACT_APP_ROLES_STORE,//Store
            ]
        },
        {
            name: t('warehouseLostPackages'),
            navigateTo: "/warehouse-lost-packages",
            icon: <i className="fe fe-help-circle side-menu__icon"></i>,
            haveChildren: false,
            allowedTo: [
                REACT_APP_ROLES_STORE,//Store
            ]
        },

        {
            name: t('administrator'),
            navigateTo: "none",
            icon: <i className="fe fe-cpu side-menu__icon"></i>,
            haveChildren: true,
            allowedTo: [
                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            ],
            childElements: [
                {
                    name: t('searchTasks'),
                    navigateTo: "/search-tasks",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                },
                {
                    name: t('serviceConfigs'),
                    navigateTo: "/service-config",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                },
                {
                    name: t('landingList'),
                    navigateTo: "/landing-list",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                },
                {
                    name: t('createPackages'),
                    navigateTo: "/create-packages",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                },
                {
                    name: t('createCoupons'),
                    navigateTo: "/create-coupons",
                    // icon: FaCar,
                    haveChildren: false,
                    allowedTo: [
                        REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                    ],
                }
            ]
        }
        // {
        //     name: "Main Seller",
        //     navigateTo: "none",
        //     //icon: FaCar,
        //     haveChildren: true,
        //     allowedTo: ["Super Admin", "Admin", "Main Seller", "Seller"],
        //     childElements: [
        //         {
        //             name: "Sub Sellers",
        //             navigateTo: "/sub-sellers",
        //             // icon: FaCar,
        //             haveChildren: false,
        //             allowedTo: ["Super Admin", "Admin", "Main Seller", "Seller"],
        //         }

        //     ]
        // }
    ];

    var logoNavigateKey = classNames({
        'HOME': isLoggedin && !(customerInfo?.role?.includes(REACT_APP_ROLES_USER) || customerInfo?.role?.includes(REACT_APP_ROLES_GUEST) || customerInfo?.role?.includes(REACT_APP_ROLES_STORE)),
        'LOGIN': !isLoggedin,
        'MARKET_SELECTION': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_USER), // user
        'MAIN_PACKAGES': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_GUEST), //guest
        'WAREHOUSE_HOME': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_STORE) // store
    });

    return (
        <>
            <aside className="app-sidebar sticky" id="sidebar">
                <div className="main-sidebar-header">
                    <DrNavigateLink className="header-logo" navigateKey={logoNavigateKey}>
                        <ReactSVG className="desktop-logo" src="../assets/images/brand-logos/logo.svg" />
                        <ReactSVG className="desktop-dark" src="../assets/images/brand-logos/logo.svg" />

                        <img src="../assets/images/brand-logos/toggle-logo.png" alt="logo" className="toggle-logo" />
                        <img src="../assets/images/brand-logos/toggle-dark.png" alt="logo" className="toggle-dark" />
                        <img src="../assets/images/brand-logos/desktop-white.png" alt="logo" className="desktop-white" />
                        <img src="../assets/images/brand-logos/toggle-white.png" alt="logo" className="toggle-white" />


                    </DrNavigateLink>
                </div>
                <div className="main-sidebar" id="sidebar-scroll">
                    <DrSideBarElements elements={sideBarData} activePath={location.pathname}></DrSideBarElements>
                </div>
            </aside>
        </>
    )
}

export default DrSideBar
