import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { withActionPromise } from "../../state-management/actions/app.actions";
import { clearCreateStoreInformationState } from "../../state-management/actions/create-store.actions";
import { getRouteByKey } from "../../routes";
import { toastr } from "react-redux-toastr";

function CustomerPackagesPage() {
  const { t } = useTranslation("customerPackages");
  const [customerPackages, setCustomerPackages] = useState([]);
  const selectedStore = useSelector((state) => state.stores.selectedStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCustomerPackages = () => {
      try {
        const persistAuth = localStorage.getItem("persist:auth");
        if (!persistAuth) {
          return;
        }

        const authData = JSON.parse(persistAuth);
        const customerData = JSON.parse(authData.customer);

        if (customerData?.customerPackageInfo?.customerPackages) {
          setCustomerPackages(
            customerData.customerPackageInfo.customerPackages
          );
        }
      } catch (error) {
        toastr.error(error.message);
      }
    };

    fetchCustomerPackages();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <>
      {selectedStore?.customerMainSellerId.length > 0 &&
        customerPackages.length !== 0 && (
          <div className="d-flex justify-content-end justify-content-sm-center justify-content-md-end mb-3">
            <button
              className="btn btn-success"
              onClick={() => navigate(getRouteByKey("MAIN_PACKAGES"))}
            >
              {t("addPackage")}
            </button>
          </div>
        )}
      <div className="content clearfix">
        {selectedStore?.customerMainSellerId.length > 0 ? (
          <div className="row">
            {customerPackages.map((pkg, index) => {
              let cardTopClass = classNames({
                "card custom-card": true,
                "ribbone-card": true,
                "overflow-hidden": false,
              });

              let discountedPrice =
                pkg.discount && pkg.discount > 0
                  ? Number(
                      pkg.price - (pkg.price * pkg.discount) / 100
                    ).toFixed(0)
                  : null;

              return (
                <div
                  key={`_customer_packages_${index}`}
                  className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex"
                >
                  <div className={cardTopClass}>
                    {pkg.discount > 0 && (
                      <div className="arrow-ribbone-right bg-teal">
                        Save {pkg.discount}%
                      </div>
                    )}
                    <div className="card-body p-0">
                      <div
                        className={`px-1 py-2 op-3 ${
                          pkg.price === 0 ? "bg-success" : "bg-info"
                        }`}
                      />
                      <div className="p-4 h-100 d-flex flex-column">
                        <div className="d-flex justify-content-center align-items-center mb-3">
                          <div
                            className="fs-16 fw-semibold"
                            style={{ minHeight: "55px" }}
                          >
                            {pkg.planName}
                          </div>
                        </div>
                        <div className="fs-24 fw-bold mb-1">
                          <span>
                            {discountedPrice
                              ? "$" + discountedPrice
                              : "$" + Number(pkg.price).toFixed(0)}
                            {discountedPrice && (
                              <span className="fs-14 text-muted text-decoration-line-through ms-1 d-inline-block op-6">
                                {"$" + Number(pkg.price).toFixed(2)}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="mb-1 text-muted">{pkg.description}</div>
                        <div className="fs-12 mb-3">
                          <u>{t("billedWhenDepleted")}</u>
                        </div>
                        <div className="flex-grow-shrink row">
                          <div className="col mt-auto">
                            <ul className="list-unstyled mb-0">
                              <li className="d-flex align-items-center mb-2">
                                <span className="me-2">
                                  <i
                                    className={`ri-checkbox-circle-line fs-15 ${
                                      pkg.productCount > 0
                                        ? "text-success"
                                        : " text-muted op-3"
                                    }`}
                                  />
                                </span>
                                <span>
                                  <strong className="me-1">
                                    {pkg.productCount} {t("product")}
                                  </strong>{" "}
                                  {t("count")}
                                </span>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="me-2">
                                  <i
                                    className={`ri-checkbox-circle-line fs-15 ${
                                      pkg.scanningCount > 0
                                        ? "text-success"
                                        : " text-muted op-3"
                                    }`}
                                  />
                                </span>
                                <span>
                                  <strong className="me-1">
                                    {pkg.scanningCount} {t("scanning")}
                                  </strong>{" "}
                                  {t("count")}
                                </span>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="me-2">
                                  <i
                                    className={`ri-checkbox-circle-line fs-15 ${
                                      pkg.sellerCount > 0
                                        ? "text-success"
                                        : " text-muted op-3"
                                    }`}
                                  />
                                </span>
                                <span>
                                  <strong className="me-1">
                                    {pkg.sellerCount} {t("seller")}
                                  </strong>{" "}
                                  {t("count")}
                                </span>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="me-2">
                                  <i
                                    className={`ri-checkbox-circle-line fs-15 ${
                                      pkg.userCount > 0
                                        ? "text-success"
                                        : " text-muted op-3"
                                    }`}
                                  />
                                </span>
                                <span>
                                  <strong className="me-1">
                                    {pkg.userCount} {t("user")}
                                  </strong>{" "}
                                  {t("count")}
                                </span>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="me-2">
                                  <i
                                    className={`ri-checkbox-circle-line fs-15 ${
                                      pkg.isFBA
                                        ? "text-success"
                                        : " text-muted op-3"
                                    }`}
                                  />
                                </span>
                                <span
                                  className={`${pkg.isFBA ? "fw-bold" : ""}`}
                                >
                                  {t("fbaOption")}
                                </span>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="me-2">
                                  <i
                                    className={`ri-checkbox-circle-line fs-15 ${
                                      pkg.isWarehouse
                                        ? "text-success"
                                        : " text-muted op-3"
                                    }`}
                                  />
                                </span>
                                <span
                                  className={`${
                                    pkg.isWarehouse ? "fw-bold" : ""
                                  }`}
                                >
                                  {t("warehouseOption")}
                                </span>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="me-2">
                                  <i
                                    className={`ri-checkbox-circle-line fs-15 ${
                                      pkg.isDropAmazon2Amazon
                                        ? "text-success"
                                        : " text-muted op-3"
                                    }`}
                                  />
                                </span>
                                <span
                                  className={`${
                                    pkg.isDropAmazon2Amazon ? "fw-bold" : ""
                                  }`}
                                >
                                  {t("dropAmazonToAmazon")}
                                </span>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="me-2">
                                  <i
                                    className={`ri-checkbox-circle-line fs-15 ${
                                      pkg.isSmartPricer
                                        ? "text-success"
                                        : " text-muted op-3"
                                    }`}
                                  />
                                </span>
                                <span
                                  className={`${
                                    pkg.isSmartPricer ? "fw-bold" : ""
                                  }`}
                                >
                                  {t("smartPricer")}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer p-3 border-top">
                      <div className="row justify-content-center text-center">
                        <div className="col-auto">
                          <div className="text-muted fs-12 mb-1">
                            {t("startDate")}
                          </div>
                          <div className="fw-semibold">
                            {formatDate(pkg.startDate)}
                          </div>
                        </div>
                        <div className="col-auto ms-4">
                          <div className="text-muted fs-12 mb-1">
                            {t("endDate")}
                          </div>
                          <div className="fw-semibold">
                            {formatDate(pkg.endDate)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="row row-md row-lg justify-content-center">
            <div className="col-lg-auto col-md-2">
              <button
                className="btn btn-info btn-wave waves-effect waves-light shadow"
                onClick={(e) => {
                  e.preventDefault();
                  Promise.all([
                    dispatch(
                      withActionPromise(clearCreateStoreInformationState())
                    ),
                  ]).then(() => {
                    navigate(getRouteByKey("MARKET_SELECTION"));
                  });
                }}
              >
                <i className="me-2 fe fe-chevrons-right"></i>
                {t("gotoMarketSelection")}
              </button>
            </div>
          </div>
        )}
        {selectedStore?.customerMainSellerId.length > 0 &&
          customerPackages.length === 0 && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <div className="row">
                <div className="col-12 text-center mb-3">
                  <p className="fs-2 fw-bold">{t("noPackages")}</p>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-success"
                    onClick={() => navigate(getRouteByKey("MAIN_PACKAGES"))}
                  >
                    {t("addPackage")}
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
}

export default CustomerPackagesPage;
