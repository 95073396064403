import { React, useEffect, useRef } from 'react'
import { backgroundVariants, borderVariants, timelineVariants } from '../../../utils/constants'
import classNames from 'classnames';
import moment from 'moment';
import "./dr-timeline.component.scss";

function DrTimeLine({ timelineSource, className, variant = timelineVariants.center,
    group0Variant = backgroundVariants.bgInfoTransparent,
    group1Variant = backgroundVariants.bgWarningTransparent,
    group2Variant = backgroundVariants.bgSuccessTransparent,
    group3Variant = backgroundVariants.bgDangerTransparent,
    group4Variant = backgroundVariants.bgSecondaryTransparent,

    group0IconVariant = borderVariants.borderInfo,
    group1IconVariant = backgroundVariants.borderWarning,
    group2IconVariant = backgroundVariants.borderSuccess,
    group3IconVariant = backgroundVariants.borderDanger,
    group4IconVariant = backgroundVariants.borderSecondary

}) {


    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timelineSource]);


    var timelineClass = classNames({
        'timeline': variant === timelineVariants.standard,
        'timeline-center': variant === timelineVariants.center,
        "list-unstyled": true
    }, className);

    var timelineObjectClass = classNames({
        '': variant === timelineVariants.standard,
        'timeline-center-widget': variant === timelineVariants.center
    });

    var timelineIconDivClass = classNames({
        'timeline-icon': variant === timelineVariants.standard,
        'timeline-center-icon': variant === timelineVariants.center
    });

    var timelineTimeClass = classNames({
        'text-end timeline-time': variant === timelineVariants.standard,
        'timeline-center-time': variant === timelineVariants.center
    });

    var timelineBodyClass = classNames({
        'timeline-body': variant === timelineVariants.standard,
        'timeline-center-body': variant === timelineVariants.center
    });

    return (
        <>
            <ul className={timelineClass}>
                {timelineSource?.length > 0 &&
                    timelineSource.map((timelineObject, index) => {
                        var colorName = (timelineObject.groupName === "0" ? group0Variant : "")
                            || (timelineObject.groupName === "1" ? group1Variant : "")
                            || (timelineObject.groupName === "2" ? group2Variant : "")
                            || (timelineObject.groupName === "3" ? group3Variant : "")
                            || (timelineObject.groupName === "4" ? group4Variant : "")
                            || backgroundVariants.bgSecondaryTransparent;

                        var iconColorName = (timelineObject.groupName === "0" ? (group0Variant + " " + group0IconVariant) : "")
                            || (timelineObject.groupName === "1" ? (group1Variant + " " + group1IconVariant) : "")
                            || (timelineObject.groupName === "2" ? (group2Variant + " " + group2IconVariant) : "")
                            || (timelineObject.groupName === "3" ? (group3Variant + " " + group3IconVariant) : "")
                            || (timelineObject.groupName === "4" ? (group4Variant + " " + group4IconVariant) : "")
                            || (backgroundVariants.bgSecondaryTransparent + " " + borderVariants.borderSecondary);

                        return (

                            <li key={"timelineObject_" + index} className={`${variant === timelineVariants.center && index % 2 === 1 ? "timeline-center-widget" : ""}`} >
                                <div className={`${timelineTimeClass}${variant === timelineVariants.center && index % 2 === 1 ? "-right" : ""}`}
                                    style={{ insetBlockStart: '1rem' }} >
                                    {timelineObject.time && moment(timelineObject.time).isValid() &&
                                        <span className="time d-inline-block">
                                            {/* {moment(timelineObject.time).format('LLLL')} */}

                                            {moment().startOf("day").diff(moment(timelineObject.time).startOf("day"), 'days') === 0 && "Today"}
                                            {moment().startOf("day").diff(moment(timelineObject.time).startOf("day"), 'days') === 1 && "Yesterday"}
                                            {moment().startOf("day").diff(moment(timelineObject.time).startOf("day"), 'days') > 1 && moment(timelineObject.time).format('LLLL')}
                                        </span>
                                    }
                                    {((timelineObject.time && !moment(timelineObject.time).isValid()) || !timelineObject.time) &&
                                        <span className="time d-inline-block">
                                            invalidDate
                                        </span>
                                    }

                                </div>
                                <div className={`${timelineIconDivClass}${timelineObject.iconCss?.length > 0 ? "-right" : ""}`} style={{ insetBlockStart: '1rem' }} >
                                    {/* <a href="javascript:void(0);" /> */}
                                    {!timelineObject.icon && <i className={`${timelineObject.iconCss} ${iconColorName}`} ></i>}
                                    {timelineObject.icon && timelineObject.icon}
                                </div>
                                <div className={`${timelineBodyClass}${variant === timelineVariants.center && index % 2 === 1 ? "-right" : ""}  ${colorName}`}
                                    style={{ marginInlineStart: 'auto !important', marginInlineEnd: 'auto' }}>

                                    <div className="row">
                                        <div className="col-auto">
                                            <p className="mb-0 fs-14 fw-semibold">{timelineObject.title}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <p className={`${timelineObject.text?.length > 0 ? "mb-1 " : "mb-4"} text-muted`}>
                                                {timelineObject.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </li >

                        )
                    })
                }
            </ul >
        </>
    )
}

export default DrTimeLine
