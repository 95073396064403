import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DrBreadCrump, DrInput, DrTagifyInput } from '../../components/component-index';
import { setActiveModal, withActionPromise } from '../../state-management/actions/app.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { useNavigate, useParams } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { modals, stringAsinsSplitRegex } from '../../utils/constants';
import { setInventoryPageSelections } from '../../state-management/actions/settings.actions';
import { CONTROLLERS, apiPost } from '../../utils/axiosApiCalls';
import Restricted from '../../security/Restricted';

function AddNewProduct() {
    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN } = process.env;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { asins } = useParams();
    const { t } = useTranslation('addNewProductPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const myStore = useSelector(state => state.stores.myStore);
    const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);
    const inventorySummary = useSelector(state => state.inventory.inventorySummary);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.inventoryPageSelections);
    const [selectedAsins, setSelectedAsins] = useState([]);

    useEffect(() => {
        if (asins && asins.length > 0) {
            setSelectedAsins(asins.split(stringAsinsSplitRegex));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = {
        asins: selectedAsins || "",
        sku: ""
    };

    const handleSubmit = async (values) => {

        var newProduts = {
            customerMainSellerId: selectedStore?.customerMainSellerId,
            //asins: values.asins.split(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ ]/).filter((str) => str !== ''),
            asins: values.asins,
            sku: values.sku || "SD"
        }

        // dispatch(createNewProducts(newProduts));

        await apiPost(``, newProduts, false, CONTROLLERS.PRODUCT, true, true)().then((response) => {
            if (response && response.isSuccess && response.resultType === 1) {
                dispatch(withActionPromise(setInventoryPageSelections({ inventoryStatus: 0, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, detailedSearchShownAccordions: pageSelections?.detailedSearchShownAccordions, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
                dispatch(setActiveModal({
                    name: modals.description,
                    isGlobal: true,
                    title: i18next.t('drSweetAlertButton:infoTitle'),
                    header: i18next.t('drSweetAlertButton:infoTitle'),
                    text: i18next.t('drSweetAlertButton:infoTexts.info5'),
                    navigationPath: "/inventory",
                    navigationText: t('gotoInventory')
                }));
            }
        }).catch((err) => {

        });
    }

    const newProductsSchema = Yup.object().shape({
        // sku: Yup.string()
        //     .min(3, 'Too Short!')
        //     .max(50, 'Too Long!')
        //     .required('SKU is required'),
        // asins: Yup.string()
        //     .min(8, 'Too Short!')
        //     .max(800, 'Too Long!')
        //     .required('Last name is required'),
        asins: Yup.array()
            // .of(Yup.string()
            //     .min(10, "Asins needs to be ten characters")
            //     .max(10, "Asins needs to be ten characters")
            //     .matches(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/, "Asins characters may only contains numbers and letters")
            //     .required("At least one asin is required")
            // )
            .test({
                message: 'At least one asin is required',
                test: arr => arr.length !== 0,
            })
    });

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
                { navigationText: i18next.t('constants:breadCrumps.addNewProduct'), navigationPath: "" }]} >
            </DrBreadCrump>
            <Formik
                initialValues={initialValues}
                validateOnBlur={true}
                onSubmit={handleSubmit}
                validationSchema={newProductsSchema}
                enableReinitialize={true}
                validateOnMount={true}
            >
                {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    setTouched,
                    setFieldValue
                }) => (
                    <>
                        <div className="page-header">
                            <div>

                            </div>
                        </div>
                        <div className="row row-md row-lg d-flex justify-content-center">
                            <div className="col-md-8 col-lg-12">
                                <form>
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            {selectedStore && myStore.sourceMarketList?.length > 0 &&
                                                <section>
                                                    {/* <div className="row row-md row-lg justify-content-center">
                                                    <div className="col-lg-2 col-md-3 col-sm-6">
                                                        <label htmlFor="asinSelection" className="rdiobox cursor-pointer">
                                                            <DrInput id="asinSelection" placeholder="" disabled="disabled" name="selection" type="radio"
                                                                value="" checked={true} />
                                                            <span>ASIN</span>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-2 col-md-3 col-sm-6">
                                                        <label htmlFor="urlSelection" className="rdiobox cursor-pointer">
                                                            <DrInput id="urlSelection" disabled="disabled" placeholder="" name="selection" type="radio"
                                                                value="" />
                                                            <span>URL</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <hr className="my-3" /> */}
                                                    <div className="row row-md row-lg justify-content-center mb-2">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <h6>{t('asinListsTitle')}</h6>
                                                                {/* <textarea name="asins" className={`form-control ${(touched.asins && errors.asins) ? " is-invalid" : ""}`} placeholder="Her satıra birer tane giriniz" pattern={"[A-Za-z]{3}"} rows={6} cols={2}
                                                                value={values.asins}
                                                                // onChange={(e) => {
                                                                //     setFieldValue("asins", e.currentTarget.value.split(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ ]/).filter((str) => str !== ''));

                                                                // }}
                                                                onChange={handleChange}
                                                                onBlur={(e) => {
                                                                    setTouched({ ...touched, 'asins': true });
                                                                    setFieldValue("asins", e.currentTarget.value.split(stringAsinsSplitRegex).filter((str) => str !== '').map(function (x) { return x.toUpperCase(); }));
                                                                }}
                                                            /> */}
                                                                <DrTagifyInput value={values.asins || []}
                                                                    settings={{
                                                                        pattern: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{10}$/,
                                                                        delimiters: ",| |:|[\\n\\r]"
                                                                    }}
                                                                    className={`tags-input-control`}
                                                                    isvalid={(touched.asins && !errors.asins)}
                                                                    isinvalid={(touched.asins && errors.asins)}
                                                                    onBlur={(e) => {
                                                                        setTouched({ ...touched, 'asins': true });
                                                                        setFieldValue("asins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}
                                                                    onRemove={(e) => {
                                                                        setTouched({ ...touched, 'asins': true });
                                                                        setFieldValue("asins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}
                                                                    onEditUpdated={(e) => {
                                                                        setTouched({ ...touched, 'asins': true });
                                                                        setFieldValue("asins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}>
                                                                </DrTagifyInput>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row row-md row-lg justify-content-center mb-2">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <h6>SKU Prefix</h6>
                                                                <DrInput value={values.sku} className={`form-control`} placeholder="SKU şöyle görünecektir: AMZR-XXXX-123456" name="sku" type="text"
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'sku': true }) }}
                                                                    isInvalid={(touched.sku && errors.sku)}
                                                                    isValid={touched.sku && !errors.sku}
                                                                    validationText={errors.sku}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row row-md row-lg justify-content-center">
                                                        <div className="col-lg-auto col-md-3 col-sm-3">
                                                            <Restricted allowedTo={[
                                                                REACT_APP_ROLES_SUPERADMIN,
                                                                REACT_APP_ROLES_ADMIN
                                                            ]}>


                                                                <button className={`btn btn-teal btn-wave waves-effect waves-light shadow ${isValid && values.asins.length > 0 && !(inventorySummary?.totalProductCount > 0 && inventorySummary?.totalProductCount >= (customerPackageInfo?.totalProductCount || 0)) ? "" : "disabled"}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (!(inventorySummary?.totalProductCount > 0 && inventorySummary?.totalProductCount >= (customerPackageInfo?.totalProductCount || 0))) {
                                                                            if (isValid && values.asins.length > 0) {
                                                                                handleSubmit(values);
                                                                            }
                                                                        }
                                                                    }}
                                                                >{t('addProducts')}</button>
                                                            </Restricted>
                                                        </div>
                                                    </div>

                                                </section>
                                            }
                                            {selectedStore && myStore.sourceMarketList?.length === 0 && <div className="row row-md row-lg justify-content-center my-3 px-1">

                                                <div className="col-auto">
                                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate(getRouteByKey("MY_STORE"));
                                                        }}>
                                                        <i className="me-2 fe fe-chevrons-right"></i>
                                                        {t('goToSourceMarketSelection')}
                                                    </button>
                                                </div>
                                            </div>}
                                            {!selectedStore && <div className="row row-md row-lg justify-content-center">

                                                <div className="col-lg-auto col-md-2">
                                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            Promise.all([
                                                                dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                            ]).then((e) => {
                                                                navigate(getRouteByKey("MARKET_SELECTION"));
                                                            });;

                                                        }}>
                                                        <i className="me-2 fe fe-chevrons-right"></i>
                                                        {t('gotoMarketSelection')}
                                                    </button>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </>
                )
                }
            </Formik >
        </>
    )
}

export default AddNewProduct;