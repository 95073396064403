import hero from "../../assets/img/hero-img.png";

import serviceOne from "../../assets/img/132.jpg";
import serviceTwo from "../../assets/img/133.jpg";
import serviceThree from "../../assets/img/134.jpg";
import serviceFour from "../../assets/img/135.jpg";
import serviceFive from "../../assets/img/136.jpg";
import serviceSix from "../../assets/img/137.jpg";
import serviceSeven from "../../assets/img/138.jpg";
import serviceEight from "../../assets/img/139.jpg";
import serviceNine from "../../assets/img/140.jpg";
import serviceTen from "../../assets/img/141.jpg";
import serviceEleven from "../../assets/img/142.jpg";
import serviceTwelve from "../../assets/img/143.jpg";

import "./introduction.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "../../state-management/actions/app.actions";
import { modals } from "../../utils/constants";
import i18next from "i18next";
import { getURLParams } from "../../utils/utils";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRouteByKey } from "../../routes";
import { DrNavigateLink } from "../../components/component-index";

function useLocationHash() {
  const [hash, setHash] = useState(window.location.hash);
  window.addEventListener("hashchange", () => setHash(window.location.hash));

  return hash;
}

function IntroductionPage() {
  const navigate = useNavigate();
  const hash = useLocationHash();

  const dispatch = useDispatch();
  const { t } = useTranslation("introductionpage");
  const language = useSelector((state) => state.settings.language);

  const servicesRef = useRef(null);
  const pricingRef = useRef(null);
  const featuresRef = useRef(null);
  const faqRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    if (window.location.hash) {
      if (
        window.location.hash === "#services" ||
        window.location.hash === "#/introduction/#services"
      ) {
        window.scrollTo({
          top: servicesRef.current.offsetTop,
          behavior: "smooth",
        });
      } else if (
        window.location.hash === "#pricing" ||
        window.location.hash === "#/introduction/#pricing"
      ) {
        window.scrollTo({
          top: pricingRef.current.offsetTop,
          behavior: "smooth",
        });
      } else if (
        window.location.hash === "#features" ||
        window.location.hash === "#/introduction/#features"
      ) {
        window.scrollTo({
          top: featuresRef.current.offsetTop,
          behavior: "smooth",
        });
      } else if (
        window.location.hash === "#faq" ||
        window.location.hash === "#/introduction/#faq"
      ) {
        window.scrollTo({
          top: faqRef.current.offsetTop,
          behavior: "smooth",
        });
      } else if (
        window.location.hash === "#contact" ||
        window.location.hash === "#/introduction/#contact"
      ) {
        window.scrollTo({
          top: contactRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <div id="introductiondiv">
      <section id="hero" className="d-flex align-items-center">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1 key={1}> {t("1")}</h1>
              <h2 key={2}>{t("2")}</h2>
              <div>
                {/* <a href="#pricing" className="btn-get-started scrollto" key={3}>
                  <i style={{ marginRight: "0.5rem" }} className="bi bi-tag-fill" />
                  {t('3')}
                </a> */}
                <span
                  // make this button scroll to the pricing section
                  onClick={() => {
                    window.scrollTo({
                      top: pricingRef.current.offsetTop,
                      behavior: "smooth",
                    });
                  }}
                  className="btn-get-started scrollto"
                >
                  <i
                    style={{ marginRight: "0.5rem" }}
                    className="bi bi-tag-fill"
                  />
                  {t("3")}
                </span>
                <span
                  key={4}
                  className="btn-login scrollto"
                  onClick={() => {
                    // dispatch(setActiveModal({ name: modals.login, isGlobal: true, title: i18next.t('drLogin:title') }));
                    navigate(getRouteByKey("LOGIN"));
                  }}
                >
                  <i
                    style={{ marginRight: "0.5rem" }}
                    className="bi bi-box-arrow-in-right"
                  />
                  {t("4")}
                </span>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay={150}
            >
              <img src={hero} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section
          id="features"
          ref={featuresRef}
          className="services section-bg"
        >
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={15}> {t("5")}</h2>
            </div>
            <div className="row gy-6">
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="icon-box card iconbox-blue">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                      ></path>
                    </svg>
                    <i className="bi bi-check-circle" />
                  </div>
                  <h4>
                    <a href="" key={17}>
                      {t("17")}
                    </a>
                  </h4>
                  <p key={18}>{t("18")}</p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={200}
              >
                <div className="icon-box card iconbox-green ">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                      ></path>
                    </svg>
                    <i className="bi bi-stopwatch" />
                  </div>
                  <h4>
                    <a href="" key={19}>
                      {t("19")}
                    </a>
                  </h4>
                  <p key={20}>{t("20")}</p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box card iconbox-orange">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"
                      ></path>
                    </svg>
                    <i className="bx bx-support" />
                  </div>
                  <h4>
                    <a href="" key={21}>
                      {t("21")}
                    </a>
                  </h4>
                  <p key={22}>{t("22")}</p>
                </div>
              </div>

            </div>
          </div>
        </section>


        <section id="services" ref={servicesRef} className="features">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={5}> {t("15")}</h2>
              <p key={6}>{t("6")}</p>
            </div>
            <div className="row">
            <div
                className="image col-lg-6 order-1 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceOne}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("132")}</h4>
                  <p key={8}>{t("133")}</p>
                </div>
              </div>
             
            </div>
            <br />
            <br />
            <div className="row">
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("134")}</h4>
                  <p key={8}>{t("135")}</p>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceTwo}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
            <div
                className="image col-lg-6 order-1 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceThree}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("136")}</h4>
                  <p key={8}>{t("137")}</p>
                </div>
              </div>
              
            </div>
            <br />
            <br />
            <div className="row">
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("138")}</h4>
                  <p key={8}>{t("139")}</p>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceFour}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
            <div
                className="image col-lg-6 order-1 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceFive}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("140")}</h4>
                  <p key={8}>{t("141")}</p>
                </div>
              </div>
              
            </div>
            <br />
            <br />
            <div className="row">
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("142")}</h4>
                  <p key={8}>{t("143")}</p>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceSix}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
            <div
                className="image col-lg-6 order-1 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceSeven}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("144")}</h4>
                  <p key={8}>{t("145")}</p>
                </div>
              </div>
              
            </div>
            <br />
            <br />
            <div className="row">
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("146")}</h4>
                  <p key={8}>{t("147")}</p>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceEight}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
            <div
                className="image col-lg-6 order-1 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceNine}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("148")}</h4>
                  <p key={8}>{t("149")}</p>
                </div>
              </div>
              
            </div>
            <br />
            <br />
            <div className="row">
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("150")}</h4>
                  <p key={8}>{t("151")}</p>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceTen}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
            <div
                className="image col-lg-6 order-1 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceEleven}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("152")}</h4>
                  <p key={8}>{t("153")}</p>
                </div>
              </div>
              
            </div>
            <br />
            <br />
            <div className="row">
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t("154")}</h4>
                  <p key={8}>{t("155")}</p>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img
                  src={serviceTwelve}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
              </div>
            </div>
          </div>

        </section>



        <section id="pricing" ref={pricingRef} className="pricing section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={29}> {t("29")}</h2>
              <p key={30}>{t("30")}</p>
            </div>
            <>
              <div className="pricing">

                <div
                  class="card-body p-0"
                  style={{
                    backgroundColor: "#FFFFFFFF",
                    borderRadius: "6px",
                    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    class="px-1 py-2 bg-success op-3"
                    style={{ borderRadius: "6px 6px 0 0" }}
                  ></div>
                  <div class="p-4">
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#000000",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      Free
                    </div>
                    <div class="fs-25 fw-bold mb-1">
                      $0
                      <sub class="text-muted fw-semibold fs-11 ms-1"></sub>
                    </div>
                    <div class="mb-1 text-muted">
                      Kanada, Meksika, Belçika, UAE, Avustralya, Japonya, Singapur, İngiltere, Almanya, İspanya, Fransa, İtalya, Hollanda , İsviçre, Polonya, Suudi Arabistan, Brezilya, Türkiye Pazaryerleri
                    </div>
                    <div class="fs-12 mb-3">
                      <u>Billed when depleted</u>
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">500 Product</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">100 Scanning</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">1 Seller</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">1 User</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> FBA Option </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> Warehouse Option </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> Drop Amazon to Amazon </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> SmartPricer </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="card-body p-0"
                  style={{
                    backgroundColor: "#FFFFFFFF",
                    borderRadius: "6px",
                    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >

                  <div
                    class="px-1 py-2 bg-info op-3"
                    style={{ borderRadius: "6px 6px 0 0" }}
                  ></div>
                  <div class="p-4">
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#000000",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      Second Plan
                    </div>
                    <div class="fs-25 fw-bold mb-1">
                    $54.90
                      {/* <sub
                        class="text-muted fw-semibold fs-14 ms-1"
                        style={{ textDecoration: "line-through" }}
                      >
                        $54.90
                      </sub> */}
                    </div>
                    <div class="mb-1 text-muted">
                      Kanada, Meksika, Belçika, UAE, Avustralya, Japonya, Singapur, İngiltere, Almanya, İspanya, Fransa, İtalya, Hollanda , İsviçre, Polonya, Suudi Arabistan, Brezilya, Türkiye Pazaryerleri
                    </div>
                    <div class="fs-12 mb-3">
                      <u>Billed when depleted</u>
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">5000 Product</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">200 Scanning</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">2 Seller</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">2 User</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> FBA Option </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> Warehouse Option </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> Drop Amazon to Amazon </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> SmartPricer </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="card-body p-0"
                  style={{
                    backgroundColor: "#FFFFFFFF",
                    borderRadius: "6px",
                    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    class="px-1 py-2 bg-info op-3"
                    style={{ borderRadius: "6px 6px 0 0" }}
                  ></div>
                  <div class="p-4">
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#000000",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      Third Plan
                    </div>
                    <div class="fs-25 fw-bold mb-1">
                      $74.90
                      {/* <sub
                        class="text-muted fw-semibold fs-14 ms-1"
                        style={{ textDecoration: "line-through" }}
                      >
                        $450.00
                      </sub> */}
                    </div>
                    <div class="mb-1 text-muted">
                      Kanada, Meksika, Belçika, UAE, Avustralya, Japonya, Singapur, İngiltere, Almanya, İspanya, Fransa, İtalya, Hollanda , İsviçre, Polonya, Suudi Arabistan, Brezilya, Türkiye Pazaryerleri
                    </div>
                    <div class="fs-12 mb-3">
                      <u>Billed when depleted</u>
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">10000 Product</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">500 Scanning</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">2 Seller</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">3 User</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> FBA Option </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> Warehouse Option </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">
                            {" "}
                            Drop Amazon to Amazon{" "}
                          </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-muted op-3"></i>
                        </span>
                        <span className="mb-1"> SmartPricer </span>
                      </li>
                    </ul>
                  </div>
                </div>


              </div>
              <div className="pricing">

                <div
                  class="card-body p-0"
                  style={{
                    backgroundColor: "#FFFFFFFF",
                    borderRadius: "6px",
                    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    class="px-1 py-2 bg-info op-3"
                    style={{ borderRadius: "6px 6px 0 0" }}
                  ></div>
                  <div class="p-4">
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#000000",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      Fourth Plan
                    </div>
                    <div class="fs-25 fw-bold mb-1">
                      $129.90
                      {/* <sub
                        class="text-muted fw-semibold fs-14 ms-1"
                        style={{ textDecoration: "line-through" }}
                      >
                        $750.00
                      </sub> */}
                    </div>
                    <div class="mb-1 text-muted">
                      Kanada, Meksika, Belçika, UAE, Avustralya, Japonya, Singapur, İngiltere, Almanya, İspanya, Fransa, İtalya, Hollanda , İsviçre, Polonya, Suudi Arabistan, Brezilya, Türkiye Pazaryerleri
                    </div>
                    <div class="fs-12 mb-3">
                      <u>Billed when depleted</u>
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">25000 Product</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">500 Scanning</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">2 Seller</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">3 User</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> FBA Option </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> Warehouse Option </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">
                            {" "}
                            Drop Amazon to Amazon{" "}
                          </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> SmartPricer </strong>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="card-body p-0"
                  style={{
                    backgroundColor: "#FFFFFFFF",
                    borderRadius: "6px",
                    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    class="px-1 py-2 bg-info op-3"
                    style={{ borderRadius: "6px 6px 0 0" }}
                  ></div>
                  <div class="p-4">
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#000000",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      Fifth Plan
                    </div>
                    <div class="fs-25 fw-bold mb-1">
                      $199.90
                      {/* <sub
                        class="text-muted fw-semibold fs-14 ms-1"
                        style={{ textDecoration: "line-through" }}
                      >
                        $750.00
                      </sub> */}
                    </div>
                    <div class="mb-1 text-muted">
                      Kanada, Meksika, Belçika, UAE, Avustralya, Japonya, Singapur, İngiltere, Almanya, İspanya, Fransa, İtalya, Hollanda , İsviçre, Polonya, Suudi Arabistan, Brezilya, Türkiye Pazaryerleri
                    </div>
                    <div class="fs-12 mb-3">
                      <u>Billed when depleted</u>
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">50000 Product</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">500 Scanning</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">2 Seller</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">3 User</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> FBA Option </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> Warehouse Option </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">
                            {" "}
                            Drop Amazon to Amazon{" "}
                          </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> SmartPricer </strong>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="card-body p-0"
                  style={{
                    backgroundColor: "#FFFFFFFF",
                    borderRadius: "6px",
                    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    class="px-1 py-2 bg-info op-3"
                    style={{ borderRadius: "6px 6px 0 0" }}
                  ></div>
                  <div class="p-4">
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#000000",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      Sixth Plan
                    </div>
                    <div class="fs-25 fw-bold mb-1">
                      $399.90
                      {/* <sub
                        class="text-muted fw-semibold fs-14 ms-1"
                        style={{ textDecoration: "line-through" }}
                      >
                        $750.00
                      </sub> */}
                    </div>
                    <div class="mb-1 text-muted">
                      Kanada, Meksika, Belçika, UAE, Avustralya, Japonya, Singapur, İngiltere, Almanya, İspanya, Fransa, İtalya, Hollanda , İsviçre, Polonya, Suudi Arabistan, Brezilya, Türkiye Pazaryerleri
                    </div>
                    <div class="fs-12 mb-3">
                      <u>Billed when depleted</u>
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">100000 Product</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">500 Scanning</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">2 Seller</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">3 User</strong>count
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> FBA Option </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> Warehouse Option </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1">
                            {" "}
                            Drop Amazon to Amazon{" "}
                          </strong>
                        </span>
                      </li>
                      <li class="d-flex align-items-center mb-0">
                        <span class="me-2">
                          <i class="ri-checkbox-circle-line fs-15 text-success"></i>
                        </span>
                        <span className="mb-1">
                          <strong class="me-1"> SmartPricer </strong>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>


              </div>
            </>
          </div>
        </section>
        <section id="faq" ref={faqRef} className="faq">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={31}> {t("31")}</h2>
              <p key={32}> {t("32")}</p>
            </div>
            <div className="faq-list">
              <ul>
                <li
                  data-aos="fade-up"
                  data-aos-delay={100}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#faq-list-1"
                    key={33}
                  >
                    {t("33")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p key={34}>{t("34")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                    key={35}
                  >
                    {t("35")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("36")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                    key={35}
                  >
                    {t("65")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("66")}</p>
                  </div>
                </li>

                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                    key={35}
                  >
                    {t("61")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("62")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                    key={35}
                  >
                    {t("63")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("64")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-6"
                    className="collapsed"
                    key={35}
                  >
                    {t("72")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-6"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("73")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-7"
                    className="collapsed"
                    key={35}
                  >
                    {t("74")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-7"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("75")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-8"
                    className="collapsed"
                    key={35}
                  >
                    {t("76")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-8"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("77")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-9"
                    className="collapsed"
                    key={35}
                  >
                    {t("78")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-9"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("79")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-10"
                    className="collapsed"
                    key={35}
                  >
                    {t("80")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-10"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("81")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-11"
                    className="collapsed"
                    key={35}
                  >
                    {t("82")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-11"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("83")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-12"
                    className="collapsed"
                    key={35}
                  >
                    {t("84")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-12"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("85")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-13"
                    className="collapsed"
                    key={35}
                  >
                    {t("86")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-13"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("87")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-14"
                    className="collapsed"
                    key={35}
                  >
                    {t("88")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-14"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("89")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-15"
                    className="collapsed"
                    key={35}
                  >
                    {t("90")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-15"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("91")}</p>
                  </div>
                </li>
                {/* keep generating like this */}
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-16"
                    className="collapsed"
                    key={35}
                  >
                    {t("92")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-16"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("93")}</p>
                  </div>
                </li>
                {/* keep generating like this */}
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-17"
                    className="collapsed"
                    key={35}
                  >
                    {t("94")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-17"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("95")}</p>
                  </div>
                </li>
                {/* keep generating like this */}
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-18"
                    className="collapsed"
                    key={35}
                  >
                    {t("96")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-18"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("97")}</p>
                  </div>
                </li>
                {/* keep generating like this */}
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-19"
                    className="collapsed"
                    key={35}
                  >
                    {t("98")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-19"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("99.1")}</p>
                    <p key={36}>{t("99.2")}</p>
                    <p key={36}>{t("99.3")}</p>
                    <p key={36}>{t("99.4")}</p>
                    <p key={36}>{t("99.5")}</p>
                    <p key={36}>{t("99.6")}</p>
                    <p key={36}>{t("99.7")}</p>
                    <p key={36}>{t("99.8")}</p>
                    <p key={36}>{t("99.9")}</p>
                    <p key={36}>{t("99.10")}</p>
                    <p key={36}>{t("99.11")}</p>
                    <p key={36}>{t("99.12")}</p>
                    <p key={36}>{t("99.13")}</p>
                    <p key={36}>{t("99.14")}</p>
                  </div>

                </li>
                {/* keep generating like this */}
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-20"
                    className="collapsed"
                    key={35}
                  >
                    {t("100")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-20"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("101")}</p>
                  </div>
                </li>
                {/* keep generating like this */}
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-21"
                    className="collapsed"
                    key={35}
                  >
                    {t("102")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-21"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("103")}</p>
                  </div>
                </li>
                {/* keep generating like this until 127*/}
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-22"
                    className="collapsed"
                    key={35}
                  >
                    {t("104")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-22"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("105")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-23"
                    className="collapsed"
                    key={35}
                  >
                    {t("106")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-23"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("107")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-24"
                    className="collapsed"
                    key={35}
                  >
                    {t("108")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-24"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("109")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-25"
                    className="collapsed"
                    key={35}
                  >
                    {t("110")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-25"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("111")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-26"
                    className="collapsed"
                    key={35}
                  >
                    {t("112")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-26"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("113")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-27"
                    className="collapsed"
                    key={35}
                  >
                    {t("114")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-27"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("115")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-28"
                    className="collapsed"
                    key={35}
                  >
                    {t("116")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-28"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("117")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-29"
                    className="collapsed"
                    key={35}
                  >
                    {t("118")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-29"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("119")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-30"
                    className="collapsed"
                    key={35}
                  >
                    {t("120")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-30"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("121")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-31"
                    className="collapsed"
                    key={35}
                  >
                    {t("122")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-31"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("123")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-32"
                    className="collapsed"
                    key={35}
                  >
                    {t("124")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-32"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("125")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay={200}
                  className="faq-expandable-card"
                >
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-33"
                    className="collapsed"
                    key={35}
                  >
                    {t("126")}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-33"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>{t("127")}</p>
                  </div>
                </li>



















              </ul>
            </div>
          </div>
        </section>

        <section id="contact" ref={contactRef} className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={37}> {t("37")}</h2>
              <p key={38}> {t("38")}</p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i className="bx bx-map" />
                  <h3 key={39}> {t("39")}</h3>
                  <p>
                  30 N Gould St Ste N Sheridan, WY 82801, United States
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope" />
                  <h3 key={40}> {t("40")}</h3>
                  <p>support@sellerdoping.com</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-phone-call" />
                  <h3 key={41}> {t("41")}</h3>
                  <p>+905355648597</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 ">
                <iframe
                  title="aaaaa"
                  className="mb-4 mb-lg-0"
                  src="https://www.google.com/maps/embed/v1/place?q=30+N+Gould+St+Ste+N+Sheridan,+WY+82801,+United%C2%A0States&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  frameBorder={0}
                  style={{ border: 0, width: "100%", height: 362 }}
                  allowFullScreen=""
                />
              </div>
              <div className="col-lg-6">
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required=""
                      />
                    </div>
                    <div className="col-md-6 form-group ">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required=""
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Message"
                      required=""
                      defaultValue={""}
                    />
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
        <div
          className="container"
          style={{
            backgroundColor: "#5846f9",
            textAlign: "center",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <p style={{ color: "white", fontSize: "22px", fontWeight: "bold" }}>
            {t("69")}
          </p>
          <p style={{ color: "white", fontSize: "22px", fontWeight: "bold" }}>
            {t("70")}
          </p>
          <span
            href="#pricing"
            className="bg-mor"
            style={{
              color: "white",
              border: "1px solid",
              padding: "10px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo({
                top: pricingRef.current.offsetTop,
                behavior: "smooth",
              });
            }}
          >
            {t("71")} <i className="bx bxs-label"></i>
          </span>
        </div>
      </main>
    </div>
  );
}

export default IntroductionPage;
