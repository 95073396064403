import React, { createRef, useEffect, useState } from "react";
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import './dr-dualdatepicker.component.css'
import datepicker_en from "./locales/datepicker-en";
import datepicker_tr from "./locales/datepicker-tr";
import moment from "moment";
import { convertDateObject } from "../../../utils/utils";
import $ from 'jquery';
import classNames from "classnames";

function DrDualDatePicker({ onCloseCallBack, selectedDates, timeOptionsSource, className, pulse = false }) {

    const { t } = useTranslation('drDualDatePicker');
    const language = useSelector(state => state.settings.language);
    const dpicker = createRef();

    const [selectedValue, setValue] = useState(selectedDates ? [(selectedDates[0] && new DateObject(selectedDates[0])), (selectedDates[1] && new DateObject(selectedDates[1]))] : [null, null]);

    const [calenderLanguage, setCalenderLanguage] = useState(datepicker_en);
    const [shouldCloseCalendar, setShouldCloseCalendar] = useState(false);
    const [timeOptions, setTimeOptions] = useState([]);
    const [selectedTimeOption, setSelectedTimeOption] = useState("0");



    useEffect(() => {
        setShouldCloseCalendar(false);
        if (timeOptionsSource) {
            let newTimeOptions = timeOptionsSource.map(x => ({ value: x.value, label: x.label, isSelected: selectedTimeOption === x.value }));

            newTimeOptions.push({ value: "custom", label: "Custom", isSelected: false })
            // console.log(newTimeOptions)
            setTimeOptions(newTimeOptions);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeOptionsSource]);

    useEffect(() => {
        if (selectedTimeOption && timeOptions?.length > 0) {
            let currentTimeOptions = Object.assign([], timeOptions);
            currentTimeOptions.forEach(option => {
                option.isSelected = option.value === selectedTimeOption;
            });
            setTimeOptions(currentTimeOptions);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimeOption]);

    useEffect(() => {
        if (language && language === "tr") {
            setCalenderLanguage(datepicker_tr);

        }
        else {
            setCalenderLanguage(datepicker_en);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    useEffect(() => {
        setValue(selectedDates ? [(selectedDates[0] && new DateObject(selectedDates[0])), (selectedDates[1] && new DateObject(selectedDates[1]))] : [null, null])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDates]);

    function HeaderPlugin({ datePickerProps, calendarProps, values }) {
        const props = datePickerProps || calendarProps
        var currentValues = props.value;
        return (
            <div className="rmdp-header-plugin top container-fluid" >
                <div className="row">
                    {currentValues[0] &&
                        <div className="col-6">
                            <div className="rmdp-hp-dddd">{currentValues && currentValues.length > 0 ? convertDateObject(currentValues[0], 'dddd') : ""}</div>
                            <div className="rmdp-hp-dd">{currentValues && currentValues.length > 0 ? convertDateObject(currentValues[0], 'DD') : ""}</div>
                            <div className="rmdp-hp-my">{currentValues && currentValues.length > 0 ? convertDateObject(currentValues[0], 'MMM') : ""} {currentValues && currentValues.length > 0 ? convertDateObject(currentValues[0], 'YYYY') : ""}</div>
                        </div>
                    }
                    {currentValues[1] &&
                        <div className="col-6">
                            <div className="rmdp-hp-dddd">{currentValues && currentValues.length > 1 ? convertDateObject(currentValues[1], 'dddd') : ""}</div>
                            <div className="rmdp-hp-dd">{currentValues && currentValues.length > 1 ? convertDateObject(currentValues[1], 'DD') : ""}</div>
                            <div className="rmdp-hp-my">{currentValues && currentValues.length > 1 ? convertDateObject(currentValues[1], 'MMM') : ""} {currentValues && currentValues.length > 1 ? convertDateObject(currentValues[1], 'YYYY') : ""}</div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    function FooterPlugin({ DatePicker, values }) {
        // const startdate = values && values.length > 0 ? moment.utc(values[0]).startOf('day').format() : null;
        // const enddate = values && values.length > 1 ? moment.utc(values[1]).startOf('day').format() : null;

        const startdate = values && values.length > 0 ? (" " + convertDateObject(values[0], "MMMM") + " " + convertDateObject(values[0], "DD") + " ") : '';
        const enddate = values && values.length > 1 ? (" " + convertDateObject(values[1], "MMMM") + " " + convertDateObject(values[1], "DD") + " ") : '';
        return (
            <div className="rmdp-range-picker-footer">
                <div className="rmdp-range-picker-footer-row px-2 mb-1">
                    <div className="rmdp-header-values" >
                        <div style={{ display: 'inline-block' }}>
                            <h6 >{t('selectedate')}</h6>
                        </div>
                        <div className="justify-content-center">

                            {values[0] &&
                                <div style={{ display: 'inline-block' }}>
                                    <i className="fa fa-times-circle date-cancel text-default fs-lg"
                                        onClick={() => {
                                            setShouldCloseCalendar(false);
                                            setValue([null, values[1]]);
                                        }}></i>
                                    <span>{language === "tr" ? (startdate + t('from')) : (t('from') + startdate)}</span>
                                </div>
                            }
                            <div style={{ display: 'inline-block' }}>
                                <span style={{ padding: '0px 10px' }}>-</span>
                            </div>

                            {values[1] &&
                                <div style={{ display: 'inline-block' }}>
                                    <i className="fa fa-times-circle date-cancel text-default fs-lg"
                                        onClick={() => {
                                            setShouldCloseCalendar(false);
                                            setValue([values[0], null]);
                                        }
                                        }></i>
                                    <span>{language === "tr" ? (enddate + t('to')) : (t('to') + enddate)}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="rmdp-range-picker-footer-row px-2 mb-1">
                    <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                        onClick={() => {
                            setShouldCloseCalendar(true);
                            onClose(true);
                        }} >
                        {t('close')}
                    </button>
                </div>
            </div>
        );
    }

    function BodyPlugin({ DatePicker, values, timeOptionsSource }) {
        return (
            <>
                {timeOptionsSource?.length > 0 &&
                    <div className="rmdp-timeoptions pb-2 mx-1">

                        {timeOptionsSource?.map((timeOption, index) => {
                            return (
                                <div className="btn-list">
                                    <button key={"timeOptions_" + index} className={`btn btn-wave waves-effect waves-light btn-sm btn-block my-2 mx-0 ${timeOption.isSelected ? "btn-teal " : "btn-outline-teal "}`}
                                        value={timeOption.value}

                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShouldCloseCalendar(false);
                                            setSelectedTimeOption(e.currentTarget.value)

                                            var localDate = new Date();
                                            if (e.currentTarget.value !== "custom") {
                                                setValue([
                                                    new DateObject(moment.utc(localDate).subtract(parseInt(e.currentTarget.value), 'days').startOf('day').format("YYYY-MM-DDTHH:mm:ss")),
                                                    new DateObject(moment.utc(localDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss"))
                                                ]);

                                            } else {
                                                setValue([null, null]);
                                            }
                                        }}
                                    >
                                        {timeOption.label}
                                    </button>
                                </div>

                            )
                        })
                        }

                    </div>
                }
            </>
        )
    }

    const onClose = (isClose) => {
        if (isClose) {
            if (onCloseCallBack) {
                onCloseCallBack(selectedValue);
            }
            dpicker.current.closeCalendar();
        }
    }

    const getFormattedText = (dates) => {
        let formattedDateString = "";
        if (dates && dates.length > 0) {
            var splittedDateStrings = dates.split('~');
            if (splittedDateStrings && splittedDateStrings.length > 0) {
                splittedDateStrings.forEach(dateString => {
                    if (formattedDateString?.length > 0) {
                        formattedDateString += " - ";
                    }
                    formattedDateString += moment.utc(dateString, "YYYY/MM/DD").format("DD/MM/YYYY");
                });
            }
        }
        return formattedDateString;
    }

    var inputClass = classNames({
        'input-group': true,
    }, className);

    class CustomComponent extends React.Component {
        render() {
            return (

                <div className={inputClass}>
                    <div className="input-group-text text-muted">
                        <i className="ri-calendar-line"></i>
                    </div>
                    <input type="text" className="form-control flatpickr-input" id="date" placeholder="Choose date" readOnly="readonly"
                        onClick={this.props.openCalendar} defaultValue={getFormattedText(this.props.value)}>

                    </input>
                    <button className="btn btn-teal btn-wave waves-effect waves-light" type="button"
                        onClick={(e) => {
                            var localDate = new Date();
                            var todayValue = [
                                new DateObject(moment.utc(localDate).subtract(0, 'days').startOf('day').format("YYYY-MM-DDTHH:mm:ss")),
                                new DateObject(moment.utc(localDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss"))
                            ];
                            setValue(todayValue);
                            if (onCloseCallBack) {
                                onCloseCallBack(todayValue);
                            }

                        }}>{t('today')}</button>
                </div>
            )
        }
    }

    return (
        <>
            <div className={`dr-dualdatepicker-content input-group ${pulse ? "pulse pulse-success" : ""}`} style={{ width: '100%' }} >
                <DatePicker
                    id="dPicker"
                    ref={dpicker}
                    value={selectedValue}
                    placeholder={t('placeholder')}
                    onChange={(e) => {
                        if (timeOptions?.length > 0) {
                            setShouldCloseCalendar(false);
                            setValue(e);
                            setSelectedTimeOption("custom")
                        } else {
                            setShouldCloseCalendar((e.length > 1));
                            setValue(e);
                        }



                        if (e.length > 0) {
                            // setShouldCloseCalendar(e.length > 1);

                            // if (e.length === 1) {
                            //     setValue([moment.utc(e[0]).startOf('day').format(), null]);
                            // }
                            // else {
                            //     setValue([moment.utc(e[0]).startOf('day').format(), moment.utc(e[1]).startOf('day').format()]);
                            // }
                        }
                    }}

                    onOpen={(e) => {
                        setShouldCloseCalendar(true)
                    }}
                    onClose={() => shouldCloseCalendar ? shouldCloseCalendar : false}
                    range
                    numberOfMonths={2}
                    locale={calenderLanguage}
                    editable={false}
                    plugins={[
                        <HeaderPlugin DatePicker={this} values={selectedValue} position="top" ></HeaderPlugin>,
                        <BodyPlugin DatePicker={this} values={selectedValue} position="left" timeOptionsSource={timeOptions}></BodyPlugin>,
                        <FooterPlugin DatePicker={this} values={selectedValue} position="bottom"></FooterPlugin>
                    ]}
                    render={<CustomComponent />}
                >
                </DatePicker>
            </div>
        </>

    )
}

export default DrDualDatePicker;
