import { call, put, takeLatest, all } from 'redux-saga/effects'
import types, { getCustomerInfo, getRoles, getRolesSuccess, getSubUsers, loginSuccess, logoutUser, setCustomerInfo, setSubUsers, setCustomerProfileInfoSuccess, getCustomerProfileInfo, getCustomerPackageInfoSuccess, getCustomerPackageInfo, loginWithEmail } from '../actions/customer.actions';
import { apiGet, apiPost, apiPut, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import Cookies from 'js-cookie'
import { agreementRequestTypes, consistantCapitalizeFirstLetter, modals } from '../../utils/constants';
import { clearActiveTermAndConditionsModal, setActiveTermAndConditionsModal, withActionPromise } from '../actions/app.actions';
import { clearCreateCustomerInformationState } from '../actions/create-customer.actions';
import { clearAllMarkets, getAllMarkets } from '../actions/market.actions';
import { clearSelectedMarketPricingData, clearSelectedMarketWarehouseSettings, clearStoreState, getAllCustomerStores } from '../actions/store.actions';
import { clearCreateStoreInformationState } from '../actions/create-store.actions';
import { sendMail } from '../actions/utilities.actions';
import { clearPageSelections, setLanguage } from '../actions/settings.actions';
import { clearInventoryProducts } from '../actions/inventory.actions';
import { clearAllOrders } from '../actions/orders.actions';
import { clearSearchTasks } from '../actions/reprizer.action';
import { toastr } from "react-redux-toastr";
import { clearAllSmartPricerData } from '../actions/smartpricer.actions';
import { clearAllShipStationData } from '../actions/shipstation.actions';
import { SetThemeColor } from '../../utils/theme-change';
import i18next from 'i18next';
import { clearAllWalletData } from '../actions/wallet.actions';
import { clearAllNotifications } from '../actions/notification.actions';
import { store } from "../../state-management/configureStore";
import { clearAllReports } from '../actions/reports.actions';
import Swal from 'sweetalert2';
import { clearCreatePackagesState } from '../actions/create-customer-packages.actions';
import { clearAllPackages } from '../actions/packages.action';
import { clearAllAgreements, getAgreements } from '../actions/agreement.action';

const { REACT_APP_HOST_URL } = process.env;

function* runLoginWithEmail(action) {
    const payload = action.payload;
    const response = yield call(apiPost(``, payload, false, CONTROLLERS.LOGIN, false, true));

    if (response && response.isSuccess && response.data && response.resultType === 1) {

        // localStorage.setItem("AuthToken", response.data.token);
        Cookies.set('AuthToken',
            response.data.token,
            {
                expires: 1,
                domain: REACT_APP_HOST_URL,
                path: "/"
            });

        // Cookies.set('AuthToken',
        //     response.data.token,
        //     {
        //         expires: 1,
        //         domain: "sellerdoping.com.tr",
        //         path: "/"
        //     });

        // Cookies.set('AuthToken',
        //     response.data.token,
        //     {
        //         expires: 1,
        //         domain: "sellersmart.com.tr",
        //         path: "/"
        //     });

        // Cookies.set('AuthToken',
        //     response.data.token,
        //     {
        //         expires: 1,
        //         domain: "74.234.34.76",
        //         path: "/"
        //     });


        /*
                REACT_APP_ROLES_SUPERADMIN,//Süper Admin
                REACT_APP_ROLES_ADMIN,//Admin
                REACT_APP_ROLES_ORDERS,//Orders
                REACT_APP_ROLES_STORE,//Store
                REACT_APP_ROLES_LOGISTICS,//Logistics
                REACT_APP_ROLES_INVENTORY,//Inventory
                REACT_APP_ROLES_REPORT //Report
        */
        /*
        
        "authorization": [
              {
                "customerMainSellerId": "8c2f9e88-7d07-4cea-b135-5e1da20df2e6",
                "roleIds": [
                  REACT_APP_ROLES_ORDERS,
                  REACT_APP_ROLES_STORE
                ]
              }
            ]
        
        */
        var roleIds = [];
        var customerSettings = store.getState().settings;

        try {
            if (response.data.authorization && response.data.authorization.length > 0) {
                roleIds = response.data.authorization[0].roleIds;
            }
        } catch (error) {

            console.log(error)
        }

        let customerInfoData = {
            customerId: response.data.id,
            firstName: response.data.firstName || "",
            lastName: response.data.lastName || "",
            profilePicture: response.data.profilePicture || "",
            email: payload.email,
            authorization: response.data.authorization,
            role: roleIds
        }

        if (response.data.warehouseId) {
            customerInfoData.warehouseId = response.data.warehouseId;
        }
        
        var tempResponse = {
            isLoggedin: true,
            token: response.data.token,
            customerInfo: customerInfoData,
            customerConfig: {
                themeColor: customerSettings?.themeColor || "light",
                lastVisitedMarketId: customerSettings?.lastVisitedMarketId || "",
                language: customerSettings?.language || "tr"
            }
        }

        SetThemeColor(tempResponse.customerConfig.themeColor);
        yield put(setLanguage(tempResponse.customerConfig.language));

        yield all([
            // put(clearActiveModal()),
            put(withActionPromise(loginSuccess(tempResponse))),
            // put(withActionPromise(getAllCustomerStores())),
            // put(withActionPromise(getAllMarkets())),
            // put(withActionPromise(getRoles()))
        ]);

        if (Cookies.get("AuthToken") || store.getState()?.customer?.token) {
            yield all([
                put(withActionPromise(getCustomerPackageInfo())),
                put(withActionPromise(getRoles())),
                put(withActionPromise(getAllMarkets())),
                put(withActionPromise(getAllCustomerStores(tempResponse.customerConfig.lastVisitedMarketId))),

            ]);
        }

        toastr.info(Cookies.get("Culture") === "tr" ? "Hoşgeldin," : "Welcome,", ((response.data.firstName ? consistantCapitalizeFirstLetter(response.data.firstName) : "") + " " + (response.data.lastName ? consistantCapitalizeFirstLetter(response.data.lastName) : " ")), { timeOut: 1000 })
    }
    else if (response && !response.isSuccess && response.resultType === 3) {
        yield put(getAgreements());
        yield put(setActiveTermAndConditionsModal({ name: modals.termsAndConditions, isGlobal: true, title: "", source: payload, requestType: agreementRequestTypes.login }));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error3') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runLogout() {

    yield all([
        put(withActionPromise(clearAllMarkets())),
        put(withActionPromise(clearStoreState())),
        put(withActionPromise(clearCreatePackagesState())),
        put(withActionPromise(clearCreateStoreInformationState())),
        put(withActionPromise(clearInventoryProducts())),
        put(withActionPromise(clearAllOrders())),
        // put(withActionPromise(clearSettings())),
        put(withActionPromise(clearSearchTasks())),
        put(withActionPromise(clearAllSmartPricerData())),
        put(withActionPromise(clearAllShipStationData())),
        put(withActionPromise(clearSelectedMarketWarehouseSettings())),
        put(withActionPromise(clearSelectedMarketPricingData())),
        put(withActionPromise(clearAllWalletData())),
        put(withActionPromise(clearAllNotifications())),
        put(withActionPromise(clearPageSelections())),
        put(withActionPromise(clearAllReports())),
        put(withActionPromise(clearAllPackages())),
        put(withActionPromise(clearAllAgreements())),
    ]);
}

function* runGetCustomerInfo(action) {
    var path = `/${action.payload}`;
    const response = yield call(apiGet(path, CONTROLLERS.CUSTOMER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(setCustomerInfo({
                "firstName": response.data?.firstName,
                "lastName": response.data?.lastName,
                "email": response.data?.email,
                "profilePicture": response.data?.profilePicture,
            }))
        ]);
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error4') + " " + (response && response.message ? response.message : ""), 'error')),
            put(logoutUser())
        ]);
    }

}

function* runUpdateCustomerInfo(action) {
    const payload = action.payload;

    const response = yield call(apiPut(``, payload, false, CONTROLLERS.CUSTOMER, true));
    if (response && response.isSuccess) {

        yield all([
            put(getCustomerInfo(payload.id)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info3'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error5') + " " + (response && response.message ? response.message : ""), 'error'))
    }
}

// function* runLoginWithAmazon() {
//     const url = `https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.addc5650d7db414ba58bba10e85f24d9
//     &scope=profile
//     &response_type=code
//     &redirect_uri=https://sellerdoping.com.tr:3000`
//     const response = yield call(apiGet2(url, true, true));

//     if (response) {

//     }
// }
// function* runForgotPassword(action) {
//     const payload = action.payload;
//     const response = yield call(apiPost(`account/forgotpassword`, payload, false, CONTROLLERS.LOGIN, false));
//     if (response) {
//        
//     }
// }

// function* runChangePassword(action) {
//     const payload = action.payload;
//     const response = yield call(apiPost(`account/changepassword`, payload, false, CONTROLLERS.LOGIN, false));
//     if (response) {
//         
//         removeParamFromUrl("token");
//     }
// }

function* runGetRoles() {

    const response = yield call(apiGet(``, CONTROLLERS.ROLE, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getRolesSuccess(response.data));
    }
}

function* runGetSubUsers(action) {
    var payload = action.payload;
    var path = `/GetMainUserSubUserList?customerMainSellerId=${payload.customerMainSellerId}`;

    const response = yield call(apiGet(path, CONTROLLERS.CUSTOMER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(setSubUsers(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error6') + " " + (response && response.message ? response.message : ""), 'error'))
    }

}

function* runCreateSubSeller(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/InsertSubUser`, payload, false, CONTROLLERS.CUSTOMER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        //send email
        yield all([
            put(getSubUsers({ customerSubUserId: payload.parentCustomerSubUserId, customerMainSellerId: payload.customerMainSellerId })),
            put(sendMail(payload.emailData))
        ]);
    }
    else {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error7') + " " + (response && response.message ? response.message : ""), 'error'))
    }
}

function* runUpdateSubSeller(action) {
    const payload = action.payload;
    var data = {
        customerSubUserId: payload.customerSubUserId,
        customerMainSellerId: payload.customerMainSellerId,
        roleIds: payload.roleIds,
        isDeleted: false
    }

    const response = yield call(apiPut(`/UpdateSubUser`, data, false, CONTROLLERS.CUSTOMER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getSubUsers({ customerSubUserId: payload.parentCustomerSubUserId, customerMainSellerId: payload.customerMainSellerId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info4'), 'success'))
        ]);
    }
    else {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error8') + " " + (response && response.message ? response.message : ""), 'error'))
    }
}

function* runResetSubSellerPassword(action) {
    const payload = action.payload;
    const response = yield call(apiPut(`/ResetPassword`, payload.customerData, false, CONTROLLERS.CUSTOMER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(sendMail(payload.emailData)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info27'), 'success'))
        ]);
    }
    else {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error59') + " " + (response && response.message ? response.message : ""), 'error'))
    }
}

function* runGetCustomerProfileInfo(action) {
    var payload = action.payload;
    var path = `/GetProfile?CustomerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.CUSTOMERMAINSELLER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setCustomerProfileInfoSuccess(response.data));
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:warningTitle'), i18next.t('drSweetAlertButton:warningTexts.warning1'), 'warning'))
        ]);
    }

}

function* runUpdateCustomerProfileInfo(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/UpsertProfile`, payload, false, CONTROLLERS.CUSTOMERMAINSELLER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getCustomerProfileInfo(payload.customerMainSellerId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info32'), 'success'))
        ]);
    }
    else {

        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

function* runForgotPassword(action) {
    const payload = action.payload;
    var path = `/CustomerForgetPassword`;

    const response = yield call(apiPost(path, payload, false, CONTROLLERS.LOGIN, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
    }
    else {
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'))
        ]);
    }
}

function* runGetCustomerPackageInfo() {

    const response = yield call(apiGet(`/CustomerPackageInfo`, CONTROLLERS.PACKAGE, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getCustomerPackageInfoSuccess(response.data));
    }
}

export default function* userSaga() {
    yield all([

        takeLatest(types.LOGIN_WITH_EMAIL_REQUESTED, safe(runLoginWithEmail)),
        takeLatest(types.LOGOUT_USER, safe(runLogout)),
        takeLatest(types.GET_CUSTOMER_INFO_REQUESTED, safe(runGetCustomerInfo)),
        takeLatest(types.UPDATE_CUSTOMER_INFO_REQUESTED, safe(runUpdateCustomerInfo)),
        takeLatest(types.CREATE_SUBUSER_REQUESTED, safe(runCreateSubSeller)),
        takeLatest(types.UPDATE_SUBUSER_REQUESTED, safe(runUpdateSubSeller)),
        takeLatest(types.GET_SUBUSERS_REQUESTED, safe(runGetSubUsers)),
        takeLatest(types.RESET_SUBUSER_PASSWORD_REQUESTED, safe(runResetSubSellerPassword)),
        takeLatest(types.GET_ROLES_REQUESTED, safe(runGetRoles, types.GET_ROLES_FAILED)),
        takeLatest(types.GET_CUSTOMER_PROFILE_INFO_REQUESTED, safe(runGetCustomerProfileInfo)),
        takeLatest(types.UPDATE_CUSTOMER_PROFILE_INFO_REQUESTED, safe(runUpdateCustomerProfileInfo)),
        takeLatest(types.FORGOT_PASSWORD_REQUESTED, safe(runForgotPassword)),
        takeLatest(types.GET_CUSTOMER_PACKAGE_INFO_REQUESTED, safe(runGetCustomerPackageInfo)),
        // takeLatest(types.LOGIN_WITH_AMAZON_REQUESTED, safe(runLoginWithAmazon)),
        // takeLatest(types.LOGIN_WITH_EXTERNAL_TOKEN_REQUESTED, safe(runLoginWithExternalToken)),
        // takeLatest(types.CREATE_ACCOUNT_WITH_EMAIL_REQUESTED, safe(runCreateAccountWithEmail, types.CREATE_ACCOUNT_WITH_EMAIL_FAILED)),
        // takeLatest(types.VALIDATE_TOKEN_REQUEST, safe(runValidateToken)),
        // takeLatest(types.FORGOT_PASSWORD_REQUESTED, safe(runForgotPassword)),
        // takeLatest(types.CHANGE_PASSWORD_REQUESTED, safe(runChangePassword)),

    ]);
}

