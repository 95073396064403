import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrPageFilter, DrSweetAlertButton, DrTagifyInput } from '../../components/component-index';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { pageFilterTypes, sweetAlertVariants } from '../../utils/constants';
import { getProductNonPricedAsins, saveDeleteProductNonPriced, saveProductNonPriced } from '../../state-management/actions/inventory.actions';
import { generatePdfReport } from '../../utils/utils';
import { ExportToExcel } from '../../excelexport';
import moment from 'moment';


function UnPrizeProductsPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('nonPrizedProductsPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const myStore = useSelector(state => state.stores.myStore);
    const nonPrizedProductsAsinList = useSelector(state => state.inventory?.nonPrizedProductsAsinList);

    useEffect(() => {
        dispatch(withActionPromise(getProductNonPricedAsins(selectedStore?.customerMainSellerId)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore]);

    // useEffect(() => {
    //     console.log(nonPrizedProductsAsinList)
    //     if (nonPrizedProductsAsinList?.length > 0) {
    //         var data = nonPrizedProductsAsinList.find(x => x.customerMainSellerId === selectedStore?.customerMainSellerId)?.asinList;

    //         var asinlistdata = data?.map(x => ({ asin: x }));

    //         setTableData(asinlistdata);
    //     }
    //     else {
    //         setTableData([]);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [nonPrizedProductsAsinList, nonPrizedProductsAsinList?.length, nonPrizedProductsAsinList?.find(x => x.customerMainSellerId === selectedStore?.customerMainSellerId)?.asinList]);

    const columns = [
        { data: "asin", title: "", minWidth: "40px", maxWidth: "40px", searchable: false, checkall: true, checkData: "asin" },
        { data: "asin", title: "Asin", minWidth: "110px", searchable: true },
        { data: "createdDate", title: "createdDate", minWidth: "110px", searchable: true },
        { data: null, title: "", width: "10%", minWidth: "100px", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                if (rowData.isBlocked) {
                    td.closest("tr").classList.toggle("greyed-out");
                }

                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.asin}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                    </>
                )
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.asin}</span><br />
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(

                    <span className="fs-12 my-auto mx-0 p-0">{moment(rowData.createdDate).format('L LT')}</span>

                );
            }
        },
        {
            targets: 3,
            orderable: false,
            className: "text-end",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <DrSweetAlertButton
                            className="btn btn-icon btn-sm btn-danger btn-wave waves-effect waves-light shadow"
                            buttonText={""}
                            buttonIcon={<i className="fe fe-trash-2"></i>}
                            onCloseConfirmCallBack={(value) => {
                                if (value) {
                                    dispatch(withActionPromise(saveDeleteProductNonPriced({
                                        customerMainSellerId: selectedStore.customerMainSellerId,
                                        asinList: [rowData.asin]
                                    })));
                                }
                            }}
                            sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                        >
                        </DrSweetAlertButton >
                    </>
                );
            }
        }
    ];

    const initialValues = {
        asinList: []
    };

    const handleSubmit = async (values) => {

        var nonPrizedProductsRequest = {
            customerMainSellerId: selectedStore?.customerMainSellerId,
            //asins: values.asinList.split(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ ]/).filter((str) => str !== ''),
            asinList: values.asinList,
        }

        dispatch(saveProductNonPriced(nonPrizedProductsRequest));


    }

    const unpriceProductsSchema = Yup.object().shape({
        // sku: Yup.string()
        //     .min(3, 'Too Short!')
        //     .max(50, 'Too Long!')
        //     .required('SKU is required'),
        // asins: Yup.string()
        //     .min(8, 'Too Short!')
        //     .max(800, 'Too Long!')
        //     .required('Last name is required'),
        asinList: Yup.array()
            // .of(Yup.string()
            //     .min(10, "Asins needs to be ten characters")
            //     .max(10, "Asins needs to be ten characters")
            //     .matches(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/, "Asins characters may only contains numbers and letters")
            //     .required("At least one asin is required")
            // )
            .test({
                message: 'At least one asin is required',
                test: arr => arr.length !== 0,
            })
    });

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

        if (selectedOperation === "0" || (selectedOperation === "1" && nonPrizedProductsAsinList && nonPrizedProductsAsinList?.length > 0)) //export
        {
            let data = nonPrizedProductsAsinList?.filter(item1 =>
                !!checkedValues.find(item2 => item1.asin === item2));

            if (selectedOperation === "0" && data?.length > 0) {
                ExportToExcel({ excelData: data, fileName: "nonprized_product_asins" });
            }
            else if (selectedOperation === "1" && data?.length > 0) {
                const tableColumn = ["ASIN"];
                // define an empty array of rows
                const tableRows = [];

                // for each ticket pass all its data into an array
                data.forEach(asindata => {
                    const blockedAsinsData = [
                        asindata.asin
                    ];
                    // push each tickcet's info into a row
                    tableRows.push(blockedAsinsData);
                });
                generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Non Prized Product Asins", orientation: 'l', reportName: "nonprized_product_asins" });

            }
        }
        else if (selectedOperation === "10" && nonPrizedProductsAsinList?.length > 0) {

            let data = nonPrizedProductsAsinList?.filter(item1 =>
                !!checkedValues.find(item2 => item1.asin === item2));

            var asins = data.map(function (o) {
                return o.asin;
            });

            var requestData = {
                customerMainSellerId: selectedStore?.customerMainSellerId,
                asinList: asins
            }

            dispatch(withActionPromise(saveDeleteProductNonPriced(requestData)));
        }

    }

    const refreshClickedCallBack = () => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getProductNonPricedAsins(selectedStore?.customerMainSellerId)));
        }
    };

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
                { navigationText: i18next.t('constants:breadCrumps.nonPrizedProducts'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validateOnBlur={true}
                                onSubmit={handleSubmit}
                                validationSchema={unpriceProductsSchema}
                                enableReinitialize={true}
                                validateOnMount={true}
                            >
                                {({
                                    isValid,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    setTouched,
                                    setFieldValue
                                }) => (
                                    <>
                                        <form>
                                            {selectedStore && myStore.sourceMarketList?.length > 0 &&
                                                <section>
                                                    <div className="row row-md row-lg justify-content-center">
                                                        <div className="col-12 px-0">
                                                            <div className="form-group">
                                                                <h6>{t('asinListsTitle')}</h6>
                                                                <DrTagifyInput value={values.asinList}
                                                                    settings={{
                                                                        pattern: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{10}$/,
                                                                        delimiters: ",| |:|[\\n\\r]"
                                                                    }}
                                                                    className="tags-input-control"
                                                                    isvalid={(touched.asinList && !errors.asinList)}
                                                                    isinvalid={(touched.asinList && errors.asinList)}
                                                                    onBlur={(e) => {
                                                                        setTouched({ ...touched, 'asinList': true });
                                                                        setFieldValue("asinList", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}
                                                                    onRemove={(e) => {
                                                                        setTouched({ ...touched, 'asinList': true });
                                                                        setFieldValue("asinList", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}
                                                                    onEditUpdated={(e) => {
                                                                        setTouched({ ...touched, 'asinList': true });
                                                                        setFieldValue("asinList", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}>
                                                                </DrTagifyInput>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row row-md row-lg justify-content-end mb-3 mt-2">
                                                        <div className="col-auto px-0">
                                                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid && values.asinList?.length > 0 ? "" : "disabled"}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (isValid && values.asinList?.length > 0) {
                                                                        handleSubmit(values);
                                                                        setFieldValue("asinList", []);
                                                                    }
                                                                }}
                                                            >{t('nonPrizedProducts')}</button>
                                                        </div>
                                                    </div>

                                                </section>
                                            }
                                        </form>
                                    </>
                                )
                                }
                            </Formik>
                            {selectedStore && myStore.sourceMarketList?.length === 0 && <div className="row row-md row-lg justify-content-center my-3 px-1">

                                <div className="col-auto">
                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(getRouteByKey("MY_STORE"));
                                        }}>
                                        <i className="me-2 fe fe-chevrons-right"></i>
                                        {t('goToSourceMarketSelection')}
                                    </button>
                                </div>
                            </div>}
                            {!selectedStore && <div className="row row-md row-lg justify-content-center">
                                <div className="col-lg-auto col-md-2">
                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            Promise.all([
                                                dispatch(withActionPromise(clearCreateStoreInformationState()))
                                            ]).then((e) => {
                                                navigate(getRouteByKey("MARKET_SELECTION"));
                                            });;

                                        }}>
                                        <i className="me-2 fe fe-chevrons-right"></i>
                                        {t('gotoMarketSelection')}
                                    </button>
                                </div>
                            </div>
                            }

                            {selectedStore && <>
                                <DrDataTable
                                    data={nonPrizedProductsAsinList}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    // headerSelectbox={true}
                                    minWidth="850px"
                                    search={true}
                                    order={[[1, 'asc']]}
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            refreshClickedCallBack={refreshClickedCallBack}
                                            filterType={pageFilterTypes.nonPrizeAsins}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                        >
                                        </DrPageFilter>
                                    }
                                />
                            </>
                            }
                        </div>
                    </div>

                </div>
            </div>


        </>

    )
}

export default UnPrizeProductsPage;