import types from "../actions/inventory.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_INVENTORY_PRODUCTS_WITH_DETAIL_SUCCESS:
            return { ...state, products: action.payload };
        case types.GET_PRODUCTS_DETAILS_SUCCESS:
            return { ...state, selectedProducts: action.payload };
        case types.CLEAR_PRODUCTS_DETAILS:
            return { ...state, selectedProducts: [] };
        case types.GET_PRODUCTS_CATEGORIES_SUCCESS:
            return { ...state, productsCategories: action.payload };
        case types.GET_PRODUCTS_BRANDS_SUCCESS:
            return { ...state, productsBrands: action.payload };
        case types.GET_BLOCKED_PRODUCTS_SUCCESS:
            return { ...state, blockedProductsAsinList: action.payload };
        case types.GET_NON_PRIZED_PRODUCTS_SUCCESS:
            return { ...state, nonPrizedProductsAsinList: action.payload };
        case types.CLEAR_ALL_PRODUCTS:
            return initialState;
        case types.SET_BLOCKED_WORDS_SUCCESS:
            return { ...state, blockedWords: action.payload };
        case types.SET_PRODUCT_VARIANTS_SUCCESS:
            return { ...state, productVariants: action.payload };
        case types.SET_BRAND_POOLS_SUCCESS:
            return { ...state, brandPools: action.payload };
        case types.SET_ASIN_POOLS_SUCCESS:
            return { ...state, asinPools: action.payload };
        case types.SET_PRODUCT_HISTORY_LIST_SUCCESS:
            return { ...state, productHistoryList: action.payload };
        case types.GET_INVENTORY_SUMMARY_SUCCESS:
            return { ...state, inventorySummary: action.payload };
        default:
            return state;
    }
}

const initialState = {
    "products": [],
    "selectedProducts": [],
    "productsCategories": [],
    "productsBrands": [],
    "blockedProductsAsinList:": [],
    "nonPrizedProductsAsinList": [],
    "blockedWords": null,
    "productVariants": [],
    "brandPools": null,
    "asinPools": null,
    "productHistoryList": [],
    "inventorySummary": null,
}

export default reducer;