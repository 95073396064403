import React, { useState } from 'react'
import { DrButtonDropdown, DrInput, DrNavigator } from '../../components/component-index';
import { setMarketSelection, setSnapshotStorePageSubmission } from '../../state-management/actions/create-store.actions';
import { useDispatch, useSelector } from 'react-redux';
import { withActionPromise } from '../../state-management/actions/app.actions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { clearSelectedStore, clearSelectedStoreFilter } from '../../state-management/actions/store.actions';
import { useEffect } from 'react';
import { buttonDropdownDirection, buttonVariants } from '../../utils/constants';

function MarketSelectionPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('marketSelectionPage');
    const marketSelection = useSelector(state => state.createStoreSnapshot.marketSelection);
    const customerStores = useSelector(state => state.stores.customerStores);
    const allMarkets = useSelector(state => state.markets.allMarkets);
    const [marketSelectionSource, setMarketSelectionSource] = useState([]);
    const settings = useSelector(state => state.settings);
    const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);

    useEffect(() => {
        Promise.all([
            dispatch(clearSelectedStore()),
            dispatch(clearSelectedStoreFilter())
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let source = allMarkets.filter(x => !customerStores.some(y => y.countryCode === x.countryCode)).map(x => ({
            value: x.countryCode,
            // label: i18next.t('constants:countries.' + x.countryCode),
            icon: <>
                <div className="row justify-content-center m-2">
                    <div className="col-auto mx-0">
                        <i className={'flag flag-' + (x.countryCode === 'UK' ? 'gb' : x.countryCode.toLowerCase())} style={{ height: '70px', width: '100px' }} ></i>
                    </div>

                </div>
                <div className="row justify-content-center m-0">
                    <div className="col-auto mx-0">
                        {i18next.t('constants:countries.' + x.countryCode)}
                    </div>
                </div>
            </>
        }));
        setMarketSelectionSource(source);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allMarkets, customerStores, settings?.language]);

    const initialValues = {
        countryCode: marketSelection?.countryCode || "",
        marketSerialNumber: marketSelection?.marketSerialNumber || "",
    };

    const marketSelectionSchema = Yup.object().shape({
        countryCode: Yup.string().required('Country code is required'),
        marketSerialNumber: Yup.string().required('Market Serial Number is required'),
    });

    return (
        <>
            {(customerPackageInfo?.totalSellerCount || 0) > 0 && (customerStores?.length || 0) < (customerPackageInfo?.totalSellerCount || 0) &&

                <Formik
                    initialValues={initialValues}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    validationSchema={marketSelectionSchema}
                    validateOnChange={true}>
                    {({
                        isValid,
                        values,
                        errors,
                        touched,
                        handleBlur,
                        setTouched,
                        setFieldValue,
                        handleChange
                    }) => (
                        <div className="content clearfix">
                            <form name="market-selection-form" className="needs-validation" autoComplete="off">


                                <section>
                                    <div className="row row-md d-flex justify-content-center">
                                        <div className="col-12">
                                            <h3 className="text-center">{t('contentTitle')}</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex">
                                            {/* <DrButtonDropdown className="m-2"
                                        options={marketSelectionSource}
                                        selectedValue={values.countryCode}
                                        optionClickCallBack={(value) => {

                                            setFieldValue("countryCode", value);
                                            setFieldValue("marketSerialNumber", value);
                                            // setTouched({ ...touched, 'countryCode': true, 'marketSerialNumber': true })
                                        }}
                                        field={field}
                                        onBlur={() => { setTouched({ ...touched, 'countryCode': true, 'marketSerialNumber': true }) }}
                                        direction={buttonDropdownDirection.horizontal}
                                        variant={buttonVariants.light}
                                        selectedVariant={buttonVariants.teal}
                                    >
                                    </DrButtonDropdown> */}
                                            <Field
                                                onBlur={() => { setTouched({ ...touched, 'countryCode': true, 'marketSerialNumber': true }) }}

                                                name={'carrierCode'}
                                                component={DrButtonDropdown}
                                                selectedValue={values.countryCode}
                                                options={marketSelectionSource}
                                                direction={buttonDropdownDirection.horizontal}
                                                variant={buttonVariants.light}
                                                selectedVariant={buttonVariants.teal}
                                                optionClickCallBack={(value) => {

                                                    setFieldValue("countryCode", value);
                                                    setFieldValue("marketSerialNumber", value);
                                                    // setTouched({ ...touched, 'countryCode': true, 'marketSerialNumber': true })
                                                }}


                                            />
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                <div className="col-12 d-flex">
                                    <div className="row">

                                        {allMarkets.filter(x => !customerStores.some(y => y.countryCode === x.countryCode)).map((market, index) => {
                                            let translateKey = 'constants:countries.' + market.countryCode;
                                            let flagClass = 'flag flag-' + (market.countryCode === 'UK' ? 'gb' : market.countryCode.toLowerCase());


                                            return (
                                                <div key={(index + 1)} className="col-xl-auto col-lg-4 col-sm-3">
                                                    <label htmlFor={("input_" + (index + 1))} className={`card custom-card selectable ${values.countryCode === market.countryCode ? "active" : ""}`}
                                                        style={{ height: '150px', width: '150px' }}>
                                                        <div className="card-body text-center">
                                                            <i className={flagClass} style={{ height: '70px', width: '100px', marginBlockStart: '5px', marginBlockEnd: '20px' }} />
                                                        </div>

                                                        <DrInput id={("input_" + (index + 1))} key={("input_" + (index + 1))} value={market.countryCode} checked={values.countryCode === market.countryCode}
                                                            className="custom-control-input" placeholder="" name="countryCode" type="checkbox"
                                                            onChange={(e) => {
                                                                setFieldValue("countryCode", market.countryCode);
                                                                setFieldValue("marketSerialNumber", market.marketSerialNumber);
                                                            }}
                                                            onBlur={() => { setTouched({ ...touched, 'countryCode': true, 'marketSerialNumber': true }) }}
                                                        />
                                                        <h2 className="card-title text-center">{i18next.t(translateKey)}</h2>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div> */}
                                </section>

                                {<DrNavigator backAddress="" nextAddress="STORE_PREFERENCES" disabled={!isValid}
                                    callback={() => {

                                        const marketSelectionValues = {
                                            countryCode: values.countryCode,
                                            marketSerialNumber: values.marketSerialNumber
                                        }

                                        return Promise.all([
                                            dispatch(withActionPromise(dispatch(setMarketSelection(marketSelectionValues)))),
                                            dispatch(withActionPromise(dispatch(setSnapshotStorePageSubmission({ marketSelectionPage: true }))))
                                        ]);
                                    }}
                                />}

                            </form>
                        </div>
                    )
                    }
                </Formik>

            }
        </>
    )
}

export default MarketSelectionPage;