import React, { useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrInput, DrSelectBox, DrTagifyInput } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Field, Formik } from "formik";
import $ from 'jquery';
import i18next from 'i18next';
import Restricted from '../../security/Restricted';
import { buttonVariants, staticRoles } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import { deleteSmartPricerStrategy, getAllSmartPricerStrategies, updateSmartPricerStrategy, updateSmartPricerStrategyDefault } from '../../state-management/actions/smartpricer.actions';
import { smartPricerPageOptions } from '../../components/hoc/Constants/dr-constants.component';
import { useEffect } from 'react';
import { clearSmartPricerState } from '../../state-management/actions/create-smartpricer-filter.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';
// import { smartpricerStrategiesMockData as smartPricerStrategies } from '../../utils/mocks-data';

function SmartPricerPage() {
    const { REACT_APP_ROLES_ADMIN,REACT_APP_ROLES_SUSPENDED_ADMIN } = process.env;
    const navigate = useNavigate();
    const { t } = useTranslation('smartPricerPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const productCategories = useSelector(state => state.inventory.productsCategories);
    const smartPricerStrategies = useSelector(state => state.smartPricer.smartPricerStrategies);
    const [selectedStrategy, setSelectedStrategy] = useState(null);
    const [selectedStrategyType, setSelectedStrategyType] = useState("-1");

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getAllSmartPricerStrategies({ customerMainSellerId: selectedStore?.customerMainSellerId, strategyTargetType: selectedStrategyType || "-1" })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    const unCheckAllSelections = () => {
        $(document.querySelectorAll('#strategiesTable tr')).removeClass("selected");
        $(document.querySelectorAll('input[type="checkbox"]:checked')).prop("checked", false);
    }

    const initialValues = {
        id: selectedStrategy?.id || "",
        smartPricerName: selectedStrategy?.smartPricerName || "",
        productCategoryId: selectedStrategy?.productCategoryId,
        smartPricerStrategy: selectedStrategy?.smartPricerStrategy.toString(),
        asins: selectedStrategy?.asins
    };


    const columns = [
        // { data: "id", title: "", minWidth: "110px", maxWidth: "110px", searchable: false, checkall: true, checkData: "id" },
        { data: "smartPricerName", title: t("listPage.datatable.smartPricerName"), width: "20%", minWidth: "150px", maxWidth: "150px", searchable: true, checkall: true, checkData: "id" },
        { data: "smartPricerStrategy", title: t("listPage.datatable.smartPricerStrategy"), minWidth: "110px", maxWidth: "150px", searchable: false },
        { data: "productCategoryId", title: t("listPage.datatable.productCategory"), width: "10%", minWidth: "130px", searchable: false },
        { data: "asins", title: t("listPage.datatable.asins"), width: "5%", minWidth: "90px", maxWidth: "90px", searchable: false },
        { data: null, title: "", minWidth: "180px", maxWidth: "180px", searchable: false }
    ];

    const columnDefs = [

        {
            targets: 0,
            orderable: true,
            className: "text-center position-relative",
            width: "20%",
            searchable: true,
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(

                    <>
                        {(rowData.isDefault && rowData.smartPricerStrategy === 0) &&
                            <span className="badge bg-teal position-absolute" style={{ left: '5px', top: '0px' }} >
                                {t("listPage.datatable.defaultStrategy")}

                            </span>
                        }
                        <span className="fs-12 my-auto mx-0 p-0">{rowData.smartPricerName}</span>
                    </>
                );
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-12 my-auto mx-0 p-0">{smartPricerPageOptions.find(x => x.value === rowData.smartPricerStrategy.toString())?.label}</span>
                );
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{productCategories.find(x => x.id === rowData.productCategoryId)?.categoryName}</span><br />
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            searchable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{rowData.asins?.length > 0 && rowData.asins[0]}</span><br />
                    </>
                );
            }
        },

        {
            targets: 4,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div className="row justify-content-end w-100">
                        <div className="col-auto pe-0 my-auto">
                            {rowData.smartPricerStrategy === 0 && !rowData.isDefault &&
                                <button className={`btn btn-teal ripple btn-wave waves-effect waves-light shadow btn-sm`}
                                    onClick={(e) => {
                                        //dispatch(withActionPromise(deleteWarehouse(warehouse.id)));
                                        e.preventDefault();
                                        // set süper admin default

                                        // set 

                                        var requestData = {
                                            selectedStrategyId: rowData.id,
                                            customerMainSellerId: selectedStore?.customerMainSellerId,
                                            strategyTargetType: selectedStrategyType || "-1"
                                        }
                                        dispatch(withActionPromise(updateSmartPricerStrategyDefault(requestData)));

                                    }}
                                >
                                    {t('listPage.datatable.setAsDefault')}
                                </button>}
                        </div>
                        <div className="col-auto pe-0 my-auto">
                            <button className="btn btn-icon btn-secondary btn-wave waves-effect waves-light shadow btn-sm" value={rowData.id} data-bs-placement="right" data-bs-toggle="tooltip" title="Edit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/smartpricer-edit/' + rowData.id);
                                }}
                            >
                                <i className="fe fe-edit" ></i>
                            </button>
                        </div>
                        <div className="col-auto pe-0 my-auto">
                            <button className="btn btn-icon btn-danger btn-wave waves-effect waves-light shadow btn-sm" value={rowData.customerSubUserId}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDelete(rowData.id);
                                }}
                            >
                                <i className="fe fe-trash-2" ></i>
                            </button>
                        </div>
                    </div>
                )
            }
        }
    ];

    const smartPricerStrategiesSchema = Yup.object().shape({
        smartPricerName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Strategy name is required')
    });

    const rowClickedCallBack = async (rowData) => {
        setSelectedStrategy(rowData);
    }

    const handleSubmit = async (values) => {
        var data = {
            selectedStrategyTypeOption: selectedStrategyType,
            smartpricerData: {
                id: values.id,
                customerMainSellerId: selectedStore?.customerMainSellerId,
                smartPricerName: values.smartPricerName,
                smartPricerStrategy: selectedStrategy?.smartPricerStrategy,
                productCategoryId: values.productCategoryId || null,
                asins: values.asins || null
            }
        }
        dispatch(updateSmartPricerStrategy(data));
    }

    const handleDelete = async (value) => {
        var data = {
            id: value,
            customerMainSellerId: selectedStore?.customerMainSellerId,
            strategyTargetType: selectedStrategyType
        }
        dispatch(deleteSmartPricerStrategy(data));
    }

    const optionClickCallBack = (value, label) => {
        setSelectedStrategyType(value);
        if (selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getAllSmartPricerStrategies({ customerMainSellerId: selectedStore?.customerMainSellerId, strategyTargetType: value })));
        }
    };

    return (
        <>
            <div>
                <DrBreadCrump
                    breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.smartPricer'), navigationPath: "" }]} >
                </DrBreadCrump>
                <div className="row row-md row-lg d-flex justify-content-center">
                    <div className="col-md-8 col-lg-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className={`row row-md row-lg w-100 justify-content-end`} >
                                    <div className="col-auto my-auto">
                                        <div className="row">
                                            <div className="col-auto px-0">
                                                <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                                                    <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            dispatch(clearSmartPricerState());
                                                            unCheckAllSelections();
                                                            setSelectedStrategy(null);
                                                            navigate(getRouteByKey("SMARTPRICER_OPTIONS"));
                                                        }}>
                                                        <i className="me-2 fe fe-plus"></i>
                                                        {t('listPage.addNewStrategy')}
                                                    </button>
                                                </Restricted>
                                            </div>
                                            <div className="col-auto px-0">
                                                <DrButtonDropdown className="ms-2"
                                                    options={smartPricerPageOptions}
                                                    optionClickCallBack={optionClickCallBack}
                                                    variant={buttonVariants.info}>
                                                </DrButtonDropdown>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validateOnBlur={true}
                                    onSubmit={handleSubmit}
                                    validationSchema={smartPricerStrategiesSchema}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                >
                                    {({
                                        isValid,
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        setTouched,
                                        setFieldValue
                                    }) => (
                                        <form>

                                            <div className="row row-md row-lg px-3 mt-3">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group has-validation">
                                                        <label>{t('listPage.smartPricerName')}</label>
                                                        <DrInput value={values.smartPricerName} className={`form-control`} placeholder={t('smartPricerName')} name="smartPricerName" type="text"
                                                            onChange={handleChange}
                                                            disabled={!selectedStrategy}
                                                            onBlur={() => { setTouched({ ...touched, 'smartPricerName': true, 'id': true }) }}
                                                            isInvalid={(touched.smartpricerName && errors.smartpricerName)}
                                                            isValid={touched.smartpricerName && !errors.smartpricerName}
                                                            validationText={errors.smartpricerName}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-mg-6 col-lg-6">
                                                    <div className="form-group has-validation">
                                                        <label>{t('listPage.productCategory')}</label>
                                                        <Field
                                                            onBlur={() => { setTouched({ ...touched.productCategoryId, 'productCategoryId': true }) }}
                                                            onChange={(e) => {
                                                                setFieldValue("productCategoryId", e.value);
                                                            }}
                                                            name={'productCategoryId'}
                                                            component={DrSelectBox}
                                                            placeholder={t('listPage.productCategory')}
                                                            disabled={values.smartPricerStrategy !== "1"}
                                                            options={productCategories?.map(x => ({ value: x.id, label: x.categoryName }))} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row px-3">
                                                <div className="col mt-2">
                                                    <label>{t('listPage.targetAsins')}</label>
                                                    <DrTagifyInput id="targetAsinsInput" value={values.asins} disabled={values.smartPricerStrategy !== "2"}
                                                        settings={{
                                                            pattern: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{10}$/,
                                                            delimiters: ",| |:|[\\n\\r]"
                                                        }}
                                                        isvalid={(touched.asins && !errors.asins)}
                                                        isinvalid={(touched.asins && errors.asins)}
                                                        className="tags-input-control"
                                                        onBlur={(e) => {
                                                            setTouched({ ...touched, 'asins': true });
                                                            setFieldValue("asins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                        }}
                                                        onRemove={(e) => {
                                                            setTouched({ ...touched, 'asins': true });
                                                            setFieldValue("asins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                        }}
                                                        onEditUpdated={(e) => {
                                                            setTouched({ ...touched, 'asins': true });
                                                            setFieldValue("asins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                        }}>
                                                    </DrTagifyInput>
                                                </div>
                                            </div>

                                            <div className="row row-md row-lg mb-1 mt-3 px-3 justify-content-end">
                                                <div className="col-auto my-auto">
                                                    <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                                                        <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (isValid) {
                                                                    handleSubmit(values);
                                                                }
                                                            }}
                                                        >{t('listPage.saveChanges')}</button>
                                                    </Restricted>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                    }
                                </Formik>
                                <div id="strategiesTable" className="row mb-5">
                                    <div className="col-lg-12 col-xl-12 col-md-12">
                                        <div className="card custom-card">
                                            <div className="card-body horizontal-scrollable">
                                                {smartPricerStrategies && <>
                                                    <DrDataTable
                                                        data={smartPricerStrategies}
                                                        columns={columns}
                                                        columnDefs={columnDefs}
                                                        doesHaveBorder={false}
                                                        headerSearch={false}
                                                        search={false}
                                                        minWidth="850px"
                                                        isRowClickEnabled={true}
                                                        rowClickedCallBack={rowClickedCallBack}
                                                    />
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SmartPricerPage;