import classNames from 'classnames';
import './dr-input.component.css'

function DrInput({
    label,
    name,
    type,
    placeholder,
    value,
    className,
    icon,
    iconPosition,
    image,
    width,
    isValid,
    isInvalid,
    validationText,

    ...rest }) {

    const numberValidation = (e) => {
        const notAllowedInput = ["e", "E", "+"];
        notAllowedInput.includes(e.key) && e.preventDefault()
    }

    if (rest.disabled && rest.disabled === "") {
        delete rest.disabled;
    }

    var inputClass = classNames({
        'cursor-pointer': className.includes('form-check-input'),
        'form-control-has-icon': icon,
        'form-control-has-icon-left': iconPosition === "left",
        'form-control-has-icon-right': iconPosition === "right",
        'is-invalid': isInvalid,
        'is-valid': isValid
    }, className);


    /*

<div className="input-group mb-3">
                                            <div className="input-group-text">
                                                <span>$</span>
                                            </div>
                                            <input aria-label="Amount (to the nearest dollar)" className="form-control" type="text">
                                            <div className="input-group-text">
                                                <span>.00</span>
                                            </div>
                                        </div>
    */

    var iconClassLeft = classNames({
        'input-group-text px-1': true,
        'hide-icon': !(iconPosition && iconPosition === "left"),
    });

    var iconClassRight = classNames({
        'input-group-text px-1': true,
        'hide-icon': !(iconPosition && iconPosition === "right"),
    });
    return (
        <div className={`dr-input-content input-group ${width ? width : ""}`}>
            {icon && <div className={iconClassLeft}>
                <span>
                    {icon}
                </span>
            </div>}
            <input
                id={name}
                type={type}
                name={name}
                value={value}
                className={inputClass}
                placeholder={placeholder}
                onKeyDown={type === "number" ? (evt) => numberValidation(evt) : rest.onKeyDown}
                {...rest}
            />
            {icon && <div className={iconClassRight}>
                <span>
                    {icon}
                </span>
            </div>}
            {/* {icon && <div className={`input-group-text has-icon ${iconPosition && iconPosition === "left" ? "has-icon-left" : ""}`}>
                <span>
                    {icon}
                </span>
            </div>} */}
            {image &&
                <div className={`has-image`}>
                    {image}
                </div>
            }

            {inputClass.includes('custom-switch-input') &&
                <span className={`custom-switch-indicator ${inputClass.includes('custom-switch-input-gold') ? "custom-switch-indicator-gold" : ""}`} />
            }

            {label &&
                <label className="ms-1 cursor-pointer no-select" htmlFor={name}>{label}</label>}

            {isInvalid && <div type="text" className="invalid-feedback">
                {validationText}
            </div>}
        </div>
    )
}

export default DrInput;
