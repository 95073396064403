import { useTranslation } from "react-i18next";
import { Formik } from 'formik';
import { clearActiveBuyoutCarrierSelectionModal, setActivePictureModal, withActionPromise } from "../../../../state-management/actions/app.actions";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import $ from 'jquery';
import "./dr-buyout-carrier-selection-modal.component.scss";
import { useEffect, useState } from "react";
import { buyoutCarrierSelectionMockData } from "../../../../utils/mocks-data";
import { modals, siteExtensions } from "../../../../utils/constants";
import { flagOptions } from "../../Constants/dr-constants.component";
import moment from "moment";
import { getOrderItem, getOrderShippingCalculate, updateOrderShipping } from "../../../../state-management/actions/orders.actions";


function DrBuyoutCarrierSelectionModal({ display, source, persistModal = false, carrierSelectionCallback }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drCaptchaModal');
    const [dataSource, setDataSource] = useState(null);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const orderShippingCalculate = useSelector(state => state.orders.orderShippingCalculate);
    const selectedOrderDetail = useSelector(state => state.orders.selectedOrder);
    const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);

    useEffect(() => {
        // setDataSource(buyoutCarrierSelectionMockData)
        if (source) {
            dispatch(withActionPromise(getOrderItem({ orderId: source })));
            dispatch(withActionPromise(getOrderShippingCalculate({ customerMainSellerId: selectedStore?.customerMainSellerId, orderId: source })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    const handleSubmit = (values) => {
        if (carrierSelectionCallback) {
            carrierSelectionCallback(values);
        }
    };

    return (

        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="buyoutCarrierSelectionModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveBuyoutCarrierSelectionModal());
                }
            }}>


            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-full-height" role="document">
                <div className="modal-content">
                    <div className="modal-header" >
                        <h6 className="modal-title" >{selectedOrderDetail?.orderNumber}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => dispatch(clearActiveBuyoutCarrierSelectionModal())} >
                        </button>
                    </div>
                    <div className="modal-body justify-contents-center">
                        <div className="row">
                            <div className="col-auto">
                                <div className="card-aside-img border border-gray-300 rounded container mt-2" style={{ textAlign: 'start', width: '64px', position: 'relative' }}>
                                    <div className="row justify-content-start">
                                        {selectedOrderDetail?.orderDetailList?.length > 0
                                            &&
                                            <div className={`px-0 rounded-0 col-12`} >
                                                <img src={selectedOrderDetail?.orderDetailList[0].imageUrl} className="img-thumbnail" alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                                ></img>
                                            </div>
                                        }

                                    </div>

                                    {selectedOrderDetail?.orderDetailList.length > 1 && <span className="badge bg-danger tx-bold" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>{selectedOrderDetail?.orderDetailList.length}</span>}
                                    {flagOptions.find(x => x.value === selectedOrderDetail?.orderFlag)?.icon &&

                                        <span style={{ position: 'absolute', bottom: '-8px', right: '-8px' }}>{flagOptions.find(x => x.value === selectedOrderDetail?.orderFlag)?.icon}</span>

                                    }
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="card-title fw-bold mb-3">{selectedOrderDetail?.orderDetailList[0].title}</h5>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p className="fs-13 fw-semibold mb-1">Asin:</p>
                                    </div>
                                    <div className="col-auto">
                                        <span> {selectedOrderDetail?.orderDetailList[0].asin} </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p className="fs-13 fw-semibold mb-1">Price:</p>
                                    </div>
                                    <div className="col-auto">
                                        {selectedStoreCurrencyType.currencyCode} {selectedOrderDetail?.orderDetailList[0].itemPriceAmount}
                                    </div>
                                    <div className="col-auto">
                                        <a className="text-white fs-13 fw-bold badge bg-info cursor-pointer" target="_blank" rel="noreferrer"
                                            href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === (selectedStore?.countryCode || "US"))?.extension + "/dp/" + selectedOrderDetail?.orderDetailList[0].asin}>
                                            Amazon'da Görüntüle
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {orderShippingCalculate?.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered border-primary">
                                            <thead>
                                                <tr>
                                                    <th className="bg-info bg-opacity-10 text-info">Warehouse</th>
                                                    <th className="bg-info bg-opacity-10 text-info">Carrier</th>
                                                    <th className="bg-info bg-opacity-10 text-info">Delivery</th>
                                                    <th className="bg-info bg-opacity-10 text-info">Shipping Price</th>
                                                    <th className="bg-info bg-opacity-10 text-info">Profit</th>
                                                    <th className="bg-info bg-opacity-10 text-info"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderShippingCalculate?.sort((b, a) => a.isSelected - b.isSelected).map((shippingItem, index) => {

                                                    return (
                                                        <tr key={"shippingItem_" + index}>
                                                            <td className={`text-center ${index % 2 === 0 ? "bg-light" : "bg-opacity-10 bg-dark"}`}>
                                                                {shippingItem.warehouseLogo?.length > 0 && <img src={shippingItem.warehouseLogo} alt={shippingItem.wareHouseName} style={{ height: '25px' }} />}
                                                                {!(shippingItem.warehouseLogo?.length > 0) && shippingItem.wareHouseName}


                                                            </td>
                                                            <td className={`text-center ${index % 2 === 0 ? "bg-light" : "bg-opacity-10 bg-dark"}`}>
                                                                {shippingItem.shippingLogo?.length > 0 && <img src={shippingItem.shippingLogo} alt={shippingItem.shippingName} style={{ height: '25px' }} />}
                                                                {!(shippingItem.shippingLogo?.length > 0) && shippingItem.shippingName}
                                                            </td>
                                                            <td className={`text-center ${index % 2 === 0 ? "bg-light" : "bg-opacity-10 bg-dark"}`}>
                                                                {/* {(moment(shippingItem?.latestShipDate).isValid() && moment(shippingItem?.latestDeliveryDate).isValid() && moment(shippingItem?.purchaseDate).isValid())
                                                                    ? moment(shippingItem?.latestShipDate).startOf("day").diff(moment(shippingItem?.purchaseDate).startOf("day"), 'days') + " - "
                                                                    + moment(shippingItem?.latestDeliveryDate).startOf("day").diff(moment(shippingItem?.latestShipDate).startOf("day"), 'days')
                                                                    : "-"} */}
                                                                {shippingItem.deliveryTime}
                                                            </td>
                                                            <td className={`text-center ${index % 2 === 0 ? "bg-light" : "bg-opacity-10 bg-dark"}`}>
                                                                {shippingItem.shippingCostCurrencyCode} {shippingItem.shippingCost}
                                                            </td>
                                                            <td className={`text-center ${index % 2 === 0 ? "bg-light" : "bg-opacity-10 bg-dark"}`}>
                                                                <div>
                                                                    <div className="row justify-content-start w-90" >
                                                                        <div className="col-12 px-0">
                                                                            <span className={`text-end badge rounded-pill fs-11 ms-2 ${shippingItem.profitPrice < 0 ? "bg-danger" : "bg-success"}`}>Profit: {selectedStoreCurrencyType.currencyCode} {Number(shippingItem.profitPrice || 0.00).toFixed(2)}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row justify-content-start w-90 mt-1" >
                                                                        <div className="col-12 px-0">
                                                                            <span className={`text-end badge rounded-pill fs-11 ms-2 ${shippingItem.profitPercent < 0 ? "bg-danger" : "bg-success"}`}>Profit %: {Number(shippingItem.profitPercent || 0.00).toFixed(2)}%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>


                                                            <td className={`text-center ${index % 2 === 0 ? "bg-light" : "bg-opacity-10 bg-dark"}`}>
                                                                <button className={`ms-2 cursor-pointer btn ripple btn-sm btn-teal ${!selectedOrderDetail?.isOrderable ? "" : ""}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        var requestData = {
                                                                            orderShippingRequest: {
                                                                                orderId: selectedOrderDetail.id || source,
                                                                                wareHouseShippingId: shippingItem.wareHouseShippingId
                                                                            },
                                                                            paymentUrl: "https://www.amazon." + siteExtensions.find(x => x.countryCode === (selectedOrderDetail?.orderDetailList[0].sourceMarketCountryCode || (selectedStore?.countryCode || "US")))?.extension + "/gp/aws/cart/add.html?AmazonOrderId=" + selectedOrderDetail?.orderDetailList[0].orderId + "&sendType=1&ASIN.1=" + selectedOrderDetail?.orderDetailList[0].asin + "&Quantity.1=" + selectedOrderDetail?.orderDetailList[0].quantityOrdered
                                                                        }

                                                                        dispatch(updateOrderShipping(requestData));
                                                                    }}>
                                                                    Satın Al
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row justify-content-start w-100">

                        </div>

                    </div>
                </div>

            </div>
        </div>

    )
}

export default DrBuyoutCarrierSelectionModal