import { React, useRef } from 'react';
import './dr-readmore.component.css';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

function DrReadMore({ text, max = 400, classes = 'fs-12', onTextClicked, ...rest }) {
    const ref = useRef(getUniqueId());

    const firstPart = text.length > max ? text.substring(0, max) : text;
    const secondPart = text.length > max ? text.substring(max) : null;

    return (
        <div className={classes} {...rest} >
            <input type="checkbox" className="read-more-state" id={"rm-info-state-" + ref.current} />
            <p className={`read-more-wrap ${onTextClicked ? "text-info text-start text-underline cursor-pointer no-select" : ""}`}
                onClick={(e) => {
                    if (onTextClicked) {
                        onTextClicked(e);
                    }
                }}>
                {firstPart}
                {secondPart && <span className="read-more-dots">...</span>}
                {secondPart && <span className="read-more-target">{secondPart}</span>}
            </p>
            {secondPart && <label htmlFor={"rm-info-state-" + ref.current} className="read-more-trigger"></label>}
        </div>
    );
}

export default DrReadMore;
