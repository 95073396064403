import { useDispatch, useSelector } from "react-redux";
import { withActionPromise } from "../../../state-management/actions/app.actions";
import { setMyStorePageSelections } from "../../../state-management/actions/settings.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getInventorySummary } from "../../../state-management/actions/reports.actions";
import { useEffect, useState } from "react";
import { getRouteByPath } from "../../../routes";

function DrPackageWarningLimitExceeds({source = []}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation('drPackageWarnings');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);
    const inventorySummary = useSelector(state => state.reports.inventorySummary);
    const [currentPageKey, setCurrentPageKey] = useState("");

    return (
        <>
            <div className="alert alert-danger alert-dismissible fade show custom-alert-icon shadow-sm bg-danger bg-opacity-10" role="alert">
                <svg className="svg-danger" xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 24 24" width="1.5rem" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM12 17.3c-.72 0-1.3-.58-1.3-1.3 0-.72.58-1.3 1.3-1.3.72 0 1.3.58 1.3 1.3 0 .72-.58 1.3-1.3 1.3zm1-4.3h-2V7h2v6z"></path></svg>

                <div className="row justify-content-left ms-2 ps-3">
                    <div className="col-auto">


                        {source?.length > 0 && source.map((warningElement, index) => {
                            return (
                                <div key={"warning_" + index}>


                                    {warningElement?.title?.length > 0 &&
                                        <div className="row mb-2">
                                            <div className="col-auto">
                                                <span className="fw-bold">
                                                    {warningElement?.title}
                                                </span>
                                            </div>
                                        </div>}
                                    {warningElement.notes?.length > 0 &&

                                        <div className="row mb-2">
                                            <div className="col-auto">
                                                <ul className="mb-0 list-unstyled">
                                                    <li className="d-flex align-items-top mb-2">
                                                        <span className="me-2">
                                                            <i className="bx bxs-circle fs-9 me-2 op-5 text-danger"></i>
                                                        </span>
                                                        <span>
                                                            {warningElement.notes.map((warningNote, index2) => {
                                                                return (
                                                                    <div key={"warningNote_" + index2} className="row">
                                                                        <div className="col-auto">
                                                                            <span>{warningNote}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}

                                                        </span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DrPackageWarningLimitExceeds
