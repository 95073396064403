const types = {
    GET_WAREHOUSES_REQUESTED: "GET_WAREHOUSES_REQUESTED",
    GET_WAREHOUSES_SUCCESS: "GET_WAREHOUSES_SUCCESS",
    CREATE_WAREHOUSE_REQUESTED: "CREATE_WAREHOUSE_REQUESTED",
    CREATE_WAREHOUSE_SUCCESS: "CREATE_WAREHOUSE_SUCCESS",
    UPDATE_WAREHOUSE_REQUESTED: "UPDATE_WAREHOUSE_REQUESTED",
    UPDATE_WAREHOUSE_SUCCESS: "UPDATE_WAREHOUSE_SUCCESS",
    DELETE_WAREHOUSE_REQUESTED: "DELETE_WAREHOUSE_REQUESTED",
    DELETE_WAREHOUSE_SUCCESS: "DELETE_WAREHOUSE_SUCCESS",

    GET_SHIPPINGS_REQUESTED: "GET_SHIPPINGS_REQUESTED",
    GET_SHIPPINGS_SUCCESS: "GET_SHIPPINGS_SUCCESS",
    CREATE_SHIPPING_REQUESTED: "CREATE_SHIPPING_REQUESTED",
    CREATE_SHIPPING_SUCCESS: "CREATE_SHIPPING_SUCCESS",
    UPDATE_SHIPPING_REQUESTED: "UPDATE_SHIPPING_REQUESTED",
    UPDATE_SHIPPING_SUCCESS: "UPDATE_SHIPPING_SUCCESS",
    DELETE_SHIPPING_REQUESTED: "DELETE_SHIPPING_REQUESTED",
    DELETE_SHIPPING_SUCCESS: "DELETE_SHIPPING_SUCCESS",

    GET_WAREHOUSE_SHIPPINGS_REQUESTED: "GET_WAREHOUSE_SHIPPINGS_REQUESTED",
    GET_WAREHOUSE_SHIPPINGS_SUCCESS: "GET_WAREHOUSE_SHIPPINGS_SUCCESS",
    CREATE_WAREHOUSE_SHIPPING_REQUESTED: "CREATE_WAREHOUSE_SHIPPING_REQUESTED",
    CREATE_WAREHOUSE_SHIPPING_SUCCESS: "CREATE_WAREHOUSE_SHIPPING_SUCCESS",
    UPDATE_WAREHOUSE_SHIPPING_REQUESTED: "UPDATE_WAREHOUSE_SHIPPING_REQUESTED",
    UPDATE_WAREHOUSE_SHIPPING_SUCCESS: "UPDATE_WAREHOUSE_SHIPPING_SUCCESS",
    DELETE_WAREHOUSE_SHIPPING_REQUESTED: "DELETE_WAREHOUSE_SHIPPING_REQUESTED",
    DELETE_WAREHOUSE_SHIPPING_SUCCESS: "DELETE_WAREHOUSE_SHIPPING_SUCCESS",

    SAVE_WAREHOUSE_SUIT_REQUESTED: "SAVE_WAREHOUSE_SUIT_REQUESTED",
    SAVE_WAREHOUSE_SUIT_SUCCESS: "SAVE_WAREHOUSE_SUIT_SUCCESS",
    DELETE_WAREHOUSE_SUIT_REQUESTED: "DELETE_WAREHOUSE_SUIT_REQUESTED",
    DELETE_WAREHOUSE_SUIT_SUCCESS: "DELETE_WAREHOUSE_SUIT_SUCCESS",

    CLEAR_WAREHOUSES: "CLEAR_WAREHOUSES",
    SET_DEFAULT_WAREHOUSES: "SET_DEFAULT_WAREHOUSES",
    SET_CUSTOMER_WAREHOUSES: "SET_CUSTOMER_WAREHOUSES",
    GET_CUSTOMER_WAREHOUSES_REQUESTED: "GET_CUSTOMER_WAREHOUSES_REQUESTED",
    GET_CUSTOMER_WAREHOUSES_SUCCESS: "GET_CUSTOMER_WAREHOUSES_SUCCESS",
    GET_DEFAULT_WAREHOUSES_REQUESTED: "GET_DEFAULT_WAREHOUSES_REQUESTED",
    GET_DEFAULT_WAREHOUSES_SUCCESS: "GET_DEFAULT_WAREHOUSES_SUCCESS",

    SAVE_WAREHOUSE_EXCEL_REQUESTED: "SAVE_WAREHOUSE_EXCEL_REQUESTED",
    SAVE_WAREHOUSE_EXCEL_SUCCESS: "SAVE_WAREHOUSE_EXCEL_SUCCESS",
    DELETE_WAREHOUSE_EXCEL_REQUESTED: "DELETE_WAREHOUSE_EXCEL_REQUESTED",
    DELETE_WAREHOUSE_EXCEL_SUCCESS: "DELETE_WAREHOUSE_EXCEL_SUCCESS",
    GET_WAREHOUSES_EXCEL_REQUESTED: "GET_WAREHOUSES_EXCEL_REQUESTED",
    GET_WAREHOUSES_EXCEL_SUCCESS: "GET_WAREHOUSES_EXCEL_SUCCESS",
    GET_WAREHOUSES_EXCEL_FAILED: "GET_WAREHOUSES_EXCEL_FAILED",
    GET_WAREHOUSE_EXCEL_REQUESTED: "GET_WAREHOUSE_EXCEL_REQUESTED",
    GET_WAREHOUSE_EXCEL_SUCCESS: "GET_WAREHOUSE_EXCEL_SUCCESS",

    UPDATE_WAREHOUSE_DEFAULT_REQUESTED: "UPDATE_WAREHOUSE_DEFAULT_REQUESTED",
    UPDATE_WAREHOUSE_DEFAULT_SUCCESS: "UPDATE_WAREHOUSE_DEFAULT_SUCCESS",

    UPDATE_WAREHOUSE_SUIT_DEFAULT_REQUESTED: "UPDATE_WAREHOUSE_SUIT_DEFAULT_REQUESTED",
    UPDATE_WAREHOUSE_SUIT_DEFAULT_SUCCESS: "UPDATE_WAREHOUSE_SUIT_DEFAULT_SUCCESS",

    SAVE_WAREHOUSE_LOST_PACKAGES_REQUESTED: "SAVE_WAREHOUSE_LOST_PACKAGES_REQUESTED",
    DELETE_WAREHOUSE_LOST_PACKAGES_REQUESTED: "DELETE_WAREHOUSE_LOST_PACKAGES_REQUESTED",
    GET_WAREHOUSE_LOST_PACKAGES_REQUESTED: "GET_WAREHOUSE_LOST_PACKAGES_REQUESTED",
    GET_WAREHOUSE_LOST_PACKAGES_SUCCESS: "GET_WAREHOUSE_LOST_PACKAGES_SUCCESS",

    GET_WAREHOUSE_HOME_PAGE_DATA_REQUESTED: "GET_WAREHOUSE_HOME_PAGE_DATA_REQUESTED",
    GET_WAREHOUSE_HOME_PAGE_DATA_SUCCESS: "GET_WAREHOUSE_HOME_PAGE_DATA_SUCCESS",

    GET_WAREHOUSE_ORDERS_INVOICES_REQUESTED: "GET_WAREHOUSE_ORDERS_INVOICES_REQUESTED",
    GET_WAREHOUSE_ORDERS_INVOICES_SUCCESS: "GET_WAREHOUSE_ORDERS_INVOICES_SUCCESS",

    GET_WAREHOUSE_ORDER_INVOICE_BY_ID_REQUESTED: "GET_WAREHOUSE_ORDER_INVOICE_BY_ID_REQUESTED",
    GET_WAREHOUSE_ORDER_INVOICE_BY_ID_SUCCESS: "GET_WAREHOUSE_ORDER_INVOICE_BY_ID_SUCCESS",

    SAVE_WAREHOUSE_SETTINGS_REQUESTED: "SAVE_WAREHOUSE_SETTINGS_REQUESTED",
}

export default types;

export const getWarehouses = (payload) => {
    return { type: types.GET_WAREHOUSES_REQUESTED, payload }
}

export const getWarehousesSuccess = (payload) => {
    return { type: types.GET_WAREHOUSES_SUCCESS, payload }
}

export const createWarehouse = (payload) => {
    return { type: types.CREATE_WAREHOUSE_REQUESTED, payload }
}

export const updateWarehouse = (payload) => {
    return { type: types.UPDATE_WAREHOUSE_REQUESTED, payload }
}

export const deleteWarehouse = (payload) => {
    return { type: types.DELETE_WAREHOUSE_REQUESTED, payload }
}

export const getShippings = () => {
    return { type: types.GET_SHIPPINGS_REQUESTED }
}

export const getShippingsSuccess = (payload) => {
    return { type: types.GET_SHIPPINGS_SUCCESS, payload }
}

export const createShipping = (payload) => {
    return { type: types.CREATE_SHIPPING_REQUESTED, payload }
}

export const updateShipping = (payload) => {
    return { type: types.UPDATE_SHIPPING_REQUESTED, payload }
}

export const deleteShipping = (payload) => {
    return { type: types.DELETE_SHIPPING_REQUESTED, payload }
}

export const getWarehouseShippings = (payload) => {
    return { type: types.GET_WAREHOUSE_SHIPPINGS_REQUESTED, payload }
}

export const getWarehouseShippingsSuccess = (payload) => {
    return { type: types.GET_WAREHOUSE_SHIPPINGS_SUCCESS, payload }
}

export const createWarehouseShipping = (payload) => {
    return { type: types.CREATE_WAREHOUSE_SHIPPING_REQUESTED, payload }
}

export const updateWarehouseShipping = (payload) => {
    return { type: types.UPDATE_WAREHOUSE_SHIPPING_REQUESTED, payload }
}

export const deleteWarehouseShipping = (payload) => {
    return { type: types.DELETE_WAREHOUSE_SHIPPING_REQUESTED, payload }
}

export const saveWarehouseSuit = (payload) => {
    return { type: types.SAVE_WAREHOUSE_SUIT_REQUESTED, payload }
}

export const deleteWarehouseSuit = (payload) => {
    return { type: types.DELETE_WAREHOUSE_SUIT_REQUESTED, payload }
}

export const getDefaultWarehouses = () => {
    return { type: types.GET_DEFAULT_WAREHOUSES_REQUESTED }
}

export const getCustomerWarehouses = (payload) => {
    return { type: types.GET_CUSTOMER_WAREHOUSES_REQUESTED, payload }
}

export const setDefaultWarehouses = (payload) => {
    return { type: types.SET_DEFAULT_WAREHOUSES, payload };
}

export const setCustomerWarehouses = (payload) => {
    return { type: types.SET_CUSTOMER_WAREHOUSES, payload };
}

export const clearWarehouses = () => {
    return { type: types.CLEAR_WAREHOUSES };
}

export const saveWarehouseExcel = (payload) => {
    return { type: types.SAVE_WAREHOUSE_EXCEL_REQUESTED, payload }
}

export const getWarehousesExcels = () => {
    return { type: types.GET_WAREHOUSES_EXCEL_REQUESTED }
}

export const getWarehousesExcelSuccess = (payload) => {
    return { type: types.GET_WAREHOUSES_EXCEL_SUCCESS, payload }
}

export const getWarehouseExcelFile = (payload) => {
    return { type: types.GET_WAREHOUSE_EXCEL_REQUESTED, payload }
}

export const getWarehouseExcelSuccess = (payload) => {
    return { type: types.GET_WAREHOUSES_EXCEL_SUCCESS, payload }
}

export const updateWarehouseDefault = (payload) => {
    return { type: types.UPDATE_WAREHOUSE_DEFAULT_REQUESTED, payload }
}

export const updateWarehouseSuitDefault = (payload) => {
    return { type: types.UPDATE_WAREHOUSE_SUIT_DEFAULT_REQUESTED, payload }
}

export const getWarehousesLostPackages = () => {
    return { type: types.GET_WAREHOUSE_LOST_PACKAGES_REQUESTED }
}

export const getWarehousesLostPackagesSuccess = (payload) => {
    return { type: types.GET_WAREHOUSE_LOST_PACKAGES_SUCCESS, payload }
}

export const saveWarehouseLostPackage = (payload) => {
    return { type: types.SAVE_WAREHOUSE_LOST_PACKAGES_REQUESTED, payload }
}

export const deleteWarehouseLostPackage = (payload) => {
    return { type: types.DELETE_WAREHOUSE_LOST_PACKAGES_REQUESTED, payload }
}

export const getWarehouseHomePageData = (payload) => {
    return { type: types.GET_WAREHOUSE_HOME_PAGE_DATA_REQUESTED, payload }
}

export const getWarehouseHomePageDataSuccess = (payload) => {
    return { type: types.GET_WAREHOUSE_HOME_PAGE_DATA_SUCCESS, payload }
}

export const getWarehouseOrdersInvoices = (payload) => {
    return { type: types.GET_WAREHOUSE_ORDERS_INVOICES_REQUESTED, payload };
}

export const getWarehouseOrdersInvoicesSuccess = (payload) => {
    return { type: types.GET_WAREHOUSE_ORDERS_INVOICES_SUCCESS, payload };
}

export const getWarehouseOrderInvoiceById = (payload) => {
    return { type: types.GET_WAREHOUSE_ORDER_INVOICE_BY_ID_REQUESTED, payload };
}

export const getWarehouseOrderInvoiceByIdSuccess = (payload) => {
    return { type: types.GET_WAREHOUSE_ORDER_INVOICE_BY_ID_SUCCESS, payload };
}

export const deleteWarehouseExcel = (payload) => {
    return { type: types.DELETE_WAREHOUSE_EXCEL_REQUESTED, payload }
}

export const deleteWarehouseExcelSuccess = (payload) => {
    return { type: types.DELETE_WAREHOUSE_EXCEL_SUCCESS, payload }
}

export const saveWarehouseSettings = (payload) => {
    return { type: types.SAVE_WAREHOUSE_SETTINGS_REQUESTED, payload }
}