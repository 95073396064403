
import SecureRoute from "./security/SecureRoute";
import { Route, Routes, useLocation } from "react-router-dom";

import {
    Congratulation,
    ErrorPage,
    HomePage,
    PaymentPage,
    RegisterPage,
    MainPackages,
    InventoryPage,
    AccountPage,
    OrdersPage,
    ProductVariantsPage,
    GeneralPage,
    OrderDetailPage,
    ProfilePage,
    CreditPage,
    InvoicesPage,
    BestSellingPage,
    DuplicatePage,
    UpdateListPage,
    MarketSelectionPage,
    StorePreferencesPage,
    WarehouseSelectionPage,
    ConnectAmazonPage,
    CompleteCreateStorePage,
    StoreFiltersPage,
    CategoriesPage,
    BrandListPage,
    BlockedWordsPage,
    BrandBlackWhiteListPage,
    RestrictedAndExcludedProductsPage,
    PrivacyAndPolicyPage,
    AddNewProduct,
    SalesReportPage,
    ProfitReportPage,
    StorePricingPage,
    ProblematicProductReportPage,
    SubSellersPage,
    ProductDetailPage,
    SearchTasksPage,
    ServiceConfigPage,
    ShippingsPage,
    SmartPricerFiltersPage,
    SmartPricerFilterSelectionPage,
    SmartPricerTargetPage,
    SmartPricerCompletePage,
    SmartPricerCompletedPage,
    SmartPricerPage,
    ProductComparePage,
    WarehouseOrdersPage,
    MessagesPage,
    MessageDetailPage,
    MyWalletPage,
    PaymentMethodPage,
    CardSelectionPage,
    WalletPaymentPage,
    EmailForwardingPage,
    EmailFeedbackConfigPage,
    SmartPricerEditPage,
    WarehousePreferencesPage,
    BlockedProductsPage,
    LandingListPage,
    WarehouseLostPackagesPage,
    UnPrizeProductsPage,
    CopyrightBrandListPage,
    CreatePackagesPage,
    CreateCouponsPage,
    CommonBrandPoolPage,
    CommonProductPoolPage,
    PackageFeatures,
    WareHouseHomePage,
    IntroductionPage,
    CustomerPackagesPage,
} from './pages/pages-index';

import { useEffect, useState } from "react";
import { ERRORS } from "./utils/constants";
import { useSelector } from "react-redux";
import MyStorePage from "./pages/MyStore/mystore.page";
import ExtensionRegisterPage from "./pages/Extension/extension-register.page";

/*
"2007990c-608d-409b-9d90-871f404370c9"	"Super Admin"
"850a513e-91b1-4b86-b524-948df61ef12d"	"Admin"
"20ab00e9-0233-4181-a977-97f528b0b07e"	"Orders"
"20c474db-3367-4530-951b-674fbfb67b33"	"Store"
"48894142-ec43-4bbc-828a-6dec02e54ae6"	"Logistics"
"d9d4bc8a-5d89-4584-9458-7c3a7713517d"	"Inventory"
"3b7d5977-9838-4966-b0e7-4b6c29fccb4a"	"Report"
"eb95cc55-0157-4cf9-82d8-4ef6a957ee6b"  "guest"

    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN,
        REACT_APP_ROLES_STORE, REACT_APP_ROLES_GUEST,
        REACT_APP_ROLES_USER, REACT_APP_ROLES_ORDERS,
        REACT_APP_ROLES_INVENTORY, REACT_APP_ROLES_REPORT, REACT_APP_ROLES_LOGISTICS } = process.env;
        
*/
const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN,
    REACT_APP_ROLES_STORE, REACT_APP_ROLES_GUEST,
    REACT_APP_ROLES_USER, REACT_APP_ROLES_ORDERS,
    REACT_APP_ROLES_INVENTORY, REACT_APP_ROLES_REPORT, REACT_APP_ROLES_LOGISTICS } = process.env;

export const pageKeys = {
    login: "login",
    privacyAndPolicy: "privacyAndPolicy",
    error: "error",
    mainPackage: "mainPackage",
    subFeatures: "subFeatures",
    payment: "payment",
    congratulations: "congratulations",
    home: "home",
    account: "account",
    orders: "orders",
    orderDetail: "orderDetail",
    general: "general",
    shippings: "shippings",
    profile: "profile",
    credit: "credit",
    invoices: "invoices",
    categories: "categories",
    brandlist: "brandlist",
    copyrightBrandlist: "copyrightBrandlist",
    commonProductPool: "commonProductPool",
    commonBrandPool: "commonBrandPool",

    blockedWords: "blockedWords",
    brandBlackWhiteList: "brandBlackWhiteList",
    restrictedAndExcludedProducts: "restrictedAndExcludedProducts",
    inventory: "inventory",
    productVariants: "productVariants",
    bestSelling: "bestSelling",
    duplicate: "duplicate",
    updateList: "updateList",
}

const ROUTES = [
    { path: "/introduction", key: "INTRODUCTION", name: "Introduction", pageType: "public", element: IntroductionPage },

    { path: "/", key: "LOGIN", name: "login", pageType: "public", element: RegisterPage },
    { path: "/login", key: "LOGIN", name: "login", pageType: "public", element: RegisterPage },
    { path: "/login/:type", key: "LOGIN", name: "login", pageType: "public", element: RegisterPage },

    { path: "/privacyandpolicy", key: "PRIVACIYANDPOLICY", name: "privacyAndPolicy", pageType: "public", element: PrivacyAndPolicyPage },
    { path: "/error", key: "ERROR", name: "error", pageType: "private", element: ErrorPage },
    // { path: "*", key: "ERROR", name: "Error", pageType: "public", element: ErrorPage },

    {
        path: "/main-packages", key: "MAIN_PACKAGES", name: "mainPackage", pageType: "private", snapshotPackagesConditionalStep: "none",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,
            REACT_APP_ROLES_ADMIN,
            REACT_APP_ROLES_SUSPENDED_ADMIN,
            REACT_APP_ROLES_GUEST,
            REACT_APP_ROLES_USER,
        ],
        element: MainPackages
    },
    {
        path: "/package-features", key: "SUB_FEATURES", name: "subFeatures", pageType: "private", snapshotPackagesConditionalStep: "mainPackagesPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin 
            REACT_APP_ROLES_ADMIN, //Admin
            REACT_APP_ROLES_GUEST, //Guest
            REACT_APP_ROLES_USER//user
        ],
        element: PackageFeatures
    },
    {
        path: "/customer-payment", key: "PAYMENT", name: "payment", pageType: "private", snapshotPackagesConditionalStep: "packageFeaturesPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin 
            REACT_APP_ROLES_ADMIN, //Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,
            REACT_APP_ROLES_GUEST, //Guest
            REACT_APP_ROLES_USER//user
        ],
        element: PaymentPage
    },
    {
        path: "/customer-packages", key: "CUSTOMER_PACKAGES", name: "customerPackages", pageType: "private", snapshotPackagesConditionalStep: "none",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,
            REACT_APP_ROLES_ADMIN,
            REACT_APP_ROLES_SUSPENDED_ADMIN,
            REACT_APP_ROLES_GUEST,
            REACT_APP_ROLES_USER,
        ],
        element: CustomerPackagesPage
    },

    { path: "/congratulations", key: "CONGRATULATIONS", name: "congratulations", pageType: "public", snapshotPackagesConditionalStep: "paymentPage", element: Congratulation },
    { path: "/congratulations", key: "CONGRATULATIONS", name: "congratulations", pageType: "private", snapshotPackagesConditionalStep: "paymentPage", element: Congratulation },
    {
        path: "/home", key: "HOME", name: "home", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin 
            REACT_APP_ROLES_ADMIN, //Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,
            REACT_APP_ROLES_ORDERS,//Orders
            REACT_APP_ROLES_LOGISTICS,//Logistics
            REACT_APP_ROLES_INVENTORY,//Inventory
            REACT_APP_ROLES_REPORT, //Report
        ],
        element: HomePage
    },
    {
        path: "/", key: "HOME", name: "home", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin 
            REACT_APP_ROLES_ADMIN, //Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,
            REACT_APP_ROLES_ORDERS,//Orders
            REACT_APP_ROLES_LOGISTICS,//Logistics
            REACT_APP_ROLES_INVENTORY,//Inventory
            REACT_APP_ROLES_REPORT, //Report
        ],
        element: HomePage
    },
    {
        path: "", key: "HOME", name: "home", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin 
            REACT_APP_ROLES_ADMIN, //Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,
            REACT_APP_ROLES_ORDERS,//Orders
            REACT_APP_ROLES_LOGISTICS,//Logistics
            REACT_APP_ROLES_INVENTORY,//Inventory
            REACT_APP_ROLES_REPORT, //Report
        ],
        element: HomePage
    },
    {
        path: "/account", key: "ACCOUNT", name: "account", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_ORDERS,//Orders
            REACT_APP_ROLES_STORE,//Store
            REACT_APP_ROLES_LOGISTICS,//Logistics
            REACT_APP_ROLES_INVENTORY,//Inventory
            REACT_APP_ROLES_REPORT, //Report
            REACT_APP_ROLES_GUEST,//Guest
            REACT_APP_ROLES_USER//user
        ],
        element: AccountPage
    },
    {
        path: "/orders", key: "ORDERS", name: "orders", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_ORDERS//Orders
        ],
        element: OrdersPage
    },
    {
        path: "/orders/s/:searchkey", key: "ORDERS", name: "orders", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_ORDERS//Orders
        ],
        element: OrdersPage
    },
    {
        path: "/order-detail", key: "ORDERDETAIL", name: "orderDetail", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_ORDERS,//Orders
            REACT_APP_ROLES_STORE//Store
        ],
        element: OrderDetailPage

    },
    {
        path: "/order-detail/:orderId", key: "ORDERDETAIL", name: "orderDetail", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_ORDERS,//Orders
            REACT_APP_ROLES_STORE//Store
        ],
        element: OrderDetailPage
    },
    {
        path: "/general", key: "GENERAL", name: "general", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_LOGISTICS//Logistics
        ],
        element: GeneralPage
    },
    {
        path: "/shippings", key: "SHIPPINGS", name: "shippings", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: ShippingsPage
    },
    {
        path: "/profile", key: "PROFILE", name: "profile", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_LOGISTICS //Logistics
        ],
        element: ProfilePage
    },
    {
        path: "/credit", key: "CREDIT", name: "credit", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_LOGISTICS //Logistics
        ],
        element: CreditPage
    },
    {
        path: "/invoices", key: "INVOICES", name: "invoices", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_LOGISTICS //Logistics
        ],
        element: InvoicesPage
    },
    {
        path: "/categories", key: "CATEGORIES", name: "categories", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY,//Inventory
        ],
        element: CategoriesPage
    },
    {
        path: "/categories/:category", key: "CATEGORIES", name: "categories", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY,//Inventory
        ],
        element: CategoriesPage
    },
    {
        path: "/brandlist", key: "BRANDLIST", name: "brandlist", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY,//Inventory
        ],
        element: BrandListPage
    },
    {
        path: "/copyright-brands", key: "COPYRIGHTBRANDLIST", name: "copyrightBrandlist", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY,//Inventory
        ],
        element: CopyrightBrandListPage
    },
    {
        path: "/common-product-pool", key: "COMMONPRODUCTPOOL", name: "commonProductPool", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY,//Inventory
        ],
        element: CommonProductPoolPage
    },
    {
        path: "/common-brand-pool", key: "COMMONBRANDPOOL", name: "commonBrandPool", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY,//Inventory
        ],
        element: CommonBrandPoolPage
    },
    {
        path: "/blockedwords", key: "BLOCKEDWORDS", name: "blockedWords", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: BlockedWordsPage
    },
    {
        path: "/brandblackwhitelist", key: "BRANDBLACKWHITELIST", name: "brandBlackWhiteList", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY,//Inventory
        ],
        element: BrandBlackWhiteListPage
    },
    {
        path: "/restrictedandexcludedproducts", key: "RESTRICTEDSNDEXCKUDEDĞRIDUCTS", name: "restrictedAndExcludedProducts", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY,//Inventory
        ],
        element: RestrictedAndExcludedProductsPage
    },

    {
        path: "/inventory", key: "INVENTORY", name: "inventory", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: InventoryPage
    },
    {
        path: "/inventory/c/:category", key: "INVENTORY", name: "inventory", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: InventoryPage
    },
    {
        path: "/inventory/b/:brand", key: "INVENTORY", name: "inventory", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: InventoryPage
    },
    {
        path: "/inventory/s/:searchkey", key: "INVENTORY", name: "inventory", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: InventoryPage
    },
    {
        path: "/inventory/f/:filterKey", key: "INVENTORY", name: "inventory", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: InventoryPage
    },
    {
        path: "/product-variants", key: "PRODUCTVARIANTS", name: "productVariants", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: ProductVariantsPage
    },
    {
        path: "/product-variants/:asin", key: "PRODUCTVARIANTS", name: "productVariants", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: ProductVariantsPage
    },
    {
        path: "/best-selling", key: "BESTSELLING", name: "bestSelling", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: BestSellingPage
    },
    {
        path: "/duplicate", key: "DUPLICATE", name: "duplicate", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: DuplicatePage
    },
    {
        path: "/update-list", key: "UPDATALIST", name: "updateList", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: UpdateListPage
    },
    {
        path: "/update-list/:asin", key: "UPDATALIST", name: "updateList", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: UpdateListPage
    },
    {
        path: "/sales-report", key: "SALESREPORT", name: "salesReport", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_REPORT //Report
        ],
        element: SalesReportPage
    },
    {
        path: "/profit-report", key: "PROFITREPORT", name: "profitReport", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_REPORT //Report
        ],
        element: ProfitReportPage
    },
    {
        path: "/problematicproduct-report", key: "PROBLEMATICPRODUCTREPORT", name: "problematicProductReport", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_REPORT //Report
        ],
        element: ProblematicProductReportPage
    },
    {
        path: "/addproduct", key: "ADD_PRODUCT", name: "addProduct", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: AddNewProduct
    },
    {
        path: "/addproduct/:asins", key: "ADD_PRODUCT", name: "addProduct", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: AddNewProduct
    },
    {
        path: "/blockedproducts", key: "BLOCKED_PRODUCTS", name: "blockedProducts", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: BlockedProductsPage
    },
    {
        path: "/non-prized-products", key: "NON_PRIZED_PRODUCTS", name: "nonPrizedProducts", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: UnPrizeProductsPage
    },
    {
        path: "/product-detail", key: "PRODUCT_DETAIL", name: "productDetail", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY, //Inventory,
            REACT_APP_ROLES_ORDERS//Orders
        ],
        element: ProductDetailPage

    },
    {
        path: "/product-detail/:asin", key: "PRODUCT_DETAIL", name: "productDetail", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: ProductDetailPage
    },
    {
        path: "/product-compare", key: "PRODUCT_COMPARE", name: "productCompare", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: ProductComparePage
    },
    {
        path: "/product-compare/:asins", key: "PRODUCT_COMPARE", name: "productCompare", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_INVENTORY //Inventory
        ],
        element: ProductComparePage
    },
    {
        path: "/market-selection", key: "MARKET_SELECTION", name: "marketSelection", pageType: "private", snapshotCreateStoreConditionalStep: "none",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: MarketSelectionPage
    },
    {
        path: "/store-preferences", key: "STORE_PREFERENCES", name: "storePreferences", pageType: "private", snapshotCreateStoreConditionalStep: "marketSelectionPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: StorePreferencesPage
    },
    {
        path: "/warehouse-selection", key: "WAREHOUSE_SELECTION", name: "warehouseSelection", pageType: "private", snapshotCreateStoreConditionalStep: "storePreferencesPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: WarehouseSelectionPage
    },
    {
        path: "/connect-amazon", key: "CONNECT_AMAZON", name: "connectAmazon", pageType: "private", snapshotCreateStoreConditionalStep: "wareHouseSelectionPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: ConnectAmazonPage
    },
    {
        path: "/complete-createstore", key: "COMPLETE_CREATESTORE", name: "completeCreateStore", pageType: "private", snapshotCreateStoreConditionalStep: "connectAmazonPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: CompleteCreateStorePage
    },
    {
        path: "/mystore", key: "MY_STORE", name: "myStore", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: MyStorePage
    },
    {
        path: "/store-filters", key: "STORE_FILTERS", name: "storeFilters", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: StoreFiltersPage
    },
    {
        path: "/store-pricing", key: "STORE_PRICING", name: "storePricing", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: StorePricingPage
    },
    {
        path: "/sub-sellers", key: "SUB_SELLERS", name: "subSellers", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SubSellersPage
    },
    {
        path: "/search-tasks", key: "SEARCH_TASKS", name: "searchTasks", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
        ],
        element: SearchTasksPage
    },
    {
        path: "/service-config", key: "SERVICE_CONFIG", name: "serviceConfigurations", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
        ],
        element: ServiceConfigPage
    },
    {
        path: "/landing-list", key: "EXTENSION_LANDING_PAGE", name: "preRegisteredPersons", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
        ],
        element: LandingListPage
    },
    // {
    //     path: "/extension", key: "EXTENSION_PRODUCT_SEARCH", name: "ProductSearchPage", pageType: "private", isExtension: true,
    //     element: ProductSearchPage,
    //     children: [
    //         {
    //             path: "/extension/product-search", key: "EXTENSION_PRODUCT_SEARCH", name: "ProductSearchPage", pageType: "private", isExtension: true,
    //             element: ProductSearchPage,
    //             allowedTo: [
    //                 REACT_APP_ROLES_SUPERADMIN,//Süper Admin
    //                             REACT_APP_ROLES_ADMIN,//Admin
    //                 REACT_APP_ROLES_ORDERS,//Orders
    //                 REACT_APP_ROLES_STORE,//Store
    //                 REACT_APP_ROLES_LOGISTICS,//Logistics
    //                 REACT_APP_ROLES_INVENTORY,//Inventory
    //                 REACT_APP_ROLES_REPORT //Report
    //             ]
    //         }
    //     ]
    // },
    {
        path: "/extension", key: "EXTENSION_", name: "extension", pageType: "public", isExtension: true,
        element: ExtensionRegisterPage,
        children: [
            // {
            //     path: "/extension/login", key: "EXTENSION_LOGIN", name: "Login", pageType: "public", isExtension: true,
            //     element: LoginPage
            // },
            {
                path: "/extension/register", key: "EXTENSION_REGISTER", name: "register", pageType: "public", isExtension: true,
                element: ExtensionRegisterPage
            }
        ]
    },
    {
        path: "/smartpricer", key: "SMARTPRICER", name: "smartPricer", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SmartPricerPage
    },
    {
        path: "/smartpricer-options", key: "SMARTPRICER_OPTIONS", name: "smartPricerOptions", pageType: "private", snapshotSmartPricerConditionalStep: "none",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SmartPricerFilterSelectionPage
    },
    {
        path: "/smartpricer-filters", key: "SMARTPRICER_FILTERS", name: "smartPricerFilters", pageType: "private", snapshotSmartPricerConditionalStep: "filterTypeSelectionPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SmartPricerFiltersPage
    },
    {
        path: "/smartpricer-target", key: "SMARTPRICER_TARGET", name: "smartPricerTarget", pageType: "private", snapshotSmartPricerConditionalStep: "smartpricerQuestionsPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SmartPricerTargetPage
    },
    {
        path: "/smartpricer-complete", key: "SMARTPRICER_COMPLETE", name: "smartPricerComplete", pageType: "private", snapshotSmartPricerConditionalStep: "smartpricerTargetPage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SmartPricerCompletePage
    },
    {
        path: "/smartpricer-completed", key: "SMARTPRICER_COMPLETED", name: "smartPricerCompleted", pageType: "private", snapshotSmartPricerConditionalStep: "smartpricerNamePage",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SmartPricerCompletedPage
    },
    {
        path: "/smartpricer-edit", key: "SMARTPRICER_EDIT", name: "smartpricerEdit", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SmartPricerEditPage

    },
    {
        path: "/smartpricer-edit/:id", key: "SMARTPRICER_EDIT", name: "smartpricerEdit", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: SmartPricerEditPage
    },
    {
        path: "/messages", key: "MESSAGES", name: "messages", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: MessagesPage
    },
    {
        path: "/message-detail", key: "MESSAGE_DETAIL", name: "messageDetail", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: MessageDetailPage

    },
    {
        path: "/message-detail/:orderNo/:messageId", key: "MESSAGE_DETAIL", name: "messageDetail", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: MessageDetailPage
    },
    {
        path: "/message-detail/:orderNo", key: "MESSAGE_DETAIL", name: "messageDetail", exact: true, pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: MessageDetailPage
    },
    {
        path: "/forwarding-emails", key: "FORWARDING_EMAILS", name: "forwardingEmails", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: EmailForwardingPage
    },
    {
        path: "/feedback-config", key: "FEEDBACK_CONFIG", name: "feedbackConfigurations", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
        ],
        element: EmailFeedbackConfigPage
    },
    {
        path: "/mywallet", key: "MY_WALLET", name: "myWallet", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: MyWalletPage
    },
    {
        path: "/payment-method", key: "MY_WALLET_PAYMENT_METHOD", name: "paymentMethod", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: PaymentMethodPage
    },
    {
        path: "/card-selection", key: "MY_WALLET_CARD_SELECTION", name: "cardSelection", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: CardSelectionPage
    },
    {
        path: "/payment", key: "MY_WALLET_PAYMENT_PAGE", name: "payment", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_USER//user
        ],
        element: WalletPaymentPage
    },
    {
        path: "/warehouse-home", key: "WAREHOUSE_HOME", name: "warehouseHome", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_STORE,//Store
        ],
        element: WareHouseHomePage
    },
    {
        path: "/warehouse-orders", key: "WAREHOUSE_ORDERS", name: "warehouseOrders", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_STORE,//Store
        ],
        element: WarehouseOrdersPage
    },
    {
        path: "/warehouse-preferences", key: "WAREHOUSE_PREFERENCES", name: "warehousePreferences", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_STORE,//Store
        ],
        element: WarehousePreferencesPage
    },
    {
        path: "/warehouse-lost-packages", key: "WAREHOUSE_LOST_PACKAGES", name: "warehouseLostPackages", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
            REACT_APP_ROLES_ADMIN,//Admin
            REACT_APP_ROLES_SUSPENDED_ADMIN,//suspended
            REACT_APP_ROLES_STORE,//Store
            REACT_APP_ROLES_ORDERS//Orders
        ],
        element: WarehouseLostPackagesPage
    },
    {
        path: "/create-packages", key: "PACKAGES", name: "packages", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
        ],
        element: CreatePackagesPage
    }
    ,
    {
        path: "/create-coupons", key: "PACKAGES", name: "packages", pageType: "private",
        allowedTo: [
            REACT_APP_ROLES_SUPERADMIN,//Süper Admin
        ],
        element: CreateCouponsPage
    }
];
export default ROUTES;

function isPublic(element, index, array) {
    return (element.pageType === "public");
}

function isPrivate(element, index, array) {
    return (element.pageType === "private");
}

function isExtensionPage(element, index, array) {
    return (element.isExtension === true);
}

export function isPathPrivate(path) {
    var route = ROUTES.find(r => r.path === SplitPath(path));
    if (route) {
        if (route.pageType === "private") {
            return true;
        }
    }
    return false;
}

export function isPathPublic(path) {
    var route = ROUTES.find(r => r.path === SplitPath(path));
    if (route) {
        if (route.pageType === "public") {
            return true;
        }
    }
    return false;
}


// function RouteWithSubRoutes(route) {
//     return (
//         <Route
//             path={route.path}
//             exact={route.exact}
//             render={(props) => (
//                 <SecureRoute allowedTo={route.allowedTo}>
//                     <route.element {...props} routes={route.routes} />
//                 </SecureRoute>
//             )}
//         />
//     );
// }

export function RenderRoutes() {
    const createPackagesPageSubmissions = useSelector(state => state.createPackagesSnapshot.pageSubmissions);
    const createStorePageSubmissions = useSelector(state => state.createStoreSnapshot.pageSubmissions);
    const smartPricerPageSubmissions = useSelector(state => state.smartPricerSnapshot.pageSubmissions);

    const isLoggedin = useSelector(state => state.customer?.isLoggedin);
    const isExtension = useSelector(state => state.customer?.isExtension);

    const location = useLocation();
    const hasLocation = getRouteByPath(location.pathname);
    const [routerComponents, setRouterComponents] = useState([]);

    useEffect(() => {

        var routes = isExtension ? ROUTES.filter(isExtensionPage) : ROUTES;
        routes = isLoggedin ? routes.filter(isPrivate) : routes.filter(isPublic);

        var components = routes.map((route) => {
            if (!isLoggedin) {
                if (hasLocation) {
                    // if (route.snapshotConditionalStep && createCustomerPageSubmissions) {
                    //     if ((route.snapshotConditionalStep === "none" || (createCustomerPageSubmissions[route.snapshotConditionalStep]))) {
                    //         return <Route path={route.path} key={route.key} name={route.name} exact={route.exact} element={<route.element {...route.props} children={route.children}></route.element>} />
                    //         //return <RouteWithSubRoutes key={route.key} {...route} />;
                    //         // return <Route path={route.path} key={route.key} name={route.name} exact={route.exact} element={() => { <route.element {...route} /> }} />
                    //     }
                    // }
                    // else if (!route.snapshotConditionalStep) {
                    //     return <Route path={route.path} key={route.key} name={route.name} element={<route.element {...route.props} children={route.children}></route.element>} >

                    //         {route.children &&
                    //             route.children.map((subRoute) => {
                    //                 return <Route path={subRoute.path} key={subRoute.key} name={subRoute.name}
                    //                     element={<route.element {...subRoute.props} children={subRoute.children} />}
                    //                 >
                    //                 </Route>
                    //             })}
                    //     </Route>
                    // }
                    // <Route path={route.path} key={route.key} name={route.name} element={<route.element {...route.props} children={route.children}></route.element>} >

                    //     {route.children &&
                    //         route.children.map((subRoute) => {
                    //             return <Route path={subRoute.path} key={subRoute.key} name={subRoute.name}
                    //                 element={<route.element {...subRoute.props} children={subRoute.children} />}
                    //             >
                    //             </Route>
                    //         })}
                    // </Route>
                    return <Route
                        path={route.path} key={route.key} name={route.name} exact={route.exact}
                        element={<route.element {...route.props} children={route.children} />}
                    >
                        {route.children &&
                            route.children.map((subRoute) => {
                                return <Route
                                    path={subRoute.path} key={subRoute.key} name={subRoute.name} exact={subRoute.exact}
                                    element={<route.element {...subRoute.props} children={subRoute.children} />}
                                >
                                </Route>
                            })}

                    </Route>
                }
                else {
                    return <Route path="/error" key="ERROR" element={() => <ErrorPage key="ERROR" customError={ERRORS.pageNotFoundError} />} />
                }
                // else {
                //     if (isExtension) {
                //         return <Route path="/login" key="LOGIN" element={() => <LoginPage />} />
                //     }
                //     else {
                //         return <Route path="/introduction" key="INTRODUCTION" element={() => <IntroductionPage />} />
                //     }
                // }
            }
            else {
                if (hasLocation) {
                    if (route.snapshotCreateStoreConditionalStep && createStorePageSubmissions) {
                        if ((route.snapshotCreateStoreConditionalStep === "none" || (createStorePageSubmissions[route.snapshotCreateStoreConditionalStep]))) {
                            //return <Route path={route.path} key={route.key} name={route.name} exact={route.exact} element={<route.element {...route.props} routes={route.routes}></route.element>} />
                            //return <RouteWithSubRoutes key={route.key} {...route} />;
                            return <Route
                                path={route.path} key={route.key} name={route.name} exact={route.exact}
                                // render={(props) => (
                                //     <SecureRoute allowedTo={route.allowedTo}>
                                //         <route.element {...props} routes={route.routes} />
                                //     </SecureRoute>
                                // )}
                                element={<SecureRoute allowedTo={route.allowedTo}>
                                    <route.element {...route.props} children={route.children} />
                                </SecureRoute>}
                            >
                                {route.children &&
                                    route.children.map((subRoute) => {
                                        return <Route
                                            path={subRoute.path} key={subRoute.key} name={subRoute.name} exact={subRoute.exact}
                                            element={<SecureRoute allowedTo={subRoute.allowedTo}>
                                                <route.element {...subRoute.props} children={subRoute.children} />
                                            </SecureRoute>}
                                        >
                                        </Route>
                                    })}

                            </Route>
                        }
                    }
                    else if (route.snapshotSmartPricerConditionalStep && smartPricerPageSubmissions) {
                        if ((route.snapshotSmartPricerConditionalStep === "none" || (smartPricerPageSubmissions[route.snapshotSmartPricerConditionalStep]))) {
                            //return <Route path={route.path} key={route.key} name={route.name} exact={route.exact} element={<route.element {...route.props} routes={route.routes}></route.element>} />
                            //return <RouteWithSubRoutes key={route.key} {...route} />;
                            return <Route
                                path={route.path} key={route.key} name={route.name} exact={route.exact}
                                // render={(props) => (
                                //     <SecureRoute allowedTo={route.allowedTo}>
                                //         <route.element {...props} routes={route.routes} />
                                //     </SecureRoute>
                                // )}
                                element={<SecureRoute allowedTo={route.allowedTo}>
                                    <route.element {...route.props} children={route.children} />
                                </SecureRoute>}
                            >
                                {route.children &&
                                    route.children.map((subRoute) => {
                                        return <Route
                                            path={subRoute.path} key={subRoute.key} name={subRoute.name} exact={subRoute.exact}
                                            element={<SecureRoute allowedTo={subRoute.allowedTo}>
                                                <route.element {...subRoute.props} children={subRoute.children} />
                                            </SecureRoute>}
                                        >
                                        </Route>
                                    })}

                            </Route>
                        }
                    }
                    else if (!route.snapshotCreateStoreConditionalStep || !route.snapshotSmartPricerConditionalStep || !route.snapshotPackagesConditionalStep) {
                        //return <Route path={route.path} key={route.key} name={route.name} exact={route.exact} element={<route.element {...route.props} routes={route.routes}></route.element>} />
                        // return <Route
                        //     path={route.path} key={route.key} name={route.name} exact={route.exact}
                        //     element={<SecureRoute allowedTo={route.allowedTo}>
                        //         <route.element {...route.props} routes={route.routes} />
                        //     </SecureRoute>}

                        // />
                        // var rs = GetRoutes(route);
                        // return rs;
                        return <Route
                            path={route.path} key={route.key} name={route.name} exact={route.exact}
                            // render={(props) => (
                            //     <SecureRoute allowedTo={route.allowedTo}>
                            //         <route.element {...props} routes={route.routes} />
                            //     </SecureRoute>
                            // )}
                            element={<SecureRoute allowedTo={route.allowedTo}>
                                <route.element {...route.props} children={route.children} />
                            </SecureRoute>}
                        >
                            {route.children &&
                                route.children.map((subRoute) => {
                                    return <Route
                                        path={subRoute.path} key={subRoute.key} name={subRoute.name} exact={subRoute.exact}
                                        element={<SecureRoute allowedTo={subRoute.allowedTo}>
                                            <route.element {...subRoute.props} children={subRoute.children} />
                                        </SecureRoute>}
                                    >
                                    </Route>
                                })}

                        </Route>
                    }
                }
                else {
                    return <Route path="/error" key="ERROR" element={() => <ErrorPage key="ERROR" customError={ERRORS.pageNotFoundError} />} />
                }

            }
            // return <Route path="/error" key="ERROR" element={() => <ErrorPage key="ERROR" customError={ERRORS.unexpectedError} />} />
        });

        setRouterComponents(components);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createPackagesPageSubmissions, createStorePageSubmissions, smartPricerPageSubmissions, isLoggedin, isExtension, hasLocation]);

    return (
        <>
            {routerComponents && <Routes>
                {routerComponents}
            </Routes>}
        </>
    );
}

export function getRouteByKey(key) {
    var route = ROUTES.find(r => r.key === key);
    if (route) {
        return route.path;
    }
    return "/";
}

export function getRouteByPath(path) {
    var route = ROUTES.find(r => r.path === path);

    if (!route) {
        route = ROUTES.find(r => r.path === SplitPath(path));
    }

    if (route) {
        return route;
    }
    return null;
}

export function IsIntroductionPage(path) {
    var route = ROUTES.find(r => r.path === SplitPath(path));
    if (route) {
        if (route.key === "INTRODUCTION") {
            return true;
        }
    }
    return false;
}

export function IsPrivacyAnPolicyPage(path) {
    var route = ROUTES.find(r => r.path === SplitPath(path));
    if (route) {
        if (route.key === "PRIVACIYANDPOLICY") {
            return true;
        }
    }
    return false;
}

export function IsLoginRegisterPage(path) {
    var route = ROUTES.find(r => r.path === SplitPath(path));
    if (route) {
        if (route.key === "LOGIN") {
            return true;
        }
    }
    return false;
}

export function IsProductComparePage(path) {
    var route = ROUTES.find(r => r.path === SplitPath(path));
    if (route) {
        if (route.key === "PRODUCT_COMPARE") {
            return true;
        }
    }
    return false;
}

export function IsMarketCreateStep(path) {
    if (path === "/market-selection" || path === "/store-preferences" || path === "/warehouse-selection" || path === "/connect-amazon") {
        var route = ROUTES.find(r => r.path === SplitPath(path));
        if (route) {
            return true;
        }
    }
    return false;
}

export function IsSmartPricerCreateStep(path) {
    if (path === "/smartpricer-filters" || path === "/smartpricer-target" || path === "/smartpricer-complete") {
        var route = ROUTES.find(r => r.path === SplitPath(path));
        if (route) {
            return true;
        }
    }
    return false;
}

export function IsErrorPage(path) {
    var route = ROUTES.find(r => r.path === SplitPath(path));
    if (route) {
        if (route.key === "ERROR") {
            return true;
        }
    }
    return false;
}

export function IsValidPage(path) {
    var route = ROUTES.find(r => r.path === SplitPath(path));
    if (route) {
        return true;
    }
    return false;
}

function SplitPath(path) {
    var splitted = path.split("/");
    if (splitted && splitted.length > 1) {
        return '/' + splitted[1];
    }
    else
        return path;
}