import '../../../i18n';
import { useTranslation } from 'react-i18next';
import langTR from '../../../locales/tr/Components/dr-datatable.json'
import langEN from '../../../locales/en/Components/dr-datatable.json'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { pageFilterTypes } from '../../../utils/constants';
import i18next from 'i18next';

export var timeOptions = [];
export var months = [];
export var handlingTimes = [];
export var maxDeliveryDays15to30 = [];
export var maxDeliveryDaysAll = [];
export var autoDeleteIntervals = [];
export var currencyConversionTypes = [];
export var filterValueOptions = [];
export var orderStatusOptions = [];
export var sellerOrderStatusOptions = [];
export var buyerOrderStatusOptions = [];
export var inventoryPageOptions = [];
export var dataTableLang = {};
export var sourceMarketOptions = [];
export var flaggedMarketOptions = [];
export var flaggedCountryOptions = [];
export var searchTasksSearchTypeOptions = [];
export var searchTasksStatusOptions = [];
export var filterBuyboxOrLowestOptions = [];
export var smartPricerPageOptions = [];
export var selectedRowsOperationOptions = [];
export var defaultMailTemplatesBodyTextOptions = [];
export var errorMessages = [];
export var infoMessages = [];
export var transactionSummaryTypeOptions = [];
export var detailedFilterYesNoOptions = [];
export var detailedFilterMinMaxOptions = [];

export var moreOrLessOptions = [];
export var minOrMaxOptions = [];
export var autoReprizeMinutesIntervals = [];
export var operationsOptions = [];

export var notificationMessageTypesOptions = [];

export var flagOptions = [];
export var trademarkOfficeOptions = [];

function DrConstants() {
    const { t } = useTranslation('constants');
    const language = useSelector(state => state.settings.language);
    const allMarkets = useSelector(state => state.markets.allMarkets);

    useEffect(() => {
        dataTableLang = language === "tr" ? { ...langTR } : { ...langEN }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    timeOptions = [
        { value: "0", label: t('timeKeys.today') },
        { value: "1", label: t('timeKeys.yesterday') },
        { value: "7", label: t('timeKeys.lastXDays', { dayNumber: "7" }) },
        { value: "15", label: t('timeKeys.lastXDays', { dayNumber: "15" }) },
        { value: "30", label: t('timeKeys.lastXDays', { dayNumber: "30" }) },
        { value: "90", label: t('timeKeys.lastXDays', { dayNumber: "90" }) },
        { value: "1000", label: t('timeKeys.alltimes') },
    ]

    months = [
        { value: 1, label: t('months.january'), labelShort: t('monthsShort.january') },
        { value: 2, label: t('months.february'), labelShort: t('monthsShort.february') },
        { value: 3, label: t('months.march'), labelShort: t('monthsShort.march') },
        { value: 4, label: t('months.april'), labelShort: t('monthsShort.april') },
        { value: 5, label: t('months.may'), labelShort: t('monthsShort.may') },
        { value: 6, label: t('months.june'), labelShort: t('monthsShort.june') },
        { value: 7, label: t('months.july'), labelShort: t('monthsShort.july') },
        { value: 8, label: t('months.august'), labelShort: t('monthsShort.august') },
        { value: 9, label: t('months.september'), labelShort: t('monthsShort.september') },
        { value: 10, label: t('months.october'), labelShort: t('monthsShort.october') },
        { value: 11, label: t('months.november'), labelShort: t('monthsShort.november') },
        { value: 12, label: t('months.december'), labelShort: t('monthsShort.december') },
    ]

    handlingTimes = [
        { value: 1, label: t('timeKeys.XDays', { dayNumber: "1" }) },
        { value: 2, label: t('timeKeys.XDays', { dayNumber: "2" }) },
        { value: 3, label: t('timeKeys.XDays', { dayNumber: "3" }) },
        { value: 4, label: t('timeKeys.XDays', { dayNumber: "4" }) },
        { value: 5, label: t('timeKeys.XDays', { dayNumber: "5" }) },
        { value: 6, label: t('timeKeys.XDays', { dayNumber: "6" }) },
        { value: 7, label: t('timeKeys.XDays', { dayNumber: "7" }) },
        { value: 8, label: t('timeKeys.XDays', { dayNumber: "8" }) },
        { value: 9, label: t('timeKeys.XDays', { dayNumber: "9" }) },
        { value: 10, label: t('timeKeys.XDays', { dayNumber: "10" }) },
        { value: 11, label: t('timeKeys.XDays', { dayNumber: "11" }) },
        { value: 12, label: t('timeKeys.XDays', { dayNumber: "12" }) },
        { value: 13, label: t('timeKeys.XDays', { dayNumber: "13" }) },
        { value: 14, label: t('timeKeys.XDays', { dayNumber: "14" }) },
        { value: 15, label: t('timeKeys.XDays', { dayNumber: "15" }) }
    ]

    maxDeliveryDays15to30 = [
        { value: 15, label: t('timeKeys.XDays', { dayNumber: "15" }) },
        { value: 16, label: t('timeKeys.XDays', { dayNumber: "16" }) },
        { value: 17, label: t('timeKeys.XDays', { dayNumber: "17" }) },
        { value: 18, label: t('timeKeys.XDays', { dayNumber: "18" }) },
        { value: 19, label: t('timeKeys.XDays', { dayNumber: "19" }) },
        { value: 20, label: t('timeKeys.XDays', { dayNumber: "20" }) },
        { value: 21, label: t('timeKeys.XDays', { dayNumber: "21" }) },
        { value: 22, label: t('timeKeys.XDays', { dayNumber: "22" }) },
        { value: 23, label: t('timeKeys.XDays', { dayNumber: "23" }) },
        { value: 24, label: t('timeKeys.XDays', { dayNumber: "24" }) },
        { value: 25, label: t('timeKeys.XDays', { dayNumber: "25" }) },
        { value: 26, label: t('timeKeys.XDays', { dayNumber: "26" }) },
        { value: 27, label: t('timeKeys.XDays', { dayNumber: "27" }) },
        { value: 28, label: t('timeKeys.XDays', { dayNumber: "28" }) },
        { value: 29, label: t('timeKeys.XDays', { dayNumber: "29" }) },
        { value: 30, label: t('timeKeys.XDays', { dayNumber: "30" }) },
    ]

    maxDeliveryDaysAll = [
        { value: 1, label: t('timeKeys.XDays', { dayNumber: "1" }) },
        { value: 2, label: t('timeKeys.XDays', { dayNumber: "2" }) },
        { value: 3, label: t('timeKeys.XDays', { dayNumber: "3" }) },
        { value: 4, label: t('timeKeys.XDays', { dayNumber: "4" }) },
        { value: 5, label: t('timeKeys.XDays', { dayNumber: "5" }) },
        { value: 6, label: t('timeKeys.XDays', { dayNumber: "6" }) },
        { value: 7, label: t('timeKeys.XDays', { dayNumber: "7" }) },
        { value: 8, label: t('timeKeys.XDays', { dayNumber: "8" }) },
        { value: 9, label: t('timeKeys.XDays', { dayNumber: "9" }) },
        { value: 10, label: t('timeKeys.XDays', { dayNumber: "10" }) },
        { value: 11, label: t('timeKeys.XDays', { dayNumber: "11" }) },
        { value: 12, label: t('timeKeys.XDays', { dayNumber: "12" }) },
        { value: 13, label: t('timeKeys.XDays', { dayNumber: "13" }) },
        { value: 14, label: t('timeKeys.XDays', { dayNumber: "14" }) },
        { value: 15, label: t('timeKeys.XDays', { dayNumber: "15" }) },
        { value: 16, label: t('timeKeys.XDays', { dayNumber: "16" }) },
        { value: 17, label: t('timeKeys.XDays', { dayNumber: "17" }) },
        { value: 18, label: t('timeKeys.XDays', { dayNumber: "18" }) },
        { value: 19, label: t('timeKeys.XDays', { dayNumber: "19" }) },
        { value: 20, label: t('timeKeys.XDays', { dayNumber: "20" }) },
        { value: 21, label: t('timeKeys.XDays', { dayNumber: "21" }) },
        { value: 22, label: t('timeKeys.XDays', { dayNumber: "22" }) },
        { value: 23, label: t('timeKeys.XDays', { dayNumber: "23" }) },
        { value: 24, label: t('timeKeys.XDays', { dayNumber: "24" }) },
        { value: 25, label: t('timeKeys.XDays', { dayNumber: "25" }) },
        { value: 26, label: t('timeKeys.XDays', { dayNumber: "26" }) },
        { value: 27, label: t('timeKeys.XDays', { dayNumber: "27" }) },
        { value: 28, label: t('timeKeys.XDays', { dayNumber: "28" }) },
        { value: 29, label: t('timeKeys.XDays', { dayNumber: "29" }) },
        { value: 30, label: t('timeKeys.XDays', { dayNumber: "30" }) },
    ]

    autoDeleteIntervals = [
        { value: 5, label: t('timeKeys.XHours', { dayNumber: "5" }) },
        { value: 6, label: t('timeKeys.XHours', { dayNumber: "6" }) },
        { value: 12, label: t('timeKeys.XHours', { dayNumber: "12" }) },
        { value: 24, label: t('timeKeys.XHours', { dayNumber: "24" }) },
        { value: 36, label: t('timeKeys.XHours', { dayNumber: "36" }) },
        { value: 48, label: t('timeKeys.XHours', { dayNumber: "48" }) }

    ]

    currencyConversionTypes = [
        { value: 1, label: t('currencyConversionTypes.auto') },
        { value: 2, label: t('currencyConversionTypes.fix') }
    ]

    filterValueOptions = [
        { value: 0, label: t('filterValueOptions.disabled') },
        { value: 1, label: t('filterValueOptions.remove') },
        { value: 2, label: t('filterValueOptions.outOfStock') },
    ]

    filterBuyboxOrLowestOptions = [
        { value: 0, label: t('filterBuyboxOrLowestOptions.lowest') },
        { value: 1, label: t('filterBuyboxOrLowestOptions.buyBox') }
    ]

    orderStatusOptions = [
        { value: 18, label: t('orderStatusOptions.all') },
        { value: 2, label: t('orderStatusOptions.unShipped') },
        { value: 10, label: t('orderStatusOptions.confirmed') },
        { value: 11, label: t('orderStatusOptions.delivered') },
        { value: 3, label: t('orderStatusOptions.partiallyShipped') },
        { value: 4, label: t('orderStatusOptions.shipped') },
        { value: 5, label: t('orderStatusOptions.invoiceUnconfirmed') },
        { value: 6, label: t('orderStatusOptions.canceled') },
        { value: 7, label: t('orderStatusOptions.unFulfillable') },
    ]

    buyerOrderStatusOptions = [
        { value: 13, label: t('orderStatusOptions.sold') },
        { value: 14, label: t('orderStatusOptions.placed') },
        { value: 15, label: t('orderStatusOptions.goingToWarehouse') },
        { value: 16, label: t('orderStatusOptions.deliveredToWarehouse') },
        { value: 17, label: t('orderStatusOptions.sentFromWarehouse') }
    ]

    sellerOrderStatusOptions = [
        { value: 2, label: t('orderStatusOptions.unShipped') },
        { value: 9, label: t('orderStatusOptions.preConfirmed') },
        { value: 10, label: t('orderStatusOptions.confirmed') },
        { value: 11, label: t('orderStatusOptions.delivered') },
        { value: 6, label: t('orderStatusOptions.canceled') },
        { value: 12, label: t('orderStatusOptions.returningToSeller') },
        { value: 7, label: t('orderStatusOptions.refunded') },
        { value: 19, label: t('orderStatusOptions.refundRequested') },
        { value: 20, label: t('orderStatusOptions.returnCompleted') },
        { value: 21, label: t('orderStatusOptions.packageMaybeLost') },
        { value: 22, label: t('orderStatusOptions.checkDeliveryInfo') },
    ]

    inventoryPageOptions = [
        { value: 4, label: t('inventoryPageOptions.transferredToStore') },
        { value: 1, label: t('inventoryPageOptions.processing') },
        { value: 0, label: t('inventoryPageOptions.queued') },

        { value: 2, label: t('inventoryPageOptions.notMeetingCriteria') },
        { value: 5, label: t('inventoryPageOptions.amazonPermission') },
        { value: 3, label: t('inventoryPageOptions.pendingApprovement') },
        // { value: -1, label: t('inventoryPageOptions.deleted'), isCritical: true }

    ];

    sourceMarketOptions = allMarkets && allMarkets.length > 0 ? allMarkets.map(x => ({ value: x.id, label: t(('constants:countries.' + x.countryCode)), icon: <i className={'w-6 me-2 flag flag-' + (x.countryCode === 'UK' ? 'gb' : x.countryCode.toLowerCase())} />, countryCode: x.countryCode, isDisabled: x.countryCode !== "US" })) : [];

    flaggedMarketOptions = allMarkets.map(x => ({ value: x.id, label: t(('constants:countries.' + x.countryCode)), icon: <i className={'w-6 me-2 flag flag-' + (x.countryCode === 'UK' ? 'gb' : x.countryCode.toLowerCase())} /> }));
    // flaggedMarketOptions.splice(0, 0, { value: "-1", label: i18next.t(('constants:countries.all')), icon: null });

    flaggedCountryOptions = allMarkets && allMarkets.length > 0 ? allMarkets.map(x => ({ value: x.countryCode, label: t(('constants:countries.' + x.countryCode)), icon: <i className={'w-6 me-2 flag flag-' + (x.countryCode === 'UK' ? 'gb' : x.countryCode.toLowerCase())} /> })) : [];

    searchTasksStatusOptions = [
        { value: "-1", label: t('searchTasksSearchTypeOptions.all') },
        { value: "0", label: t('searchTasksStatusOptions.pending') },
        { value: "1", label: t('searchTasksStatusOptions.running') },
        { value: "2", label: t('searchTasksStatusOptions.finished') },
        { value: "3", label: t('searchTasksStatusOptions.stuckAtCaptcha') },
        { value: "4", label: t('searchTasksStatusOptions.failed') }

    ];

    searchTasksSearchTypeOptions = [
        { value: "-1", label: t('searchTasksSearchTypeOptions.all') },
        { value: "0", label: t('searchTasksSearchTypeOptions.bySearchAsinsForDetail') },
        { value: "1", label: t('searchTasksSearchTypeOptions.bySearchAsinsForReprize') }
        // { value: "2", label: t('searchTasksSearchTypeOptions.forSellerDetail') },
        // { value: "3", label: t('searchTasksSearchTypeOptions.forSellerProducts') }

    ];

    smartPricerPageOptions = [
        { value: "-1", label: t('smartPricerPageOptions.all') },
        { value: "0", label: t('smartPricerPageOptions.defaultStrategies') },
        { value: "1", label: t('smartPricerPageOptions.targetCategoryStrategies') },
        { value: "2", label: t('smartPricerPageOptions.targetAsinsStrategies') }
    ];

    selectedRowsOperationOptions = [
        { value: "0", label: t('selectedRowsOperationOptions.exportToExcell'), sortOrder: 0, pagetype: pageFilterTypes.all },
        { value: "1", label: t('selectedRowsOperationOptions.exportToPDF'), sortOrder: 1, pagetype: pageFilterTypes.all },
        { value: "2", label: t('selectedRowsOperationOptions.compareSelectedProducts'), sortOrder: 2, pagetype: pageFilterTypes.inventory },
        { value: "3", label: t('selectedRowsOperationOptions.deleteProducts'), sortOrder: 4, pagetype: pageFilterTypes.inventory, isCritical: true },
        { value: "4", label: t('selectedRowsOperationOptions.resetTasks'), sortOrder: 5, pagetype: pageFilterTypes.searchTasks, isCritical: true },
        { value: "5", label: t('selectedRowsOperationOptions.approveSelectedProducts'), sortOrder: 3, pagetype: pageFilterTypes.inventory },
        { value: "6", label: t('selectedRowsOperationOptions.deleteTasks'), sortOrder: 6, pagetype: pageFilterTypes.searchTasks, isCritical: true },
        { value: "7", label: t('selectedRowsOperationOptions.unBlockAsins'), sortOrder: 7, pagetype: pageFilterTypes.blockedAsins, isCritical: true },
        { value: "8", label: t('selectedRowsOperationOptions.blockAsins'), sortOrder: 8, pagetype: pageFilterTypes.inventory, isCritical: true },
        { value: "9", label: t('selectedRowsOperationOptions.nonPrizeAsins'), sortOrder: 9, pagetype: pageFilterTypes.inventory, isCritical: true },
        { value: "10", label: t('selectedRowsOperationOptions.deleteNonPrizeAsins'), sortOrder: 10, pagetype: pageFilterTypes.nonPrizeAsins, isCritical: true },
        { value: "11", label: t('selectedRowsOperationOptions.addProductToInventory'), sortOrder: 11, pagetype: pageFilterTypes.productVariations },
        { value: "12", label: t('selectedRowsOperationOptions.addToBlockedBrands'), sortOrder: 12, pagetype: pageFilterTypes.commonBrandPool, isCritical: true },
        { value: "13", label: t('selectedRowsOperationOptions.addToBlockedBrands'), sortOrder: 13, pagetype: pageFilterTypes.commonProductPool, isCritical: true },
        { value: "14", label: t('selectedRowsOperationOptions.addToBlockedProducts'), sortOrder: 14, pagetype: pageFilterTypes.commonProductPool, isCritical: true },
        { value: "15", label: t('selectedRowsOperationOptions.addToBothBlocked'), sortOrder: 15, pagetype: pageFilterTypes.commonProductPool, isCritical: true },
        { value: "16", label: t('selectedRowsOperationOptions.addBrandsBlocked'), sortOrder: 16, pagetype: pageFilterTypes.brands, isCritical: true },
        { value: "17", label: t('selectedRowsOperationOptions.addBrandsUnBlocked'), sortOrder: 17, pagetype: pageFilterTypes.brands, isCritical: true },
        { value: "18", label: t('selectedRowsOperationOptions.addCategoriesBlocked'), sortOrder: 18, pagetype: pageFilterTypes.categories, isCritical: true },
        { value: "19", label: t('selectedRowsOperationOptions.addCategoriesUnBlocked'), sortOrder: 19, pagetype: pageFilterTypes.categories, isCritical: true }

    ];

    defaultMailTemplatesBodyTextOptions = [
        { value: t('defaultMailTemplates.emptyTemplate.value'), label: t('defaultMailTemplates.emptyTemplate.label') },
        { value: t('defaultMailTemplates.initialTemplate.value'), label: t('defaultMailTemplates.initialTemplate.label') },
        { value: t('defaultMailTemplates.shippingInfoTemplate.value'), label: t('defaultMailTemplates.shippingInfoTemplate.label') },
    ];

    transactionSummaryTypeOptions = [
        { value: "0", label: t('transactionSummaryTypeOptions.all') },
        { value: "1", label: t('transactionSummaryTypeOptions.income') },
        { value: "2", label: t('transactionSummaryTypeOptions.outgoing') }
    ];

    detailedFilterYesNoOptions = [
        { value: "0", label: t('detailedFilterYesNoOptions.disabled') },
        { value: "1", label: t('detailedFilterYesNoOptions.yes') },
        { value: "2", label: t('detailedFilterYesNoOptions.no') },
    ];

    detailedFilterMinMaxOptions = [
        { value: "0", label: t('detailedFilterMinMaxOptions.disabled') },
        { value: "1", label: t('detailedFilterMinMaxOptions.min') },
        { value: "2", label: t('detailedFilterMinMaxOptions.max') },
    ]

    moreOrLessOptions = [
        { value: ">", label: t('moreOrLessOptions.moreThan') },
        { value: "<", label: t('moreOrLessOptions.lessThan') }
    ]

    minOrMaxOptions = [
        { value: "min", label: t('minOrMaxOptions.min') },
        { value: "max", label: t('minOrMaxOptions.max') }
    ]

    autoReprizeMinutesIntervals = [
        { value: "15", label: t('autoReprizeMinutesIntervals.everyFifteen') },
        { value: "30", label: t('autoReprizeMinutesIntervals.everyThirty') },
        { value: "60", label: t('autoReprizeMinutesIntervals.everyHour') },
        { value: "180", label: t('autoReprizeMinutesIntervals.everyTwoHours') },
        { value: "240", label: t('autoReprizeMinutesIntervals.everyFourHours') },
        { value: "480", label: t('autoReprizeMinutesIntervals.everyEightHours') },
        { value: "960", label: t('autoReprizeMinutesIntervals.everySixteenHours') },
        { value: "1920", label: t('autoReprizeMinutesIntervals.everyDay') }

    ]
    operationsOptions = [
        { value: "", label: t('detailedFilterYesNoOptions.disabled') },
        { value: "<", label: " < " },
        { value: "<=", label: " <= " },
        { value: "=", label: " = " },
        { value: ">=", label: " >= " },
        { value: ">", label: " > " },
    ]

    notificationMessageTypesOptions = [
        { value: "confirmCustomizationDetails", label: t('notificationMessageTypesOptions.confirmCustomizationDetails') },
        { value: "confirmDeliveryDetails", label: t('notificationMessageTypesOptions.confirmDeliveryDetails') },
        { value: "legalDisclose", label: t('notificationMessageTypesOptions.legalDisclose') },
        // { value: "negativeFeedbackRemoval", label: t('notificationMessageTypesOptions.negativeFeedbackRemoval') },
        { value: "confirmOrderDetails", label: t('notificationMessageTypesOptions.confirmOrderDetails') },
        { value: "confirmServiceDetails", label: t('notificationMessageTypesOptions.confirmServiceDetails') },
        { value: "amazonMotors", label: t('notificationMessageTypesOptions.amazonMotors') },
        { value: "warranty", label: t('notificationMessageTypesOptions.warranty') },
        { value: "attributes", label: t('notificationMessageTypesOptions.attributes') },
        { value: "digitalAccessKey", label: t('notificationMessageTypesOptions.digitalAccessKey') },
        { value: "unexpectedProblem", label: t('notificationMessageTypesOptions.unexpectedProblem') },
        { value: "sendInvoice", label: t('notificationMessageTypesOptions.sendInvoice') }
    ];

    flagOptions = [
        { value: 0, label: "Bayrak Seçiniz", },
        { value: 1, label2: "Red", icon: <i className="bx bxs-flag fs-16" style={{ color: '#df1111' }} /> },
        { value: 2, label2: "Green", icon: <i className="bx bxs-flag fs-16" style={{ color: '#02aa02' }} /> },
        { value: 3, label2: "Blue", icon: <i className="bx bxs-flag fs-16" style={{ color: '#0a8cc9' }} /> },
        { value: 4, label2: "Indigo", icon: <i className="bx bxs-flag fs-16" style={{ color: '#005881' }} /> },
        { value: 5, label2: "Orange", icon: <i className="bx bxs-flag fs-16" style={{ color: '#e68a00' }} /> },
        { value: 6, label2: "Yellow", icon: <i className="bx bxs-flag fs-16" style={{ color: '#e2e600' }} /> },
        { value: 7, label2: "Violet", icon: <i className="bx bxs-flag fs-16" style={{ color: '#9200e6' }} /> },
        { value: 8, label2: "Grey", icon: <i className="bx bxs-flag fs-16" style={{ color: '#7c7c7c' }} /> },
        { value: 9, label2: "Maroon", icon: <i className="bx bxs-flag fs-16" style={{ color: '#a50505' }} /> },
        { value: 10, label2: "Black", icon: <i className="bx bxs-flag fs-16" style={{ color: '#000000' }} /> },
        { value: 11, label2: "Olive", icon: <i className="bx bxs-flag fs-16" style={{ color: '#75793c' }} /> },
        { value: 12, label2: "Cyan", icon: <i className="bx bxs-flag fs-16" style={{ color: '#4cffb5' }} /> },
        { value: 13, label2: "Pink", icon: <i className="bx bxs-flag fs-16" style={{ color: '#ff04de' }} /> },
        { value: 14, label2: "Magenta", icon: <i className="bx bxs-flag fs-16" style={{ color: '#e355ff' }} /> },
        { value: 15, label2: "Tan", icon: <i className="bx bxs-flag fs-16" style={{ color: '#f0baba' }} /> },
        { value: 16, label2: "Teal", icon: <i className="bx bxs-flag fs-16" style={{ color: '#039c95' }} /> }
    ]


    trademarkOfficeOptions = [
        { value: "USA", label: t(('constants:countries.US')), icon: <i className={'w-6 me-2 flag flag-us'} /> },
    ]

}

export default DrConstants